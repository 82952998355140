import React, { Component } from 'react';
import vendorService from '../../services/vendorService';
import moment from 'moment';
import NumberFormat from 'react-number-format';

export class VendorScorePage extends Component {
    constructor(props) {
        super(props);

        var months = [];

        for (var ii = 0; ii < 12; ii++)
            months.push(moment().subtract(ii, 'months').startOf('month'));

        this.state = {
            loading: false,
            vendors: [],
            onSolve: {},
            months: months,
            month: months[1]
        };
    }

    async loadData(month) {
        var [vendors] = await Promise.all([
            vendorService.getScorcard(month.format(), moment(month).endOf('month').format())
        ]);

        var index = vendors.findIndex(v => v.id === 1); // onSolve

        var onSolve = vendors.splice(index, 1)[0];

        this.setState({ vendors: vendors, onSolve: onSolve, loading: false });
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.loadData(this.state.month);
    }

    monthSelected(e) {
        this.setState({ loading: true, 'month': moment(e.target.value) })
        this.loadData(moment(e.target.value));
    }

    renderRow(label, key, render) {
        return <tr key={`score-card-row-${key}`}>
            <td>{label}</td>
            {this.state.vendors.map(v => <td key={`score-card-row-${key}-${v.id}`}>{render(v)}</td>)}
            <td key={`score-card-row-${key}-${this.state.onSolve.id}`}>{render(this.state.onSolve)}</td>
        </tr>;
    }

    render() {
        return (
            <section className="score-card">
                <h1 id="tabelLabel">Vendor Score Card</h1>
                <form className="form-inline">
                    <label>Month</label>

                    <select type="text" className="form-control" placeholder="First name" value={this.state.month.format('l')} onChange={this.monthSelected.bind(this)} >
                        {this.state.months.map(m => <option key={m.format('l')} value={m.format('l')}>{m.format('l')}</option>)}
                    </select>
                </form>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Vendor</th>
                                {this.state.vendors.map(v => <th key={`score-card-vendor-name-${v.id}`}>{v.name}</th>)}
                                <th>OnSolve</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRow("Head Count", 'head-count', v => v.headCount)}
                            {this.renderRow("Contributor Count", 'contributor-count', v => v.contributorCount)}
                            {this.renderRow("Team Count", 'team-count', v => v.teamCount)}
                            {this.renderRow("Employment Class", 'employment-class', v => v.employmentClass)}
                            {this.renderRow("Mean Rate", 'mean-rate', v => {
                                return v.meanRate
                                ? <NumberFormat value={v.meanRate.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                : null;
                            })}
                            {this.renderRow("Blended Rate", 'blended-rate', v => {
                                return v.invoiceDollars
                                ? <NumberFormat value={(v.invoiceDollars / v.headCount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                : 'No Invoices';
                            })}
                            {this.renderRow("Tenure", 'tenure', v => {
                                const value = (v.totalActiveDays / 365).toFixed(1);
                                return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} suffix={'yr'} />
                            })}
                            {this.renderRow("Average Tenure", 'mean-tenure', v => {
                                const value = (v.totalActiveDays / v.twelveMonthCount / (365 / 12)).toFixed(1);                                
                                return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} suffix={'mo'} />
                            })}
                            {this.renderRow("Turnover", 'turnover', v => (100 * v.twelveMonthTurnover / v.twelveMonthCount).toFixed(0) + '%' )}
                            {this.renderRow("Monthly Invoice", 'monthly-invoice', v => {
                                return v.invoiceDollars
                                ? <NumberFormat value={v.invoiceDollars.toFixed(0)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                : 'No Invoices';
                            })}
                            {this.renderRow("Total Impact", 'total-impact', v => v.totalImpact || '')}
                            {this.renderRow("Impact Cost", 'impact-cost', v => {
                                const value = (v.invoiceDollars / v.totalImpact).toFixed(2);
                                return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            })}
                            {this.renderRow("Average Impact", 'average-impact', v => (v.totalImpact / v.contributorCount).toFixed(0) || '')}
                            {this.renderRow("Average Activity", 'average-activity', v => (v.totalActivity / v.contributorCount).toFixed(0) || '')}
                        </tbody>
                    </table>
                </div>
            </section>
        );
    }
}