import React, { Component } from "react";
import * as moment from 'moment';
import 'moment-timezone';

export class TimeZoneToolTip extends Component {
    render() {
        var { timeZone } = this.props;

        if (timeZone) {
            var l = moment().utcOffset();
            var r = moment().tz(timeZone).utcOffset();
            var p = (r - l) / 60; // it is possible to be 26 hours apart in time zones

            return <div>
                <div>{timeZone}</div>
                <div>GMT {r / 60} hours</div>
                <div>Relative offset: {p} hours</div>
            </div>
        } else {
            return <div>Unset timezone</div>;
        }
    }
}

export class TimeZoneGMTOffset extends Component {
    render() {
        var { timeZone } = this.props;

        if (timeZone) {
            var r = moment().tz(timeZone).utcOffset();
            return <span>GMT {r / 60} hours</span>
        } else {
            return <span>Unset timezone</span>;
        }
    }
}
