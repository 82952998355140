import React, { Component } from 'react';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler,
    NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Dropdown
} from 'reactstrap';

import { Link } from 'react-router-dom';
import './NavMenu.css';
import Conditional from '../components/Conditional';
import { Loadable } from '../components/Loadable';

import teamService from '../services/teamService';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            loaded: false,
            myTeams: []
        };
    }

    componentDidMount() {
        this.loadSessionConstantData();
    }

    async loadSessionConstantData() {

        this.setState({ loaded: false });

        var [myTeams] = await Promise.all([
            teamService.getMy()
        ]);

        this.setState({ loaded: true, myTeams });

    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    dashboardMenu() {
        return <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav className="text-dark">
                Dashboard <i className="fa fa-angle-down" aria-hidden="true" />
            </DropdownToggle>
            <DropdownMenu right>
                {/*<DropdownItem>*/}
                {/*    <NavLink tag={Link} className="text-dark" to="/Dashboard/ProductDashboard">Product Dashboard</NavLink>*/}
                {/*</DropdownItem>*/}
                <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/Dashboard/CapExDashboard">CapEx Dashboard</NavLink>
                </DropdownItem>
                <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/Dashboard/DevOpsMergeRequestDashboard">DevOps MergeRequest Dashboard</NavLink>
                </DropdownItem>

                <Conditional if={this.props.claims.isAdmin()}>
                    {/*<DropdownItem>*/}
                    {/*    <NavLink tag={Link} className="text-dark" to="/Dashboard/ObjectiveDashboard">Objective Dashboard</NavLink>*/}
                    {/*</DropdownItem>*/}
                    {/*<DropdownItem>*/}
                    {/*    <NavLink tag={Link} className="text-dark" to="/Dashboard/ObjectiveHealthDashboard">Objective Health Dashboard</NavLink>*/}
                    {/*</DropdownItem>*/}
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/Dashboard/AhaJiraChangeLogDashboard">AhaJiraChangeLog Dashboard</NavLink>
                    </DropdownItem>
                </Conditional>
                {/*<DropdownItem>*/}
                {/*    <NavLink tag={Link} className="text-dark" to="/Dashboard/FeatureHealthDashboard">Feature Health Dashboard</NavLink>*/}
                {/*</DropdownItem>*/}
                <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/Dashboard/serviceNowDashboard">ServiceNow Dashboard</NavLink>
                </DropdownItem>
                <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/Dashboard/IncidentSla">ServiceNow Incident SLA</NavLink>
                </DropdownItem>
                <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/Dashboard/ProblemData">ServiceNow Problem Data</NavLink>
                </DropdownItem>
                <Conditional if={this.props.claims.isAdmin()}>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/Dashboard/ManualKPIEntryDashboard">Manual KPI Entry Dashboard</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/Dashboard/ManualServiceNowEntryDashboard">Manual ServiceNow Entry Dashboard</NavLink>
                    </DropdownItem>
                </Conditional>
            </DropdownMenu>
        </UncontrolledDropdown>
    }

    programOperationsMenu() {
        return <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav className="text-dark">
                Program Operations <i className="fa fa-angle-down" aria-hidden="true" />
            </DropdownToggle>
            <DropdownMenu right>
                <Conditional if={this.props.claims.isAdmin()}>
                    {/*<DropdownItem>*/}
                    {/*    <NavLink tag={Link} className="text-dark" to="/ProgramOperations/R&DTeamResourceReport">R&D Team & Resource Report</NavLink>*/}
                    {/*</DropdownItem>*/}
                    {/*<DropdownItem>*/}
                    {/*    <NavLink tag={Link} className="text-dark" to="/ProgramOperations/R&DResourceChart">R&D Resource Chart</NavLink>*/}
                    {/*</DropdownItem>*/}
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/ProgramOperations/ActiveSprintReport">Active Sprint Report</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/ProgramOperations/FlashReport">Flash Report Data</NavLink>
                    </DropdownItem>
                    {/*<DropdownItem>*/}
                    {/*    <NavLink tag={Link} className="text-dark" to="/ProgramOperations/DeliveryStatusOnTimeData">Delivery Status & On Time Data</NavLink>*/}
                    {/*</DropdownItem>*/}
                </Conditional>
            </DropdownMenu>
        </UncontrolledDropdown>
    }

    adminArea() {
        return this.props.claims.isAdmin() && <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav className="text-dark">
                Admin Area <i className="fa fa-angle-down" aria-hidden="true" />
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/members">Members</NavLink>
                </DropdownItem>
                <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/teams">Teams</NavLink>
                </DropdownItem>
                <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/vendors">Vendors</NavLink>
                </DropdownItem>
                <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/Departments">Departments</NavLink>
                </DropdownItem>
                <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/POD">PODs</NavLink>
                </DropdownItem>
                <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/PODObjectives">Flash Report Objectives</NavLink>
                </DropdownItem>
                <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/DeliveryReportInitiatives">Delivery Report Initiatives</NavLink>
                </DropdownItem>


            </DropdownMenu>
        </UncontrolledDropdown>;
    }

    configureArea() {
        return this.props.claims.canConfigure() &&
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav className="text-dark">
                    Configure <i className="fa fa-angle-down" aria-hidden="true" />
                </DropdownToggle>
                <DropdownMenu right>
                    <Conditional if={this.props.claims.canConfigure('Office')}>
                        <DropdownItem>
                            <NavLink tag={Link} className="text-dark" to="/configure/offices">Offices</NavLink>
                        </DropdownItem>
                    </Conditional>
                </DropdownMenu>
            </UncontrolledDropdown>;
    }

    scorecardArea() {
        return (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav className="text-dark">
                    Scorecards <i className="fa fa-angle-down" aria-hidden="true" />
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem>
                        <NavLink tag={Link} className="text-dark" to="/scorecard/workstreams">Workstream</NavLink>
                    </DropdownItem>
                    <Conditional if={this.props.claims.isAdmin()}>
                        <DropdownItem>
                            <NavLink tag={Link} className="text-dark" to="/scorecard/vendors">Vendor Scorecard</NavLink>
                        </DropdownItem>
                    </Conditional>
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }

    myTeamMenu() {
        var teams = this.state.myTeams
        if (!teams)
            return;
        if (teams.length === 1)
            return < NavItem >
                <NavLink tag={Link} className="text-dark" to={`/my/team/${teams[0].id}`}>My Team</NavLink>
            </NavItem >;

        return <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav className="text-dark">
                My Teams <i className="fa fa-angle-down" aria-hidden="true" />
            </DropdownToggle>
            <DropdownMenu right>
                <Loadable loaded={this.state.loaded}>
                    {teams.map(t =>
                        <DropdownItem key={`my-team-nav-${t.id}`}>
                            <NavLink tag={Link} className="text-dark" to={`/my/team/${t.id}`}>{t.name}</NavLink>
                        </DropdownItem>
                    )}
                </Loadable>
            </DropdownMenu>
        </UncontrolledDropdown>
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">Engineering Dashboard</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/team/composition">Team Composition</NavLink>
                                </NavItem>
                                {
                                    this.programOperationsMenu()
                                }
                                {/* <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/overview">Overview</NavLink>
                                </NavItem>
                                {this.scorecardArea()} */
                                    this.dashboardMenu()
                                }
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/my/profile">Profile</NavLink>
                                </NavItem>
                                {this.myTeamMenu()}
                                {this.configureArea()}
                                {this.adminArea()}
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
