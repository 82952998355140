import React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import "./POD.css";

function ClusterRole(props) {
  const Role = props.Role;
  const clusterName = props.clusterName;
  const clusterLeads = props.clusterLeads;
  const addtionalInfo = props.additionInfo;
  const memberOption = props.clusterLeadOptions;

  return (
      <Modal scrollable="true"
        size="xl"
        className="modal"
        show={props.show}
        backdrop="static"
        aria-labelledby="example-modal-sizes-title-xl"
        onHide={() => props.setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-xl">
            Add POD Support Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p>Cluster Name</p>
            </Col>
            <Col>
              <p>{clusterName.label}</p>
            </Col>
            <Col>
              <p>Cluster Leads</p>
            </Col>
            <Col>
              <p>
                {clusterLeads.map((x, i) =>
                  i === clusterLeads.length - 1 ? x.label : x.label + ", "
                )}
              </p>
            </Col>
          </Row>  
          <Row>
            <Col>
              <p>POD Name</p>
            </Col>
            <Col>
              <p>{addtionalInfo.podName}</p>
            </Col>
            <Col>
              <p>POD Leads</p>
            </Col>
            <Col>
              <p>{addtionalInfo.podLead.name}</p>
            </Col>
          </Row>
       
          <Row >
            <Table responsive size="sm" style={{ tableLayout: "fixed" }}>
              <thead className="stickyHeader">
                <tr>
                  <th className="stickyCol" style={{ width: "200px",zIndex:"2" }}>Team Name</th>
                  {Role.map((x, i) => (
                    <th style={{ width: "200px" }} key={x.id}>
                      {x.supportType}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(addtionalInfo.team) && addtionalInfo.team.map((t, i) => (
                  <tr key={i}>
                    <th className="freeze">{t.name}</th>
                    {Role.map((x, i) => (
                      ((t.supportTypes.filter(st=> st.supportType===x.supportType)).length>0?
                      <td key={x.id}>
                        <Select
                          isMulti
                          id= {x.id}
                          defaultValue= {t.supportTypes.flatMap((s, i) => {
                               if(s.supportType===x.supportType)
                               return s.supportMembers.map(sm=>
                                 ({value:sm.id,label:sm.name})
                               )
                          })}
                          menuPlacement="auto"
                          options=  {memberOption}                           
                          closeMenuOnSelect= {false}
                          hideSelectedOptions= {false}
                          onChange={(e) => props.handleRoleMember(t.name, x, e)}
                        />
                      </td>
                      :
                      <td key={x.id}>
                        <Select
                          isMulti
                          id= {x.id}
                          menuPlacement="auto"                       
                          options=  {memberOption}
                          closeMenuOnSelect= {false}
                          hideSelectedOptions= {false}
                          onChange={(e) => props.handleRoleMember(t.name, x, e)}
                        />
                      </td>
                    )))}
                  </tr>
                ))}
                { (addtionalInfo.team && addtionalInfo.team[0]===undefined) &&  <tr><td>No Team Selected</td></tr> }
               <tr>
                 <td colSpan="9"> </td>
               </tr>
              </tbody>
            </Table>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>props.setShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
  );
}

export default ClusterRole;
