import React, { useState } from 'react';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const camelCase = (data) => {
  data = data.replace(/([a-z])([A-Z])/g, "$1 $2");
  data = data.charAt(0).toUpperCase() + data.slice(1);
  return data;
}
const excelColumns = (data, index) => {

  let label = camelCase(data);
  if (data == "capexReportMonth") return null;
  return (<ExcelColumn label={label} value={data} key={index} />);
}

const fiveSheetExcel = (sheetData, sheetData2, sheetData3, sheetData4, sheetData5, sheetName, sheetName2, sheetName3, sheetName4, sheetName5, fileName, buttonName) => {

  if (sheetData.length <= 0 &&
    sheetData2.length <= 0 &&
    sheetData3.length <= 0 &&
    sheetData4.length <= 0 &&
    sheetData5.length <= 0) {
    return null
  }
  else {

    if (sheetData.length <= 0) {
      sheetData.push({ "": "" })
    }
    if (sheetData2.length <= 0) {
      sheetData2.push({ "": "" })
    }
    if (sheetData3.length <= 0) {
      sheetData3.push({ "": "" })
    }
    if (sheetData4.length <= 0) {
      sheetData4.push({ "": "" })
    }
    if (sheetData5.length <= 0) {
      sheetData5.push({ "": "" })
    }
    return (
      <div>
        <ExcelFile
          element={
            <button className="btn btn-primary" style={{ marginBottom: "10px" }}>
              {buttonName} <i className="fa fa-download" aria-hidden="true"></i>
            </button>
          }
          filename={fileName}
        >
          <ExcelSheet data={sheetData} name={sheetName}>
            {Object.keys(sheetData[0]).map(excelColumns)}
          </ExcelSheet>
          <ExcelSheet data={sheetData2} name={sheetName2}>
            {Object.keys(sheetData2[0]).map(excelColumns)}
          </ExcelSheet>
          <ExcelSheet data={sheetData3} name={sheetName3}>
            {Object.keys(sheetData3[0]).map(excelColumns)}
          </ExcelSheet>
          <ExcelSheet data={sheetData4} name={sheetName4}>
            {Object.keys(sheetData4[0]).map(excelColumns)}
          </ExcelSheet>
          <ExcelSheet data={sheetData5} name={sheetName5}>
            {Object.keys(sheetData5[0]).map(excelColumns)}
          </ExcelSheet>
        </ExcelFile>
      </div>
    );
  }
}

const threeSheetExcel = (sheetData, sheetData2, sheetData3, sheetName, sheetName2, sheetName3, fileName, buttonName) => {

  if (sheetData.length <= 0 &&
    sheetData2.length <= 0 &&
    sheetData3.length <= 0) {
    return null
  }
  else {

    if (sheetData.length <= 0) {
      sheetData.push({ "": "" })
    }
    if (sheetData2.length <= 0) {
      sheetData2.push({ "": "" })
    }

    if (sheetData3.length <= 0) {
      sheetData3.push({ "": "" })
    }

    const tempData = JSON.parse(JSON.stringify(sheetData));
    tempData.forEach(function (v) { delete v.capexReportMonth });
    return (
      <div>
        <ExcelFile
          element={
            <button className="btn btn-primary" style={{ marginBottom: "10px" }}>
              {buttonName} <i className="fa fa-download" aria-hidden="true"></i>
            </button>
          }
          filename={fileName}
        >
          <ExcelSheet data={tempData} name={sheetName}>
            {Object.keys(tempData[0]).map(excelColumns)}
          </ExcelSheet>
          <ExcelSheet data={sheetData2} name={sheetName2}>
            {Object.keys(sheetData2[0]).map(excelColumns)}
          </ExcelSheet>
          <ExcelSheet data={sheetData3} name={sheetName3}>
            {Object.keys(sheetData3[0]).map(excelColumns)}
          </ExcelSheet>
        </ExcelFile>
      </div>
    );
  }
}

const twoSheetExcel = (sheetData, sheetData2, sheetName, sheetName2, fileName, buttonName) => {

  if (sheetData.length <= 0 &&
    sheetData2.length <= 0) {
    return null
  }
  else {

    if (sheetData.length <= 0) {
      sheetData.push({ "": "" })
    }
    if (sheetData2.length <= 0) {
      sheetData2.push({ "": "" })
    }

    const tempData = JSON.parse(JSON.stringify(sheetData));
    tempData.forEach(function (v) { delete v.capexReportMonth });
    return (
      <div>
        <ExcelFile
          element={
            <button className="btn btn-primary" style={{ marginBottom: "10px" }}>
              {buttonName} <i className="fa fa-download" aria-hidden="true"></i>
            </button>
          }
          filename={fileName}
        >
          <ExcelSheet data={tempData} name={sheetName}>
            {Object.keys(tempData[0]).map(excelColumns)}
          </ExcelSheet>
          <ExcelSheet data={sheetData2} name={sheetName2}>
            {Object.keys(sheetData2[0]).map(excelColumns)}
          </ExcelSheet>
        </ExcelFile>
      </div>
    );
  }
}

const singleSheetExcel = (fileName, sheetData, sheetName, buttonName) => {
  if (sheetData.length > 0) {
    return (<div><ExcelFile
      element={
        <button
          className="btn btn-primary"
          style={{ marginBottom: "10px" }}
        >
          {buttonName}{" "}
          <i className="fa fa-download" aria-hidden="true"></i>
        </button>
      }
      filename={fileName}
    >
      <ExcelSheet data={sheetData} name={sheetName}>
        {Object.keys(sheetData[0]).map(excelColumns)}
      </ExcelSheet>
    </ExcelFile></div>);
  } else return null;
}
function ExportExcel(props) {
  let fileName = props.fileName;
  let buttonName = props.buttonName;
  let sheetData = (props.sheetData) ? [...props.sheetData] : [];
  let sheetName = props.sheetName;
  let sheetData2 = (props.sheetData2) ? [...props.sheetData2] : [];
  let sheetName2 = props.sheetName2;
  let sheetData3 = (props.sheetData3) ? [...props.sheetData3] : [];
  let sheetName3 = props.sheetName3;
  let sheetData4 = (props.sheetData4) ? [...props.sheetData4] : [];
  let sheetName4 = props.sheetName4;
  let sheetData5 = (props.sheetData5) ? [...props.sheetData5] : [];
  let sheetName5 = props.sheetName5;

  if (sheetName != undefined &&
    sheetName2 != undefined &&
    sheetName3 != undefined &&
    sheetName4 != undefined &&
    sheetName5 != undefined) {
    return fiveSheetExcel(sheetData, sheetData2, sheetData3, sheetData4, sheetData5, sheetName, sheetName2, sheetName3, sheetName4, sheetName5, fileName, buttonName);
  }

  if (sheetName != undefined &&
    sheetName2 != undefined &&
    sheetName3 != undefined) {
    return threeSheetExcel(sheetData, sheetData2, sheetData3, sheetName, sheetName2, sheetName3, fileName, buttonName);
  }

  if (sheetName != undefined &&
    sheetName2 != undefined) {
    return twoSheetExcel(sheetData, sheetData2, sheetName, sheetName2, fileName, buttonName);
  }

  if (sheetName != undefined) {
    return singleSheetExcel(fileName, sheetData, sheetName, buttonName);
  }

  return null;

}
export default ExportExcel;