import React, { useState } from "react";
import { Nav, NavItem,NavLink,TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';
import ProductionMetricsForm from "../../components/ManualKPIEntryDashboard/ProductionMetricsForm";
import RiskIntelligenceMetrics from "../../components/ManualKPIEntryDashboard/RiskIntelligenceMetrics";
import GitPrimeMetricsForm from "../../components/ManualKPIEntryDashboard/GitPrimeMetricsForm";
import Style from "../../pages/Dashboard/ManualKPIEntryDashboard.module.css";

const ProductionMetricsTab  = "1";
const RiskIntelligenceTab = "2";
const GitPrimeTeamTab="3";
function ManualKPIEntryDashboard(props) {
  const [activeTab, setTab] = useState(ProductionMetricsTab );

  const validateForm = (errors,field) => {
    let valid = true;
    Object.values(errors).forEach(
      (val) => val != undefined && val.length > 0 && (valid = false)
    );
    Object.values(field).forEach((val)=>(val === undefined  || val.length<=0) && (valid=false));
    return valid;
  };

  return (
    <div>
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === ProductionMetricsTab  })}
              onClick={() => {
                setTab(ProductionMetricsTab );
              }}
            >
              Production Metrics
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === RiskIntelligenceTab })}
              onClick={() => {
                setTab(RiskIntelligenceTab);
              }}
            >
              Risk Intelligence
            </NavLink>
          </NavItem>
          {/*<NavItem>*/}
          {/*  <NavLink*/}
          {/*    className={classnames({ active: activeTab === GitPrimeTeamTab })}*/}
          {/*    onClick={() => {*/}
          {/*      setTab(GitPrimeTeamTab);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    GitPrime */}
          {/*  </NavLink>*/}
          {/*</NavItem>*/}
        </Nav>
      </div>
      <div>
        <TabContent activeTab={activeTab}>
        <div className={Style.notes}>* Dates available align with data recording and report automation</div>
          <TabPane tabId={ProductionMetricsTab }>
            &nbsp;
            <div>
              <ProductionMetricsForm
                validateForm={validateForm}
              />
            </div> 
          </TabPane>

          <TabPane tabId={RiskIntelligenceTab}>
            &nbsp;
           <div>
           <RiskIntelligenceMetrics  validateForm={validateForm}/>
           </div>
          </TabPane>
          {/*<TabPane tabId={GitPrimeTeamTab}>*/}
          {/*  &nbsp;*/}
          {/* <div>*/}
          {/*   <GitPrimeMetricsForm/>*/}
          {/* </div>*/}
          {/*</TabPane>*/}
        </TabContent>
      </div>

      {/* */}
      <div>{/* */}</div>
    </div>
  );
}

export default ManualKPIEntryDashboard;
