import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Style from "../../pages/Dashboard/ManualKPIEntryDashboard.module.css";
import manualKPIDashboardService from "../../services/manualKPIDashboardService";
function ProductionMetricsForm(props) {
  const validateForm = props.validateForm;

  const [date, setDate] = useState();
  const [field, setField] = useState({
    date: undefined,
    mir3Uptime: undefined,
    onSolvePlatformUptime: undefined,
    ocnUptime: undefined,
    codeRedUptime: undefined,
    swnUptime: undefined,
    stabilitasUptime: undefined,
    downtimeIncidents: undefined,
    partialDowntimeIncidents: undefined,
    mir3OnPremVoice: undefined,
    mir3OnPremSMS: undefined,
    mir3OnPremEmail: undefined,
    mir3AWSVoice: undefined,
    mir3AWSSMS: undefined,
    mir3AWSEmail: undefined,
    swnVoice: undefined,
    swnSMS: undefined,
    swnEmail: undefined,
    ocnVoice: undefined,
    ocnSMS: undefined,
    ocnEmail: undefined,
    ecnVoice: undefined,
    ecnSMS: undefined,
    ecnEmail: undefined,
  });
  const [formErrors, setFormErrors] = useState({
    mir3Uptime: undefined,
    onSolvePlatformUptime: undefined,
    ocnUptime: undefined,
    codeRedUptime: undefined,
    swnUptime: undefined,
    stabilitasUptime: undefined,
    downtimeIncidents: undefined,
    partialDowntimeIncidents: undefined,
    mir3OnPremVoice: undefined,
    mir3OnPremSMS: undefined,
    mir3OnPremEmail: undefined,
    mir3AWSVoice: undefined,
    mir3AWSSMS: undefined,
    mir3AWSEmail: undefined,
    swnVoice: undefined,
    swnSMS: undefined,
    swnEmail: undefined,
    ocnVoice: undefined,
    ocnSMS: undefined,
    ocnEmail: undefined,
    ecnVoice: undefined,
    ecnSMS: undefined,
    ecnEmail: undefined,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    let keys = Object.keys(field).length;
    if (keys != 0 && validateForm(formErrors, field)) {
      let ans = window.confirm("Are you sure you want to submit this data?");
      if (ans === true) {
        var res = await manualKPIDashboardService.postProductionMetricsForm(field);
        if (res.ok) alert("Form Is Submitted Successfully");
        else alert("Something Went Wrong");
      }
    } else {
      alert("Make Sure All Fields Are Filled");
    }
  };

  const clearAll = (event) => {
    event.preventDefault();
    setField({
      date: undefined,
      mir3Uptime: undefined,
      onSolvePlatformUptime: undefined,
      ocnUptime: undefined,
      codeRedUptime: undefined,
      swnUptime: undefined,
      stabilitasUptime: undefined,
      downtimeIncidents: undefined,
      partialDowntimeIncidents: undefined,
      mir3OnPremVoice: undefined,
      mir3OnPremSMS: undefined,
      mir3OnPremEmail: undefined,
      mir3AWSVoice: undefined,
      mir3AWSSMS: undefined,
      mir3AWSEmail: undefined,
      swnVoice: undefined,
      swnSMS: undefined,
      swnEmail: undefined,
      ocnVoice: undefined,
      ocnSMS: undefined,
      ocnEmail: undefined,
      ecnVoice: undefined,
      ecnSMS: undefined,
      ecnEmail: undefined,
    });
    setDate();
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let currentformErrors = formErrors;

    switch (name) {
      case "mir3Uptime":
        if (value > 1.0) {
          currentformErrors.mir3Uptime =
            "Incorrect Formet(Correct Formet Example:- 99.99%=0.9999)";
        } else if (CheckdecimalPlace(value)) {
          currentformErrors.mir3Uptime =
            "there should be only 4 decimal places";
        } else if (value < 0) {
          currentformErrors.mir3Uptime = "number should be positive";
        } else {
          delete currentformErrors.mir3Uptime;
          setField({ ...field, mir3Uptime: parseFloat(value) });
        }
        break;
      case "onSolvePlatformUptime":
        if (value > 1.0) {
          currentformErrors.onSolvePlatformUptime =
            "Incorrect Formet(Correct Formet Example:- 99.99%=0.9999)";
        } else if (CheckdecimalPlace(value)) {
          currentformErrors.onSolvePlatformUptime =
            "there should be only 4 decimal places";
        } else if (value < 0) {
          currentformErrors.onSolvePlatformUptime = "number should be positive";
        } else {
          delete currentformErrors.onSolvePlatformUptime;
          setField({ ...field, onSolvePlatformUptime: parseFloat(value) });
        }
        break;
      case "ocnUptime":
        if (value > 1.0) {
          currentformErrors.ocnUptime =
            "Incorrect Formet(Correct Formet Example:- 99.99%=0.9999)";
        } else if (CheckdecimalPlace(value)) {
          currentformErrors.ocnUptime = "there should be only 4 decimal places";
        } else if (value < 0) {
          currentformErrors.ocnUptime = "number should be positive";
        } else {
          delete currentformErrors.ocnUptime;
          setField({ ...field, ocnUptime: parseFloat(value) });
        }
        break;
      case "codeRedUptime":
        if (value > 1.0) {
          currentformErrors.codeRedUptime =
            "Incorrect Formet(Correct Formet Example:- 99.99%=0.9999)";
        } else if (CheckdecimalPlace(value)) {
          currentformErrors.codeRedUptime =
            "there should be only 4 decimal places";
        } else if (value < 0) {
          currentformErrors.codeRedUptime = "number should be positive";
        } else {
          delete currentformErrors.codeRedUptime;
          setField({ ...field, codeRedUptime: parseFloat(value) });
        }
        break;
      case "swnUptime":
        if (value > 1.0) {
          currentformErrors.swnUptime =
            "Incorrect Formet(Correct Formet Example:- 99.99%=0.9999)";
        } else if (CheckdecimalPlace(value)) {
          currentformErrors.swnUptime = "there should be only 4 decimal places";
        } else if (value < 0) {
          currentformErrors.swnUptime = "number should be positive";
        } else {
          delete currentformErrors.swnUptime;
          setField({ ...field, swnUptime: parseFloat(value) });
        }
        break;
      case "stabilitasUptime":
        if (value > 1.0) {
          currentformErrors.stabilitasUptime =
            "Incorrect Formet(Correct Formet Example:- 99.99%=0.9999)";
        } else if (CheckdecimalPlace(value)) {
          currentformErrors.stabilitasUptime =
            "there should be only 4 decimal places";
        } else if (value < 0) {
          currentformErrors.stabilitasUptime = "number should be positive";
        } else {
          delete currentformErrors.stabilitasUptime;
          setField({ ...field, stabilitasUptime: parseFloat(value) });
        }
        break;
      case "downtimeIncidents":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.downtimeIncidents = "Value Should be integers";
        } else if (value < 0) {
          currentformErrors.downtimeIncidents = "number should be positive";
        } else {
          delete currentformErrors.downtimeIncidents;
          setField({ ...field, downtimeIncidents: parseInt(value) });
        }
        break;
      case "partialDowntimeIncidents":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.partialDowntimeIncidents =
            "Value Should be integers";
        } else if (value < 0) {
          currentformErrors.partialDowntimeIncidents =
            "number should be positive";
        } else {
          delete currentformErrors.partialDowntimeIncidents;
          setField({ ...field, partialDowntimeIncidents: parseInt(value) });
        }
        break;
      case "mir3OnPremVoice":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.mir3OnPremVoice = "Value Should be integers";
        } else if (value < 0) {
          currentformErrors.mir3OnPremVoice = "number should be positive";
        } else {
          delete currentformErrors.mir3OnPremVoice;
          setField({ ...field, mir3OnPremVoice: parseInt(value) });
        }
        break;
      case "mir3OnPremSMS":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.mir3OnPremSMS = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.mir3OnPremSMS = "number should be positive";
        } else {
          delete currentformErrors.mir3OnPremSMS;
          setField({ ...field, mir3OnPremSMS: parseInt(value) });
        }
        break;
      case "mir3OnPremEmail":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.mir3OnPremEmail = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.mir3OnPremEmail = "number should be positive";
        } else {
          delete currentformErrors.mir3OnPremEmail;
          setField({ ...field, mir3OnPremEmail: parseInt(value) });
        }
        break;
      case "mir3AWSVoice":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.mir3AWSVoice = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.mir3AWSVoice = "number should be positive";
        } else {
          delete currentformErrors.mir3AWSVoice;
          setField({ ...field, mir3AWSVoice: parseInt(value) });
        }
        break;
      case "mir3AWSSMS":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.mir3AWSSMS = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.mir3AWSSMS = "number should be positive";
        } else {
          delete currentformErrors.mir3AWSSMS;
          setField({ ...field, mir3AWSSMS: parseInt(value) });
        }
        break;
      case "mir3AWSEmail":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.mir3AWSEmail = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.mir3AWSEmail = "number should be positive";
        } else {
          delete currentformErrors.mir3AWSEmail;
          setField({ ...field, mir3AWSEmail: parseInt(value) });
        }
        break;
      case "swnVoice":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.swnVoice = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.swnVoice = "number should be positive";
        } else {
          delete currentformErrors.swnVoice;

          setField({ ...field, swnVoice: parseInt(value) });
        }
        break;
      case "swnSMS":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.swnSMS = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.swnSMS = "number should be positive";
        } else {
          delete currentformErrors.swnSMS;

          setField({ ...field, swnSMS: parseInt(value) });
        }
        break;
      case "swnEmail":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.swnEmail = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.swnEmail = "number should be positive";
        } else {
          delete currentformErrors.swnEmail;

          setField({ ...field, swnEmail: parseInt(value) });
        }
        break;
      case "ocnVoice":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.ocnVoice = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.ocnVoice = "number should be positive";
        } else {
          delete currentformErrors.ocnVoice;
          setField({ ...field, ocnVoice: parseInt(value) });
        }
        break;
      case "ocnSMS":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.ocnSMS = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.ocnSMS = "number should be positive";
        } else {
          delete currentformErrors.ocnSMS;
          setField({ ...field, ocnSMS: parseInt(value) });
        }
        break;
      case "ocnEmail":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.ocnEmail = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.ocnEmail = "number should be positive";
        } else {
          delete currentformErrors.ocnEmail;
          setField({ ...field, ocnEmail: parseInt(value) });
        }
        break;
      case "ecnVoice":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.ecnVoice = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.ecnVoice = "number should be positive";
        } else {
          delete currentformErrors.ecnVoice;
          setField({ ...field, ecnVoice: parseInt(value) });
        }
        break;
      case "ecnSMS":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.ecnSMS = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.ecnSMS = "number should be positive";
        } else {
          delete currentformErrors.ecnSMS;
          setField({ ...field, ecnSMS: parseInt(value) });
        }
        break;
      case "ecnEmail":
        if (value && !Number.isInteger(parseFloat(value))) {
          currentformErrors.ecnEmail = "Value should be integers";
        } else if (value < 0) {
          currentformErrors.ecn = "number should be positive";
        } else {
          delete currentformErrors.ecnEmail;
          setField({ ...field, ecnEmail: parseInt(value) });
        }
        break;
      default:
        break;
    }
    setFormErrors({ ...formErrors, currentformErrors });
  };

  const handleDateChange = (d) => {
    setDate(d);
    setField({ ...field, date: new Date(d) });
  };

  function CheckdecimalPlace(num) {
    if (num) {
      var s = num.split(".");
      if (s[1] && s[1].length > 4) {
        return true;
      }
      return false;
    }
  }

  return (
    <form noValidate>
      <table className="table table-striped" aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th colSpan="2">Production Metrics</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Date</td>
            <td>
              <DatePicker
                placeholderText="MM/DD/YYYY"
                selected={date}
                name="date"
                autoComplete="off"
                filterDate={date => date.getDay() === 0}
                onChange={(d) => handleDateChange(d)}
              />
            </td>
          </tr>
          <tr>
            <td>MIR3 Uptime</td>
            <td>
              <input
                type="number"
                name="mir3Uptime"
                onChange={handleChange}
                value={field.mir3Uptime||""}
                noValidate
              />

              {formErrors.mir3Uptime != undefined && (
                <div className={Style.error}>{formErrors.mir3Uptime}</div>
              )}
            </td>
          </tr>
          <tr>
            <td>OnSolve Platform Uptime</td>
            <td>
              <input
                type="number"
                name="onSolvePlatformUptime"
                onChange={handleChange}
                value={field.onSolvePlatformUptime||""}
                noValidate
              />
              {formErrors.onSolvePlatformUptime && (
                <div className={Style.error}>
                  {formErrors.onSolvePlatformUptime}
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>OCN Uptime</td>
            <td>
              <input
                type="number"
                name="ocnUptime"
                onChange={handleChange}
                value={field.ocnUptime||""}
                noValidate
              />
              {formErrors.ocnUptime != null &&
                formErrors.ocnUptime.length > 0 && (
                  <div className={Style.error}>{formErrors.ocnUptime}</div>
                )}
            </td>
          </tr>
          <tr>
            <td>CodeRed Uptime</td>
            <td>
              <input
                type="number"
                name="codeRedUptime"
                onChange={handleChange}
                value={field.codeRedUptime||""}
                noValidate
              />
              {formErrors.codeRedUptime != null &&
                formErrors.codeRedUptime.length > 0 && (
                  <div className={Style.error}>{formErrors.codeRedUptime}</div>
                )}
            </td>
          </tr>
          <tr>
            <td>SWN Uptime</td>
            <td>
              <input
                type="number"
                name="swnUptime"
                onChange={handleChange}
                value={field.swnUptime||""}
                noValidate
              />
              {formErrors.swnUptime != null &&
                formErrors.swnUptime.length > 0 && (
                  <div className={Style.error}>{formErrors.swnUptime}</div>
                )}
            </td>
          </tr>
          <tr>
            <td>Stabilitas Uptime</td>
            <td>
              <input
                type="number"
                name="stabilitasUptime"
                onChange={handleChange}
                value={field.stabilitasUptime||""}
                noValidate
              />
              {formErrors.stabilitasUptime != null &&
                formErrors.stabilitasUptime.length > 0 && (
                  <div className={Style.error}>
                    {formErrors.stabilitasUptime}
                  </div>
                )}
            </td>
          </tr>
          <tr>
            <td>Downtime Incidents</td>
            <td>
              <input
                type="number"
                name="downtimeIncidents"
                onChange={handleChange}
                value={field.downtimeIncidents }
                noValidate
              />
              {formErrors.downtimeIncidents != null &&
                formErrors.downtimeIncidents.length > 0 && (
                  <div className={Style.error}>
                    {formErrors.downtimeIncidents}
                  </div>
                )}
            </td>
          </tr>
          <tr>
            <td>Partial Downtime Incidents </td>
            <td>
              <input
                type="number"
                name="partialDowntimeIncidents"
                onChange={handleChange}
                value={field.partialDowntimeIncidents }
                noValidate
              />
              {formErrors.partialDowntimeIncidents != null &&
                formErrors.partialDowntimeIncidents.length > 0 && (
                  <div className={Style.error}>
                    {formErrors.partialDowntimeIncidents}
                  </div>
                )}
            </td>
          </tr>
          <tr>
            <td>MIR3 OnPrem Voice</td>
            <td>
              <input
                type="number"
                name="mir3OnPremVoice"
                value={field.mir3OnPremVoice||""}
                onChange={handleChange}
                noValidate
              />
              {formErrors.mir3OnPremVoice != null &&
                formErrors.mir3OnPremVoice.length > 0 && (
                  <div className={Style.error}>
                    {formErrors.mir3OnPremVoice}
                  </div>
                )}
            </td>
          </tr>
          <tr>
            <td>MIR3 OnPrem SMS</td>
            <td>
              <input
                type="number"
                name="mir3OnPremSMS"
                onChange={handleChange}
                value={field.mir3OnPremSMS||""}
                noValidate
              />
              {formErrors.mir3OnPremSMS != null &&
                formErrors.mir3OnPremSMS.length > 0 && (
                  <div className={Style.error}>{formErrors.mir3OnPremSMS}</div>
                )}
            </td>
          </tr>
          <tr>
            <td>MIR3 OnPrem Email</td>
            <td>
              <input
                type="number"
                name="mir3OnPremEmail"
                onChange={handleChange}
                value={field.mir3OnPremEmail||""}
                noValidate
              />

              {formErrors.mir3OnPremEmail != null &&
                formErrors.mir3OnPremEmail.length > 0 && (
                  <div className={Style.error}>
                    {formErrors.mir3OnPremEmail}
                  </div>
                )}
            </td>
          </tr>
          <tr>
            <td>MIR3 AWS Voice</td>
            <td>
              <input
                type="number"
                name="mir3AWSVoice"
                onChange={handleChange}
                value={field.mir3AWSVoice||""}
                noValidate
              />
              {formErrors.mir3AWSVoice != null &&
                formErrors.mir3AWSVoice.length > 0 && (
                  <div className={Style.error}>{formErrors.mir3AWSVoice}</div>
                )}
            </td>
          </tr>
          <tr>
            <td>MIR3 AWS SMS</td>
            <td>
              <input
                type="number"
                name="mir3AWSSMS"
                onChange={handleChange}
                value={field.mir3AWSSMS||""}
                noValidate
              />
              {formErrors.mir3AWSSMS != null &&
                formErrors.mir3AWSSMS.length > 0 && (
                  <div className={Style.error}>{formErrors.mir3AWSSMS}</div>
                )}
            </td>
          </tr>
          <tr>
            <td>MIR3 AWS Email</td>
            <td>
              <input
                type="number"
                name="mir3AWSEmail"
                onChange={handleChange}
                value={field.mir3AWSEmail||""}
                noValidate
              />
              {formErrors.mir3AWSEmail != null &&
                formErrors.mir3AWSEmail.length > 0 && (
                  <div className={Style.error}>{formErrors.mir3AWSEmail}</div>
                )}
            </td>
          </tr>
          <tr>
            <td>SWN Voice</td>
            <td>
              <input
                type="number"
                name="swnVoice"
                onChange={handleChange}
                value={field.swnVoice||""}
                noValidate
              />

              {formErrors.swnVoice != null &&
                formErrors.swnVoice.length > 0 && (
                  <div className={Style.error}>{formErrors.swnVoice}</div>
                )}
            </td>
          </tr>
          <tr>
            <td>SWN SMS</td>
            <td>
              <input
                type="number"
                name="swnSMS"
                onChange={handleChange}
                value={field.swnSMS||""}
                noValidate
              />
              {formErrors.swnSMS != null && formErrors.swnSMS.length > 0 && (
                <div className={Style.error}>{formErrors.swnSMS}</div>
              )}
            </td>
          </tr>
          <tr>
            <td>SWN Email</td>
            <td>
              <input
                type="number"
                name="swnEmail"
                onChange={handleChange}
                value={field.swnEmail||""}
                noValidate
              />
              {formErrors.swnEmail != null &&
                formErrors.swnEmail.length > 0 && (
                  <div className={Style.error}>{formErrors.swnEmail}</div>
                )}
            </td>
          </tr>
          <tr>
            <td>OCN Voice</td>
            <td>
              <input
                type="number"
                name="ocnVoice"
                onChange={handleChange}
                value={field.ocnVoice||""}
                noValidate
              />
              {formErrors.ocnVoice != null &&
                formErrors.ocnVoice.length > 0 && (
                  <div className={Style.error}>{formErrors.ocnVoice}</div>
                )}
            </td>
          </tr>
          <tr>
            <td>OCN SMS</td>
            <td>
              <input
                type="number"
                name="ocnSMS"
                onChange={handleChange}
                value={field.ocnSMS||""}
                noValidate
              />
              {formErrors.ocnSMS != null && formErrors.ocnSMS.length > 0 && (
                <div className={Style.error}>{formErrors.ocnSMS}</div>
              )}
            </td>
          </tr>
          <tr>
            <td>OCN Email</td>
            <td>
              <input
                type="number"
                name="ocnEmail"
                onChange={handleChange}
                value={field.ocnEmail||""}
                noValidate
              />
              {formErrors.ocnEmail != undefined && (
                <div className={Style.error}>{formErrors.ocnEmail}</div>
              )}
            </td>
          </tr>
          <tr>
            <td>ECN Voice</td>
            <td>
              <input
                type="number"
                name="ecnVoice"
                onChange={handleChange}
                value={field.ecnVoice||""}
                noValidate
              />
              {formErrors.ecnVoice != null &&
                formErrors.ecnVoice.length > 0 && (
                  <div className={Style.error}>{formErrors.ecnVoice}</div>
                )}
            </td>
          </tr>
          <tr>
            <td>ECN SMS</td>
            <td>
              <input
                type="number"
                name="ecnSMS"
                onChange={handleChange}
                value={field.ecnSMS||""}
                noValidate
              />
              {formErrors.ecnSMS != null && formErrors.ecnSMS.length > 0 && (
                <div className={Style.error}>{formErrors.ecnSMS}</div>
              )}
            </td>
          </tr>
          <tr>
            <td>ECN Email</td>
            <td>
              <input
                type="number"
                name="ecnEmail"
                value={field.ecnEmail||""}
                onChange={handleChange}
                noValidate
              />
              {formErrors.ecnEmail != null &&
                formErrors.ecnEmail.length > 0 && (
                  <div className={Style.error}>{formErrors.ecnEmail}</div>
                )}
            </td>
          </tr>
          <tr>
            <td>
              <button
                type="submit"
                className="btn btn-info btn-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </td>
            <td>
              <button
                type="reset"
                className="btn btn-secondary"
                onClick={clearAll}
              >
                Clear
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
}

export default ProductionMetricsForm;
