import React from "react";
import StackBar from "../../components/Widgets/StackBar";
import Style from "./ObjectiveDashboard.module.css";
import * as moment from "moment";
import { Col } from "react-bootstrap";
import {Draggable} from "react-beautiful-dnd";

function ObjectiveTableBody(props) {
  const objData = props.objData;
  const GetStatusIcon = props.GetStatusIcon;
  const showFeatures = props.showFeatures;
  const collapse = props.collapse;
  const key=props.index;

  const ProgressTable = (objData) => {
    return (
      <table border="1px black" className={Style.insideTableStyle}>
        <thead>
          <tr>
            <th style={{ backgroundColor: "#548235" }}>Prod</th>
            <th style={{ backgroundColor: "#a9d08e" }}>Beta</th>
            <th style={{ backgroundColor: "#c6e0b4" }}>Intg</th>
            <th style={{ backgroundColor: "#e2efda" }}>DevDone</th>
            <th style={{ backgroundColor: "#d0cece" }}>Remaining</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{objData.featureProgressProd}</td>
            <td>{objData.featureProgressBeta}</td>
            <td>{objData.featureProgressIntg}</td>
            <td>{objData.featureProgressDevDone}</td>
            <td>{objData.featureProgressRemaining}</td>
          </tr>
        </tbody>
      </table>
    );
  };
  const collapseBody = () => {
    return (   
      <Draggable key={key} draggableId={key+''} index={key}> 
      {(provided,snapshot)=>(
      <tbody
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      > 
      {(snapshot.isDragging)?<tr style={{backgroundColor:"lightgray",border:"1px black solid"}}>
        <td>
        <b style={{ fontSize: "12px" }}> {objData.objective}</b>
        </td>
      </tr>
      :
        <tr>
          <td className={Style.borderStyle} rowSpan="3">
          <b style={{ fontSize: "12px" }}>
              <a
                href="https://onsolve.aha.io/strategic_imperatives/ONSLV-G-12"
                target="_blank"
              >
            {objData.objective}
            </a>
            </b>
          </td>
          <td className={Style.borderStyle}></td>
          <td className={Style.borderStyle}>Master Feature</td>
          <td className={Style.borderStyle}>
            {objData.objectiveMasterFeatures}
          </td>
          <td className={Style.borderStyle} colSpan="4">
            {objData.masterFeatureDefineAvg}
          </td>
          <td className={Style.borderStyle} colSpan="4">
            {objData.masterFeatureBuildAvg}
          </td>
          <td className={Style.borderStyle} colSpan="4">
            {objData.masterFeatureDeployedProd}
          </td>
          <td className={Style.borderStyle} colSpan="4">
            {" "}
          </td>
          <td className={Style.borderStyle} colSpan="2" rowSpan="2">
            {ProgressTable(objData)}
          </td>
          <td className={Style.borderStyle} colSpan="5"></td>
        </tr>
  }
  { (!snapshot.isDragging)&&
        <tr>
          <td className={Style.borderStyle}></td>
          <td className={Style.borderStyle}>Feature</td>
          <td
            className={Style.borderStyle}
            onClick={() => showFeatures(objData.objective)}
          >
           <span className={Style.clickable}> {objData.objectiveFeatures} </span>
          </td>
          <td className={Style.borderStyle} colSpan="4">
            {objData.featureDefineAvg}
          </td>
          <td className={Style.borderStyle} colSpan="4">
            {objData.featureBuildAvg}
          </td>
          <td className={Style.borderStyle} colSpan="4">
            {objData.featureDeployAvg}
          </td>
          <td className={Style.borderStyle} colSpan="4">
            #N/A
          </td>
          <td className={Style.borderStyle} colSpan="5"></td>
        </tr>
  }
   { (!snapshot.isDragging)&&
        <tr style={{ borderBottom: "2px solid cornflowerblue" }}
>
          <td className={Style.borderStyle} ><i
              className="fa fa-circle fa-2x"
              style={{ color: "green" }}
              aria-hidden="true"
            />
          </td>
          <td className={Style.borderStyle} ><i
              className="fa fa-circle"
              style={{ color: "green" }}
              aria-hidden="true"
            />
          </td>
          <td className={Style.borderStyle} ><i
              className="fa fa-circle"
              style={{ color: "green" }}
              aria-hidden="true"
            />
          </td>
          <td  className={Style.borderStyle} colSpan="4">
          {GetStatusIcon(
              Math.min(
                objData.completionFactorDefineArch,
                objData.completionFactorDefineUX,
                objData.completionFactorDefineStory
              )
            )}
          </td>
          <td  className={Style.borderStyle} colSpan="4">
          {GetStatusIcon(
              Math.min(
                objData.completionFactorBuildReady,
                objData.completionFactorBuildStarted,
                objData.completionFactorBuildDone
              )
            )}
          </td>
          <td  className={Style.borderStyle} colSpan="4">
          {GetStatusIcon(
              Math.min(
                objData.completionFactorDeployIntegration,
                objData.completionFactorDeployBeta,
                objData.completionFactorDeployProd
              )
            )}
          </td>
          <td className={Style.borderStyle} colSpan="4"></td>
          <td className={Style.borderStyle} colSpan="2">
            <div
              style={{
                display: "inline-flex",
                justifyContent:"space-evenly"
              }}
            >
              <StackBar
                Prod={objData.featureProgressProd}
                Beta={objData.featureProgressBeta}
                Intg={objData.featureProgressIntg}
                DevDone={objData.featureProgressDevDone}
                Remaining={objData.featureProgressRemaining}
              />
            </div>
          </td>
          <td>Timeline</td>
          <td>
            {objData.featuresScopeStartDate != null
              ? moment(objData.featuresScopeStartDate).format("MM-DD")
              : ""}
          </td>
          <td>
            {objData.featuresReleaseEndDate != null
              ? moment(objData.featuresReleaseEndDate).format("MM-DD")
              : ""}
          </td>
          <td>{objData.daysPlannedTimeline}</td>
          <td className={Style.borderStyle}>{objData.daysElapsedTimeline}</td>
        </tr>}
      </tbody>)}
      </Draggable>    
    );
  };

  const unCollapseBody = () => {
    return (
      <Draggable key={key} draggableId={key+''} index={key}> 
      {(provided,snapshot)=>(
      <tbody  ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}>
        { (snapshot.isDragging)?<tr  style={{backgroundColor:"lightgray",border:"1px black solid"}}>
          <td>
          <b>
              {objData.objective}
           </b>
          </td>
        </tr>
        :
        <tr>
          <td
            className={Style.borderStyle}
            rowSpan="8"
            style={{ borderBottom: "2px solid cornflowerblue" }}
          >
            <b>
              {objData.workspace}
              <br />
              {objData.objectiveDueDate != null
                ? new Date(objData.objectiveDueDate).toLocaleDateString()
                : ""}
            </b>
            <br />
            <b style={{ fontSize: "12px" }}>
              <a
                href="https://onsolve.aha.io/strategic_imperatives/ONSLV-G-12"
                target="_blank"
              >
                {objData.objective}
              </a>
            </b>
          </td>
          <td className={Style.borderStyle}></td>
          <td className={Style.borderStyle}>%Complete</td>
          <td className={Style.borderStyle}></td>
          <td>{objData.percentageCompleteDefineAvg + "%"}</td>
          <td>{objData.percentageCompleteDefineArch + "%"}</td>
          <td>{objData.percentageCompleteDefineUX + "%"}</td>
          <td className={Style.borderStyle}>
            {objData.percentageCompleteDefineStory + "%"}
          </td>
          <td>{objData.percentageCompleteBuildAvg + "%"}</td>
          <td>{objData.percentageCompleteBuildReady + "%"}</td>
          <td>{objData.percentageCompleteBuildStarted + "%"}</td>
          <td className={Style.borderStyle}>
            {objData.percentageCompleteBuildDone + "%"}
          </td>
          <td>{objData.percentageCompleteDeployAvg + "%"}</td>
          <td>{objData.percentageCompleteDeployIntegration + "%"}</td>
          <td>{objData.percentageCompleteDeployBeta + "%"}</td>
          <td className={Style.borderStyle}>
            {objData.percentageCompleteDeployProd + "%"}
          </td>
          <td>{objData.percentageCompleteReleaseAvg + "%"}</td>
          <td>{objData.percentageCompleteReleaseIntegration + "%"}</td>
          <td>{objData.percentageCompleteReleaseBeta + "%"}</td>
          <td className={Style.borderStyle}>
            {objData.percentageCompleteReleaseProd + "%"}
          </td>
          <td></td>
          <td className={Style.borderStyle}></td>
          <td>Scope</td>
          <td>
            {objData.featuresScopeStartDate != null
              ? moment(objData.featuresScopeStartDate).format("MM-DD")
              : ""}
          </td>
          <td>
            {objData.featuresScopeEndDate != null
              ? moment(objData.featuresScopeEndDate).format("MM-DD")
              : null}
          </td>
          <td>{objData.daysPlannedScope}</td>
          <td className={Style.borderStyle}>{objData.daysElapsedScope}</td>
        </tr>
  }
  { (!snapshot.isDragging) &&
        <tr>
          <td className={Style.borderStyle}></td>
          <td className={Style.borderStyle}>%Time Elapsed</td>
          <td className={Style.borderStyle}></td>
          <td>{objData.percentageTimeElapsedDefineAvg + "%"}</td>
          <td>{objData.percentageTimeElapsedDefine + "%"}</td>
          <td>{objData.percentageTimeElapsedDefine + "%"}</td>
          <td className={Style.borderStyle}>
            {objData.percentageTimeElapsedDefine + "%"}
          </td>
          <td>{objData.percentageTimeElapsedBuildAvg + "%"}</td>
          <td>{objData.percentageTimeElapsedBuild + "%"}</td>
          <td>{objData.percentageTimeElapsedBuild + "%"}</td>
          <td className={Style.borderStyle}>
            {objData.percentageTimeElapsedBuild + "%"}
          </td>
          <td>{objData.percentageTimeElapsedDeployAvg + "%"}</td>
          <td>{objData.percentageTimeElapsedDeploy + "%"}</td>
          <td>{objData.percentageTimeElapsedDeploy + "%"}</td>
          <td className={Style.borderStyle}>
            {objData.percentageTimeElapsedDeploy + "%"}
          </td>
          <td>{objData.percentageTimeElapsedReleaseAvg + "%"}</td>
          <td>{objData.percentageTimeElapsedRelease + "%"}</td>
          <td>{objData.percentageTimeElapsedRelease + "%"}</td>
          <td className={Style.borderStyle}>
            {objData.percentageTimeElapsedRelease + "%"}
          </td>
          <td></td>
          <td className={Style.borderStyle}></td>
          <td>Define</td>
          <td>
            {objData.featuresDefineStartDate != null
              ? moment(objData.featuresDefineStartDate).format("MM-DD")
              : ""}
          </td>
          <td>
            {objData.featuresDefineEndDate != null
              ? moment(objData.featuresDefineEndDate).format("MM-DD")
              : ""}
          </td>
          <td>{objData.daysPlannedDefine}</td>
          <td className={Style.borderStyle}>{objData.daysElapsedDefine}</td>
        </tr>
  }
  { (!snapshot.isDragging)&&
        <tr>
          <td className={Style.borderStyle}></td>
          <td className={Style.borderStyle}>Completion Factor</td>
          <td className={Style.borderStyle}></td>
          <td>{objData.completionFactorDefineAvg + "%"}</td>
          <td>{objData.completionFactorDefineArch}</td>
          <td>{objData.completionFactorDefineUX}</td>
          <td className={Style.borderStyle}>
            {objData.completionFactorDefineStory}
          </td>
          <td>{objData.completionFactorBuildAvg + "%"}</td>
          <td>{objData.completionFactorBuildReady}</td>
          <td>{objData.completionFactorBuildStarted}</td>
          <td className={Style.borderStyle}>
            {objData.completionFactorBuildDone}
          </td>
          <td>{objData.completionFactorDeployAvg + "%"}</td>
          <td>{objData.completionFactorDeployIntegration}</td>
          <td>{objData.completionFactorDeployBeta}</td>
          <td className={Style.borderStyle}>
            {objData.completionFactorDeployProd}
          </td>
          <td>{objData.completionFactorReleaseAvg.toFixed(0) + "%"}</td>
          <td>{objData.completionFactorReleaseIntegration}</td>
          <td>{objData.completionFactorReleaseBeta}</td>
          <td className={Style.borderStyle}>
            {objData.completionFactorReleaseProd}
          </td>
          <td></td>
          <td className={Style.borderStyle}></td>
          <td>Build</td>
          <td>
            {objData.featuresBuildStartDate != null
              ? moment(objData.featuresBuildStartDate).format("MM-DD")
              : null}
          </td>
          <td>
            {objData.featuresBuildEndDate != null
              ? moment(objData.featuresBuildEndDate).format("MM-DD")
              : null}
          </td>
          <td>{objData.daysPlannedBuild}</td>
          <td className={Style.borderStyle}>{objData.daysElapsedBuild}</td>
        </tr>
  }
  { (!snapshot.isDragging)&&
        <tr>
          <td className={Style.borderStyle}></td>
          <td className={Style.borderStyle}></td>
          <td className={Style.borderStyle}></td>
          <td className={Style.borderStyle} colSpan="4"></td>
          <td className={Style.borderStyle} colSpan="4"></td>
          <td className={Style.borderStyle} colSpan="4"></td>
          <td className={Style.borderStyle} colSpan="4"></td>
          <td className={Style.borderStyle} colSpan="2"></td>
          <td className={Style.textAlign}>Deploy</td>
          <td>
            {objData.featuresDeployStartDate != null
              ? moment(objData.featuresDeployStartDate).format("MM-DD")
              : " "}
          </td>
          <td>
            {objData.featuresDeployEndDate != null
              ? moment(objData.featuresDeployEndDate).format("MM-DD")
              : " "}
          </td>
          <td>{objData.daysPlannedDeploy}</td>
          <td className={Style.borderStyle}>{objData.daysElapsedDeploy}</td>
        </tr>
  }
  { (!snapshot.isDragging)&&
        <tr>
          <td className={Style.borderStyle}></td>
          <td className={Style.borderStyle}>Stories</td>
          <td className={Style.borderStyle}>{objData.storiesCountOf}</td>
          <td
            className={Style.borderStyle}
            colSpan="4"
            style={{ textAlign: "end", paddingRight: "5px" }}
          >
            {objData.storiesCountOf}
          </td>
          <td></td>
          <td>{objData.storiesReady}</td>
          <td>{objData.storiesStarted}</td>
          <td className={Style.borderStyle}>{objData.storiesDone}</td>
          <td colSpan="2" className={Style.textAlign}>
            {objData.storiesDeployedIntegration}
          </td>
          <td>{objData.storiesDeployedBeta}</td>
          <td className={Style.borderStyle}>{objData.storiesDeployedProd}</td>
          <td className={Style.borderStyle} colSpan="4" rowSpan="2"></td>
          <td className={Style.borderStyle} colSpan="2" rowSpan="2">
            {ProgressTable(objData)}
          </td>
          <td>Release</td>
          <td>
            {objData.featuresReleaseStartDate != null
              ? moment(objData.featuresReleaseStartDate).format("MM-DD")
              : ""}
          </td>
          <td>
            {objData.featuresReleaseEndDate != null
              ? moment(objData.featuresReleaseEndDate).format("MM-DD")
              : ""}
          </td>
          <td>{objData.daysPlannedRelease}</td>
          <td className={Style.borderStyle}>{objData.daysElapsedRelease}</td>
        </tr>
  }
  { (!snapshot.isDragging)&&
        <tr>
          <td className={Style.borderStyle}></td>
          <td className={Style.borderStyle}>Master Feature (count)</td>
          <td className={Style.borderStyle}>
            {objData.objectiveMasterFeatures}
          </td>
          <td>{objData.masterFeatureDefineAvg}</td>
          <td>{objData.masterFeatureArch}</td>
          <td>{objData.masterFeatureUX}</td>
          <td className={Style.borderStyle}>{objData.masterFeatureStory}</td>
          <td>{objData.masterFeatureBuildAvg}</td>
          <td>{objData.masterFeatureReady}</td>
          <td>{objData.masterFeatureStarted}</td>
          <td className={Style.borderStyle}>{objData.masterFeatureDone}</td>
          <td>{objData.masterFeatureDeployedProd}</td>
          <td
            className={Style.borderStyle}
            colSpan="3"
            style={{ textAlign: "end", paddingRight: "10px" }}
          >
            {objData.masterFeatureDeployedProd}
          </td>
          <td className={Style.borderStyle} colSpan="5"></td>
        </tr>
  }
  { (!snapshot.isDragging)&&
        <tr>
          <td className={Style.borderStyle}></td>
          <td className={Style.borderStyle}>Feature (count)</td>
          <td
            className={Style.borderStyle}
            onClick={() => showFeatures(objData.objective)}
          >
            {" "}
            <span className={Style.clickable}>  {objData.objectiveFeatures}</span>
          </td>
          <td>{objData.featureDefineAvg}</td>
          <td>{objData.featuresArch}</td>
          <td>{objData.featuresUX}</td>
          <td className={Style.borderStyle}>{objData.featuresStory}</td>
          <td>{objData.featureBuildAvg}</td>
          <td>{objData.featuresReady}</td>
          <td>{objData.featuresStarted}</td>
          <td className={Style.borderStyle}>{objData.featuresDone}</td>
          <td>{objData.featureDeployAvg}</td>
          <td>{objData.featuresDeployedIntegration}</td>
          <td>{objData.featuresDeployedBeta}</td>
          <td className={Style.borderStyle}>{objData.featuresDeployedProd}</td>
          <td>{objData.featureReleaseAvg}</td>
          <td>{objData.featuresReleasedIntegration}</td>
          <td>{objData.featuresReleaseBeta}</td>
          <td className={Style.borderStyle}>{objData.featuresReleasedProd}</td>
          <td className={Style.borderStyle} colSpan="2"></td>
          <td className={Style.borderStyle} colSpan="5"></td>
        </tr>
  }
  { (!snapshot.isDragging)&&
        <tr style={{ borderBottom: "2px solid cornflowerblue" }}>
          <td className={Style.borderStyle}>
            <i
              className="fa fa-circle fa-2x"
              style={{ color: "green" }}
              aria-hidden="true"
            ></i>
          </td>
          <td className={Style.borderStyle}>
            <i
              className="fa fa-circle fa-1x"
              style={{ color: "green" }}
              aria-hidden="true"
            ></i>
          </td>
          <td className={Style.borderStyle}>
            <i
              className="fa fa-circle"
              style={{ color: "green" }}
              aria-hidden="true"
            ></i>
          </td>
          <td>
            {GetStatusIcon(
              Math.min(
                objData.completionFactorDefineArch,
                objData.completionFactorDefineUX,
                objData.completionFactorDefineStory
              )
            )}
          </td>
          <td>{GetStatusIcon(objData.completionFactorDefineArch)}</td>
          <td>{GetStatusIcon(objData.completionFactorDefineUX)}</td>
          <td className={Style.borderStyle}>
            {GetStatusIcon(objData.completionFactorDefineStory)}
          </td>
          <td>
            {GetStatusIcon(
              Math.min(
                objData.completionFactorBuildReady,
                objData.completionFactorBuildStarted,
                objData.completionFactorBuildDone
              )
            )}
          </td>
          <td>{GetStatusIcon(objData.completionFactorBuildReady)}</td>
          <td>{GetStatusIcon(objData.completionFactorBuildStarted)}</td>
          <td className={Style.borderStyle}>
            {GetStatusIcon(objData.completionFactorBuildDone)}
          </td>
          <td>
            {GetStatusIcon(
              Math.min(
                objData.completionFactorDeployIntegration,
                objData.completionFactorDeployBeta,
                objData.completionFactorDeployProd
              )
            )}
          </td>
          <td>{GetStatusIcon(objData.completionFactorDeployIntegration)}</td>
          <td>{GetStatusIcon(objData.completionFactorDeployBeta)}</td>
          <td className={Style.borderStyle}>
            {GetStatusIcon(objData.completionFactorDeployProd)}
          </td>
          <td>
            {GetStatusIcon(
              Math.min(
                objData.completionFactorReleaseIntegration,
                objData.completionFactorReleaseBeta,
                objData.completionFactorReleaseProd
              )
            )}
          </td>
          <td>{GetStatusIcon(objData.completionFactorReleaseIntegration)}</td>
          <td>{GetStatusIcon(objData.completionFactorReleaseBeta)}</td>
          <td className={Style.borderStyle}>
            {GetStatusIcon(objData.completionFactorReleaseProd)}
          </td>
          <td
            className={Style.borderStyle}
            colSpan="2"
            style={{ height: "77px" }}
          >
            <StackBar
              Prod={objData.featureProgressProd}
              Beta={objData.featureProgressBeta}
              Intg={objData.featureProgressIntg}
              DevDone={objData.featureProgressDevDone}
              Remaining={objData.featureProgressRemaining}
            />
          </td>
          <td>Timeline</td>
          <td>
            {objData.featuresScopeStartDate != null
              ? moment(objData.featuresScopeStartDate).format("MM-DD")
              : ""}
          </td>
          <td>
            {objData.featuresReleaseEndDate != null
              ? moment(objData.featuresReleaseEndDate).format("MM-DD")
              : ""}
          </td>
          <td>{objData.daysPlannedTimeline}</td>
          <td className={Style.borderStyle}>{objData.daysElapsedTimeline}</td>
        </tr>}
      </tbody>)}
      </Draggable>
    );
  };

  if (collapse == true) return collapseBody();
   else return unCollapseBody();
}

export default ObjectiveTableBody;
