import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import Conditional from './Conditional';

export class Loadable extends Component {
    render() {
        return (
            <React.Fragment>
                <Conditional if={this.props.loaded === false}>
                    <Progress animated value="100">Loading...</Progress>                            
                </Conditional>
                <Conditional if={this.props.loaded}>
                    {this.props.children}
                </Conditional>
            </React.Fragment>
        );
    }
}
