import React, { Component } from 'react';
import * as moment from 'moment';

import { Editor } from '../editor';
import { Row, Col, Button, ButtonGroup } from 'reactstrap';
import { SlateReadOnly, SlateEditor } from '../slate';
import { confirmAlert } from 'react-confirm-alert';

import sprintNoteService from '../../services/sprintNotesService';
import blockerService from '../../services/blockersService';

class BlockerRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: !this.props.blocker.id,
            blocker: {...this.props.blocker }
        };
    }

    componentWillReceiveProps(nextProps) {
        const { blocker } = this.props
        if (nextProps.blocker !== blocker) {
            var b = { ...nextProps.blocker };
            this.setState({ blocker: b, editing: !blocker.id });
        }
    }

    save = async () => {
        this.setState({ editing: false });
        await this.props.save(this.state.blocker);
    }

    edit = () => {
        this.setState({ editing: true });
    }

    cancel = () => {
        this.setState({ editing: false, blocker: { ...this.props.blocker } })
    }

    reslove = async () => {
        var blocker = { ...this.state.blocker };
        blocker.closedOn = moment.utc().format();
        await this.props.save(blocker);
    }

    reopen = () => {
        var blocker = { ...this.state.blocker };
        blocker.closedOn = null;
        this.setState({ blocker: blocker });
        this.save();
    }

    delete = async () => {
        confirmAlert({
            title: `Delete Blocker?`,
            message: 'Are you sure you want to delete this blocker?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await blockerService.deleteBlocker(this.state.blocker.id);
                        await this.props.reload();
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    renderStatus(blocker) {
        return <td className={blocker.closedOn ? 'resolved' : 'blocked'}>
            <span className="status">{blocker.closedOn ? 'Resolved' : 'Blocked'}</span>
        </td>;
    }

    editor(blocker) {
        var descriptionChanged = v => {
            var blocker = { ...this.state.blocker };
            blocker.descritpion = v;
            this.setState({ blocker });
        }

        return <tr>
            <td><SlateEditor value={blocker.descritpion} onChange={descriptionChanged}/></td>
            <td>{blocker.createdOn && moment(blocker.createdOn).format('l')}</td>
            {this.renderStatus(blocker)}
            <td>{blocker.closedOn && moment(blocker.closedOn).format('l')}</td>
            <td>
                <ButtonGroup>
                    <Button outline color="primary" onClick={this.save}>{blocker.id ? 'Save' : 'Add'}</Button>
                    {blocker.id && <Button outline color="danger" onClick={this.cancel}>Cancel</Button>}
                </ButtonGroup>
            </td>
        </tr>
    }

    readOnly(blocker) {
        return <tr>
            <td>{blocker.descritpion && <SlateReadOnly value={blocker.descritpion} />}</td>
            <td>{ moment(blocker.createdOn).format('l') }</td>
            {this.renderStatus(blocker)}
            <td>{blocker.closedOn && moment(blocker.closedOn).format('l')}</td>
            <td>
                <ButtonGroup>
                    <Button outline color="primary" onClick={this.edit}>Edit</Button>
                    {blocker.closedOn && <Button outline color="primary" onClick={this.reopen}>Reopen</Button>}
                    {!blocker.closedOn && <Button outline color="primary" onClick={this.reslove}>Reslove</Button>}
                    <Button outline color="danger" onClick={this.delete}>Delete</Button>
                </ButtonGroup>
            </td>
        </tr>
    }

    render() {
        var { blocker } = this.state;
        return this.state.editing ? this.editor(blocker) : this.readOnly(blocker);
    }
}

class Blockers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blockers: []
        };
    }

    save = async (blocker) => {
        const { team } = this.props

        await blockerService.mergeBlocker(team.id, blocker);
        await this.fetch();
    }

    fetch = async () => {
        await this.reload(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const { sprint, team } = this.props
        if (nextProps.sprint !== sprint || nextProps.team !== team) {
            this.reload(nextProps);
        }
    }

    componentWillMount() {
        this.reload(this.props);
    }

    async reload({ sprint, team }) {
        if (sprint && team) {
            var blockers = await blockerService.getBlockers(sprint.id, team.id);
            this.setState({ blockers });
        } else {
            this.setState({ blockers: [] });
        }
    }

    emptyBlocker() {
       return { id: null, descritpion: null };
    }

    render() {
        var { blockers } = this.state;
        var { addAllowed } = this.props;

        var blocker = this.emptyBlocker();

        return <table className="table blockers">
            <thead>
                <tr>
                    <th>Description</th>
                    <th>Created On</th>
                    <th>Status</th>
                    <th>Resloved On</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {blockers.map(b => <BlockerRow key={b.id} blocker={b} save={this.save} reload={this.fetch} />)}
                {addAllowed && <BlockerRow blocker={blocker} save={this.save} reload={this.fetch} />}
            </tbody>
        </table>;
    }
}

export class SprintTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            notes: this.emptyNotes()
        };
    }

    emptyNotes() {
        return {
            'retrospective-discussed': null,
            'retrospective-action-items': null,
            'sprint-planning-sprint-goal': null,
            'sprint-planning-risks': null,
            'sprint-planning-additional-notes': null
        };
    }

    componentWillReceiveProps(nextProps) {
        const { sprint, team } = this.props
        if (nextProps.sprint !== sprint || nextProps.team !== team ) {
            this.reload(nextProps);
        }
    }

    componentWillMount() {
        this.reload(this.props);
    }

    async reload({ sprint, team }) {
        if (sprint && team) {
            var notes = await sprintNoteService.getNotes(sprint.id, team.id);
            this.setState({ notes: notes.sprintNotes });
        } else {
            this.setState({
                notes: this.emptyNotes()
            });
        }
    }

    saveSection(name) {
        return async (value) => {
            var { sprint, team } = this.props;
            var notes = this.state.notes;
            notes[name] = await sprintNoteService.setNotes(sprint.id, team.id, name, value);
            this.setState({ notes });
        }
    }

    render() {
        var { notes } = this.state;
        var { sprint, team } = this.props;

        return <section>
            <h4>Sprint Planning Notes</h4>
            <Row>
                <Col>
                    <h5>Sprint Goal</h5>
                    <Editor canEdit={true} value={notes["sprint-planning-sprint-goal"] || null} onSave={this.saveSection("sprint-planning-sprint-goal")} />
                </Col>
                <Col>
                    <h5>Risks</h5>
                    <Editor canEdit={true} value={notes["sprint-planning-risks"] || null} onSave={this.saveSection("sprint-planning-risks")} />
                </Col>
                <Col>
                    <h5>Additional Notes</h5>
                    <Editor canEdit={true} value={notes["sprint-planning-additional-notes"] || null} onSave={this.saveSection("sprint-planning-additional-notes")} />
                </Col>
            </Row>
            <br />
            <h4>Retrospective Notes</h4>
            <Row>
                <Col>
                    <h5>Discussed</h5>
                    <Editor canEdit={true} value={notes["retrospective-discussed"] || null} onSave={this.saveSection("retrospective-discussed")}/>
                </Col>
                <Col>
                    <h5>Action Items</h5>
                    <Editor canEdit={true} value={notes["retrospective-action-items"] || null} onSave={this.saveSection("retrospective-action-items")}/>
                </Col>
            </Row>
            <br />
            <h4>Blocking Issues</h4>
            {moment(sprint.start).isAfter(moment()) ?
                <div>No Blocker for future sprints</div> :
                <Blockers sprint={sprint} team={team} addAllowed={moment(sprint.end).isSameOrAfter(moment())} />
            }
            
        </section>;
    }
}