import React, { Component } from 'react';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { ButtonGroup, Button, Input } from 'reactstrap';
import { SearchDropDown } from '../../components/SearchDropDown';

import common from '../../components/common';

import officeService from '../../services/officeService';

export class OfficeRow extends Component {
    constructor(props) {
        super(props);
        var o = this.office();
        this.state = {
            office: { ...o },
            editing: !o.id,
            loading: false,
            vendors: props.vendors || [],
            timezones: props.timezones || [],
            timezone: {},
            vendor: {}
        }

        this.handleInputChange = common.createInputHandler(this, 'office');
        this.selectVendor = common.createSearchDropDownHandelChange(this, 'office', 'vendor', 'primaryVendorId');
        this.selectTimeZone = common.createSearchDropDownHandelChange(this, 'office', 'timezone', 'timeZoneId');
    }

    office() {
        return this.props.office || { name: '', primaryVendorId: this.props.defaultVendorId };
    }

    edit = () => this.setState({ editing: true });
    save = async () => {
        
        await officeService.save(this.state.office);

        this.props.reload();
    }

    cancel = () => {
        this.setState({
            editing: false,
            office: {...this.office() }
        });
    }

    delete = async ( ) => {
        confirmAlert({
            title: `Delete ${this.state.office.name} office?`,
            message: 'Are you sure you want to delete this office, any members will move to the "Unset" office?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await officeService.deleteOffice(this.state.office.id);
                        this.props.reload();
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    editor() {
        var o = this.state.office;
        var vs = this.state.vendors;

        return <tr>
            <td><Input type="text" name="name" value={o.name} onChange={this.handleInputChange} /></td>
            <td>
                <SearchDropDown
                    onSelection={this.selectTimeZone}
                    display={x => x.name}
                    title="Select Timezone"
                    initialSet={() => this.state.timezones}
                    initialSelectionId={o.timeZoneId}
                    max={20}
                />
            </td>
            <td>
                {!this.props.isAdmin && (vs.find(v => v.id === o.primaryVendorId) || {}).name}
                {this.props.isAdmin && <SearchDropDown
                    onSelection={this.selectVendor}
                    display={x => x.name}
                    title="Select Vendor"
                    initialSet={() => this.state.vendors}
                    initialSelectionId={o.primaryVendorId}
                />}
            </td>
            <td>
                <ButtonGroup>
                    <Button outline color="primary" onClick={this.save}>{o.id ? "Save" : "Add"}</Button>
                    {this.state.office.id && <Button outline color="danger" onClick={this.cancel} >Cancel</Button>}
                </ButtonGroup>
            </td>
        </tr>
    }

    readonly() {
        var o = this.state.office;
        var ts = this.state.timezones;
        var vs = this.state.vendors;
        var editHoldays = () => this.props.editHolidays(o);

        return <tr>
            <td>{o.name}</td>
            <td>{(ts.find(t => t.id === o.timeZoneId) || {}).name}</td>
            <td>{(vs.find(v => v.id === o.primaryVendorId) || {}).name}</td>
            <td>
                <ButtonGroup>
                    <Button outline color="primary" onClick={this.edit} >Edit</Button>
                    {this.state.office.id && <Button outline color="primary" onClick={editHoldays} >Edit Holdays</Button>}
                    {(this.state.office.id && this.props.isAdmin) && <Button outline color="danger" onClick={this.delete}>Delete</Button>}
                </ButtonGroup>
            </td>
        </tr>
    }

    render() {
        return this.state.editing ? this.editor() : this.readonly();
    }
}