import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import PODService from "../../services/PODService";
import FlashReportObjectiveMappingService from "../../services/FlashReportObjectiveMappingService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Option = (props) => {
    return (
        <div>
            {" "}
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                {"  "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const MultiValue = (props) => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);

const animatedComponents = makeAnimated();

export default function PODObjectives(props) {
    const [podOptions, setPodOptions] = useState([]);
    const [podObjectiveOptions, setPodObjectiveOptions] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        updatePodOption(gridData);
    }, [gridData])

    const getDefaultGridData = () => ({
        gridData: [],
    });

    const getDefaultObjectiveOptions = () => ({
        podObjectiveOptions: [],
    });

    const loadPODOptions = async () => {
        let clusterServiceData = await PODService.GetPodOptionsData();
        let podData = clusterServiceData.map(function (obj) {
            return { value: obj.id, label: obj.podName };
        });

        setPodOptions(podData);
    };

    const loadObjectivesOptions = async () => {
        const podObjectiveOptions = await FlashReportObjectiveMappingService.GetGoalOptionsData();
        const objData = podObjectiveOptions.map(function (obj) {
            return { value: obj.id, label: obj.goalName };
        });

        setPodObjectiveOptions({ ...getDefaultObjectiveOptions() })
        setPodObjectiveOptions(objData);
    };

    const loadData = async () => {
        setIsLoading(true);

        loadPODOptions();
        loadObjectivesOptions();

        let JsonData = await FlashReportObjectiveMappingService.GetPodGoalGridData();

        if (JsonData.length > 0) {
            let newData = [];
            let count = 1;

            JsonData.map(function (podgoal) {
                newData.push({
                    recNo: count,
                    podId: podgoal.podId,
                    podName: podgoal.podName,
                    goals: podgoal.goals,
                });
                count++;
            });

            setGridData({ ...getDefaultGridData() });
            setGridData(newData);
        } else {
            setGridData({ ...getDefaultGridData() });
            setGridData([
                {
                    recNo: 0,
                    podId: 0,
                    podName: null,
                    goals: [],
                },
            ]);
        }
        setIsLoading(false);
    };

    const handlePodChange = (index, selected) => {
        let currentGridData = [...gridData];
        currentGridData[index].podId = selected.value;
        currentGridData[index].podName = selected.label;
        setGridData(currentGridData);
    };

    const updatePodOption = (currentGridData) => {
        podOptions.filter(pod => {
            if (currentGridData.length > 0) {
                let exist = currentGridData.some(x => x.podId === pod.value);
                if (exist) {
                    pod.isdisabled = true;
                }
                else {
                    pod.isdisabled = false;
                }
            }
        })
        setPodOptions(podOptions);
    }

    const handleObjectiveChange = (index, podId, selected) => {
        let currentGridData = [...gridData];
        let currentPod = currentGridData.filter(x => x.podId == podId);
        let existingObjectivesList = currentPod.flatMap(x => x.goals)
        let selectedObjectiveList = selected.map(function (newitem) {
            if (existingObjectivesList) {
                let existingItem = existingObjectivesList.filter(
                    (obj) => obj.id == newitem.value
                );
                if (existingItem !== undefined && existingItem[0])
                    return {
                        id: newitem.value,
                        goalName: newitem.label
                    };
                else
                    return {
                        id: newitem.value,
                        goalName: newitem.label
                    };
            } else return { id: newitem.value, goalName: newitem.label };
        });

        currentGridData[index].goals = selectedObjectiveList;
        setGridData(currentGridData);
    };

    const saveClick = async () => {
        setIsLoading(true);
        let newData = gridData.map(function (obj) {
            return { podId: obj.podId, podName: obj.podName, goals: obj.goals };
        });

        let res = await FlashReportObjectiveMappingService.PostPodObjectiveMappingsData(newData);
        if (res.ok) alert("Flash Report Objectives saved successfully.");
        else alert("Something went wrong");

        setIsLoading(false);
    }

    const handleAdd = (recNo, index1, e) => {
        setGridData((currentGridData) => [
            ...currentGridData,
            {
                recNo: recNo + 1,
                podId: 0,
                podName: "",
                goals: [],
            },
        ]);
    };

    const handlePodDelete = (recNo, index, podId, e) => {
        let ans = window.confirm(
            "Are you sure you want to delete Row No. " + (index + 1) + " ?"
        );
        if (ans === true) {
            let currentGridData = [...gridData];
            currentGridData = currentGridData.filter(
                (obj) => obj.recNo !== recNo
            );
            setGridData(currentGridData);
        }
    };

    const renderFields = (data) => {
        if (data.length > 0) {
            return data.map((data, index) => loadWithExistingData(data, index));
        }
    };

    const loadWithExistingData = (data, index) => {
        const { recNo, podId, podName, goals } = data; //destructuring

        let existingPodList = [];
        let existingObjectivesList = [];

        if (podId !== 0) {
            existingPodList = { value: podId, label: podName };

            if (goals !== null && goals !== undefined) {
                existingObjectivesList = goals.map(function (obj) {
                    return { value: obj.id, label: obj.goalName };
                });
            }
        }

        return (
            <tr key={recNo}>
                <td className="col-sm-4">
                    <Select
                        name="podName"
                        id={recNo}
                        defaultValue={existingPodList}
                        options={podOptions}
                        classNamePrefix="select"
                        isOptionDisabled={(option) => option.isdisabled}
                        onChange={(e) => handlePodChange(index, e)}
                    />
                </td>
                <td className="col-sm-7">
                    <Select
                        id={recNo}
                        key={recNo}
                        defaultValue={existingObjectivesList}
                        isMulti
                        options={podObjectiveOptions}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option, MultiValue, animatedComponents }}
                        allowSelectAll={true}
                        menuPortalTarget={document.body}
                        onChange={(e) => handleObjectiveChange(index, podId, e)}
                    />
                </td>
                <td className="col-sm-1">
                    <div className="row" style={{ margin: 5 }}>
                        {gridData.length > index + 1 && (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">Delete POD</Tooltip>}
                            >
                                <a onClick={(e) => handlePodDelete(recNo, index, podId, e)}>
                                    <svg
                                        width="35"
                                        height="35"
                                        fill="currentColor"
                                        className="bi bi-trash"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path
                                            fillRule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                        />
                                    </svg>
                                </a>
                            </OverlayTrigger>
                        )}
                        {gridData.length <= index + 1 && (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">Add POD</Tooltip>}
                            >
                                <a onClick={(e) => handleAdd(recNo, index, e)}>
                                    <svg
                                        width="35"
                                        height="35"
                                        fill="currentColor"
                                        className="bi bi-plus-square"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                                        />
                                    </svg>
                                </a>
                            </OverlayTrigger>
                        )}
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <div className="container">
            <h2>Flash Report Objectives </h2>

            <hr className="mt-2 mb-3" />

            <div className="row spinner-row d-flex justify-content-center">
                {isLoading && (
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
            </div>

            <div className="row table-mycontainer ">
                <div className="col">
                    <table
                        className="table table-sm table-hover .table-responsive-sm"
                        aria-labelledby="tabelLabel"
                        style={{ overflowWrap: "break-word" }}
                    >
                        <thead className="stickyHeader">
                            <tr>
                                <th className="col-sm-3">POD Name</th>
                                <th className="col-sm-3">Goal Name</th>
                            </tr>
                        </thead>
                        <tbody>{renderFields(gridData)}</tbody>
                    </table>
                </div>
            </div>
            <div className="row footer-mycontainer">
                <div className="col">
                    <hr className="mt-2 mb-3" />
                    <button
                        onClick={saveClick}
                        type="button"
                        className="btn btn-primary"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}
