import React from 'react';

function CapexMonthlyGroupData(props) {
    return (
        <table className="table table-striped" align="center" >
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Group Location</th>
                    <th>Expensed Hours</th>
                    <th>Capitalized Hours</th>
                    <th>Capitalized Ptc</th>
                </tr>
            </thead>
            <tbody>
                {(props.capextotalData.length != 0) ? props.capextotalData.map((data, index) =>
                    <tr key={index} style={{ borderTop: (index + 1 == props.capextotalData.length) && "3px solid #87A5D9" }}>
                        <td>{data.type || <b>Total</b>}</td>
                        <td>{data.groupLocation}</td>
                        <td style={{ fontWeight: (index + 1 == props.capextotalData.length) && "bold" }}>{data.expenseHour}</td>
                        <td style={{ fontWeight: (index + 1 == props.capextotalData.length) && "bold" }}>{data.capitalizedHours}</td>
                        <td style={{ fontWeight: (index + 1 == props.capextotalData.length) && "bold" }}>{data.capitalizedPercent.toFixed(2)}</td>
                    </tr>
                ) :
                    <tr><td colSpan='5'>NO DATA TO SHOW</td></tr>
                }
            </tbody>
        </table>
    );
}

export default CapexMonthlyGroupData;
