export const GetJiraLastUpdateDate= async ()=>{
    const jiraResponseDate = await fetch(`ProductDashboard/GetDataUpdateDate`);
    return await jiraResponseDate;
}

export const GetActiveSprintsData= async ()=>{
    const response = await fetch(`ActiveSprint`);
    return await response.json();
}

export default{
    GetJiraLastUpdateDate,
    GetActiveSprintsData
}