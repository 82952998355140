import React, { Component } from 'react';
import officeService from '../../services/officeService';
import profileService from '../../services/profileService';
import { confirmAlert } from 'react-confirm-alert';
import { Loadable } from '../../components/Loadable';
import common from '../../components/common';

import { Row, Col, Button, ButtonGroup, Input } from 'reactstrap';
import { SearchDropDown } from '../../components/SearchDropDown';
import * as moment from 'moment';

class PTORow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pto: props.pto || { name: '', startOn: '', endOn: '' },
            editing: props.editing || false
        }

        this.handleInputChange = common.createInputHandler(this, 'pto');
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.pto && nextProps.pto.id && this.state.editing)
            this.setState({ editing: false, pto: nextProps.pto });
    }

    edit = () => {
        this.setState({ editing: true });
    }

    cancel = () => {
        this.setState({ editing: false });
    }

    save = () => {
        var pto = this.state.pto;

        if (!this.state.pto.id)
            this.setState({ pto: { name: '', startOn: '', endOn: '' } });
        this.props.save(pto);
    }

    delete = () => {
        confirmAlert({
            title: `Delete ${this.state.pto.name}?`,
            message: 'Are you sure you want to delete this PTO?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        this.props.delete(this.state.pto);
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    readonly() {
        var pto = this.state.pto;
        return <Row>
            <Col>{pto.name}</Col>
            <Col>{moment(pto.startOn).format('l')}</Col>
            <Col>{moment(pto.endOn).format('l')}</Col>
            <Col xs="2">
                <ButtonGroup>
                    <Button outline onClick={this.edit}>Edit</Button>
                    <Button outline color="danger" onClick={this.delete}>Delete</Button>
                </ButtonGroup>
            </Col>
        </Row>;
    }

    editing() {
        var pto = this.state.pto;
        return <Row>
            <Col><Input name="name" type="text" value={pto.name} onChange={this.handleInputChange}/></Col>
            <Col><Input name="startOn" type="date" value={common.dateFormat(pto.startOn)} valid={pto.startOn} onChange={this.handleInputChange}/></Col>
            <Col><Input name="endOn" type="date" value={common.dateFormat(pto.endOn)} valid={pto.endOn && ( pto.endOn === pto.startOn || moment(pto.endOn).isAfter(pto.startOn))} onChange={this.handleInputChange}/></Col>
            <Col xs="2">
                <ButtonGroup>
                    <Button outline onClick={this.save} disabled={!pto.startOn || !pto.endOn || moment(pto.endOn).isBefore(pto.startOn)} >{pto.id ? "Save" : "Add"}</Button>
                    {pto.id && <Button outline onClick={this.cancel} >Cancel</Button>}
                </ButtonGroup>
            </Col>
        </Row>;
    }

    render() {
        return this.state.editing ? this.editing() : this.readonly();
    }
}

export class ProfilePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: {},
            newProfile: {},
            editing: false,
            loading: false,
            offices: [],
            pto: [],
            selectedOffice: {}
        };

        this.handleInputChange = common.createInputHandler(this, 'newProfile');
        this.changeOffice = common.createSearchDropDownHandelChange(this, 'newProfile', 'selectedOffice', 'officeId');
    }

    componentDidMount() {
        this.reload();
    }

    savePTO = async (p) => {
        var pto = await profileService.updateMyPto(p);
        this.setState({ pto });
    }

    deletePTO = async (p) => {
        var pto = await profileService.deleteMyPto(p.id);
        this.setState({ pto });
    }

    async reload() {
        this.setState({ loading: true });

        var [profile, offices, pto] = await Promise.all([
            profileService.getMy(),
            officeService.getAll(),
            profileService.getMyPto()
        ]);
        this.setState({ profile, offices, pto, loading:false });
    }

    edit = () => {
        var newProfile = { ...this.state.profile };
        this.setState({ editing: true, newProfile })
    }

    cancel = () => {
        this.setState({ editing: false, newProfile: {} });
    }

    save = async () => {
        var profile = await profileService.updateMy(this.state.newProfile);
        this.setState({ editing: false, profile });
    }

    renderProfile() {
        var editing = this.state.editing;
        var profile = this.state.profile;
        var office = this.state.offices.find(o => o.id === profile.officeId) || {};

        return <Col md="4">
            <Row>
                <Col xs="4"><label>Preferred Name</label></Col>
                <Col xs="8">
                    {!editing && profile.preferredName}
                    {editing && <Input name="preferredName" type="text" value={this.state.newProfile.preferredName} onChange={this.handleInputChange} />}
                </Col>
            </Row>
            <Row>
                <Col xs="4"><label>Legal Name</label></Col>
                <Col xs="8">{profile.legalName}</Col>
            </Row>
            <Row>
                <Col xs="4"><label>Given Name</label></Col>
                <Col xs="8">{profile.givenName}</Col>
            </Row>
            <Row>
                <Col xs="4"><label>Surname</label></Col>
                <Col xs="8">{profile.surname}</Col>
            </Row>
            <Row>
                <Col xs="4"><label>OnSolve Email</label></Col>
                <Col xs="8">{profile.onSolveEmail}</Col>
            </Row>
            <Row>
                <Col xs="4"><label>Employee Number</label></Col>
                <Col xs="8">
                    {!editing && profile.employeeNumber}
                    {editing && <Input name="employeeNumber" type="text" value={this.state.newProfile.employeeNumber} onChange={this.handleInputChange} />}
                </Col>
            </Row>
            <Row>
                <Col xs="4"><label>Primary Function</label></Col>
                <Col xs="8">{profile.primaryFunction}</Col>
            </Row>
            <Row>
                <Col xs="4"><label>Teams</label></Col>
                <Col xs="8">{profile.teams}</Col>
            </Row>
            <Row>
                <Col xs="4"><label>Office</label></Col>
                <Col xs="8">
                    {!editing && `${office.name} | ${office.timeZone}`}
                    {editing && <SearchDropDown
                        onSelection={this.changeOffice}
                        display={x => `${x.name} | ${x.timeZone}`}
                        title={this.state.selectedOffice.name}
                        initialSet={officeService.getAll}
                        initialSelectionId={this.state.newProfile.officeId}
                    />}
                </Col>
            </Row>
            <h4>Linked Accounts</h4>
            <Row>
                <Col xs="4"><label>Git Lab</label></Col>
                <Col xs="8">{this.state.profile.gitLabUser || 'Unlinked'}</Col>
            </Row>
            <Row>
                <Col xs="4"><label>Git Prime Id</label></Col>
                <Col xs="8">{this.state.profile.gitPrimeId || 'Unlinked'}</Col>
            </Row>
            <Row>
                <Col xs="4"><label>Jira Id</label></Col>
                <Col xs="8">{this.state.profile.jiraId || 'Unlinked'}</Col>
            </Row>
            <Row>
                <Col>
                    {!editing && <Button color="primary" outline onClick={this.edit}>Edit</Button>}
                    {editing && <ButtonGroup>
                        <Button outline color="primary" onClick={this.save}>Save</Button>
                        <Button outline onClick={this.cancel}>Cancel</Button>
                    </ButtonGroup>
                    }
                </Col>
            </Row>
        </Col>
    }

    renderPTO() {
        return <Col md="8">
            <h4>PTO</h4>
            <Row>
                <Col>Name</Col>
                <Col>Start</Col>
                <Col>End</Col>
                <Col xs="2"></Col>
            </Row>
            {this.state.pto.map(p => <PTORow key={p.id} pto={p} save={this.savePTO} delete={this.deletePTO} />)}
            <PTORow editing={true} save={this.savePTO} delete={this.deletePTO}/>
        </Col>;
    }

    render() {
        return <section>
            <h3>Profile</h3>
            <Loadable loaded={!this.loading}>
                <Row>
                    {this.renderProfile()}
                    {this.renderPTO()}
                </Row>
            </Loadable>
        </section>;
    }
}