import moment from "moment";
import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from 'reactstrap';
import ExportExcel from "../../components/ExportExcel";
import RnDTeamResourceReportService from "../../services/RnDTeamResourceReportService";
import { Loadable } from "../../components/Loadable";
import classnames from 'classnames';
import Style from "../../pages/ProgramOperations/RnDTeamResourceReport.module.css";
import common from "../../components/common";
import ResultByQueryTable from "../../components/SortableGridTable/ResultByQueryTable";
import UserSelectionDropDown from "../../components/ObjectiveDashBoard/UserSelectionDropDown";

const TeamMetricsTab = "1";
const TeamResourcesTab = "2";
const Ascending = "ascending";
const Descending = "descending";

export default function RnDTeamResourceReport(props) {

    const [activeTab, setTab] = useState(TeamMetricsTab);
    const [isLoader, setIsLoader] = useState(false);
    const [jiraRefreshDate, setJiraRefreshDate] = useState("");
    const [ahaRefreshDate, setAhaRefreshDate] = useState("");
    const [teamMetricsData, setTeamMetricsData] = useState([]);
    const [teamResourcesData, setTeamResourcesData] = useState([]);

    const [filteredQueryResultTeamMetrics, setFilteredQueryResultTeamMetrics] = useState([]);
    const [showTeamMetrics, setShowTeamMetrics] = useState(false);
    const [sortOrderTeamMetrics, setSortOrderTeamMetrics] = useState(Ascending);
    const [filterNameTeamMetrics, setFilterNameTeamMetrics] = useState();
    const [distinctAttributeValuesTeamMetrics, setDistinctAttributeValuesTeamMetrics] =
        useState(teamMetricsData);

    const [filteredQueryResultTeamResources, setFilteredQueryResultTeamResources] = useState([]);
    const [showTeamResources, setShowTeamResources] = useState(false);
    const [sortOrderTeamResources, setSortOrderTeamResources] = useState(Ascending);
    const [filterNameTeamResources, setFilterNameTeamResources] = useState();
    const [distinctAttributeValuesTeamResources, setDistinctAttributeValuesTeamResources] =
        useState(teamResourcesData);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        setFilteredQueryResultTeamMetrics(teamMetricsData);
    }, [teamMetricsData]);

    useEffect(() => {
        setFilteredQueryResultTeamResources(teamResourcesData);
    }, [teamResourcesData]);

    useEffect(() => {
        if (activeTab === TeamMetricsTab)
            setFilteredQueryResultTeamMetrics([...teamMetricsData])
        else
            setFilteredQueryResultTeamResources([...teamResourcesData])
    }, [activeTab]);

    const selectionListTeamMetrics = (attribute,) => {
        setFilterNameTeamMetrics(attribute);
        let distinctValue = common.getDistinctValues(
            teamMetricsData,
            attribute,
        );
        setDistinctAttributeValuesTeamMetrics(distinctValue);
        setShowTeamMetrics(true);
    };

    const toggleSortOrderTeamMetrics = (property) => {
        if (sortOrderTeamMetrics == Ascending) setSortOrderTeamMetrics(Descending);
        if (sortOrderTeamMetrics == Descending) setSortOrderTeamMetrics(Ascending);
        sortDataTeamMetrics(property, sortOrderTeamMetrics);
    };

    const sortDataTeamMetrics = (property) => {
        let sort_order;
        if (sortOrderTeamMetrics == Ascending) sort_order = 1;
        if (sortOrderTeamMetrics == Descending) sort_order = -1;
        let sortData = [...filteredQueryResultTeamMetrics].sort(
            common.dynamicSort(property, sort_order),
        );
        setFilteredQueryResultTeamMetrics(sortData);
    };

    const selectionListTeamResources = (attribute,) => {
        setFilterNameTeamResources(attribute);
        let distinctValue = common.getDistinctValues(
            teamResourcesData,
            attribute,
        );
        setDistinctAttributeValuesTeamResources(distinctValue);
        setShowTeamResources(true);
    };

    const toggleSortOrderTeamResources = (property) => {
        if (sortOrderTeamResources == Ascending) setSortOrderTeamResources(Descending);
        if (sortOrderTeamResources == Descending) setSortOrderTeamResources(Ascending);
        sortDataTeamResources(property, sortOrderTeamResources);
    };

    const sortDataTeamResources = (property) => {
        let sort_order;
        if (sortOrderTeamResources == Ascending) sort_order = 1;
        if (sortOrderTeamResources == Descending) sort_order = -1;
        let sortData = [...filteredQueryResultTeamResources].sort(
            common.dynamicSort(property, sort_order),
        );
        setFilterNameTeamResources(sortData);
    };

    const loadData = async () => {
        setIsLoader(false);
        let jiraResponseDate = await fetch(`ProductDashboard/GetDataUpdateDate`);
        let jiradateString = await jiraResponseDate.text();
        let jiradate = new Date(jiradateString + " UTC");
        setJiraRefreshDate(jiradate.toLocaleString());

        let ahaResponseDate = await fetch(`ProductDashboard/GetAhaDataUpdateDate`);
        let ahaDateString = await ahaResponseDate.text();
        let ahadate = new Date(ahaDateString + " UTC");
        setAhaRefreshDate(ahadate.toLocaleString());

        let data = await RnDTeamResourceReportService.GetRnDTeamResourceData();
        setTeamMetricsData(data["teamMetrics"]);
        setTeamResourcesData(data["teamResources"]);
        setIsLoader(true);
    };

    return (
        <div>
            <div className="row justify-content-end">
                <div className="col-auto"></div>
                <div className="col-auto">
                    <div className="mt-2 d-flex align-items-end flex-column">
                        <ExportExcel sheetData={teamMetricsData} sheetData2={teamResourcesData} fileName={"R&D Team & Resource Report " + moment(new Date()).format("MM-DD-YYYY-hh-mm-A")} buttonName="Export R&D Team & Resource Report" sheetName="Team Metrics" sheetName2="Team Resources" />
                    </div>
                </div>
                <div className="col-auto">
                    <div className="d-flex align-items-end flex-column">
                        <div>
                            <p className={Style.pstyle}>
                                Last Aha Refresh: {ahaRefreshDate}
                            </p>
                            <p className={Style.pstyle2}>
                                Last Jira Refresh: {jiraRefreshDate}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === TeamMetricsTab })}
                                onClick={() => {
                                    setTab(TeamMetricsTab);
                                }}
                            >
                                Team Metrics
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === TeamResourcesTab })}
                                onClick={() => {
                                    setTab(TeamResourcesTab);
                                }}
                            >
                                Team Resources
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div>
                    <Loadable loaded={isLoader}>
                        <TabContent activeTab={activeTab}>
                            <div className={Style.notes}>* Data displayed is based on the previous 7 days. Reporting is based on a weekly Friday refresh.</div>
                            <TabPane tabId={TeamMetricsTab}>
                                &nbsp;
                                {filteredQueryResultTeamMetrics.length > 0 ? (
                                    <ResultByQueryTable
                                        objData={filteredQueryResultTeamMetrics}
                                        selectionList={selectionListTeamMetrics}
                                        toggleSortOrder={toggleSortOrderTeamMetrics}
                                    />
                                ) : (
                                    <div>
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr>
                                                    <td>No Data to Display</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </TabPane>

                            <TabPane tabId={TeamResourcesTab}>
                                &nbsp;
                                {filteredQueryResultTeamResources.length > 0 ? (
                                    <ResultByQueryTable
                                        objData={filteredQueryResultTeamResources}
                                        selectionList={selectionListTeamResources}
                                        toggleSortOrder={toggleSortOrderTeamResources}
                                    />
                                ) : (
                                    <div>
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr>
                                                    <td>No Data to Display</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </TabPane>

                        </TabContent>
                    </Loadable>
                    {showTeamMetrics && (
                        <UserSelectionDropDown
                            show={showTeamMetrics}
                            setshow={setShowTeamMetrics}
                            attributeArray={distinctAttributeValuesTeamMetrics}
                            originalData={teamMetricsData}
                            filterName={filterNameTeamMetrics}
                            filterFunction={setFilteredQueryResultTeamMetrics}
                        />
                    )}

                    {showTeamResources && (
                        <UserSelectionDropDown
                            show={showTeamResources}
                            setshow={setShowTeamResources}
                            attributeArray={distinctAttributeValuesTeamResources}
                            originalData={teamResourcesData}
                            filterName={filterNameTeamResources}
                            filterFunction={setFilteredQueryResultTeamResources}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
