import React from 'react';

function DepartmentTable(prop) {
  return (
    <div>
      <button className="btn btn-primary" onClick={prop.add}>
        Add Department
      </button>
      <table className="table table-striped" aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <td>ID</td>
            <td>Name</td>
            <td>Manager</td>
            <td>Parent Department</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {prop.data.map(d => (
            <tr key={d.id}>
              <td> {d.id} </td>
              <td> {d.name} </td>
              <td> {d.managerName} </td>
              <td> {d.parentDepartmentName} </td>
              <td>
                   <button className="btn btn-primary" onClick={() => prop.update(d.id)}>Modify </button>
                   <button className="btn btn-danger" onClick={() => prop.delete(d.id)}>Delete </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DepartmentTable;
