import './site.scss';

import 'bootstrap/dist/js/bootstrap.bundle.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import queryString from 'query-string';
import jwtDecode from 'jwt-decode';
import { Claims } from './utility/claims';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const parsedHash = queryString.parse(window.location.hash);

var token = parsedHash.id_token;
var appToken = parsedHash.app_token;

if (appToken == null) {
    var storedToken = window.localStorage.getItem('appToken');

    if (storedToken != null) {

        appToken = storedToken;
    }

}

const setupApp = (data) => {


    var rawClaims = jwtDecode(data.jwtToken);

    var claims = new Claims(rawClaims);

    ReactDOM.render(
        <BrowserRouter basename={baseUrl}>
            <App jwtToken={data.jwtToken} claims={claims} />
        </BrowserRouter>,
        rootElement);
};

const retrieveAppToken = async (azureToken) => {

    var response = await fetch(`api/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "jwtToken": azureToken})
    });

    var data = await response.json();

    window.localStorage.setItem('appToken', data.jwtToken);

    setupApp(data);

};

if (appToken != null) {

    window.history.pushState({}, '', window.location.pathname);
    setupApp({jwtToken: appToken});

}

else if (token == null) {

    var tenant = "onsolve.com";
    var azureUrl = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize`;
    var clientId = "6595aca8-de32-4180-86a0-85304095eb05";
    var returnUrl = [window.location.protocol, '//', window.location.host].join('');

    window.location = `${azureUrl}?client_id=${clientId}&response_type=id_token&redirect_uri=${returnUrl}&scope=openid email profile&response_mode=fragment&state=12345&nonce=678910`;

}
else {
    window.history.pushState({}, '', '/');

    retrieveAppToken(token);
    
}

