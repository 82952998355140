

export const getProblemData = async () => {
    const response = await fetch(`/serviceNow/GetProblemData`);
    return await response.json();
}

export const storeProblemData = async () => {
    await fetch('/serviceNow/StoreProblemData', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export default {
    getProblemData,
    storeProblemData
}