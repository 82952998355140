import React from "react";
import Style from "./OverviewCard.module.css"

function OverviewCard(props) {
  return (
    <div style={{ margin: "1px" }}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title" style={{fontSize:"15px"}}>{props.title}</h5>
            <div className={Style.mainDiv}>
              <div className={Style.smallDiv}>
              <p  className={Style.count}>{props.countAdd}</p>
              <p className={Style.insideText}>ADD</p>
              </div>
              <div  className={Style.smallDiv}>
              <p className={Style.count}>{props.countUpdate}</p>
              <p className={Style.insideText}>UPDATE</p>
              </div>
              <div className={Style.smallDiv}>
              <p className={Style.count}>{props.countDelete}</p>
              <p  className={Style.insideText} >REMOVE</p>
              </div>  
            </div>   
        </div>
      </div>
    </div>
  );
}

export default OverviewCard;
