import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Progress } from 'reactstrap';
import Conditional from './Conditional';

export class SearchDropDown extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            isDropDownOpen: false,
            internalResults: [],
            results: [],
            needle: '',
            loading: false,
            searching: false,
            selection: {},
            title: this.props.title || '',
            useNeedleFilter: this.props.useNeedleFilter || this.props.search == null,
        };

        this.toggle = this.toggle.bind(this);
        this.onNeedleChange = this.onNeedleChange.bind(this);
        this.display = this.display.bind(this);
        this.isDisabled = this.isDisabled.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.applyNeedleFilter = this.applyNeedleFilter.bind(this);


    }

    componentDidMount() {
        this.setState({loading: true });
        this.initialSearch();
    }

    async initialSearch() {

        var internalResults = [];

        if (this.props.initialSet) {
            internalResults = await this.props.initialSet();
        }

        const results = internalResults;

        this.setState({loading: false, internalResults, results }, () => {

            
            if(this.props.initialSelectionId) {

                var result = this.state.results.find(x => x.id === this.props.initialSelectionId);
                
                this.onSelection(result);
            }

        });
    }

    toggle(event) {
        const target = event.target;

        if (target.name !== "dropDownSearch") {
            this.setState({isDropDownOpen: !this.state.isDropDownOpen });
        }
    };

    async onNeedleChange(needle) {

        var results = this.state.internalResults;

        if (this.props.search) {
            this.setState({searching: true });
            results = await this.props.search(needle);
        }

        if (this.state.useNeedleFilter) {
            results = this.applyNeedleFilter(needle, results);
        }

        this.setState({searching: false, results, needle });

    }

    applyNeedleFilter(needle, haystack) {

        return haystack.filter((x) => {

            var target = this.display(x).toLowerCase();
            var term = needle.toLowerCase();

            return target.startsWith(term)

        });

    }

    display(result) {

        var predicate = this.props.display ? this.props.display : x => x;

        return predicate(result);

    }
    
    //Kinda annoying that the reactstrap checks for disabled instead of checking for enabled
    isDisabled(result) {

        //if no predicate is supplied we assume it's enabled, which means return false that it is disabled

        var predicate = this.props.enabledPredicate ? this.props.enabledPredicate : () => true;

        return !predicate(result);

    }

    onSelection(result) {

        const title = this.display(result);
        this.setState({selection: result, title });
        this.props.onSelection(result);

    }

    render() {
        var results = this.state.results;
        var hasMore = false;
        if (this.props.max) {
            hasMore = results.length > this.props.max;
            results = results.slice(0, this.props.max - 1);
        }

        return (
            <Dropdown isOpen={this.state.isDropDownOpen} toggle={this.toggle}>
                <DropdownToggle caret color={this.state.loading ? "danger" : "primary"} disabled={this.state.loading}>{this.state.loading ? "Loading..." : this.state.title}</DropdownToggle>
                <DropdownMenu direction="left">
                    <DropdownItem header>Name</DropdownItem>
                    <DropdownItem>
                        <input autoFocus name="dropDownSearch" type="text" value={this.state.needle} onChange={(event) => this.onNeedleChange(event.target.value)} />
                    </DropdownItem>
                    <DropdownItem divider />
                    <Conditional if={this.state.searching === true}>
                        <DropdownItem>
                            <Progress animated value="100">Searching...</Progress>
                        </DropdownItem>                        
                    </Conditional>
                    <Conditional if={this.props.searching !== true}>
                        {results.map(result => 
                            <DropdownItem 
                                key={result.id}
                                disabled={this.isDisabled(result)} 
                                onClick={() => this.onSelection(result)}>
                                    {this.display(result)}
                            </DropdownItem>)}
                        {hasMore && <DropdownItem disabled>...</DropdownItem>}
                    </Conditional>
                </DropdownMenu>
            </Dropdown>
        );
    }
}
