import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Button, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import classnames from 'classnames';
import * as moment from 'moment';

import teamService from '../../services/teamService';
import sprintService from '../../services/sprintService';

import { SimpleTooltip } from '../../components/SimpleToolTip';
import { TimeZoneToolTip, TimeZoneGMTOffset } from '../../components/TimeZone';
import { ManageTeamEventsModal } from '../../components/teams/ManageTeamEventsModal';
import { SprintTab } from '../../components/teams/SprintTab';

const overviewTab = '1';
const calendarTab = '2';
const deliveryTab = '3';
const sprintsTab = '4';

export class MyTeam extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            team: { },
            activeTab: overviewTab,
            showTeamEvent: false,
            showWeekends: false
        };
    }

    componentDidMount() { 
        this.reload(); 
    }

    reload = async () => {
        this.setState({ loaded: false });
        var [team, sprints] = await Promise.all([
            teamService.get(this.props.teamId),
            sprintService.getAll()
        ]);

        var now = moment();

        var sprintIndex = sprints.findIndex((s) => moment(s.start) < now && now <=  moment(s.end) );

        this.setState({ loaded: true, team, sprints, sprintIndex });
    }

    setTab = (tab) => {
        this.setState({ activeTab: tab });
    }

    showMangeEvents = () => {
        this.setState({ showTeamEvent: true });
    }

    renderMembers(team) {
        if (!team.members) return;

        return <table className="table table-striped">
            <thead>
                <tr>
                    <th>Team Member</th>
                    <th>Role</th>
                    <th>Timezone</th>
                </tr>
            </thead>
            <tbody>
                {team.members.map(m =>
                    <tr key={`myteam-${team.id}-overview-member-${m.id}`}>
                        <td>{m.preferredName}</td>
                        <td>{m.primaryFunction}</td>
                        <td id={`myteam-${team.id}-member-${m.id}-timezone`}>
                            <TimeZoneGMTOffset timeZone={m.timeZone} />
                            <SimpleTooltip placement="right" target={`myteam-${team.id}-member-${m.id}-timezone`}><TimeZoneToolTip timeZone={m.timeZone} /></SimpleTooltip>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }

    renderEvents(team) {

        var close = async () => {
            this.setState({ loaded: false });
            await this.reload();
            this.setState({ showTeamEvent: false });
        }

        var { showTeamEvent } = this.state;
        return <section>
            <table className="table table-striped">
                <thead><tr><td>&nbsp;</td><td>&nbsp;</td></tr></thead>
                <tbody>
                    {team.events && team.events.map(e =>
                        <tr key={`my-team-event-${e.id}`}>
                            <th>{e.name}</th>
                            <td>{e.description}</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <ManageTeamEventsModal team={team} isOpen={showTeamEvent} close={close} refresh={this.reload} />
            <Button onClick={this.showMangeEvents} outline={true} color="primary">Manage Events</Button>
        </section>
    }

    renderCalendar(team) {
        var { sprints, sprintIndex, showWeekends } = this.state;

        var sprint = sprints[sprintIndex];
        var start = moment(sprint.start);
        var end = moment(sprint.end);

        var days = end.diff(start, 'days');

        var dates = [];

        for (var ii = 0; ii < days; ii++) {
            var date = moment(sprint.start).add(ii, 'day');
            if (showWeekends || date.isoWeekday() < 6)
                dates.push(date);
        }

        // colors in site.scss
        var getDateClassName = (member, date) => {


            if (moment(member.startOn) > date || moment(member.endOn) < date)
                return { c: 'not-employed-here', t: null };

            var h = member.holdays.find(h => moment(h.on).isSame(date, 'day'));
            if (h)
                return { c: 'holiday', t: h.description };

            var pto = member.pto.find(p => moment(p.startOn) <= date && date <= moment(p.endOn))

            if (pto)
                return { c: 'pto', t: `PTO${pto.name && ':'} ${pto.name}` };

            return { c: 'available', t: null };
        }

        var toggleWeekend = () => { this.setState({ showWeekends: !showWeekends }) };

        return <section>
           
            <table className="table team-calendar">
                <thead>
                    <tr>
                        <th> <Button size="sm" outline onClick={toggleWeekend}>{showWeekends ? 'Hide' : 'Show'} Weekends</Button></th>
                        {dates.map(d => <th key={`myteam-${team.id}-calendar-${d.format()}`}>{d.format('M/D')}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {team.members.map(m =>
                        <tr key={`myteam-${team.id}-calendar-member-${m.id}`}>
                            <td>{m.preferredName}</td>
                            {dates.map(d => {
                                var { c, t } = getDateClassName(m, d)
                                var key = `myteam-${team.id}-calendar-${d.format('YYYY-MM-DD')}-member-${m.id}`

                                return <td id={key} key={key} className={c}>
                                    { t && <SimpleTooltip placement="right" target={key}>{t}</SimpleTooltip>}
                                </td>;
                            })}
                        </tr>
                    )}
                </tbody>
            </table>
        </section>
    }

    render() {

        const { activeTab, team, sprintIndex, sprints } = this.state;

        if (!sprints)
            return null;

        var sprint = sprints[sprintIndex];
        var start = moment(sprint.start);
        var end = moment(sprint.end);

        var now = moment();
        var todayIndex = sprints.findIndex((s) => moment(s.start) < now && now <= moment(s.end));

        var next = () => this.setState({ sprintIndex: sprintIndex + 1 })
        var today = () => this.setState({ sprintIndex: todayIndex })
        var prev = () => this.setState({ sprintIndex: sprintIndex - 1 })

        return <section className="my-teams">
            <h3>
                {this.state.team.name}

                <div className="sprint-controls">
                    Sprint {start.format('l')} - {end.format('l')}
                    &nbsp;
                    <ButtonGroup>
                        <Button onClick={prev} disabled={sprintIndex === 0} outline ><FontAwesomeIcon icon={faChevronLeft} /></Button>
                        <Button onClick={today} outline ><FontAwesomeIcon icon={faCircle} /></Button>
                        <Button onClick={next} disabled={sprintIndex + 1 === sprints.length} outline ><FontAwesomeIcon icon={faChevronRight} /></Button>
                    </ButtonGroup>
                </div>
            </h3>

            <ul style={{'listStyleType':'none'}}>
                <li>{team.rapidBoardId && <a target="_blank" rel="noopener noreferrer" href={`https://onsolve.atlassian.net/secure/RapidBoard.jspa?rapidView=${team.rapidBoardId}`} > Jira Board</a>}</li>
                <li>{team.confluencePageUrl && <a target="_blank" rel="noopener noreferrer" href={team.confluencePageUrl} >Confluence Page</a>}</li>
                <li>{team.slackChannelUrl && <a target="_blank" rel="noopener noreferrer" href={team.slackChannelUrl} >Slack Channel</a>}</li>
            </ul>

            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === overviewTab })}
                            onClick={() => { this.setTab(overviewTab); }}
                        >
                            Overview
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === calendarTab })}
                            onClick={() => { this.setTab(calendarTab); }}
                        >
                            Calendar
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink disabled={true}
                            className={classnames({ active: activeTab === deliveryTab })}
                            onClick={() => { this.setTab(deliveryTab); }}
                        >
                            Delivery
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === sprintsTab })}
                            onClick={() => { this.setTab(sprintsTab); }}
                        >
                            Sprints
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={overviewTab}>
                        <Row>
                            <Col>{this.renderMembers(team)}</Col>
                            <Col>{this.renderEvents(team)}</Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId={calendarTab}>
                        {this.renderCalendar(team)}
                    </TabPane>
                    <TabPane tabId={deliveryTab}>
                        TODO Delivery
                    </TabPane>
                    <TabPane tabId={sprintsTab}>
                        <SprintTab team={team} sprint={sprint} />
                    </TabPane>
                </TabContent>
            </div>
        </section>;
    }
}