import React, { useEffect, useState } from "react";
import ObjectiveTable from "../../components/ObjectiveDashBoard/ObjectiveTable";
import SourceDataTable from "../../components/ObjectiveDashBoard/SourceDataTable";
import Style from "../../components/ObjectiveDashBoard/ObjectiveDashboard.module.css";
import "./ObjectiveDashboard.css";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import objectiveServices from "../../services/objectiveTrackerDashboardService";
import { Loadable } from "../../components/Loadable";

function ObjectiveDashboard(props) {
  const ObjectiveTab = "1";
  const SourceTab = "2";
  const [activetab, setAactivtab] = useState(ObjectiveTab);
  const [objectiveTrackerData, setObjectiveTrackerData] = useState([]);
  const [ahaJiraData, setAhaJiraData] = useState([]);
  const[filterData,setfilterData]= useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoad(false);
    let data = await objectiveServices.GetObjectiveTrackerData();
    let ahaData= await objectiveServices.GetAhaJiraData();
    setObjectiveTrackerData(data);
    setAhaJiraData(ahaData);
    setfilterData(ahaData);
    setLoad(true);
  };

  const showFeatures = (objective) =>{
    let filterData = ahaJiraData.filter(x=> x.goalName== objective);
    setfilterData(filterData);
    setAactivtab(SourceTab);
  }
  return (
    <Loadable loaded={load}>
      <div>
        <Nav tabs>
          <NavItem cssModule={Style} className={Style.navstyle}>
            <NavLink
              className={activetab == ObjectiveTab ? "active" : null}
              onClick={() => setAactivtab(ObjectiveTab)}
            >
              View Objective Table
            </NavLink>
          </NavItem>
          <NavItem cssModule={Style} className={Style.navstyle}>
            <NavLink
              className={activetab == SourceTab ? "active" : null}
              onClick={() => setAactivtab(SourceTab)}
            >
              View Source Table
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activetab}>
          <TabPane tabId={ObjectiveTab}>
            <div className={Style.tabPanStyle}>
              <ObjectiveTable ObjectiveTrackerData={objectiveTrackerData} showFeatures={showFeatures} />
            </div>
          </TabPane>
          <TabPane tabId={SourceTab}>
            <div className={Style.tabPanStyle}>
              <SourceDataTable AhaJiraData={filterData}/>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </Loadable>
  );
}

export default ObjectiveDashboard;
