import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './layout/Layout';

import { Claims } from './utility/claims';

import { Home } from './pages/Home';
import { TeamComposition } from './pages/TeamComposition';
import { Overview } from './pages/Overview';
import { WorkstreamScorePage } from './pages/scorecard/WorkstreamScorePage';
import { VendorScorePage } from './pages/scorecard/VendorScorePage';

import { Members } from './pages/members/Members';
import { ModifyMember } from './pages/members/ModifyMember';

import { Teams } from './pages/teams/Teams';
import { ModifyTeam } from './pages/teams/ModifyTeam';
import { MyTeam } from './pages/teams/MyTeam';

import { VendorsPage } from './pages/vendors/VendorsPage';
import { OfficePage } from './pages/office/OfficePage';
import { ProfilePage } from './pages/profile/ProfilePage';

import Conditional from './components/Conditional';
import fetchIntercept from 'fetch-intercept';
import jwtDecode from 'jwt-decode';

import tokenService from './services/tokenService';
import { Departments } from './pages/Departments/Departments';
import { ModifyDepartment } from './pages/Departments/ModifyDepartment';
//import ProductDashboard from './pages/Dashboard/ProductDashboard';
import CapExDashboard from './pages/Dashboard/CapExDashboard';
import ServiceNow from './pages/Dashboard/serviceNowDashboard';
import IncidentSLA from './pages/Dashboard/incidentSla'
import ProblemData from './pages/Dashboard/ProblemData'
import DevOpsMergeRequestDashboard from './pages/Dashboard/DevOpsMergeRequestDashboard';
import ObjectiveDashboard from './pages/Dashboard/ObjectiveDashboard';
import AhaJiraChangeLogDashboard from './pages/Dashboard/AhaJiraChangeLogDashboard';
import FeatureHealthDashboard from './pages/Dashboard/FeatureHealthDashboard';
import ManualKPIEntryDashboard from './pages/Dashboard/ManualKPIEntryDashboard'
import RnDTeamResourceReport from './pages/ProgramOperations/RnDTeamResourceReport';
import RnDResourceChart from './pages/ProgramOperations/RnDResourceChart';
import POD from './pages/PODs/POD';
import ActiveSprintReport from './pages/ProgramOperations/ActiveSprintReport';
import FlashReport from './pages/ProgramOperations/FlashReport';
import PODObjectives from './pages/PODs/PODObjectives';
import ObjectiveHealthDashboard from './pages/Dashboard/ObjectiveHealthDashboard';
import DeliveryStatusOnTimeData from './pages/ProgramOperations/DeliveryStatusOnTimeData';
import DeliveryReportInitiatives from './pages/DeliveryReportInitiatives/DeliveryReportInitiatives';
import ManualServiceNowEntryDashboard from './pages/Dashboard/ManualServiceNowEntryDashboard';

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {

        super(props);

        var claims = this.props.claims;
        claims.reissue = this.reissue;

        this.state = {
            claims: claims
        };

        fetchIntercept.register({
            request: function (url, config) {
                // Modify the URL or configuration here

                if (config == null) {

                    config = {};
                }

                if (config.headers == null) {
                    config.headers = {};
                }

                url = `api${url.startsWith('/') ? '' : '/'}${url}`;

                config.headers.Authorization = `Bearer ${props.jwtToken}`;

                return [url, config];
            },

            requestError: function (error) {
                // Called when an error occurred during another 'request' interceptor call
                return Promise.reject(error);
            },

            response: function (response) {
                // Modify the response object
                return response;
            },

            responseError: function (error) {
                // Handle an fetch error
                return Promise.reject(error);
            }
        });

        this.reissue = this.reissue.bind(this);

    }

    componentDidMount() {
        if (this.props.claims.Impersonator != null)
            this.reissue();
    }

    async reissue() {

        var data = await tokenService.get();

        var rawClaims = jwtDecode(data.jwtToken);
        var claims = new Claims(rawClaims);

        window.localStorage.setItem('appToken', data.jwtToken);

        this.setState({ claims: claims });

    }

    render() {
        return (
            <Layout claims={this.state.claims}>
                <Route exact path='/' render={(props) => <Home {...props} claims={this.state.claims} />} />
                <Route exact path='/team/composition' render={() => <TeamComposition claims={this.state.claims} />} />
                <Route exact path='/my/profile' render={() => <ProfilePage claims={this.state.claims} />} />
                <Route path="/my/team/:teamId" render={({ match: { params: { teamId } } }) => <MyTeam claims={this.state.claims} teamId={teamId} />} />
                <Conditional if={this.state.claims.canConfigure()}>
                    <Route exact path='/configure/offices' render={() => <OfficePage claims={this.state.claims} />} />
                </Conditional>
                {/*<Route exact path='/Dashboard/ProductDashboard' component={ProductDashboard} />*/}
                <Route exact path='/Dashboard/CapExDashboard' render={() => <CapExDashboard claims={this.state.claims} />} />
                <Route exact path='/Dashboard/ServiceNowDashboard' component={ServiceNow} />
                <Route exact path='/Dashboard/IncidentSla' component={IncidentSLA} />
                <Route exact path='/Dashboard/ProblemData' component={ProblemData}/>
                <Route exact path='/Dashboard/DevOpsMergeRequestDashboard' component={DevOpsMergeRequestDashboard} />
                {/*<Route exact path='/Dashboard/FeatureHealthDashboard' component={FeatureHealthDashboard} />*/}
                {/*<Route exact path='/Dashboard/ObjectiveHealthDashboard' component={ObjectiveHealthDashboard} />*/}

                <Conditional if={this.state.claims.isAdmin()}>
                    <Route exact path='/members' render={(props) => <Members {...props} claims={this.state.claims} />} />
                    <Route exact path='/members/modify' component={ModifyMember} />
                    <Route path='/members/modify/:memberId' component={ModifyMember} />
                    <Route exact path='/teams' component={Teams} />
                    <Route exact path='/teams/modify' component={ModifyTeam} />
                    <Route path='/teams/modify/:teamId' component={ModifyTeam} />
                    <Route exact path='scorecard/vendors' component={VendorScorePage} />
                    <Route exact path='/vendors' component={VendorsPage} />
                    <Route exact path='/departments' component={Departments} />
                    <Route exact path='/departments/modify' component={ModifyDepartment} />
                    <Route path='/departments/modify/:id' component={ModifyDepartment} />
                    <Route exact path='/pod' component={POD} />
                    <Route exact path='/podobjectives' component={PODObjectives} />
                    <Route exact path='/DeliveryReportInitiatives' component={DeliveryReportInitiatives} />
                    {/*<Route exact path='/Dashboard/ObjectiveDashboard' component={ObjectiveDashboard} />*/}
                    <Route exact path='/Dashboard/AhaJiraChangeLogDashboard' component={AhaJiraChangeLogDashboard} />
                    <Route exact path='/Dashboard/ManualKPIEntryDashboard' component={ManualKPIEntryDashboard} />
                    <Route exact path='/ProgramOperations/R&DTeamResourceReport' component={RnDTeamResourceReport} />
                    {/*<Route exact path='/ProgramOperations/R&DResourceChart' component={RnDResourceChart} />*/}
                    <Route exact path='/ProgramOperations/ActiveSprintReport' component={ActiveSprintReport} />
                    <Route exact path='/ProgramOperations/FlashReport' component={FlashReport} />
                    <Route exact path='/ProgramOperations/DeliveryStatusOnTimeData' component={DeliveryStatusOnTimeData} />
                    <Route exact path='/Dashboard/ManualServiceNowEntryDashboard' component={ManualServiceNowEntryDashboard} />
                </Conditional>
            </Layout>
        );
    }
}
