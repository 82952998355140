import React, { Component} from 'react';
import DepartmentTable from './DepartmentTable';
import departmentService from '../../services/departmentService';
import Conditional from '../../components/Conditional';
import { confirmAlert } from 'react-confirm-alert';

export class Departments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dept: [],
            loading: true
        };
        
    }

    loadDepartments = async () => {
        this.setState({ loading: true });
        const data = await departmentService.getAll();
        this.setState({ dept: data, loading: false });
    }

     editDepartment = (id) => {
        this.props.history.push(`/departments/modify/${id}`);
    }

     addDepartment = () => {
         this.props.history.push('/departments/modify');
    }

    deleteDepartment = async (id) => {
        await fetch(`/departments/${id}`, { method: 'DELETE' });
        this.loadDepartments();
    }

    confirmDelete = async (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this department?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        this.setState({ loading: true });
                        this.deleteDepartment(id);
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    componentDidMount() {
        this.loadDepartments();
    }

    render() {
        return (
            <div>
                <h1 id='tabelLabel'>Departments</h1>
                <p>Tracked Departments</p>
                <Conditional if={this.state.loading}>
                    <p> <em>Loading...</em> </p>
                </Conditional>
                <Conditional if={this.state.loading === false}>
                    <DepartmentTable data={this.state.dept} add={this.addDepartment} delete={this.confirmDelete} update={this.editDepartment} />
                </Conditional>
            </div>
        );
    }
};
