import React, { useState,useEffect } from 'react';
import Pagination from "../Pagination";
import Style from "./ChangeLogTable.module.css";

function ChangeLogTable(props) {
  const changeLogData = props.changeLogData;
  const [header, setHeader] = useState([]);
  //pagination
  const [dataPerPage, setDataPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const lastIndexofdata = currentPage * dataPerPage;
  const firstIndexofdata = lastIndexofdata - dataPerPage;
  const currentData = changeLogData.slice(firstIndexofdata, lastIndexofdata);
  const ItemPage = (number) => {
    setDataPerPage(number);
    setCurrentPage(1);
  };
  const pageNumber = Array.from(
    new Array(Math.ceil(changeLogData.length / dataPerPage)),
    (val, index) => index + 1
  );
  //change page
  const paginate = (currentPage) => {
    if (currentPage >= 1 && currentPage <= pageNumber.length)
      setCurrentPage(currentPage);
  };

  useEffect(() => {
    setHeader([]);
    if (changeLogData.length != 0) {
      Object.keys(changeLogData[0]).map((key) => setHeader((x) => [...x, key]));
    }
  }, [changeLogData]);

  const camelCase = (data) => {
    data = data.replace(/([a-z])([A-Z])/g, "$1 $2");
    data = data.charAt(0).toUpperCase() + data.slice(1);
    return data;
  };

  return (
    <div>
      <table className="table" align="center">
        <thead>
          <tr>
            {header.map(
              (colName, index) =>
                colName != "id" && <th key={index}>{camelCase(colName)}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {currentData.length!=0 ? currentData.map((x, index) => (
            <tr
              key={index}
              className={
                  x.changeType=="Added"? 
                  Style.Added
                  : x.changeType=="Removed"?
                  Style.Remove:
                  x.previousValue == null
                  ? Style.Added
                  : x.newValue == null 
                  ? Style.Remove
                  : Style.Updated
                }
            >
              {header.map(
                (colName, index) =>
                   (colName.includes('date'))?
                  <td  key={index}>{new Date(x[colName]).toLocaleDateString() }</td>
                  : (colName != "id") &&<td key={index}>{x[colName]}</td>
              )}
            </tr>
          )): <tr className={Style.NotFound}><td colSpan="4">No Data To Show</td></tr>}
        </tbody>
      </table>
      <Pagination
        paginate={paginate}
        pageNumber={pageNumber}
        currentPage={currentPage}
        ItemPage={ItemPage}
        dataPerPage={dataPerPage}
      />
    </div>
  );
}

export default ChangeLogTable;
