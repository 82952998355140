

export const getSnowData= async ()=>{
    const response = await fetch(`/serviceNow/GetServiceNowData`);
    return await response.json();
}

export const storeSnowData = async () => {
    await fetch('/serviceNow/StoreServiceNowData', {
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        }
    });
}


//export const postHistoricDate= async (month, year, closedBy)=>{
//    var object={
//        month: month,
//        year: year,
//        closedBy: closedBy
//    };

//    await fetch(`CapexDashboard/MarkHistoric`, {
//        method: 'POST',
//        headers: {
//            'Content-Type': 'application/json'
//        },
//        body: JSON.stringify({
//            month: month,
//            year: year,
//            closedBy: closedBy
//        })
//    });
//}

export default {
    getSnowData,
    storeSnowData
}