import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import "./POD.css";
import PODService from "../../services/PODService";
import ClusterRole from "./ClusterRole";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Option = (props) => {
  return (
    <div>
      {" "}
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        {"  "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props;
  const nbValues = getValue();
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...props}>
      {`${nbValues.reduce((acc, curr) => `${acc}${curr.label},`, "")}`}
    </components.ValueContainer>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 200,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
};

const animatedComponents = makeAnimated();

export default function POD(props) {
  const [clusterOptions, setClusterOptions] = useState([]);
  const [clusterOptionsSelected, setClusterOptionsSelected] = useState([]);
  const [clusterLeadOptions, setClusterLeadOptions] = useState([]);
  const [clusterLeadOptionSelected, setClusterLeadOptionSelected] = useState(
    []
  );
  const [podOptions, setPodOptions] = useState([]);
  const [podLeadOptions, setPodLeadOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [clusterGridData, setClusterGridData] = useState([]);
  const [show, setShow] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [supportedRoleOption, setSupportedRoleOption] = useState([]);
  const [podLeadDiasable, setPODLeadDisable] = useState(true);
  const [TeamDiasable, setTeamDisable] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const getDefaultClusterGridState = () => ({
    clusterGridData: [],
  });

  const getDefaultTeamOptions = () => ({
    teamOptions: [],
  });
  const getDefaultLeadOptions = () => ({
    podLeadOptions: [],
  });

  const loadData = async () => {
    loadClusterOptions();
    loadClusterLeadOptions();

    setClusterGridData([
      {
        recNo: 0,
        podId: 0,
        podName: null,
        podLead: [],
        team: [],
      },
    ]);

    loadPODOptions();
    loadPodLeadOptions();
    loadTeamOptions();
    loadSupportType();
  };

  const loadSupportType = async () => {
    let supportType = await PODService.GetSupportType();
    setSupportedRoleOption(supportType);
  };

  const loadClusterLeadOptions = async () => {
    let clusterServiceData = await PODService.GetMembersOptionsData();
    let clusterLeadData = clusterServiceData.map(function (obj) {
      return { value: obj.id, label: obj.name };
    });

    setClusterLeadOptions(clusterLeadData);
  };

  const loadClusterOptions = async () => {
    let clusterServiceData = await PODService.GetClusterOptionsData();
    let clusterData = clusterServiceData.map(function (obj) {
      return { value: obj.id, label: obj.clusterName };
    });
    handleClusterOptionsChange(clusterData[0]);
    setClusterOptions(clusterData);
  };

  const handleClusterOptionsChange = async (selected) => {
    setClusterOptionsSelected(selected);
    setIsLoading(true);

    let JsonData = await PODService.GetClusterDetailsData(
      selected.value,
      selected.label
    );

    if (JsonData.clusterLeads.length > 0) {
      let defaultClusterLeadValue = JsonData.clusterLeads.map(function (obj) {
        return { value: obj.id, label: obj.name };
      });

      // setClusterLeadOptionSelected([]);
      setClusterLeadOptionSelected(defaultClusterLeadValue);
    } else {
      setClusterLeadOptionSelected([]);
    }

    if (JsonData.poDs.length > 0) {
      let newData = [];
      let count = 1;

      JsonData.poDs.map(function (pods) {
        if (pods.podLeads.length > 1) {
          pods.podLeads.map(function (podLead) {
            newData.push({
              recNo: count,
              podId: pods.id,
              podName: pods.name,
              podLead: podLead,
              team: podLead.teams,
            });
            count++;
          });
        } else {
          newData.push({
            recNo: count,
            podId: pods.id,
            podName: pods.name,
            podLead:
              pods.podLeads[0] === null || pods.podLeads[0] === undefined
                ? null
                : pods.podLeads[0],
            team:
              pods.podLeads[0] === null || pods.podLeads[0] === undefined
                ? null
                : pods.podLeads[0].teams,
          });
          count++;
        }
        setPODLeadDisable(false);
        setTeamDisable(false);
      });

      setClusterGridData({ ...getDefaultClusterGridState() });
      setClusterGridData(newData);
    } else {
      setClusterGridData({ ...getDefaultClusterGridState() });
      setClusterGridData([
        {
          recNo: 0,
          podId: 0,
          podName: null,
          podLead: [],
          team: [],
        },
      ]);
      setTeamDisable(true);
      setPODLeadDisable(true);
    }

    setIsLoading(false);
  };

  const handleClusterLeadOptionChange = (selected) => {
    setClusterLeadOptionSelected(selected);
  };

  const loadPODOptions = async () => {
    let clusterServiceData = await PODService.GetPodOptionsData();
    let podData = clusterServiceData.map(function (obj) {
      return { value: obj.id, label: obj.podName };
    });

    setPodOptions(podData);
  };

  const loadPodLeadOptions = async () => {
    let clusterServiceData = await PODService.GetMembersOptionsData();
    let podLeadData = clusterServiceData.map(function (obj) {
      return { value: obj.id, label: obj.name,isDisable:false };
    });

    setPodLeadOptions(podLeadData);
  };

  const loadTeamOptions = async () => {
    let clusterServiceData = await PODService.GetTeamOptionsData();
    let teamData = clusterServiceData.map(function (obj) {
      return { value: obj.id, label: obj.name, isDisabled: false };
    });

    setTeamOptions(teamData);
  };

  const handlePodChange = (index, selected) => {
    let currentClusterGrid = [...clusterGridData];

    currentClusterGrid[index].podId = selected.value;
    currentClusterGrid[index].podName = selected.label;
    if (currentClusterGrid) setPODLeadDisable(false);
    else setPODLeadDisable(true);

    setClusterGridData(currentClusterGrid);
  };

  const handlePodDelete = (recNo, index, podId, e) => {
    let ans = window.confirm(
      "Are you sure you want to delete Row No. " + (index + 1) + " ?"
    );
    if (ans === true) {
      let currentClusterGrid = [...clusterGridData];
      currentClusterGrid = currentClusterGrid.filter(
        (obj) => obj.recNo !== recNo
      );
      setClusterGridData(currentClusterGrid);
    }
  };

  const handlePodLeadChange = (index, selected) => {
    //to be used for multiselect
    // let selectedPodLeadList = selected.map(function (obj) {
    //   return { id: obj.value, name: obj.label };
    // })

    let selectedPodLeadList = { id: selected.value, name: selected.label };

    let currentClusterGrid = [...clusterGridData];

    currentClusterGrid[index].podLead = selectedPodLeadList;
    if (currentClusterGrid) setTeamDisable(false);
    else setTeamDisable(true);
    setClusterGridData(currentClusterGrid);
  };

  const handlePodLeadFocus = (index, selected) => {

    let currentClusterGrid = [...clusterGridData];

    let currentPOD = currentClusterGrid.filter(obj => obj.podId === currentClusterGrid[index].podId);
    let alreadySelectePodLead = currentPOD.map(x=> x.podLead);

    let newPodLeadOptions = [...podLeadOptions];
    alreadySelectePodLead.map(disableLead => {
      newPodLeadOptions.map(lead => {
        if (lead.value === disableLead.id)
          lead.isDisabled = true;
      })
    });

    setPodLeadOptions({ ...getDefaultLeadOptions() });
    setPodLeadOptions(newPodLeadOptions);
  };

  const handlePodLeadMenuClose = (index, selected) => {
    let newPodLeadOptions = [...podLeadOptions];
    newPodLeadOptions.map(lead => {
      lead.isDisabled = false;
    });

    setPodLeadOptions({ ...getDefaultLeadOptions() });
    setPodLeadOptions(newPodLeadOptions);
  };

  const handleTeamChange = (index,podId, selected) => {
    let currentClusterGrid = [...clusterGridData];
    let podCluster =currentClusterGrid.filter(x=> x.podId==podId);
    let exsiting = podCluster.flatMap(x=> x.team)
    let selectedTeamList = selected.map(function (newitem) {
      if (exsiting) {
        let existingItem = exsiting.filter(
          (obj) => obj.teamId == newitem.value
        );
        if (existingItem !== undefined && existingItem[0])
          return {
            teamId: newitem.value,
            name: newitem.label,
            supportTypes: existingItem[0].supportTypes,
          };
        else
          return {
            teamId: newitem.value,
            name: newitem.label,
            supportTypes: [],
          };
      } else return { teamId: newitem.value, name: newitem.label, supportTypes: [] };
    });

    currentClusterGrid[index].team = selectedTeamList;
    setClusterGridData(currentClusterGrid);
  };

  const handleTeamFocus = (index, selected) => {

    let currentClusterGrid = [...clusterGridData];
    let currentPODLead = currentClusterGrid[index].podLead;

    let currentPOD = currentClusterGrid.filter(obj => obj.podId === currentClusterGrid[index].podId);
    let alreadySelecteTeams = [];

    currentPOD.map((pod, index) => {
      if (currentPODLead && pod.podLead && currentPODLead.id === pod.podLead.id) {
        pod.team && pod.team.length > 0 && pod.team.map(team => {
          alreadySelecteTeams.push(team);
        });
      }
    });

    let newTeamOptions = [...teamOptions];
    alreadySelecteTeams.map(disableteam => {
      newTeamOptions.map(team => {
        if (team.value === disableteam.teamId)
          team.isDisabled = true;
      })
    });

    setTeamOptions({ ...getDefaultTeamOptions() });
    setTeamOptions(newTeamOptions);
  };

  const handleTeamMenuClose = (index, selected) => {
    let newTeamOptions = [...teamOptions];
    newTeamOptions.map(team => {
      team.isDisabled = false;
    });

    setTeamOptions({ ...getDefaultTeamOptions() });
    setTeamOptions(newTeamOptions);
  };

  const handleAdd = (recNo, index1, e) => {
    setClusterGridData((clusterLeadOptions) => [
      ...clusterLeadOptions,
      {
        recNo: recNo + 1,
        podId: 0,
        podName: "",
        podLead: [],
        team: [],
      },
    ]);
    setPODLeadDisable(true);
    setTeamDisable(true);
  };

  const handlePopUp = (index, e) => {
    let currentRowData = clusterGridData[index];
    setAdditionalInfo(currentRowData);
    e.preventDefault();
    setShow(true);
  };

  const handleRoleMember = (team, role, members) => {
    let selectedSupport = {
      supportTypeId: role.id,
      supportType: role.supportType,
      supportMembers: members.map((m) => {
        return new Object({
          id: m.value,
          name: m.label,
          teams: null,
        });
      }),
    };

    clusterGridData.map((c) => {
      if (c.podId == additionalInfo.podId) {
        let teamindex = c.team.findIndex((t) => t.name == team);
        if (teamindex > -1) {
          let prevData = Array.from(c.team[teamindex]["supportTypes"]);
          let supportIndex = prevData.findIndex(
            (x) => x.supportType === role.supportType
          );
          if (supportIndex > -1) {
            prevData[supportIndex] = selectedSupport;
            c.team[teamindex]["supportTypes"] = prevData;
          } else {
            c.team[teamindex]["supportTypes"] = [...prevData, selectedSupport];
          }
        }
      }
    });
    setClusterGridData(clusterGridData);
  };

  const renderFields = (gridData) => {
    if (gridData.length > 0) {
      return gridData.map((data, index) => loadWithExistingData(data, index));
    }
  };

  const loadWithExistingData = (gridData, index) => {
    const { recNo, podId, podName, podLead, team } = gridData; //destructuring

    let existingPodList = [];
    let existingPodLeadList = [];
    let existingTeamList = [];

    if (podId !== 0) {
      existingPodList = { value: podId, label: podName };
      if (podLead !== null && podLead !== undefined) {
        existingPodLeadList = { value: podLead.id, label: podLead.name };
      }
      if (team !== null && team !== undefined) {
        existingTeamList = team.map(function (obj) {
          return { value: obj.teamId, label: obj.name };
        });
      }
    }

    return (
      <tr key={recNo}>
        <td className="col-sm-2">
          <Select
            name="podName"
            id={recNo}
            defaultValue={existingPodList}
            options={podOptions}
            classNamePrefix="select"
            menuPortalTarget={document.body}
            onChange={(e) => handlePodChange(index, e)}
          />
        </td>
        <td className="col-sm-4">
          <Select
            id={recNo}
            key={recNo}
            defaultValue={existingPodLeadList}
            options={podLeadOptions}
            isDisabled={podLeadDiasable}
            classNamePrefix="select"
            menuPortalTarget={document.body}
            onFocus={(e)=>handlePodLeadFocus(index,e)}
            onMenuClose={(e)=>handlePodLeadMenuClose(index,e)}
            onChange={(e) => handlePodLeadChange(index, e)}
          />
        </td>
        <td className="col-sm-4">
          <Select
            id={recNo}
            key={recNo}
            defaultValue={existingTeamList}
            isMulti
            options={teamOptions}
            closeMenuOnSelect={false}
            isDisabled={TeamDiasable}
            hideSelectedOptions={false}
            components={{ Option, MultiValue, animatedComponents }}
            allowSelectAll={true}
            menuPortalTarget={document.body}
            onChange={(e) => handleTeamChange(index,podId, e)}
            onFocus={(e) => handleTeamFocus(index, e)}
            onMenuClose={(e) => handleTeamMenuClose(index, e)}
          />
        </td>
        <td className="col-sm-2">
          <div className="row" style={{ margin: 5 }}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2">Add POD Support Detail</Tooltip>
              }
            >
              <a onClick={(e) => handlePopUp(index, e)}>
                <svg
                  width="35"
                  height="35"
                  fill="currentColor"
                  className="bi bi-chevron-double-right"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </a>
            </OverlayTrigger>

            {clusterGridData.length > index + 1 && (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2">Delete POD</Tooltip>}
              >
                <a onClick={(e) => handlePodDelete(recNo, index, podId, e)}>
                  <svg
                    width="35"
                    height="35"
                    fill="currentColor"
                    className="bi bi-trash"
                    viewBox="0 0 20 20"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fillRule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </a>
              </OverlayTrigger>
            )}
            {clusterGridData.length <= index + 1 && (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2">Add POD</Tooltip>}
              >
                <a onClick={(e) => handleAdd(recNo, index, e)}>
                  <svg
                    width="35"
                    height="35"
                    fill="currentColor"
                    className="bi bi-plus-square"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    />
                  </svg>
                </a>
              </OverlayTrigger>
            )}
          </div>
        </td>
      </tr>
    );
  };

  const saveCluster = async () => {
    setIsLoading(true);
    let newData = {
      clusterName: clusterOptionsSelected.label,
      clusterId: clusterOptionsSelected.value,
      clusterLeads: clusterLeadOptionSelected.map(function (obj) {
        return { id: obj.value, Name: obj.label };
      }),
      poDs: [],
    };

    let DisitinctPODs = [];

    clusterGridData.map(function (pod) {
      if (pod.podId !== 0) {
        let isExist = DisitinctPODs.some(function (el) {
          return el.Name === pod.podName;
        });
        if (!isExist) {
          DisitinctPODs.push({
            id: pod.podId,
            Name: pod.podName,
            PODLeads: [],
          });
          newData.poDs.push({
            id: pod.podId,
            Name: pod.podName,
            PODLeads: [],
          });
        }
      }
    });

    newData.poDs.map(function (disPod) {
      clusterGridData.map(function (pod) {
        if (pod.podId !== 0) {
          if (disPod.Name === pod.podName) {
            if (Array.isArray(pod.podLead)) {
              disPod.PODLeads.push({
                id: pod.podLead[0].id,
                Name: pod.podLead[0].name,
                Teams: pod.team,
              });
            } else {
              disPod.PODLeads.push({
                id: pod.podLead.id,
                Name: pod.podLead.name,
                Teams: pod.team,
              });
            }
          }
        }
      });
    });

    let res = await PODService.PostClusterDetailsData(newData);
    if (res.ok) alert("Cluster details saved successfully.");
    else alert("Something went wrong");

    setIsLoading(false);
  };

  return (
    <div className="container">
      <h2>Setup Cluster </h2>

      <hr className="mt-2 mb-3" />

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label> Cluster Name</label>
            <Select
              name="clusterName"
              value={clusterOptionsSelected}
              options={clusterOptions}
              classNamePrefix="select"
              onChange={(option) => handleClusterOptionsChange(option)}
              menuPortalTarget={document.body}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label> Cluster Lead</label>
            <Select
              value={clusterLeadOptionSelected}
              isMulti
              options={clusterLeadOptions}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={(option) => handleClusterLeadOptionChange(option)}
              allowSelectAll={true}
              menuPortalTarget={document.body}
            />
          </div>
        </div>
        <div className="col-4"></div>
      </div>
      <div className="row spinner-row d-flex justify-content-center">
        {isLoading && (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>

      <div className="row table-mycontainer ">
        <div className="col">
          <table
            className="table table-sm table-hover .table-responsive-sm"
            aria-labelledby="tabelLabel"
            style={{ overflowWrap: "break-word" }}
          >
            <thead className="stickyHeader">
              <tr>
                <th className="col-sm-3">POD Name</th>
                <th className="col-sm-3">POD Lead</th>
                <th className="col-sm-3">Team Name</th>
              </tr>
            </thead>
            <tbody>{renderFields(clusterGridData)}</tbody>
          </table>
        </div>
      </div>
      <div className="row footer-mycontainer">
        <div className="col">
          <hr className="mt-2 mb-3" />
          <button
            onClick={saveCluster}
            type="button"
            className="btn btn-primary"
          >
            Save
          </button>
        </div>
      </div>
      {show && (
        <ClusterRole
          additionInfo={additionalInfo}
          clusterName={clusterOptionsSelected}
          clusterLeads={clusterLeadOptionSelected}
          show={show}
          setShow={setShow}
          clusterLeadOptions={clusterLeadOptions}
          handleRoleMember={handleRoleMember}
          Role={supportedRoleOption}
        />
      )}
    </div>
  );
}
