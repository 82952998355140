import React, { Component } from 'react';

import {Bar} from 'react-chartjs-2';

import { GridLoadable } from './GridLoadable';

export class ChartCard extends Component {
    static displayName = ChartCard.name;

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'velocity'
        };


    }

    render () {
        return (
            <React.Fragment>
                <GridLoadable loaded={this.props.loading === false} color={`rgba(${this.props.color},1)`} size={50}>
                    <p>{this.props.title}</p>
                    <p>Average: {this.props.average}</p>
                    <div>
                        <Bar
                            data={{
                                labels: this.props.dataSet.map(entry => ''),
                                datasets: [{
                                    label: this.props.title,
                                    backgroundColor: `rgba(${this.props.color},0.2)`,
                                    borderColor: `rgba(${this.props.color},1)`,
                                    borderWidth: 1,
                                    hoverBackgroundColor: `rgba(${this.props.color},0.2)`,
                                    hoverBorderColor: `rgba(${this.props.color},1)`,
                                    data: this.props.dataSet,
                                    barPercentage: 1,
                                    categoryPercentage: .97
                                }]
                            }}
                            width={this.props.width}
                            height={this.props.height}
                            options={{
                                maintainAspectRatio: false,
                                legend: {
                                    display: false
                                },
                                scales: {
                                    xAxes: [{
                                        display:false
                                    }],
                                    yAxes: [{
                                        display:false,
                                        ticks: {
                                            beginAtZero: true,
                                        }
                                    }]
                                },
                                tooltips: {
                                    enabled: false
                                }
                            }}
                        />
                    </div>
                </GridLoadable>
            </React.Fragment>
        );
    }


}

