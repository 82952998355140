import React, { useState,useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "reactstrap";
import Style from "../../components/ObjectiveDashBoard/ObjectiveDashboard.module.css";

function UserSelectionDropDown(props) {
  const show = props.show;
  const setshow = props.setshow;
  const attributeArray = props.attributeArray;
  const originalData = props.originalData;
  const filterName = props.filterName;
  const filterFunction = props.filterFunction;
  const [searchList, setSearchList] = useState(attributeArray);
  const [selectedOption, setSelectedOptions] = useState(attributeArray);
  const [flag, setFlag] = useState(true);

  useEffect(()=>{
    let checkboxes = document.getElementsByName(filterName);
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = true;
    }
    let selectAllcheckbox = document.getElementById("all");
    selectAllcheckbox.checked=true;
  },
  [filterName])

  const onHide = () => {
    setshow(false);
    setFlag(true);
  }
  //filter the data based on userSelected Value
  const filterDataBasedOnSelection = () => {
    let temparray = [];
    temparray = originalData.filter((data) =>
      selectedOption.includes(data[filterName])
    );
    filterFunction(temparray);
    setshow(false);
    setFlag(true);
  };

  //Search for finding the attribute (for textField inside model )
  const search = (e) => {
    let value = e.target.value;
    let tempArray = attributeArray;
    if (value == "") {
      setSearchList(tempArray);
    } else {
      tempArray = attributeArray.filter((data) =>
        data.toUpperCase().includes(value.toUpperCase())
      );
      setSearchList(tempArray);
    }
  };

  //get the selected value from the popup Options
  const handleSelectedValues = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    if (checked) {
      setSelectedOptions([...selectedOption, value]);
    }
    else if (flag == true && checked == false) {
      document.getElementById("all").checked = false;
      let attributes = document.getElementsByName(filterName);
      for (var i = 0, n = attributes.length; i < n; i++) {
        attributes[i].checked = false;
      }
      document.getElementById(e.target.id).checked = true;
      setSelectedOptions(selectedOption.filter((x) => x == value));
      setFlag(false);
    }
    else {
      document.getElementById(e.target.id).checked = false;
      setSelectedOptions(selectedOption.filter((x) => x != value));
    }
  };
  //for Getting the ticks in the model(Popup) (for Select option ) 
  const toggleChecks = (e) => {
    let checkboxes = document.getElementsByName(filterName);
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = e.target.checked;
    }
    //if all attribute is checked then blue tick appear in front of all
    if (e.target.checked) {
      setSelectedOptions(searchList);
      setFlag(true);
    }
    //if all attribute is unchecked then blue tick not appear in front of all    
    else {
      setSelectedOptions(attributeArray.filter(item => !searchList.includes(item)));
    }
  };

  return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>{filterName}</Modal.Header>
        <Modal.Body>
          <input type="seacrh" onChange={search} />
          <div className="scroll">
            <span>
              <input
                type="checkbox"
                onClick={toggleChecks}
                id="all"
              />
              <span>  </span>
              Select All
              <br />
            </span>
            {searchList.map((data, index) => {
              return (
                <span key={data}>
                  <input
                    type="checkbox"
                    id={data}
                    name={filterName}
                    value={(data!=null)?data:""}
                    onChange={handleSelectedValues}
                  />
                   <span>  </span>
                  <label className={Style.labelStyle} htmlFor={data}>{data}</label>
                  <br />
                </span>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={filterDataBasedOnSelection}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

export default UserSelectionDropDown;
