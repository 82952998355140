import React, { Component } from 'react';
import { SlateEditor, SlateReadOnly } from './slate';
import { Button, ButtonGroup } from 'reactstrap';

export class Editor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            value: this.cloneValue(this.props)
        };
    }

    componentWillReceiveProps(nextProps) {
        const { value } = this.props
        if (nextProps.value !== value) {
            this.setState({ value: this.cloneValue(nextProps) });
        }
    }

    cloneValue({ value }) {
        return JSON.parse(JSON.stringify(value)); // ensures a deep copy
    }

    edit = () => this.setState({ editing: true });
    cancel = () => this.setState({ value: this.cloneValue(this.props), editing: false });
    save = () => {
        this.props.onSave(this.state.value);
        this.setState({ editing: false });
    };
    onChange = value => {
        this.setState({ value });
    }

    render() {
        var { canEdit } = this.props;
        var { editing, value } = this.state;
        var readonly = !canEdit || !editing;

        return <div>
            { readonly && value && <SlateReadOnly value={value} /> }
            { !readonly && <SlateEditor value={value} onChange={this.onChange} /> }
            
            {(canEdit && !editing) && <Button outline color="primary" onClick={this.edit}>Edit</Button>}
            {editing && <ButtonGroup>
                <Button outline color="danger" onClick={this.cancel}>Cancel</Button>
                <Button outline color="primary" onClick={this.save}>Save</Button>
            </ButtonGroup>}
        </div>;
    }
}