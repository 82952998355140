export const getBlockers = async (sprintId, teamId) => {
    const response = await fetch(`blockers/${teamId}/${sprintId}`);
    return await response.json();
};

export const mergeBlocker = async (teamId, blocker) => {
    const response = await fetch(`blockers/${teamId}/`, {
        method: 'Post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(blocker)
    });

    return await response.json();
};

export const deleteBlocker = async (blockerId) => {
    const response = await fetch(`blockers/${blockerId}`, {
        method: 'Delete',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return await response.text();
};


export default {
    getBlockers,
    mergeBlocker,
    deleteBlocker
}