import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button, Table } from "react-bootstrap";
import '../../components/TeamComposition.css';
import podStyle from './PODDetail.module.css';
function PODDetail(props) {
  const ROLES = [
    "Tech Lead",
    "Product Owner",
    "Team Members",
    "Product Support",
    "DevOps Support",
    "DBA Support",
    "Architecture Support",
    "ProdOps Support",
    "UX Support",
    "R&D Operations Support",
    "QA Support",
  ];
  const pod = props.podDetail;
  const [team, setTeam] = useState([
    {
      teamId: null,
      teamName: null,
      techLead: [],
      productOwner: [],
      teamMembers: [],
      productSupport: [],
      devOpsSupport: [],
      dbaSupport: [],
      architectureSupport: [],
      uxSupport: [],
      prodOpsSupport: [],
      rnDOpperationsSupport: [],
      qaSupport: [],
    },
  ]);
  const [podLeads, setPODLeads] = useState([
    {
      podLeadId: null,
      podLeadName: null,
      teams: team,
    },
  ]);

  useEffect(() => {
    let teams = new Set();
    let podLeads = [];
    let podTeam = [];
    let podLeadDetail = {};
    let localteam = team;

    podLeadDetail["podLeadId"] = pod.podLeads.map((l) => l.podLeadId);
    podLeadDetail["podLeadName"] = pod.podLeads.map((l) => l.podLeadName);
    podLeadDetail["teams"] = pod.podLeads.flatMap((t) =>
      t.teams.map((t) => {
        let team = {};
        team["teamId"] = t.teamId;
        team["teamName"] = t.teamName;
        team["techLead"] = t.techLead.map((x) => x.name);
        team["productOwner"] = t.productOwner.map((x) => x.name);
        team["teamMembers"] = t.teamMembers.map((x) => x.name);
        team["productSupport"] = t.productSupport.map((x) => x.name);
        team["devOpsSupport"] = t.devOpsSupport.map((x) => x.name);
        team["dbaSupport"] = t.dbaSupport.map((x) => x.name);
        team["architectureSupport"] = t.architectureSupport.map((x) => x.name);
        team["uxSupport"] = t.uxSupport.map((x) => x.name);
        team["prodOpsSupport"] = t.prodOpsSupport.map((x) => x.name);
        team["rnDOpperationsSupport"] = t.rnDOpperationsSupport.map(
          (x) => x.name
        );
        team["prodOpsSupport"] = t.prodOpsSupport.map((x) => x.name);
        team["qaSupport"] = t.qaSupport.map((x) => x.name);

        podTeam.push(team);
        return team;
      })
    );
    podLeadDetail.teams = podLeadDetail.teams.filter(function ({ teamId }) {
      return !this.has(teamId) && this.add(teamId);
    }, new Set());
    podLeads.push(podLeadDetail);
    setPODLeads(podLeads);
  }, []);

  return (
      <Modal
        size="lg"
        show={props.show}
        backdrop="static"
        onHide={() => props.setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            POD Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm="2">POD Name</Col>
            <Col>{pod.podName}</Col>
          </Row>
          <Row>
            <Col sm="2">POD Leads</Col>
            <Col m="4">
              {podLeads.map((l, index) => {
                if (index === pod.length - 1) return l.podLeadName;
                return l.podLeadName + " ";
              })}
            </Col>
          </Row>
          <Row>
            <Table striped responsive hover size="sm">
              <thead className="stickyHeader">
                <tr>
                  <th>Team Name</th>
                  {podLeads.map((l) =>
                    l.teams.map((t, index) => <th key={index}>{t.teamName}</th>)
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{ROLES[0]}</th>
                  {podLeads.map((l) =>
                    l.teams.map((t, index) => <td key={index} className={podStyle.Info}>{t.techLead.map((x, index) => (index === t.techLead.length - 1) ? x : x + ", ")}</td>)
                  )}
                </tr>
                <tr>
                  <th>{ROLES[1]}</th>
                  {podLeads.map((l) =>
                    l.teams.map((t, index) => <td key={index} className={podStyle.Info}>{t.productOwner.map((x, index) => (index === t.productOwner.length - 1) ? x : x + ", ")}</td>)
                  )}
                </tr>
                <tr>
                  <th>{ROLES[2]}</th>
                  {podLeads.map((l) =>
                    l.teams.map((t, index) => <td key={index} className={podStyle.Info}>{t.teamMembers.map((x, index) => (index === t.teamMembers.length - 1) ? x : x + ", ")}</td>)
                  )}
                </tr>
                <tr>
                  <th>{ROLES[3]}</th>
                  {podLeads.map((l) =>
                    l.teams.map((t, index) => <td key={index} className={podStyle.Info}>{t.productSupport.map((x, index) => (index === t.productSupport.length - 1) ? x : x + ", ")}</td>)
                  )}
                </tr>
                <tr>
                  <th>{ROLES[4]}</th>
                  {podLeads.map((l) =>
                    l.teams.map((t, index) => <td key={index} className={podStyle.Info}>{t.devOpsSupport.map((x, index) => (index === t.devOpsSupport.length - 1) ? x : x + ", ")}</td>)
                  )}
                </tr>
                <tr>
                  <th>{ROLES[5]}</th>
                  {podLeads.map((l) =>
                    l.teams.map((t, index) => <td key={index} className={podStyle.Info}>{t.dbaSupport.map((x, index) => (index === t.dbaSupport.length - 1) ? x : x + ", ")}</td>)
                  )}
                </tr>
                <tr>
                  <th>{ROLES[6]}</th>
                  {podLeads.map((l) =>
                    l.teams.map((t, index) => <td key={index} className={podStyle.Info}>{t.architectureSupport.map((x, index) => (index === t.architectureSupport.length - 1) ? x : x + ", ")}</td>)
                  )}
                </tr>
                <tr>
                  <th>{ROLES[7]}</th>
                  {podLeads.map((l) =>
                    l.teams.map((t, index) => <td key={index} className={podStyle.Info}>{t.uxSupport.map((x, index) => (index === t.uxSupport.length - 1) ? x : x + ", ")}</td>)
                  )}
                </tr>
                <tr>
                  <th>{ROLES[8]}</th>
                  {podLeads.map((l) =>
                    l.teams.map((t, index) => <td key={index} className={podStyle.Info}>{t.prodOpsSupport.map((x, index) => (index === t.prodOpsSupport.length - 1) ? x : x + ", ")}</td>)
                  )}
                </tr>
                <tr>
                  <th>{ROLES[9]}</th>
                  {podLeads.map((l) =>
                    l.teams.map((t, index) => <td key={index} className={podStyle.Info}>{t.rnDOpperationsSupport.map((x, index) => (index === t.rnDOpperationsSupport.length - 1) ? x : x + ", ")}</td>)
                  )}
                </tr>
                <tr>
                  <th>{ROLES[10]}</th>
                  {podLeads.map((l) =>
                    l.teams.map((t, index) => <td key={index} className={podStyle.Info}>{t.qaSupport.map((x, index) => (index === t.qaSupport.length - 1) ? x : x + ", ")}</td>)
                  )}
                </tr>
              </tbody>
            </Table>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Row className={podStyle.Button}>
            <Button variant="primary" onClick={() => props.setShow(false)}>Close</Button>
          </Row>
        </Modal.Footer>
      </Modal>
  );
}

export default PODDetail;
