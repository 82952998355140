export const GetChangeLog= async ()=>{
    const response = await fetch(`AhaJiraChangeLog/GetLogChanges`);
    return await response.json();
}

export const GetDailyCount= async ()=>{
    const response = await fetch(`AhaJiraChangeLog/GetDailyCount`);
    return await response.json();
}

export const GetDailyChangeLog= async (dataSource,changeType)=>{
   let startDate='',endDate='';
    const response = await fetch(`AhaJiraChangeLog/GetDailyChangeLogs?dataSource=${dataSource}&changeType=${changeType}&startDate=${startDate}&endDate=${endDate}`);
    return await response.json();
}

export const GetDateChangeLog= async (dataSource,changeType,startDate,endDate)=>{
    const response = await fetch(`AhaJiraChangeLog/GetDailyChangeLogs?dataSource=${dataSource}&changeType=${changeType}&startDate=${startDate}&endDate=${endDate}`);
    return await response.json();
}

export const GetPreSavedQuery=async (filter)=>{
    const reponse = await fetch(`AhaJiraChangeLog/GetPreSavedQueries?filterName=${filter}`);
    return await reponse.json();
}

export const GetDateChangesLog=async (startDate,endDate)=>{
    const reponse = await fetch(`AhaJiraChangeLog/GetDateChangesLog?startDate=${startDate}&endDate=${endDate}`);
    return await reponse.json();
}

export const GetDetailOverview=async (dataSource,changeType,startDate,endDate)=>{
    const reponse = await fetch(`AhaJiraChangeLog/GetDetailOverview?dataSource=${dataSource}&changeType=${changeType}&startDate=${startDate}&endDate=${endDate}`);
    return await reponse.json();
}

export default{
    GetChangeLog,
    GetDailyCount,
    GetDailyChangeLog,
    GetDateChangeLog,
    GetPreSavedQuery,
    GetDateChangesLog,
    GetDetailOverview
}