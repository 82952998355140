import React, { Component } from 'react';
import { Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import Conditional from '../../components/Conditional';
import { Loadable } from '../../components/Loadable';

import { Bar, HorizontalBar, Line } from 'react-chartjs-2';
import "chartjs-plugin-annotation";

import workstreamService from '../../services/workstreamService';

import Color from 'color';

export class WorkstreamScorePage extends Component {

    constructor(props) {

        super(props);

        this.state = {
            isLoading: true,
            data: {}
        };

    }

    componentDidMount() {
        this.setState({isLoading: true });
        this.loadScorecard();
    }

    async loadScorecard() {
        
        const data = await workstreamService.getScorecard();

        this.setState({ isLoading: false, activeTab: 'Productivity', data });

    }

    toggle (tab) {
        if(this.state.activeTab !== tab) {
            this.setState({activeTab: tab})
        }
    }

    render () {
        return (
            <div>
                <Loadable loaded={this.state.isLoading === false}>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={{ active: this.state.activeTab === 'Productivity' }} onClick={() => { this.toggle('Productivity'); }}>
                                Productivity
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={{ active: this.state.activeTab === 'Quality' }} onClick={() => { this.toggle('Quality'); }}>
                                Quality
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={{ active: this.state.activeTab === 'DevOps' }} onClick={() => { this.toggle('DevOps'); }}>
                                DevOps
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <Row>
                        <Col sm="12">
                        {this.renderActiveTab()}
                        </Col>
                    </Row>
                </Loadable>
            </div>
        );
    }

    renderActiveTab() {

        if (this.state.isLoading) {
            return;
        }

        if (this.state.activeTab == 'Productivity') {
            return this.renderProductivity();
        }

        if (this.state.activeTab == 'Quality') {
            return this.renderQuality();
        }

        if (this.state.activeTab == 'DevOps') {
            return this.renderDevOps();
        }
    }

    renderProductivity() {

        var productivity = this.state.data.productivity;
        var sprintLabels = this.state.data.sprintLabels;

        return (
            <React.Fragment>
                <Row className="productivity-row">
                    <Col sm="6">
                        {this.renderSingleBarChart(productivity.velocity, '101,157,189', sprintLabels)}
                    </Col>
                    <Col sm="6" className="sla-chart">
                        {this.renderSLAChart(productivity.velocitySLA, 'Velocity SLA')}
                    </Col>
                </Row>
                <Row className="productivity-row">
                    <Col sm="6">
                        {this.renderSingleBarChart(productivity.completion, '139,47,151', sprintLabels)}
                    </Col>
                    <Col sm="6" className="sla-chart">
                        {this.renderSLAChart(productivity.completionSLA, 'Completion SLA')}
                    </Col>
                </Row>
                <Row className="productivity-row">
                    <Col sm="6">
                        {this.renderSingleBarChart(productivity.impact, '255,99,132', sprintLabels)}
                    </Col>
                    <Col sm="6" className="sla-chart">
                        {this.renderSLAChart(productivity.impactSLA, 'Impact SLA')}
                    </Col>
                </Row>
                <Row className="productivity-row">
                    <Col sm="6">
                        {this.renderSingleBarChart(productivity.activity, '92,219,149', sprintLabels)}
                    </Col>
                    <Col sm="6" className="sla-chart">
                        {this.renderSLAChart(productivity.activitySLA, 'Activity SLA')}
                    </Col>
                </Row>
            </React.Fragment>
        );
        
    }

    renderQuality() {

        var quality = this.state.data.quality;
        var sprintLabels = this.state.data.sprintLabels;

        return (
            <React.Fragment>
                <Row className="quality-row">
                    <Col sm="6">
                    {this.renderStackedBarChart(quality.defectTrend, 'Defect Trend')}
                    </Col>
                    <Col sm="6">
                    {this.renderStackedLineChart(quality.defectCumulativeFlow, 'Open vs Closed', ['192,0,0','112,173,71'], sprintLabels)}
                    </Col>
                </Row>
                <Row className="quality-row">
                    <Col sm="6">
                        <Row>
                            {this.renderSeparateStatChart(
                                quality.blockerOutstanding, 
                                quality.majorOutstanding, 
                                quality.minorOutstanding, 
                                quality.trivialOutstanding, 
                                'Outstanding Defects')}
                        </Row>
                        <Row>
                            {this.renderSeparateStatChart(
                                quality.blockerMTTR, 
                                quality.majorMTTR, 
                                quality.minorMTTR, 
                                quality.trivialMTTR, 
                                'Mean time to Recover')}
                        </Row>
                    </Col>
                    <Col sm="6">
                    {this.renderUnstackedLineChart(quality.mttrTrend, 'MTTR Trend', ['68,114,196','255,192,0','237,125,49','192,0,0'], sprintLabels)}
                    </Col>
                </Row>
                <Row className="quality-row">
                    <Col sm="6">
                        {this.renderStackedLineChart(quality.slaViolation, 'SLA Violations', ['68,114,196','255,192,0','237,125,49','192,0,0'], sprintLabels)}
                    </Col>
                    <Col sm="6">
                    {this.renderStackedLineChart(quality.changeFailure, 'Change Failure', ['192,0,0'], sprintLabels)}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    renderDevOps() {

        var devops = this.state.data.devOps;
        var sprintLabels = this.state.data.sprintLabels.slice(-5);

        return (
            <React.Fragment>
                <Row>
                    <Col sm="4">
                        <Row className="devops-row-left">
                        {this.renderSLAChart(devops.capabilities.slice().reverse(), 'Capabilities')}
                        </Row>
                        <Row className="devops-row-left">
                        {this.renderSLAChart(devops.implementations.slice().reverse(), 'Implementations')}
                        </Row>
                        <Row className="devops-row-left">
                        {this.renderSLAChart(devops.codeCoverage.slice().reverse(), 'Code Coverage')}
                        </Row>
                        <Row className="devops-row-left">
                        {this.renderSLAChart(devops.sonarQube.slice().reverse(), 'SonarQube')}
                        </Row>
                        <Row className="devops-row-left">
                        {this.renderSLAChart(devops.veracode.slice().reverse(), 'Veracode')}
                        </Row>
                        <Row className="devops-row-left">
                        {this.renderSLAChart(devops.sourceClear.slice().reverse(), 'Source Clear')}
                        </Row>
                    </Col>
                    <Col sm="4">
                        <Row className="devops-row-right">
                        {this.renderStackedLineChart(devops.capabilities, 'Capabilities', ['192,0,0','255,192,0','112,173,71'], sprintLabels)}
                        </Row>
                        <Row className="devops-row-right">
                        {this.renderStackedLineChart(devops.codeCoverage, 'Code Coverage', ['192,0,0','255,192,0','112,173,71'], sprintLabels)}
                        </Row>
                        <Row className="devops-row-right">
                        {this.renderStackedLineChart(devops.veracode, 'Veracode', ['192,0,0','255,192,0','112,173,71'], sprintLabels)}
                        </Row>
                    </Col>
                    <Col sm="4">
                        <Row className="devops-row-right">
                        {this.renderStackedLineChart(devops.implementations, 'Implementations', ['192,0,0','255,192,0','112,173,71'], sprintLabels)}
                        </Row>
                        <Row className="devops-row-right">
                        {this.renderStackedLineChart(devops.sonarQube, 'SonarQube', ['192,0,0','255,192,0','112,173,71'], sprintLabels)}
                        </Row>
                        <Row className="devops-row-right">
                        {this.renderStackedLineChart(devops.sourceClear, 'Source Clear', ['192,0,0','255,192,0','112,173,71'], sprintLabels)}
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        );
        
    }

    renderSingleBarChart(dataSet, color, labels) {

        return (
            <Bar
                data={{
                    labels: labels,
                    datasets: [
                    {
                        label: dataSet.label,
                        backgroundColor: `rgba(${color},0.2)`,
                        borderColor: `rgba(${color},1)`,
                        borderWidth: 1,
                        hoverBackgroundColor: `rgba(${color},0.5)`,
                        hoverBorderColor: `rgba(${color},1)`,
                        data: dataSet.data,
                        barPercentage: 1,
                        categoryPercentage: .95
                    }
                    ]
                }}
                width={100}
                height={290}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                max: Math.max(...dataSet.data) * 1.10
                            }
                        }]
                    }
                }}
            />
        );

    }

    renderSLAChart(dataSets, title) {

        var problem = dataSets[0];
        var watch = dataSets[1];
        var good = dataSets[2];

        return (
            <HorizontalBar
                data={{
                    datasets: [
                    {
                        label: problem.label,
                        backgroundColor: `rgba(197,90,17,0.2)`,
                        borderColor: `rgba(197,90,17,1)`,
                        borderWidth: 1,
                        hoverBackgroundColor: `rgba(197,90,17,0.5)`,
                        hoverBorderColor: `rgba(197,90,17,1)`,
                        data: problem.data.slice(-1),
                        barPercentage: 1,
                        categoryPercentage: .95
                    },
                    {
                        label: watch.label,
                        backgroundColor: `rgba(255,192,0,0.2)`,
                        borderColor: `rgba(255,192,0,1)`,
                        borderWidth: 1,
                        hoverBackgroundColor: `rgba(255,192,0,0.5)`,
                        hoverBorderColor: `rgba(255,192,0,1)`,
                        data: watch.data.slice(-1),
                        barPercentage: 1,
                        categoryPercentage: .95
                    },
                    {
                        label: good.label,
                        backgroundColor: `rgba(112,173,71,0.2)`,
                        borderColor: `rgba(112,173,71,1)`,
                        borderWidth: 1,
                        hoverBackgroundColor: `rgba(112,173,71,0.5)`,
                        hoverBorderColor: `rgba(112,173,71,1)`,
                        data: good.data.slice(-1),
                        barPercentage: 1,
                        categoryPercentage: .95
                    }
                    ]
                }}
                width={100}
                height={150}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            ticks: {
                                beginAtZero:true,
                            },
                            scaleLabel:{
                                display:false
                            },
                            gridLines: {
                            }, 
                            stacked: true
                        }],
                        yAxes: [{
                            
                            stacked: true
                        }]
                    },
                    animation: {
                        onComplete: function () {
                            var chartInstance = this.chart;
                            var ctx = chartInstance.ctx;
                            ctx.textAlign = "left";
                            ctx.font = "24px Open Sans";
                            ctx.fillStyle = "#000";

                            this.data.datasets.forEach(function (dataset, i) {
                                var meta = chartInstance.controller.getDatasetMeta(i);
                                
                                meta.data.forEach(function (bar, index) {
                                    var data = dataset.data[index];

                                    if (data == 0) {
                                        return;
                                    }

                                    if(i==0){
                                        ctx.fillText(data, 50, bar._model.y+4);
                                    } else {
                                        ctx.fillText(data, bar._model.x-25, bar._model.y+4);
                                    }
                                })

                            });
                
                        }
                    },
                    title: {
                        display: true,
                        text: title
                    }
                }}
            />
        );

    }

    renderStackedBarChart(dataSets, title) {

        var blocker = dataSets[0];
        var major = dataSets[1];
        var minor = dataSets[2];
        var trivial = dataSets[3];

        var length = blocker.data.length;

        var max = 0;

        for (var i = 0; i < length; i++) {
            
            var size = blocker.data[i] + major.data[i] + minor.data[i] + trivial.data[i];

            if (size > max) {
                max = size;
            }

        }

        return (
            <Bar
                data={{
                    datasets: [
                        {
                            label: trivial.label,
                            backgroundColor: `rgba(68,114,196,0.2)`,
                            borderColor: `rgba(68,114,196,1)`,
                            borderWidth: 1,
                            hoverBackgroundColor: `rgba(68,114,196,0.5)`,
                            hoverBorderColor: `rgba(68,114,196,1)`,
                            data: trivial.data,
                            barPercentage: 1,
                            categoryPercentage: .95
                        },
                        {
                            label: minor.label,
                            backgroundColor: `rgba(255,192,0,0.2)`,
                            borderColor: `rgba(255,192,0,1)`,
                            borderWidth: 1,
                            hoverBackgroundColor: `rgba(255,192,0,0.5)`,
                            hoverBorderColor: `rgba(255,192,0,1)`,
                            data: minor.data,
                            barPercentage: 1,
                            categoryPercentage: .95
                        },
                        {
                            label: major.label,
                            backgroundColor: `rgba(237,125,49,0.2)`,
                            borderColor: `rgba(237,125,49,1)`,
                            borderWidth: 1,
                            hoverBackgroundColor: `rgba(237,125,49,0.5)`,
                            hoverBorderColor: `rgba(237,125,49,1)`,
                            data: major.data,
                            barPercentage: 1,
                            categoryPercentage: .95
                        },
                        {
                            label: blocker.label,
                            backgroundColor: `rgba(192,0,0,0.2)`,
                            borderColor: `rgba(192,0,0,1)`,
                            borderWidth: 1,
                            hoverBackgroundColor: `rgba(192,0,0,0.5)`,
                            hoverBorderColor: `rgba(192,0,0,1)`,
                            data: blocker.data,
                            barPercentage: 1,
                            categoryPercentage: .95
                        }
                    
                    ]
                }}
                width={100}
                height={385}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            stacked: true
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero:true,
                                max: max * 1.10
                            },
                            stacked: true
                        }]
                    },
                    title: {
                        display: true,
                        text: title
                    }
                }}
            />
        );

    }
    
    renderSeparateStatChart(blocker, major, minor, trivial, label) {

        var max = blocker + major + minor + trivial;

        return (
            <Bar
                data={{
                    labels: [label],
                    datasets: [
                        {
                            label: 'Blocker',
                            backgroundColor: `rgba(192,0,0,0.2)`,
                            borderColor: `rgba(192,0,0,1)`,
                            borderWidth: 1,
                            hoverBackgroundColor: `rgba(192,0,0,0.5)`,
                            hoverBorderColor: `rgba(192,0,0,1)`,
                            data: [blocker],
                            barPercentage: 1,
                            categoryPercentage: .95
                        },
                        {
                            label: 'Major',
                            backgroundColor: `rgba(237,125,49,0.2)`,
                            borderColor: `rgba(237,125,49,1)`,
                            borderWidth: 1,
                            hoverBackgroundColor: `rgba(237,125,49,0.5)`,
                            hoverBorderColor: `rgba(237,125,49,1)`,
                            data: [major],
                            barPercentage: 1,
                            categoryPercentage: .95
                        },
                        {
                            label: 'Minor',
                            backgroundColor: `rgba(255,192,0,0.2)`,
                            borderColor: `rgba(255,192,0,1)`,
                            borderWidth: 1,
                            hoverBackgroundColor: `rgba(255,192,0,0.5)`,
                            hoverBorderColor: `rgba(255,192,0,1)`,
                            data: [minor],
                            barPercentage: 1,
                            categoryPercentage: .95
                        },
                        {
                            label: 'Trivial',
                            backgroundColor: `rgba(68,114,196,0.2)`,
                            borderColor: `rgba(68,114,196,1)`,
                            borderWidth: 1,
                            hoverBackgroundColor: `rgba(68,114,196,0.5)`,
                            hoverBorderColor: `rgba(68,114,196,1)`,
                            data: [trivial],
                            barPercentage: 1,
                            categoryPercentage: .95
                        }
                    ]
                }}
                width={100}
                height={190}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                max: max * 1.10,
                                maxTicksLimit: 6
                            }
                        }]
                    },
                    animation: {
                        onComplete: function () {
                            var chartInstance = this.chart;
                            var ctx = chartInstance.ctx;
                            ctx.textAlign = "left";
                            ctx.font = "16px Open Sans";
                            ctx.fillStyle = "#000";

                            this.data.datasets.forEach(function (dataset, i) {
                                var meta = chartInstance.controller.getDatasetMeta(i);
                                
                                meta.data.forEach(function (bar, index) {
                                    var data = dataset.data[index];

                                    ctx.fillText(data, bar._model.x-25, bar._model.y-20);
                                })

                            });
                
                        }
                    },
                }}
            />
        );

    }

    renderStackedLineChart(dataSets, title, colors, labels) {

        var builtDataSets = dataSets.slice().reverse().map((x, i) => {

            var colorString = `rgb(${colors[i]})`;
            var original = Color(colorString);
            var lighter = original
                .lighten(0.5)
                .rgb()
                .array()
                .map(x => {
                    return Math.round(x);
                });

            var lightColor = `rgba(${lighter[0]},${lighter[1]},${lighter[2]},1)`;

            return {
                label: x.label,
                backgroundColor: lightColor,
                pointBackgroundColor: `rgba(${colors[i]},1)`,
                borderColor: `rgba(${colors[i]},1)`,
                pointHighlightStroke: `rgba(${colors[i]},1)`,
                data: x.data,
                barPercentage: 1,
                categoryPercentage: .95,
                fill: true
            };

        });

        var length = dataSets[0].data.length;

        var max = 0;

        for (var i = 0; i < length; i++) {
            
            var size = dataSets.reduce((total, x) => {

                return total + x.data[i];

            }, 0);

            if (size > max) {
                max = size;
            }

        }

        return (
            <Line
                data={{
                    labels: labels,
                    datasets: builtDataSets
                }}
                width={100}
                height={385}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            stacked: true
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero:true,
                                max: max * 1.10
                            },
                            stacked: true
                        }]
                    },
                    title: {
                        display: true,
                        text: title
                    }
                }}
            />
        );

    }

    renderUnstackedLineChart(dataSets, title, colors, labels) {

        var builtDataSets = dataSets.slice().reverse().map((x, i) => {

            var colorString = `rgb(${colors[i]})`;
            var original = Color(colorString);
            var lighter = original
                .lighten(0.5)
                .rgb()
                .array()
                .map(x => {
                    return Math.round(x);
                });

            var lightColor = `rgba(${lighter[0]},${lighter[1]},${lighter[2]},1)`;

            return {
                label: x.label,
                backgroundColor: lightColor,
                pointBackgroundColor: `rgba(${colors[i]},1)`,
                borderColor: `rgba(${colors[i]},1)`,
                pointHighlightStroke: `rgba(${colors[i]},1)`,
                data: x.data,
                barPercentage: 1,
                categoryPercentage: .95,
                fill: false
            };

        });

        var length = dataSets[0].data.length;

        var max = 0;

        for (var i = 0; i < length; i++) {
            
            dataSets.forEach(x => {

                const size = x.data[i];

                if (size > max) {
                    max = size;
                }

            });

        }

        return (
            <Line
                data={{
                    labels: labels,
                    datasets: builtDataSets
                }}
                width={100}
                height={385}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero:true,
                                max: max * 1.10
                            },
                        }]
                    },
                    title: {
                        display: true,
                        text: title
                    }
                }}
            />
        );

    }

}
