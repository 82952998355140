import React, { useEffect, useMemo,useState } from "react";
import Pagination from "../Pagination";
import common from "../common";

function TabGridTable(props) {
  const flashReportData = props.data;
  const [header, setHeader] = useState([]);

   //pagination
   const [dataPerPage, setDataPerPage] = useState(50);
   const [currentPage, setCurrentPage] = useState(1);
   const lastIndexofdata = currentPage * dataPerPage;
   const firstIndexofdata = lastIndexofdata - dataPerPage;
   const currentData = flashReportData.slice(firstIndexofdata, lastIndexofdata);
   const ItemPage = (number) => {
     setDataPerPage(number);
     setCurrentPage(1);
   };
 
   const pageNumber = useMemo(
     () =>
       Array.from(
         new Array(Math.ceil(flashReportData.length / dataPerPage)),
         (val, index) => index + 1
       ),
     [flashReportData]
   );
 
   //change page
   const paginate = (currentPage) => {
     if (currentPage >= 1 && currentPage <= pageNumber.length)
       setCurrentPage(currentPage);
   };

  useEffect(() => {
    setCurrentPage(1);
    if (flashReportData.length != 0) {
      Object.keys(flashReportData[0]).map((key) => {
        if (header.includes(key) === false) setHeader((x) => [...x, key])
      }
      );
    }
  }, [flashReportData]);

  return (
    <div>
      <div className="table-responsive"
        style={{ height: (90 * window.innerHeight / 100) + "px", maxHeight: (window.innerHeight) + "px"}}
      >
        <table className="table table-striped" aria-labelledby="tabelLabel" >
          <thead className="stickyHeader">
            <tr>
              {header.map((key, index) => (
                <th key={index} style={{ zIndex: "0" }}> {common.camelCase(key) + " "}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(currentData.length != 0) ?
              currentData.map((data, index) => (
                <tr key={index}>
                  {
                    header.map((key, i) => (
                        <td key={i}>
                          {data[key]}
                        </td>
                    ))
                  }
                </tr>
              )) :
              <tr><td>No data available</td></tr>
            }
          </tbody>
        </table>
      </div>
      <Pagination
        paginate={paginate}
        pageNumber={pageNumber}
        currentPage={currentPage}
        ItemPage={ItemPage}
        dataPerPage={dataPerPage}
      />
    </div>
  );
}

export default TabGridTable;
