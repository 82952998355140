export const GetGoalOptionsData = async () => {
    const response = await fetch(`FlashReportObjectiveMapping/GetGoals`);
    return await response.json();
}

export const GetPodGoalGridData = async () => {
    const response = await fetch(`FlashReportObjectiveMapping/GetPodGoalMappings`);
    return await response.json();
}

export const PostPodObjectiveMappingsData = async (podData) => {
    const response = await fetch(
        `FlashReportObjectiveMapping/UploadPodGoalMappings`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(podData),
    }).then((res) => {
        if (res.status !== 200) throw new Error(res.status);
        else return res;
    })
        .catch((error) => {
            return "error";
        });

    return response;
};

export default {
    GetGoalOptionsData,
    GetPodGoalGridData,
    PostPodObjectiveMappingsData
}