import React, { useEffect, useState } from "react";
import Style from "./DevOpsDashboard.module.css"

function IncorrectReleaseNoteTable(props) {
  const data = props.incorrectReleaseData;
  const [headers, setHeaders] = useState([]);
  //pagination
  const [dataPerPage, setDataPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const lastIndexofdata = currentPage * dataPerPage;
  const firstIndexofdata = lastIndexofdata - dataPerPage;
  const currentData = data.slice(firstIndexofdata, lastIndexofdata);
  const ItemPage = (number) => {
    setDataPerPage(number);
    setCurrentPage(1);
  };
  const pageNumber = Array.from(
    new Array(Math.ceil(data.length / dataPerPage)),
    (val, index) => index + 1
  );

  //change page
  const paginate = (currentPage) => {
    if (currentPage >= 1 && currentPage <= pageNumber.length)
      setCurrentPage(currentPage);
  };

  useEffect(() => {
    if (props.incorrectReleaseData.length != 0) {
      Object.keys(data[0]).map((key) => setHeaders((x) => [...x, key]));
    }
    setCurrentPage(1);
  }, []);
  const camelCase = (data) => {
    data = data.replace(/([a-z])([A-Z])/g, "$1 $2");
    data = data.charAt(0).toUpperCase() + data.slice(1);
    return data;
  };
  return (
    <div  className={Style.tableFont}
    >
      <div className="table-responsive">
        <table
          className="table table-striped"
          align="center"        >
          <thead>
            <tr>
              {data.length != 0
                ? headers.map((key, index) => (
                    <th key={index}>{camelCase(key)}</th>
                  ))
                : null}
            </tr>
          </thead>
          <tbody>
            {data.length != 0 ? (
              currentData.map((data, index) => (
                <tr key={index}>
                  {headers.map((key, index) =>
                    key == "mergeRequestKey" ||
                    key == "releaseNotesKey" ||
                    key == "releaseItemKey" ? (
                      <td key={index}>
                        <a
                          href={
                            "https://onsolve.atlassian.net/browse/" + data[key]
                          }
                          target="_blank"
                        >
                          {data[key]}
                        </a>
                      </td>
                    ) : (
                      <td key={index}>{data[key]}</td>
                    )
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="13">NO ERROR DATA TO SHOW</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </a>
            </li>
            {pageNumber.map((number) => (
              <li
                key={number}
                className={
                  currentPage == number ? "page-item active" : "page-item"
                }
              >
                <a className="page-link" onClick={() => paginate(number)}>
                  {number}
                </a>
              </li>
            ))}
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </a>
            </li>

            <li>
              <div>
                &nbsp; Items per page:
                <div className="btn-group dropup">
                  &nbsp;{" "}
                  <button
                    type="button"
                    className="btn  btn-sm  btn-secondary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {dataPerPage}
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" onClick={() => ItemPage(25)}>
                      25
                    </a>
                    <a className="dropdown-item" onClick={() => ItemPage(50)}>
                      50
                    </a>
                    <a className="dropdown-item" onClick={() => ItemPage(75)}>
                      75
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default IncorrectReleaseNoteTable;
