import React, { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import common from "../../components/common"
import { Loadable } from "../../components/Loadable";
import activeSprintReportService from "../../services/activeSprintReportService";
import reportStyle from './ActiveSprintReport.module.css';
const Ascending = "ascending";
const Descending = "descending";

export default function ActiveSprintReport() {

    const [load, setLoad] = useState(false);
    const [filterArray, setFilterArray] = useState([]);
    const [jiraRefreshDate, setJiraRefreshDate] = useState("");
    const [sortOrder, setSortOrder] = useState(Ascending);
    const [isHovered, setIsHovered] = useState(false);
    const [isHoverActiveSprint, setIsHoverActiveSprint] = useState(false);
    const [isHoverActiveEpics, setIsHoverActiveEpics] = useState(false);
    const [isHoverStoryPoints, setIsHoverStoryPoints] = useState(false);
    const [isHoverActiveStories, setIsHoverActiveStories] = useState(false);
    const [isHoverActiveBugs, setIsHoverActiveBugs] = useState(false);
    const [isHoverActiveTasks, setIsHoverActiveTasks] = useState(false);
    const [isHoverIssuesNoPoints, setIsHoverIssuesNoPoints] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    //pagination
    const [dataPerPage, setDataPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const lastIndexofdata = currentPage * dataPerPage;
    const firstIndexofdata = lastIndexofdata - dataPerPage;
    const currentData = filterArray.slice(firstIndexofdata, lastIndexofdata);

    const ItemPage = (number) => {
        setDataPerPage(number);
        setCurrentPage(1);
    };

    const pageNumber = Array.from(
        new Array(Math.ceil(filterArray.length / dataPerPage)),
        (val, index) => index + 1
    );

    //change page
    const paginate = (currentPage) => {
        if (currentPage >= 1 && currentPage <= pageNumber.length)
            setCurrentPage(currentPage);
    };

    //sorting Data based on which col. is clicked.
    const sortData = (property) => {
        let sort_order;
        if (sortOrder == Ascending) sort_order = 1;
        if (sortOrder == Descending) sort_order = -1;
        let sortData = [...filterArray].sort(common.dynamicSort(property, sort_order));
        setFilterArray(sortData);
    }

    const toggleSortOrder = (property) => {
        if (sortOrder == Ascending) setSortOrder(Descending);
        if (sortOrder == Descending) setSortOrder(Ascending);
        sortData(property, sortOrder);
    }

    const loadData = async () => {
        let jiraResponseDate = await activeSprintReportService.GetJiraLastUpdateDate();
        let jiradateString = await jiraResponseDate.text();
        let jiradate = new Date(jiradateString + " UTC");
        setJiraRefreshDate(jiradate.toLocaleString());

        setLoad(false);
        let data = await activeSprintReportService.GetActiveSprintsData();

        setFilterArray(data);
        setLoad(true);
    };
    const renderFields = (sprintData, index) => {
        return (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>
                    <div>{sprintData.teamName} </div>
                </td>
                <td>
                    <div>{sprintData.activeSprint}</div>
                </td>
                <td>
                    <div>{sprintData.activeEpics}</div>
                </td>
                <td>
                    <div>{sprintData.storyPoints}</div>
                </td>
                <td>
                    <div>{sprintData.activeStories}</div>
                </td>
                <td>
                    <div>{sprintData.activeBugs}</div>
                </td>
                <td>
                    <div>{sprintData.activeTasks}</div>
                </td>
                <td>
                    <div>{sprintData.issuesNoPoints}</div>
                </td>
            </tr>
        );
    };


    return (
        <Loadable loaded={load}>
            <div>
                <div style={{ textAlign: "end" }}>
                    <p >Last Jira Refresh: {jiraRefreshDate}</p>
                </div>
                <div>
                    <table
                        className="table table-striped table-responsive-xl"
                        aria-labelledby="tabelLabel"
                        style={{ overflowWrap: "break-word" }}
                    >
                        <thead className="stickyHeader">
                            <tr>
                                <th>
                                    <p>S.No</p>
                                </th>
                                <th key={'teamName'}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p>Team Name </p>
                                        </div>

                                        <div >
                                            <span tabIndex="0" data-toggle="tooltip" title="Sort">
                                                <i className={`fa fa-sort ${isHovered ? reportStyle.SortHoverStyle : reportStyle.SortStyle}`}
                                                    onClick={() => toggleSortOrder('teamName')}
                                                    onMouseOver={() => setIsHovered(!isHovered)}
                                                    onMouseOut={() => setIsHovered(!isHovered)}
                                                >
                                                </i>
                                            </span>
                                        </div>

                                    </div>
                                </th>
                                <th key={'activeSprint'}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p>Active Sprint </p>
                                        </div>
                                        <div>
                                            <span tabIndex="0" data-toggle="tooltip" title="Sort">
                                                <i className={`fa fa-sort ${isHoverActiveSprint ? reportStyle.SortHoverStyle : reportStyle.SortStyle}`}
                                                    onClick={() => toggleSortOrder('activeSprint')}
                                                    onMouseOver={() => setIsHoverActiveSprint(!isHoverActiveSprint)}
                                                    onMouseOut={() => setIsHoverActiveSprint(!isHoverActiveSprint)}
                                                >
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </th>
                                <th key={'activeEpics'}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p>Active Epics </p>
                                        </div>
                                        <div>
                                            <span tabIndex="0" data-toggle="tooltip" title="Sort">
                                                <i className={`fa fa-sort ${isHoverActiveEpics ? reportStyle.SortHoverStyle : reportStyle.SortStyle}`}
                                                    onClick={() => toggleSortOrder('activeEpics')}
                                                    onMouseOver={() => setIsHoverActiveEpics(!isHoverActiveEpics)}
                                                    onMouseOut={() => setIsHoverActiveEpics(!isHoverActiveEpics)}
                                                >
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </th>
                                <th key={'storyPoints'}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p>Story Points </p>
                                        </div>
                                        <div>
                                            <span tabIndex="0" data-toggle="tooltip" title="Sort">
                                                <i className={`fa fa-sort ${isHoverStoryPoints ? reportStyle.SortHoverStyle : reportStyle.SortStyle}`}
                                                    onClick={() => toggleSortOrder('storyPoints')}
                                                    onMouseOver={() => setIsHoverStoryPoints(!isHoverStoryPoints)}
                                                    onMouseOut={() => setIsHoverStoryPoints(!isHoverStoryPoints)}
                                                >
                                                </i>
                                            </span>
                                        </div>
                                    </div>

                                </th>
                                <th key={'activeStories'}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p>Active Stories </p>
                                        </div>
                                        <div>
                                            <span tabIndex="0" data-toggle="tooltip" title="Sort">
                                                <i className={`fa fa-sort ${isHoverActiveStories ? reportStyle.SortHoverStyle : reportStyle.SortStyle}`}
                                                    onClick={() => toggleSortOrder('activeStories')}
                                                    onMouseOver={() => setIsHoverActiveStories(!isHoverActiveStories)}
                                                    onMouseOut={() => setIsHoverActiveStories(!isHoverActiveStories)}
                                                >
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </th>
                                <th key={'activeBugs'}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p>Active Bugs </p>
                                        </div>
                                        <div>
                                            <span tabIndex="0" data-toggle="tooltip" title="Sort">
                                                <i className={`fa fa-sort ${isHoverActiveBugs ? reportStyle.SortHoverStyle : reportStyle.SortStyle}`}
                                                    onClick={() => toggleSortOrder('activeBugs')}
                                                    onMouseOver={() => setIsHoverActiveBugs(!isHoverActiveBugs)}
                                                    onMouseOut={() => setIsHoverActiveBugs(!isHoverActiveBugs)}
                                                >
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </th>
                                <th key={'activeTasks'}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p>Active Tasks </p>
                                        </div>
                                        <div>
                                            <span tabIndex="0" data-toggle="tooltip" title="Sort">
                                                <i className={`fa fa-sort ${isHoverActiveTasks ? reportStyle.SortHoverStyle : reportStyle.SortStyle}`}
                                                    onClick={() => toggleSortOrder('activeTasks')}
                                                    onMouseOver={() => setIsHoverActiveTasks(!isHoverActiveTasks)}
                                                    onMouseOut={() => setIsHoverActiveTasks(!isHoverActiveTasks)}
                                                >
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </th>
                                <th key={'issuesNoPoints'}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p>Issues - No Points </p>
                                        </div>
                                        <div>
                                            <span tabIndex="0" data-toggle="tooltip" title="Sort">
                                                <i className={`fa fa-sort ${isHoverIssuesNoPoints ? reportStyle.SortHoverStyle : reportStyle.SortStyle}`}
                                                    onClick={() => toggleSortOrder('issuesNoPoints')}
                                                    onMouseOver={() => setIsHoverIssuesNoPoints(!isHoverIssuesNoPoints)}
                                                    onMouseOut={() => setIsHoverIssuesNoPoints(!isHoverIssuesNoPoints)}
                                                >
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.map((data, index) => renderFields(data, index))}
                        </tbody>
                    </table>
                    <div>
                        <Pagination paginate={paginate} pageNumber={pageNumber} currentPage={currentPage} ItemPage={ItemPage} dataPerPage={dataPerPage} />
                    </div>
                </div>
            </div>
        </Loadable>
    );
}

