export class Claims {

    constructor(claims) {

        this.rawClaims = claims;
        this.reissue = () => { };

    }

    isAdmin() {
        return this.rawClaims.Role.includes("Administrator");
    }


    isWhiteListUser() {
        return this.rawClaims.OnSolveEmail.toUpperCase() == "brad.andrikowich@onsolve.com".toUpperCase() || this.rawClaims.OnSolveEmail.toUpperCase() == "vinay.jhedu@onsolve.com".toUpperCase() || this.rawClaims.OnSolveEmail.toUpperCase() == "abhinav.goyal@onsolve.com".toUpperCase() || this.rawClaims.OnSolveEmail.toUpperCase() == "deep.kaur@onsolve.com".toUpperCase() || this.rawClaims.OnSolveEmail.toUpperCase() == "Priyanshi.Saini@onsolve.com".toUpperCase() || this.rawClaims.OnSolveEmail.toUpperCase() == "nayan.kapadia@onsolve.com".toUpperCase() || this.rawClaims.OnSolveEmail.toUpperCase() == "miraj.ansari@onsolve.com";
    }

    canConfigure(resource) {

        //Admins can configure anything
        if (this.isAdmin()) {
            return true;
        }

        //if you're not an admin and there are not configure claims you can't configure
        if (!Array.isArray(this.rawClaims.Configure)) {
            return false;
        }

        //If no resource but you have configure ability, you can at least configure one thing
        if (resource == null) {
            return true;
        }

        return this.rawClaims.Configure.includes(resource);
    }

    isImpersonator() {
        return this.rawClaims.Impersonator != null;
    }

    canConfigureSpecificResource(resource, resourceId) {
        return this.isAdmin() || (this.canConfigure(resource) && this.rawClaims[`Configure${resource}`].includes(`${resourceId}`));
    }

}