import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Conditional from '../components/Conditional';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        this.impersonate = this.impersonate.bind(this);
    }

    async impersonate() {
        var response = await fetch(`/token/impersonator`);
        var data = await response.json()
        window.history.pushState({}, '', '/endimpersonation');
        window.location = `#app_token=${data.jwtToken}`;
    }


    render() {
        return (
            <div>
                <NavMenu claims={this.props.claims} />
                    <Conditional if={this.props.claims.isImpersonator()}>
                        <div className="alert alert-info" role="alert">
                            <p>Impersonation Mode | Source User - {this.props.claims.rawClaims.ImpersonatorName}</p>
                            <button type="button" className="btn btn-warning" onClick={() => this.impersonate()}>End Impersonation</button>
                        </div>
                    </Conditional>
                    <Container className="main-container">
                        {this.props.children}
                    </Container>
            </div>
        );
    }
}
