import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Col, Row } from "reactstrap";
import manualKPIDashboardService from "../../services/manualKPIDashboardService";
import { Loadable } from "../Loadable";
import Style from "../../pages/Dashboard/ManualKPIEntryDashboard.module.css";

function GitPrimeMetricsForm(props) {
    const [gitfields, setGitField] = useState([]);
    const [orginalGitData, setOrginalGitData] = useState();
    const [date, setDate] = useState(new Date());
    const [editRowId, setEditRowId] = useState();
    const [activityErrorField, setActivityErrorField] = useState();
    const [impactErrorField, setImpactErrorField] = useState();
    const [loaded, setLoaded] = useState(true);
    const [errorIndex, setErrorIndex] = useState();
    useEffect(() => {
        loadData();
    }, [date]);

    const loadData = async () => {
        setLoaded(false);
        let gitdata = await manualKPIDashboardService.getGitPrimeData(date);
        setGitField(gitdata);
        var original = JSON.parse(JSON.stringify(gitdata));
        setOrginalGitData(original);
        setLoaded(true);
    };

    const validateForm = () => {
        debugger;
        if (impactErrorField != undefined || activityErrorField != undefined)
            return false;
        else return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let finalobj = gitfields.filter((data) => {
            if (data.impact || data.activity) {
                if (data.id) data.tranType = "update";
                else data.tranType = "add";
                return data;
            }
        });
        finalobj = finalobj.filter((data) => {
            let original = orginalGitData.find((x) => x.id === data.id);
            if (data.impact != original.impact || data.activity != original.activity)
                return data;
        });

        if (finalobj.length < 1) {
            alert("No changes to submit");
        } else {
            if (validateForm()) {
                let ans = window.confirm("Are you sure you want to submit?");
                if (ans === true) {
                    let res = await manualKPIDashboardService.postUpdateGitPrimeData(
                        finalobj
                    );
                    if (res.ok) alert("The form is submitted successfully");
                    else alert("Something went wrong");
                }
            } else {
                alert("Make sure there is no error");
            }
        }
        await loadData();
    };

    const handleDateChange = (date) => {
        setDate(date);
        let datefields = gitfields.map((gitfield) => {
            gitfield.date = new Date(date);
            return gitfield;
        });
        setGitField(datefields);
    };

    function CheckdecimalPlace(num) {
        if (num) {
            var s = num.split(".");
            if (s[1] && s[1].length > 1) {
                return true;
            }
            return false;
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value, id } = event.target;
        let currentgitfields = gitfields;
        switch (name) {
            case "activity": {
                if (CheckdecimalPlace(value)) {
                    setErrorIndex(parseInt(id));
                    setActivityErrorField(
                        "There should be only one decimal place (Ex.:- 9.9)"
                    );
                } else if (parseFloat(value) > 9.9) {
                    setErrorIndex(parseInt(id));
                    setActivityErrorField("Value cannot be larger than 9.9");
                } else {
                    setActivityErrorField();
                    setErrorIndex();
                }
                currentgitfields[id][name] = parseFloat(value);
                break;
            }
            case "impact": {
                if (value && !Number.isInteger(parseFloat(value))) {
                    setErrorIndex(parseInt(id));
                    setImpactErrorField("The value cannot be decimal");
                } else {
                    setErrorIndex();
                    setImpactErrorField();
                }
                currentgitfields[id][name] = parseInt(value);
            }
        }

        setGitField(currentgitfields);
    };

    const renderFields = (gitData, index) => {
        if (!gitData.impact && !gitData.activity)
            return editableRow(gitData, index);
        else {
            return editRowId === index ? (
                editableRow(gitData, index)
            ) : (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                            <div>{gitData.teamName}</div>
                        </td>
                        <td onClick={() => setEditRowId(index)}>
                            <div>{gitData.activity}</div>
                            {index == errorIndex &&
                                activityErrorField &&
                                activityErrorField.length > 0 && (
                                    <div className={Style.error}>{activityErrorField}</div>
                                )}
                        </td>
                        <td onClick={() => setEditRowId(index)}>
                            <div>{gitData.impact}</div>
                            {index == errorIndex &&
                                impactErrorField &&
                                impactErrorField.length > 0 && (
                                    <div className={Style.error}>{impactErrorField}</div>
                                )}
                        </td>
                    </tr>
                );
        }
    };
    const editableRow = (gitData, index) => {
        return (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>
                    <div>{gitData.teamName}</div>
                </td>
                <td>
                    <input
                        type="number"
                        id={index}
                        defaultValue={gitData.activity || " "}
                        name="activity"
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") setEditRowId();
                        }}
                    />
                    {index == errorIndex &&
                        activityErrorField &&
                        activityErrorField.length > 0 && (
                            <div className={Style.error}>{activityErrorField}</div>
                        )}
                </td>
                <td>
                    <input
                        type="number"
                        id={index}
                        defaultValue={gitData.impact || " "}
                        name="impact"
                        style={{ width: "100%" }}
                        onChange={handleChange}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") setEditRowId();
                        }}
                    />
                    {index == errorIndex &&
                        impactErrorField &&
                        impactErrorField.length > 0 && (
                            <div className={Style.error}>{impactErrorField}</div>
                        )}
                </td>
            </tr>
        );
    };

    return (
        <Loadable loaded={loaded}>
            <div>
                <form onSubmit={handleSubmit} noValidate>
                    <Row>
                        <Col sm="1">
                            <div>
                                <label htmlFor="date">Date:</label>
                            </div>
                        </Col>
                        <Col>
                            <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={date}
                                name="date"
                                //maxDate={new Date()}
                                required={true}
                                filterDate={date => date.getDay() === 0}
                                onChange={(d) => handleDateChange(d)}
                                autoComplete="off"
                            />
                        </Col>
                    </Row>
                    <table
                        className={'table table-striped ' + Style.gitPrimeMetricsTable}
                        aria-labelledby="tabelLabel"
                        style={{ tableLayout: "fixed" }}
                    >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Team Name</th>
                                <th>Activity</th>
                                <th>Impact</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gitfields.map((data, index) => renderFields(data, index))}
                        </tbody>
                    </table>
                    <div style={{ marginLeft: "50px" }}>
                        <button type="submit" className="btn btn-info btn-primary">
                            Submit
            </button>
                    </div>
                </form>
            </div>
        </Loadable>
    );
}

export default GitPrimeMetricsForm;
