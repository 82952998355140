import React, { useCallback, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import { Editor, Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { ButtonGroup, Button } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic, faUnderline, faCode, faHeading, faQuoteLeft, faListOl, faListUl } from '@fortawesome/free-solid-svg-icons';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const MarkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
            outline
            color="primary"
        >
            <FontAwesomeIcon icon={icon} />
        </Button>
    )
}

const BlockButton = ({ format, icon, label }) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
            outline
            color="primary"
        >
            <FontAwesomeIcon icon={icon} />{label}
        </Button>
    )
}

export const SlateReadOnly = ({ value }) => {
    const editor = useMemo(() => withReact(createEditor()), [])
    const renderElement = useCallback(p => <Element {...p} />, [])
    const renderLeaf = useCallback(p => <Leaf {...p} />, [])

    return (
        <Slate editor={editor} value={value}>
            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
            />
        </Slate>
    )
}

export const SlateEditor = ({ value, onChange }) => {
    const renderElement = useCallback(p => <Element {...p} />, [])
    const renderLeaf = useCallback(p => <Leaf {...p} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])

    if (!value)
        value = [{ type: "paragraph", children: [{ text: "" }] }];

    return (
        <div className="editor">
            <Slate editor={editor} value={value} onChange={onChange}>
                <ButtonGroup>
                    <MarkButton format="bold" icon={faBold} />
                    <MarkButton format="italic" icon={faItalic} />
                    <MarkButton format="underline" icon={faUnderline} />
                    <MarkButton format="code" icon={faCode} />
                    <BlockButton format="heading-one" icon={faHeading} label="1" />
                    <BlockButton format="heading-two" icon={faHeading} label="2" />
                    <BlockButton format="block-quote" icon={faQuoteLeft} />
                    <BlockButton format="numbered-list" icon={faListOl} />
                    <BlockButton format="bulleted-list" icon={faListUl} />
                </ButtonGroup>
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    onKeyDown={event => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault()
                                const mark = HOTKEYS[hotkey]
                                toggleMark(editor, mark)
                            }
                        }
                    }}
                />
            </Slate>
        </div>
    )
}

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format)
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n => LIST_TYPES.includes(n.type),
        split: true,
    })

    Transforms.setNodes(editor, {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    })

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n => n.type === format,
    })

    return !!match
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }) => {
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>
        case 'list-item':
            return <li {...attributes}>{children}</li>
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>
        default:
            return <p {...attributes}>{children}</p>
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}
