import React, { Component } from 'react';
import { ListGroup, ListGroupItem, Input, Button, Row, Col } from 'reactstrap';

import Toggle from 'react-toggle';
import "react-toggle/style.css";

import teamService from '../services/teamService';
import memberService from '../services/memberService';
import officeService from '../services/officeService';
import vendorService from '../services/vendorService';

import Conditional from '../components/Conditional';
import { Loadable } from '../components/Loadable';
import { SimpleTooltip } from '../components/SimpleToolTip';
import { EditTeamModal } from '../components/EditTeamModal';

import { TimeZoneToolTip } from '../components/TimeZone';
import '../components/TeamComposition.css'

export class TeamComposition extends Component {
    static displayName = TeamComposition.name;

    constructor(props) {
        super(props);

        this.state = {
            teams: [],
            loading: false,
            directOnly: true,
            unassigned: [],
            offices: [],
            vendors: [],
            officeColors: {},
            searchQuery: '',
            editing: 0,
            editNote: '',
            editTeamMembers: [],
            showActiveTeam: true,
        };

        this.renderTeam = this.renderTeam.bind(this);
        this.renderMembers = this.renderMembers.bind(this);
        this.renderMember = this.renderMember.bind(this);
        this.toggleDirectOnly = this.toggleDirectOnly.bind(this);
        this.toggleOfficeColorCode = this.toggleOfficeColorCode.bind(this);
        this.searchTeams = this.searchTeams.bind(this);
        this.filterToMe = this.filterToMe.bind(this);
        this.editTeam = this.editTeam.bind(this);
        this.closeEdit = this.closeEdit.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {

        this.setState({ loading: true });

        var [teams, members, offices, vendors] = await Promise.all([
            teamService.getTeamComposition(),
            memberService.getUnassigned(),
            officeService.getAll(),
            vendorService.getAll()]
        );

        this.setState({ teams: teams, unassigned: members, offices: offices, vendors: vendors, loading: false });
    }

    toggleDirectOnly() {

        this.setState({ directOnly: !this.state.directOnly });
    }

    toggleOfficeColorCode() {

        this.setState({ colorCodeOffices: !this.state.colorCodeOffices });
    }

    async editTeam(teamId) {

        var data = await teamService.get(teamId);

        this.setState({ editing: teamId, editTeamMembers: data.members });
    }

    closeEdit(needsRefresh) {
        this.setState({ editing: 0 });

        if (needsRefresh) {
            this.loadData();
        }
    }

    ActiveTeamOnly = () => this.setState({ showActiveTeam: !this.state.showActiveTeam })

    render() {

        return (
            <Loadable loaded={this.state.loading === false}>
                <Conditional if={this.state.editing !== 0}>
                    <EditTeamModal isOpen={this.state.editing !== 0} teamId={this.state.editing} teamMembers={this.state.editTeamMembers} close={this.closeEdit} />
                </Conditional>
                <Row style={{ paddingBottom: '5px' }}>
                    <Col sm="0.5">
                        <Toggle
                            defaultChecked={this.state.directOnly}
                            onChange={this.toggleDirectOnly} />

                    </Col>
                    &nbsp;Show Direct Team Members Only
                </Row>
                <Row style={{ paddingBottom: '5px' }}>
                    <Col sm="0.5">
                        <Toggle
                            defaultChecked={this.state.showActiveTeam}
                            onChange={this.ActiveTeamOnly} />
                    </Col>
                    &nbsp;Show Active Team
                </Row>
                <Row style={{ padding: '5px' }}>
                    <Col sm="2">
                        <Input
                            type="search"
                            name="search"
                            placeholder="Search the teams . . ."
                            onChange={e => this.setState({ searchQuery: e.target.value })}
                            value={this.state.searchQuery}
                        />
                    </Col>
                    <Col sm="1">
                        <label>Quick Filter</label>
                    </Col>
                    <Col sm="2">
                        <Button onClick={this.filterToMe}>My Teams</Button>
                    </Col>
                </Row>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead  class="stickyHeader">
                        <tr>
                            <th>Name</th>
                            <Conditional if={this.state.directOnly === false}>
                                <th>Manager</th>
                                <th>Delivery Manager</th>
                                <th>Product Manager</th>
                            </Conditional>
                            <th>Product Owner</th>
                            <th>Scrum Master</th>
                            <th>Tech Lead</th>
                            <th>Developers</th>
                            <th>Test Engineers</th>
                            <th>Embedded Devops</th>
                            <th>SRE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.teams.filter(this.searchTeams).map(this.renderTeam)}
                    </tbody>
                </table>
            </Loadable>
        );
    }

    filterToMe() {
        var name = this.props.claims.rawClaims.LegalName;
        this.setState({ searchQuery: name });
    }

    searchTeams(team) {
        if (!this.state.searchQuery) {
            return true;
        }

        var exp = new RegExp(`^(${this.state.searchQuery.trim()})|\\s(${this.state.searchQuery.trim()})`, 'ig');

        var match = m => {
            return m && m.name && m.name.match(exp);
        };

        return team.name.match(exp) ||
            team.managers.some(d => match(d)) ||
            team.deliveryManagers.some(d => match(d)) ||
            team.productManagers.some(d => match(d)) ||
            team.productOwners.some(d => match(d)) ||
            team.scrumMasters.some(d => match(d)) ||
            team.techLeads.some(d => match(d)) ||
            team.developers.some(d => match(d)) ||
            team.devOps.some(t => match(t)) ||
            team.developers.some(d => match(d))||
            team.sre.some(d=>match(d));
    }

    renderTeam(team) {
        var showactive = team.active;
        if (!this.state.showActiveTeam) {
            showactive = true;
        }
        return (
            (showactive) && <tr key={team.id} style={{ fontSize: '0.75em' }}>
                <td>
                    <Row>
                        <Col lg="12">
                            <h2 className="text-center">{team.name}</h2>
                        </Col>
                    </Row>
                    <Conditional if={this.props.claims.canConfigureSpecificResource('Team', team.id)}>
                        <Row>
                            <Col lg="12">
                                <Button color="primary" onClick={() => this.editTeam(team.id)}>Edit Members</Button>
                            </Col>
                        </Row>
                    </Conditional>
                </td>
                <Conditional if={this.state.directOnly === false}>
                    <td>{this.renderMembers(team.id, team.managers)}</td>
                    <td>{this.renderMembers(team.id, team.deliveryManagers)}</td>
                    <td>{this.renderMembers(team.id, team.productManagers)}</td>
                </Conditional>
                <td>{this.renderMembers(team.id, team.productOwners)}</td>
                <td>{this.renderMembers(team.id, team.scrumMasters)}</td>
                <td>{this.renderMembers(team.id, team.techLeads)}</td>
                <td>{this.renderMembers(team.id, team.developers)}</td>
                <td>{this.renderMembers(team.id, team.testEngineers)}</td>
                <td>{this.renderMembers(team.id, team.devOps)}</td>
                <td>{this.renderMembers(team.id, team.sre)}</td>
            </tr>
        );
    }

    renderMembers(teamid, members) {

        if (members == null) {
            return;
        }

        if (!Array.isArray(members)) {
            members = [members];
        }

        return (
            <ListGroup>
                {members.map(m => this.renderMember(teamid, m))}
            </ListGroup>
        );
    }

    renderMember(teamid, member) {

        var office = this.state.offices.find(o => o.id === member.officeId);

        return (
            <ListGroupItem className="team-member" key={member.id}>
                <table>
                    <tbody>
                        <tr>
                            <td className="time-zone" style={{ backgroundColor: office.timeZoneColor || '#FF69B4' }} id={`timezone-${teamid}-${member.id}`} >
                                <SimpleTooltip placement="right" target={`timezone-${teamid}-${member.id}`}><TimeZoneToolTip timeZone={office.timeZone} /></SimpleTooltip>
                            </td>
                            <td className="member-info">{member.name}</td>
                        </tr>
                    </tbody>
                </table>
            </ListGroupItem>
        );
    }
}
