import React, { useEffect, useMemo, useState } from "react";
import Pagination from "../Pagination";
import common from "../common";

function ResultByQueryTable(props) {
  const objectiveData = props.objData;
  const selectionList = props.selectionList;
  const toggleSortOrder = props.toggleSortOrder;

  const [header, setHeader] = useState([]);
  //pagination
  const [dataPerPage, setDataPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const lastIndexofdata = currentPage * dataPerPage;
  const firstIndexofdata = lastIndexofdata - dataPerPage;
  const currentData = objectiveData.slice(firstIndexofdata, lastIndexofdata);
  const ItemPage = (number) => {
    setDataPerPage(number);
    setCurrentPage(1);
  };

  const pageNumber = useMemo(
    () =>
      Array.from(
        new Array(Math.ceil(objectiveData.length / dataPerPage)),
        (val, index) => index + 1
      ),
    [objectiveData]
  );

  //change page
  const paginate = (currentPage) => {
    if (currentPage >= 1 && currentPage <= pageNumber.length)
      setCurrentPage(currentPage);
  };

  useEffect(() => {
    setCurrentPage(1);
    if (objectiveData.length != 0) {
      Object.keys(objectiveData[0]).map((key) => setHeader((x) => [...x, key]));
    }

  }, []);

  return (
    <div>
      <div className="table-responsive"
        style={{ height: (90 * window.innerHeight / 100) + "px", maxHeight: (window.innerHeight) + "px", fontSize: "x-small" }}
      >
        {header.length > 0 && <table className="table table-striped" align="center">
          <thead className="stickyHeader">
            <tr>
              {header.map((data, index) => (
                <th key={index}>
                  {common.camelCase(data) + " "}
                  <i
                    className="fa fa-filter"
                    onClick={() => selectionList(data)}
                  />
                  {"  "}
                  <i
                    className="fa fa-sort"
                    onClick={() => toggleSortOrder(data)}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {objectiveData.length > 0 ? (
              currentData.map((data, index) => (
                <tr key={index}>
                  {header.map((key, index) => {
                    return <td key={index}>{data[key]}</td>
                  })}
                </tr>
              ))
            ) : (
              <tr>
                <th colSpan={header.length}>No Data to Display</th>
              </tr>
            )}
          </tbody>
        </table>}
      </div>
      <Pagination
        paginate={paginate}
        pageNumber={pageNumber}
        currentPage={currentPage}
        ItemPage={ItemPage}
        dataPerPage={dataPerPage}
      />
    </div>
  );
}

export default ResultByQueryTable;
