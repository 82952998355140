import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Style from "../../pages/Dashboard/ManualKPIEntryDashboard.module.css";
import manualKPIDashboardService from "../../services/manualKPIDashboardService";

function RiskIntelligenceMetrics(props) {
    const validateForm = props.validateForm;

    const [date, setDate] = useState();
    const [field, setField] = useState({
        date: undefined,
        itinerariesProcessed: undefined,
        intelligenceSources: undefined,
        intelligenceFeeds: undefined,
        eventsIdentified: undefined,
        travelersIngested: undefined,
        itinerariesUpdated: undefined,
        eoqValue: false,
        quarter: "",
    });
    const [formErrors, setFormErrors] = useState({
        date: undefined,
        itinerariesProcessed: undefined,
        intelligenceSources: undefined,
        intelligenceFeeds: undefined,
        eventsIdentified: undefined,
        travelersIngested: undefined,
        itinerariesUpdated: undefined,
    });
    useEffect(() => {
        handlequarter();
    }, [date]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm(formErrors, field)) {
            let ans = window.confirm("Are you sure you want to submit this data?");
            if (ans === true) {
                let res = await manualKPIDashboardService.postRiskMetricsForm(field);
                if (res.ok)
                    alert("Form Is Submitted Successfully");
                else
                    alert("Something Went Wrong")
            }
        } else {
            alert("Make Sure All Fields Are Filled");
        }
    };

    const clearAll = (event) => {
        event.preventDefault();
        setField({
            date: undefined,
            itinerariesProcessed: undefined,
            intelligenceSources: undefined,
            intelligenceFeeds: undefined,
            eventsIdentified: undefined,
            travelersIngested: undefined,
            itinerariesUpdated: undefined,
            eoqValue: false,
            quarter: "",
        });
        setDate();
    };

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let currentformErrors = formErrors;

        switch (name) {
            case "itinerariesProcessed":
                if (value && !Number.isInteger(parseFloat(value))) {
                    currentformErrors.itinerariesProcessed = "Value should be integers";
                } else if (value < 0) {
                    currentformErrors.itinerariesProcessed = "number should be positive";
                } else {
                    delete currentformErrors.itinerariesProcessed;

                    setField({ ...field, itinerariesProcessed: parseInt(value) });
                }
                break;
            case "intelligenceSources":
                if (value && !Number.isInteger(parseFloat(value))) {
                    currentformErrors.intelligenceSources = "Value should be integers";
                } else if (value < 0) {
                    currentformErrors.intelligenceSources = "number should be positive";
                } else {
                    delete currentformErrors.intelligenceSources;
                    setField({ ...field, intelligenceSources: parseInt(value) });
                }
                break;
            case "intelligenceFeeds":
                if (value && !Number.isInteger(parseFloat(value))) {
                    currentformErrors.intelligenceFeeds = "Value should be integers";
                } else if (value < 0) {
                    currentformErrors.intelligenceFeeds = "number should be positive";
                } else {
                    delete currentformErrors.intelligenceFeeds;
                    setField({ ...field, intelligenceFeeds: parseInt(value) });
                }
                break;
            case "eventsIdentified":
                if (value && !Number.isInteger(parseFloat(value))) {
                    currentformErrors.eventsIdentified = "Value should be integers";
                } else if (value < 0) {
                    currentformErrors.eventsIdentified = "number should be positive";
                } else {
                    delete currentformErrors.eventsIdentified;
                    setField({ ...field, eventsIdentified: parseInt(value) });
                }
                break;
            case "itinerariesUpdated":
                if (value && !Number.isInteger(parseFloat(value))) {
                    currentformErrors.itinerariesUpdated = "Value should be integers";
                } else if (value < 0) {
                    currentformErrors.itinerariesUpdated = "number should be positive";
                } else {
                    delete currentformErrors.itinerariesUpdated;
                    setField({ ...field, itinerariesUpdated: parseInt(value) });
                }
                break;
            case "travelersIngested":
                if (value && !Number.isInteger(parseFloat(value))) {
                    currentformErrors.travelersIngested = "Value should be integers";
                } else if (value < 0) {
                    currentformErrors.travelersIngested = "number should be positive";
                } else {
                    delete currentformErrors.travelersIngested;
                    setField({ ...field, travelersIngested: parseInt(value) });
                }
                break;
            case "eoqValue":
                setField({ ...field, eoqValue: JSON.parse(value) });
                break;
        }

        setFormErrors({ ...formErrors, currentformErrors });
    };

    const handlequarter = () => {
        let selectedMonth = new Date(date).getMonth();
        let quatar;
        if (selectedMonth >= 0 && selectedMonth <= 2) {
            quatar = "Q1";
        } else if (selectedMonth >= 3 && selectedMonth <= 5) {
            quatar = "Q2";
        } else if (selectedMonth >= 6 && selectedMonth <= 8) {
            quatar = "Q3";
        } else if (selectedMonth >= 9 && selectedMonth <= 11) {
            quatar = "Q4";
        }
        quatar = quatar + new Date(date).getFullYear();
        setField({ ...field, quarter: quatar });
    };

    return (
        <div>
            <form onSubmit={handleSubmit} noValidate>
                <table className="table table-striped" aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th colSpan="2">Risk Intelligence Metrics</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Date</td>
                            <td>
                                <DatePicker
                                    placeholderText="MM/DD/YYYY"
                                    selected={date}
                                    name="date"
                                    onChange={(d) => {
                                        setDate(d);
                                        setField({ ...field, date: new Date(d) });
                                    }}
                                    autoComplete="off"
                                    filterDate={date => date.getDay() === 1}
                                />
                                <div>
                                    {formErrors.date != undefined && (
                                        <div className={Style.error}>{formErrors.date}</div>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Quarter</td>
                            <td>
                                <input
                                    type="text"
                                    name="quatar"
                                    disabled={true}
                                    value={field.quarter || ""}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Itineraries Processed</td>
                            <td>
                                <input
                                    type="number"
                                    name="itinerariesProcessed"
                                    onChange={handleChange}
                                    value={field.itinerariesProcessed || ""}
                                />
                                <div>
                                    {formErrors.itinerariesProcessed != undefined && (
                                        <div className={Style.error}>
                                            {formErrors.itinerariesProcessed}
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Intelligence Sources</td>
                            <td>
                                <input
                                    type="number"
                                    name="intelligenceSources"
                                    onChange={handleChange}
                                    value={field.intelligenceSources || ""}
                                />
                                <div>
                                    {formErrors.intelligenceSources != undefined && (
                                        <div className={Style.error}>
                                            {formErrors.intelligenceSources}
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Intelligence Feeds</td>
                            <td>
                                <input
                                    type="number"
                                    name="intelligenceFeeds"
                                    onChange={handleChange}
                                    value={field.intelligenceFeeds || ""}
                                />
                                <div>
                                    {formErrors.intelligenceFeeds != undefined && (
                                        <div className={Style.error}>
                                            {formErrors.intelligenceFeeds}
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Events Identified</td>
                            <td>
                                <input
                                    type="number"
                                    name="eventsIdentified"
                                    onChange={handleChange}
                                    value={field.eventsIdentified || ""}
                                />
                                <div>
                                    {formErrors.eventsIdentified != undefined && (
                                        <div className={Style.error}>
                                            {formErrors.eventsIdentified}
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Itineraries Updated</td>
                            <td>
                                <input
                                    type="number"
                                    name="itinerariesUpdated"
                                    onChange={handleChange}
                                    value={field.itinerariesUpdated || ""}
                                />
                                <div>
                                    {formErrors.itinerariesUpdated != undefined && (
                                        <div className={Style.error}>
                                            {formErrors.itinerariesUpdated}
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Travelers Ingested</td>
                            <td>
                                <input
                                    type="number"
                                    name="travelersIngested"
                                    onChange={handleChange}
                                    value={field.travelersIngested || ""}
                                />
                                <div>
                                    {formErrors.travelersIngested != undefined && (
                                        <div className={Style.error}>
                                            {formErrors.travelersIngested}
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                EOQ Value
                                <div>
                                    <i>
                                        {" "}
                                        <small>
                                            Select “Yes” for recording the final total quarterly
                                            metrics. Select “No” if not recording total quarterly
                                            metrics.
                                        </small>
                                    </i>
                                </div>
                            </td>
                            <td>
                                <select
                                    name="eoqValue"
                                    id="eoqValue"
                                    onChange={handleChange}
                                    value={field.eoqValue}
                                >
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type="submit" className="btn btn-info btn-primary" >
                                    Submit
                                </button>
                            </td>
                            <td>
                                <button type="reset" className="btn btn-secondary" onClick={clearAll} >
                                    Clear
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}

export default RiskIntelligenceMetrics;
