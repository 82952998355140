import React, { useEffect, useState } from "react";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import Select from "react-select";
import { Loadable } from "../../components/Loadable";
import common from "../../components/common";
import objectiveHealthService from "../../services/objectiveHealthDashboardService";
import UserSelectionDropDown from "../../components/ObjectiveDashBoard/UserSelectionDropDown";
import ResultByQueryTable from "../../components/SortableGridTable/ResultByQueryTable";
import ExportExcel from "../../components/ExportExcel";
import moment from "moment";

const Ascending = "ascending";
const Descending = "descending";
const Option = (props) => {
  return (
    <div>
      {" "}
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        {"  "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const animatedComponents = makeAnimated();
const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

function ObjectiveHealthDashboard() {
  const [podOptions, setPodOptions] = useState([]);
  const [selectedPod, setSelectedPod] = useState([]);
  const [objectiveNameOptions, setObjectiveNameOptions] = useState([]);
  const [selectedObjectiveName, setSelectedObjectiveName] = useState([]);
  const [releaseNameOptions, setReleaseNameOptions] = useState([]);
  const [selectedreleaseName, setSelectedReleaseName] = useState([]);
  const [podLoading, setPodLoading] = useState(false);
  const [objectiveNameLoading, setObjectiveNameLoading] = useState(false);
  const [releaseNameLoading, setReleaseNameLoading] = useState(false);
  const [loadedFilter, setloadedFilter] = useState(false);
  const [queryResult, setQueryResult] = useState([]);
  const [filteredQueryResult, setFilteredQueryResult] = useState([]);
  const [show, setShow] = useState(false);
  const [sortOrder, setSortOrder] = useState(Ascending);
  const [filterName, setFilterName] = useState();
  const [distinctAttributeValues, setDistinctAttributeValues] =
    useState(queryResult);

  useEffect(() => {
    loadFilters();
  }, []);

  useEffect(() => {
    setFilteredQueryResult(queryResult);
  }, [queryResult]);

  const selectionList = (attribute) => {
    setFilterName(attribute);
    let distinctValue = common.getDistinctValues(
      queryResult,
      attribute,
    );
    setDistinctAttributeValues(distinctValue);
    setShow(true);
  };

  const toggleSortOrder = (property) => {
    if (sortOrder == Ascending) setSortOrder(Descending);
    if (sortOrder == Descending) setSortOrder(Ascending);
    sortData(property, sortOrder);
  };

  const sortData = (property) => {
    let sort_order;
    if (sortOrder == Ascending) sort_order = 1;
    if (sortOrder == Descending) sort_order = -1;
    let sortData = [...filteredQueryResult].sort(
      common.dynamicSort(property, sort_order),
    );
    setFilteredQueryResult(sortData);
  };

  const loadFilters = async () => {
    populateFilterData();
    loadPodOptions();
    loadObjectiveOptions();
    loadReleaseOptions();
  };

  const loadPodOptions = async () => {
    setPodLoading(true);
    const podOptionsData = await objectiveHealthService.GetPods();
    if (podOptionsData !== null) {
      const podOptions = podOptionsData.map(function (obj) {
        return { value: obj.id, label: obj.podName };
      });
      setPodOptions(podOptions);
    }
    setPodLoading(false);
  };

  const loadObjectiveOptions = async () => {
    setObjectiveNameLoading(true);
    const podObjectiveOptions = await objectiveHealthService.GetObjectives();
    if (podObjectiveOptions !== null) {
      const objData = podObjectiveOptions.map(function (obj) {
        return { value: obj.id, label: obj.goalName };
      });
      setObjectiveNameOptions(objData);
    }
    setObjectiveNameLoading(false);
  };

  const loadReleaseOptions = async () => {
    setReleaseNameLoading(true);
    const releaseOptions = await objectiveHealthService.GetRelease();
    if (releaseOptions !== null) {
      const objData = releaseOptions.map(function (obj) {
        return { value: obj.id, label: obj.name };
      });
      setReleaseNameOptions(objData);
    }
    setReleaseNameLoading(false);
  };

  const handlePodChange = (selected) => {
    setSelectedPod(selected);
    populateFilterData(
      selected,
      selectedObjectiveName,
      selectedreleaseName
    );
  };

  const handleObjectiveChange = (selected) => {
    setSelectedObjectiveName(selected);
    populateFilterData(
      selectedPod,
      selected,
      selectedreleaseName
    );
  };

  const handleReleaseChange = (selected) => {
    setSelectedReleaseName(selected);
    populateFilterData(
      selectedPod,
      selectedObjectiveName,
      selected
    );
  };

  const populateFilterData = async (
    podSelected = undefined,
    objectiveNameSelected = undefined,
    releaseNameSelected = undefined,
  ) => {
    setloadedFilter(false);
    let params = new URLSearchParams({});
    if (podSelected !== undefined) {
      podSelected.map(function (obj) {
        params.append("pods", obj.label);
      });
    }

    if (objectiveNameSelected !== undefined) {
      objectiveNameSelected.map(function (obj) {
        params.append("goals", obj.label);
      });
    }

    if (releaseNameSelected !== undefined) {
      releaseNameSelected.map(function (obj) {
        params.append("releases", obj.label);
      });
    }

    let data = await objectiveHealthService.GetObjectiveHealthReport(params);
    if (data !== null) {
      setQueryResult(data)
    }
    setloadedFilter(true);
  };
  return (
    <div>
      <div className="row">
        <div className="col-10">
          <div className="row">
            <div className="col-lg-4">
              <label>
                <b>POD</b>
              </label>
              <Select
                value={selectedPod}
                isMulti
                options={podOptions}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(option) => handlePodChange(option)}
                isClearable={true}
                isLoading={podLoading}
                allowSelectAll={true}
                menuPortalTarget={document.body}
              />
            </div>
            <div className="col-lg-4">
              <label>
                <b>Objective</b>
              </label>
              <Select
                value={selectedObjectiveName}
                isMulti
                options={objectiveNameOptions}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(option) => handleObjectiveChange(option)}
                isClearable={true}
                isLoading={objectiveNameLoading}
                allowSelectAll={true}
                menuPortalTarget={document.body}
              />
            </div>
            <div className="col-lg-4">
              <label>
                <b>Release</b>
              </label>
              <Select
                value={selectedreleaseName}
                isMulti
                options={releaseNameOptions}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(option) => handleReleaseChange(option)}
                isClearable={true}
                isLoading={releaseNameLoading}
                allowSelectAll={true}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
        </div>
        <div className="col-2">
          <label>
            <b><br /></b>
          </label>
          <div style={{ textAlign: "end", verticalAlign: "bottom" }}>
            <ExportExcel
              fileName={
                `ObjectiveHealthReport_` +
                moment(new Date()).format("MM-DD-YYYY-hh-mm-A")
              }
              buttonName="Export Data"
              sheetName={`ObjectiveHealthReport`}
              sheetData={filteredQueryResult}
            />
          </div>
        </div>
      </div>
      <br />
      <div>
        <Loadable loaded={loadedFilter}>
          {filteredQueryResult.length > 0 ? (
            <ResultByQueryTable
              objData={filteredQueryResult}
              selectionList={selectionList}
              toggleSortOrder={toggleSortOrder}
            />
          ) : (
            <div>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>No Data to Display</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Loadable>
        {show && (
          <UserSelectionDropDown
            show={show}
            setshow={setShow}
            attributeArray={distinctAttributeValues}
            originalData={queryResult}
            filterName={filterName}
            filterFunction={setFilteredQueryResult}
          />
        )}
      </div>
    </div>
  );
}

export default ObjectiveHealthDashboard;