import * as moment from 'moment';

function getValue(event) {
    const target = event.target;
    var value = '';

    if (target.type === 'checkbox') {
        value = target.checked;
    }
    else if (target.type === "number") {
        value = parseFloat(target.value);
    }
    else if (target.type === "date") {

        if (!target.value)
            value = null;
        else
            value = target.value;
    }
    else {
        value = target.value;
    }

    return value;
}

function createInputHandler(control, key) {
    var handler = (event) => {
        var model = { ...control.state[key] };
        model[event.target.name] = getValue(event);
        control.setState({ [key]: model });
    };

    return handler;
}

function dateFormat(date) {
    if (!date)
        return "";
    return moment(date).format("YYYY-MM-DD");
}

function createSearchDropDownHandelChange(control, modelKey, selectedKey, setKey, getKey = 'id') {
    return (selected) => {
        var model = { ...control.state[modelKey] };

        model[setKey] = selected ? selected[getKey] : null;
        control.setState({ [modelKey]: model, [selectedKey]: selected, selected });
    };
}

const camelCase = (data) => {
    data = data.replace(/([a-z])([A-Z])/g, "$1 $2");
    data = data.charAt(0).toUpperCase() + data.slice(1);
    return data;
  };

  const getDistinctValues =(dataArray,field)=>{
    let distinctField = [...new Set(dataArray.map((data) => data[field]))];
    distinctField =distinctField.filter(data=> data!=null);
    return distinctField;
  }

  //compare function for sorting 
  const dynamicSort= (property,sortOrder)=>{
    return function (a,b){
      if(a[property] < b[property]){
        return -1 * sortOrder;
      }else if(a[property] > b[property]){
            return 1 * sortOrder;
      }else{
            return 0 * sortOrder;
      }   
   }
  }
 
export default {
    getValue,
    createInputHandler,
    createSearchDropDownHandelChange,
    dateFormat,
    camelCase,
    getDistinctValues,
    dynamicSort
};