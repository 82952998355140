import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import AssignmentGroupForm from "../../components/ServiceNow/AssignmentGroupForm";
import IncidentStateMappingForm from "../../components/ServiceNow/IncidentStateMappingForm";
import UserForm from "../../components/ServiceNow/UserForm";


const AssignmentGroupTab = "1";
const IncidentStateMappingTab = "2";
const UserTab = "3";
function ManualServiceNowEntryDashboard(props) {
    const [activeTab, setTab] = useState(AssignmentGroupTab);

    const validateForm = (errors, field) => {
        let valid = true;
        Object.values(errors).forEach(
            (val) => val != undefined && val.length > 0 && (valid = false)
        );
        Object.values(field).forEach((val) => (val === undefined || val.length <= 0) && (valid = false));
        return valid;
    };


    return (
        <div>
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === AssignmentGroupTab })}
                            onClick={() => {
                                setTab(AssignmentGroupTab);
                            }}
                        >
                            Assignment Group
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === IncidentStateMappingTab })}
                            onClick={() => {
                                setTab(IncidentStateMappingTab);
                            }}
                        >
                            Incident State Mapping
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === UserTab })}
                            onClick={() => {
                                setTab(UserTab);
                            }}
                        >
                            User
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
            <div>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={AssignmentGroupTab}>
                        &nbsp;
                        <div>
                            <AssignmentGroupForm />
                        </div>
                    </TabPane>

                    <TabPane tabId={IncidentStateMappingTab}>
                        &nbsp;
                        <div>
                            <IncidentStateMappingForm />
                        </div>
                    </TabPane>
                    <TabPane tabId={UserTab}>
                        &nbsp;
                        <div>
                            <UserForm />
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </div>
    );
}

export default ManualServiceNowEntryDashboard;