export const get = async (teamId) => {
    const response = await fetch(`metric/team/${teamId}`);
    return await response.json();
};

export const getMetricLevel = async (metricType, metricUri, parentUri, weeksBack) => {
    const response = await fetch(`metric/${metricType}${metricUri}?parentUri=${parentUri}&weeks=${weeksBack}`);
    return await response.json();
};

export default
{
    get,
    getMetricLevel
}