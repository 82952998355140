import React, { useEffect, useState } from "react";
import manualServiceNowEntryService from "../../services/manualServiceNowEntryService";
import { Modal, Button } from "react-bootstrap";

function AssignmentGroupForm(props) {
 
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showWarningAlert, setShowWarningAlert] = useState(false);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmedSubmit, setConfirmedSubmit] = useState(false);


    const [field, setField] = useState({
        groupID: undefined,
        groupName: undefined,
    });


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!Object.values(field).some((value) => value === undefined || value === '')){
            setShowConfirmationModal(true);
        } else {
            setShowWarningAlert(true);
            setTimeout(() => { 
                setShowWarningAlert(false);
            }, 3000);
        }

    };

    const handleModalConfirm = async () => {
        setShowConfirmationModal(false);
        setConfirmedSubmit(true);
        let res = await manualServiceNowEntryService.postAssignmentGroupForm(field);
        if (res.ok) {
            setShowSuccessAlert(true);
            setTimeout(() => {
                setShowSuccessAlert(false);
            }, 3000);
        } else {
            setShowErrorAlert(true);
            setTimeout(() => {
                setShowErrorAlert(false);
            }, 3000);
        }
    };

    const handleModalCancel = () => {
        setShowConfirmationModal(false);
    };

    const clearAll = (event) => {
        event.preventDefault();
        setField({
            groupID: undefined,
            groupName: undefined,
        });
    };

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
      

        switch (name) {
            case "groupID":

                setField({ ...field, groupID: value });

                break;
            case "groupName":

                setField({ ...field, groupName: value });

                break;
        }
    };

    return (
        <div>

            {/* Confirmation modal */}
            <Modal show={showConfirmationModal} onHide={handleModalCancel} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to submit this data?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleModalConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Success alert */}
            {showSuccessAlert && (
                <div className="alert alert-success" role="alert">
                    Form Is Submitted Successfully
                </div>
            )}

            {/* Error alert */}
            {showErrorAlert && (
                <div className="alert alert-danger" role="alert">
                    Something Went Wrong
                </div>
            )}

            {/* Warning alert */}
            {showWarningAlert && (
                <div className="alert alert-warning" role="alert">
                    Please fill all the fields
                </div>
            )}

            <form onSubmit={handleSubmit} noValidate>
                <table className="table table-striped" aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th colSpan="2">Assignment Group</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Group ID</td>
                            <td>
                                <input
                                    type="text"
                                    name="groupID"
                                    value={field.groupID || ""}
                                    onChange={handleChange}
                                />
                               
                            </td>
                        </tr>
                        <tr>
                            <td>Group Name</td>
                            <td>
                                <input
                                    type="text"
                                    name="groupName"
                                    value={field.groupName || ""}
                                    onChange={handleChange}
                                />
                                
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <button type="submit" className="btn btn-info btn-primary" >
                                    Submit
                                </button>
                            </td>
                            <td>
                                <button type="reset" className="btn btn-secondary" onClick={clearAll} >
                                    Clear
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}
export default AssignmentGroupForm;