import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import vendorService from '../../services/vendorService';
import { Loadable } from '../../components/Loadable';
import { ButtonGroup, Button, Input } from 'reactstrap';
import * as moment from 'moment';

class Invoice extends Component {
    constructor(props) {
        super(props);

        var invoice = props.invoice || { value: 0, hours: 0 };

        this.state = {
            editing: this.props.editing,
            invoice: {...invoice}
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        var value = '';

        if (target.type === 'checkbox') {
            value = target.checked;
        }
        else if (target.type === "number") {
            value = parseInt(target.value, 10);
        }
        else if (target.type === "date") {

            if (!target.value)
                value = null;
            else
                value = target.value;
        }
        else {
            value = target.value;
        }

        const name = target.name;

        var invoice = { ...this.state.invoice }
        invoice[name] = value;
        this.setState({ invoice });
    }

    format(date) {
        if (!date)
            return "";
        var iso = moment(date).format("YYYY-MM-DD");
        return iso;
    }

    save = async () => {
        var invoice = this.state.invoice;

        await fetch(`vendor/${this.props.vendorId}/invoices`, {
            method: invoice.id ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.invoice)
        })
        if (!invoice.id)
            this.setState({ editing: true, invoice: { value: 0, hours: 0 } });
        else
            this.setState({ editing: false });

        this.props.reload();
    }

    edit = () => {
        this.setState({ editing: true })
    }

    close = () => {
        this.setState({
            invoice: { ...this.props.invoice }
        });
        this.setState({ editing: false })
    }

    delete = () => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this invoice?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        this.setState({ loading: true });
                        await fetch(`vendor/${this.props.vendorId}/invoices`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(this.state.invoice)
                        })
                        this.setState({ loading: false });
                        this.props.reload();
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    render() {
        if (this.state.editing)
            return <tr>
                <td><Input type="date" name="peroidStart" value={this.format(this.state.invoice.peroidStart)} onChange={this.handleInputChange} /></td>
                <td><Input type="date" name="peroidEnd" value={this.format(this.state.invoice.peroidEnd)} onChange={this.handleInputChange} /></td>
                <td><Input type="number" name="value" placeholder="$" value={this.state.invoice.value} onChange={this.handleInputChange} /></td>
                <td><Input type="number" name="hours" placeholder="Hours" value={this.state.invoice.hours} onChange={this.handleInputChange} /></td>
                <td>
                    <ButtonGroup>
                        <Button outline color="primary" onClick={this.save}>{this.state.invoice.id ? "Save" : "Add"}</Button>
                        {this.state.invoice.id && <Button outline color="danger" onClick={this.close} >Cancel</Button>}
                    </ButtonGroup>
                </td>
            </tr>;
        return <tr>
            <td>{moment(this.state.invoice.peroidStart).format('L')}</td>
            <td>{moment(this.state.invoice.peroidEnd).format('L')}</td>
            <td>{this.state.invoice.value}</td>
            <td>{this.state.invoice.hours}</td>
            <td>
                <ButtonGroup>
                    <Button outline color="primary" onClick={this.edit} >Edit</Button>
                    {this.state.invoice.id && <Button outline color="danger" onClick={this.delete}>Delete</Button>}
                </ButtonGroup>
            </td>
        </tr>
    }
}

class VendorInvoices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoices: [],
            loading: false
        };
    }

    async loadData() {
        var [invoices] = await Promise.all([
            vendorService.getInvoices(this.props.vendor.id)
        ]);

        this.setState({
            invoices: invoices,
            loading: false
        });
    }

    reloadData() {
        this.setState({ loading: true });
        this.loadData();
    }

    componentDidMount() {
        this.reloadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.vendor.id !== this.props.vendor.id) {
            this.reloadData();
        }
    }


    render() {
        var vendor = this.props.vendor;
        return <section>
            <h3>{vendor.name}s Invoices</h3>
            <table>
                <thead>
                    <tr>
                        <th>Start</th>
                        <th>End</th>
                        <th>Total $</th>
                        <th>Total Hours</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.invoices.map(i => <Invoice invoice={i} key={i.id} reload={this.reloadData.bind(this)} vendorId={vendor.id} />)}
                    <Invoice editing="true" reload={this.reloadData.bind(this)} vendorId={vendor.id} />
                </tbody>
            </table>
        </section>;
    }
}

export class VendorsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            vendors: [],
            vendor: {}
        };
        this.selectVendor = this.selectVendor.bind(this);
    }

    async loadData() {
        var [vendors] = await Promise.all([
            vendorService.getAll()
        ]);

        this.setState({
            vendors: vendors,
            loading: false
        });
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.loadData();
    }

    selectVendor(v) {
        this.setState({ vendor: v });
    }

    render() {
        return <section className="vendors">
            <h1>Vendors</h1>
            <Loadable loaded={!this.state.loading}>
                <div className="row">
                    <div className="col-4">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Scored</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.vendors.map(v => <tr key={v.id}><td>{v.name}</td><td>{v.isScored ? "Yes" : ""}</td><td><Button onClick={() => this.selectVendor(v)} outline color="primary">Invoices</Button></td></tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div className="col">
                        {this.state.vendor.id ? <VendorInvoices vendor={this.state.vendor} /> : "No vendor selected"}
                    </div>
                </div>
            </Loadable>
        </section>;
    }
}