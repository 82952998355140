import React, { forwardRef, useEffect, useState } from "react";
import ChangeLogTable from "../../components/AhaJiraChangeLogDashboard/ChangeLogTable";
import OverviewCard from "../../components/AhaJiraChangeLogDashboard/OverviewCard";
import { Loadable } from "../../components/Loadable";
import * as moment from "moment";
import changeLogService from "../../services/changeLogService";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import Style from "./AhaJiraChangeLog.module.css";
import {
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledButtonDropdown,
  Button,
  Row,
  Col,
} from "reactstrap";
import ExportExcel from "../../components/ExportExcel";

function AhaJiraChangeLogDashboard() {
  const dataSource = ["Aha", "Jira"];
  const ahaChangeType = [
    "Release Feature Changes",
    "Release MasterFeature Changes",
    "Goal Feature Changes",
    "Goal MasterFeature Changes",
    "Feature Changes",
    "MasterFeature Changes",
  ];
  const jiraChangeType = [
    "Epics Changes",
    "Story Changes",
    "Bug Changes",
    "Task Changes",
    "Sprint Changes"
  ];
  const preSavedQueries = [
    "Release: Features Added (30/60/90 day timeframe)",
    "Release: Features Removed (30/60/90 day timeframe)",
    "Scope Change: Current Release",
  ];
  const dateFormet = "YYYY-MM-DD";
  const disable=true;
  const [changeLogData, setChangeLogData] = useState([]);
  const [load, setLoad] = useState(false);
  const [jiraRefreshDate, setJiraRefreshDate] = useState("");
  const [ahaRefreshDate, setAhaRefreshDate] = useState("");
  const [dailyCount, setDailyCount] = useState({});
  const [selectedDataSource, setSelectedDataSource] = useState("");
  const [selectedChangeType, setSelectedChangeType] = useState("");
  const [filteredchangeLogData, setFilteredChangeLogData] = useState([]);
  const [loadableForTable, setloadableForTable] = useState(true);
  const [show, setshow] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedQuery, setSelectedQuery] = useState("");

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    FilterData();
    overviewData();
  }, [selectedChangeType, selectedDataSource, endDate]);

  useEffect(() => {
    preSavedQueriesLoadData();
  }, [selectedQuery]);

  const loadData = async () => {
    let jiraResponseDate = await fetch(`ProductDashboard/GetDataUpdateDate`);
    let jiradateString = await jiraResponseDate.text();
    let jiradate = new Date(jiradateString + " UTC");
    setJiraRefreshDate(jiradate.toLocaleString());

    let ahaResponseDate = await fetch(`ProductDashboard/GetAhaDataUpdateDate`);
    let ahaDateString = await ahaResponseDate.text();
    let ahadate = new Date(ahaDateString + " UTC");
    setAhaRefreshDate(ahadate.toLocaleString());

    let changeLogdata = await changeLogService.GetChangeLog();
    setChangeLogData(changeLogdata);
    setFilteredChangeLogData(changeLogdata);
    let dailyCount = await changeLogService.GetDailyCount();
    setDailyCount(dailyCount);
    setLoad(true);
  };

  const overviewData= async ()=>{
    if(selectedDataSource||selectedChangeType||endDate){
    let startdate=''; 
    let enddate='';
    if(startDate && endDate){
      startdate = moment(startDate).format(dateFormet);
      enddate = moment(endDate).format(dateFormet);
    }
    let data = await changeLogService.GetDetailOverview(
      selectedDataSource,
      selectedChangeType,startdate,enddate);
    setDailyCount(data);
    }else{
      let dailyCount = await changeLogService.GetDailyCount();
      setDailyCount(dailyCount);
    }

  }
  const FilterData = async () => {
    setloadableForTable(false);
    let data;
    if (selectedDataSource && selectedChangeType){   
      if(startDate && endDate){
        data = await changeLogService.GetDateChangeLog(
          selectedDataSource,
          selectedChangeType,
          moment(startDate).format(dateFormet),
          moment(endDate).format(dateFormet)
        );
        setFilteredChangeLogData(data);
      }else{
        data = await changeLogService.GetDailyChangeLog(
          selectedDataSource,
          selectedChangeType
        );
        setFilteredChangeLogData(data);
      }
    }else if(startDate && endDate){
      data = await changeLogService.GetDateChangesLog(
        moment(startDate).format(dateFormet),
        moment(endDate).format(dateFormet));
      if(selectedDataSource){
        data = data.filter(x=> x.keySource==selectedDataSource);     
      }
       setFilteredChangeLogData(data);
      }else if(selectedDataSource){
        data = changeLogData.filter((x) => x.keySource == selectedDataSource );
        setFilteredChangeLogData(data);
      }else{
        setFilteredChangeLogData(changeLogData);
      }  
    setloadableForTable(true);
  };

  const preSavedQueriesLoadData = async () => {
    if (selectedQuery) {
      let preSavedQueriesData = await changeLogService.GetPreSavedQuery(
        selectedQuery
      );
      setFilteredChangeLogData(preSavedQueriesData);
    }
  };

  const reset = (data) => {
    setSelectedDataSource(data);
    setSelectedChangeType("");
  };
  const DataSourceDropdownItems = (data, index) => (
    <DropdownItem key={index} onClick={() => reset(data)}>
      {data}
    </DropdownItem>
  );

  const ChangeTypeDropdownItems = (data, index) => (
    <DropdownItem key={index} onClick={() => setSelectedChangeType(data)}>
      {data}
    </DropdownItem>
  );

  const preSavedQueryDropdownItems = (data, index) => (
    <DropdownItem key={index} onClick={() => setSelectedQuery(data)}>
      {data}
    </DropdownItem>
  );

  const PopUp = () => {
    return (
      <Modal show={show} onHide={() => setshow(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>Select Filter Before Exporting Data</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshow(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DateButton = forwardRef(({ value, onClick, buttonName }, ref) => (
    <button className="btn btn-info btn-sm" onClick={onClick} ref={ref}>
      {value == "" ? buttonName : value}
    </button>
  ));

  const resetDateFilter = () => {
    setStartDate("");
    setEndDate("");
  };

  const cancelFilter = () => {
    setSelectedDataSource("");
    setSelectedChangeType("");
  };

  const resetQueryData = () => {
    setSelectedQuery("");
    FilterData();
  };
  return (
    <Loadable loaded={load}>
      <div>
        {show && PopUp()}
        <Row className={Style.reset}>
          <Col sm="2" className={Style.position}>
            <Row>
              <UncontrolledButtonDropdown>
                <DropdownToggle caret size="sm" disabled={selectedQuery?true:false}>
                  Select Data Source
                  <i
                    className="fa fa-angle-down"
                    aria-hidden="true"
                    style={{ padding: "0.3rem" }}
                  />
                </DropdownToggle>
                <DropdownMenu>
                  {dataSource.map(DataSourceDropdownItems)}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </Row>
            <Row>
              {selectedDataSource && (
                <Button
                  size="sm"
                  outline
                  color="primary"
                  onClick={() => cancelFilter()}
                  className={Style.buttonStyle}
                >
                  {selectedDataSource}
                  <i
                    className="fa fa-times-circle"
                    style={{ padding: "0.3rem" }}
                  />
                </Button>
              )}
            </Row>
          </Col>
          {selectedDataSource && (
            <Col sm="2" className={Style.position}>
              <Row>
                <UncontrolledButtonDropdown>
                  <DropdownToggle
                    caret
                    size="sm"
                    disabled={selectedQuery?true:false}
                  >
                    Select Change Type
                    <i
                      className="fa fa-angle-down"
                      aria-hidden="true"
                      style={{ padding: "0.3rem" }}
                    />
                  </DropdownToggle>

                  {selectedDataSource == "Aha" && (
                    <DropdownMenu>
                      {ahaChangeType.map(ChangeTypeDropdownItems)}
                    </DropdownMenu>
                  )}

                  {selectedDataSource == "Jira" && (
                    <DropdownMenu>
                      {jiraChangeType.map(ChangeTypeDropdownItems)}
                    </DropdownMenu>
                  )}
                </UncontrolledButtonDropdown>
              </Row>
              <Row style={{ margin: "0px" }}>
                {selectedChangeType && (
                  <Button
                    size="sm"
                    outline
                    color="primary"
                    onClick={() => setSelectedChangeType("")}
                    className={Style.buttonStyle}
                  >
                    {selectedChangeType}
                    <i
                      className="fa fa-times-circle"
                      style={{ padding: "0.3rem" }}
                    />
                  </Button>
                )}
              </Row>
            </Col>
          )}
          <Col sm="2" className={Style.positionSm}>
            <Row>
              <UncontrolledButtonDropdown>
                <DropdownToggle
                  caret
                  size="sm"
                  disabled={(selectedChangeType || selectedDataSource)?true:false}
                >
                  Select Pre-saved Queries
                  <i
                    className="fa fa-angle-down"
                    aria-hidden="true"
                    style={{ padding: "0.3rem" }}
                  />
                </DropdownToggle>
                <DropdownMenu>
                  {preSavedQueries.map(preSavedQueryDropdownItems)}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </Row>
            <Row>
              {selectedQuery && (
                <Button
                  size="sm"
                  outline
                  color="primary"
                  onClick={() => resetQueryData()}
                  className={Style.buttonStyle}
                >
                  {selectedQuery}
                  <i
                    className="fa fa-times-circle"
                    style={{ padding: "0.3rem" }}
                  />
                </Button>
              )}
            </Row>
          </Col>

          <Col align="right">
            <DatePicker
              selected={startDate}
              closeOnScroll={true}
              minDate={new Date("05-13-2021")}
              maxDate={new Date()}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={(date) => setStartDate(date)}
              customInput={<DateButton buttonName="Start Date" />}
            />
          </Col>
          <Col sm="2.5" align="right" style={{ paddingRight: "15px" }}>
            <DatePicker
              selected={endDate}
              disabled={startDate == "" ? true : false}
              closeOnScroll={true}
              minDate={startDate}
              maxDate={new Date()}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={<DateButton buttonName="End Date" />}
            />
          </Col>
          <Col sm="2.5" align="right" className={Style.space}>
            <button
              className="btn btn-light btn-sm"
              onClick={() => resetDateFilter()}
            >
              Reset
            </button>
          </Col>

          <Col sm="2.5" align="right" className={Style.space}>
            {selectedDataSource == "" && selectedChangeType == "" ? (
              <button
                className="btn btn-primary btn-sm"
                style={{ marginBottom: "10px" }}
                onClick={() => setshow(true)}
              >
                {"Export Log"}{" "}
                <i className="fa fa-download" aria-hidden="true"></i>
              </button>
            ) : (
              <ExportExcel
                sheetData={filteredchangeLogData}
                fileName={
                  "ChangeLog-" +new Date(ahaRefreshDate).toLocaleString()
                }
                buttonName="Export Log"
                sheetName="ChangeLog"
              />
            )}{" "}
          </Col>
          <Col align="right">
            <p className={Style.pstyle}>Last Aha Refresh:{ahaRefreshDate}</p>
            <p className={Style.pstyle2}>Last Jira Refresh:{jiraRefreshDate}</p>
          </Col>
        </Row>
      </div>
      <br />
      <div className={Style.overviewCard}>
        <OverviewCard
          title={"Today Total"}
          countAdd={dailyCount.totalAdd}
          countUpdate={dailyCount.totalUpdate}
          countDelete={dailyCount.totalRemoved}
        />
        <OverviewCard
          title={"Aha Release Change"}
          countAdd={dailyCount.ahaReleaseChangeAdd}
          countUpdate={dailyCount.ahaReleaseChangeUpdate}
          countDelete={dailyCount.ahaReleaseChangeRemove}
        />
        <OverviewCard
          title={"Aha Master Feature Change"}
          countAdd={dailyCount.ahaMasterFeatureChangeAdd}
          countUpdate={dailyCount.ahaMasterFeatureChangeUpdate}
          countDelete={dailyCount.ahaMasterFeatureChangeRemove}
        />
        <OverviewCard
          title={"Aha Feature Change"}
          countAdd={dailyCount.ahaFeatureChangeAdd}
          countUpdate={dailyCount.ahaFeatureChangeUpdate}
          countDelete={dailyCount.ahaFeatureChangeRemove}
        />
        <OverviewCard
          title={"Aha Goal Change"}
          countAdd={dailyCount.ahaGoalChangeAdd}
          countUpdate={dailyCount.ahaGoalChangeUpdate}
          countDelete={dailyCount.ahaGoalChangeRemove}
        />

        <OverviewCard
          title={"Jira Task Change"}
          countAdd={dailyCount.jiraTaskChangeAdd}
          countUpdate={dailyCount.jiraTaskChangeUpdate}
          countDelete={dailyCount.jiraTaskChangeRemove}
        />
        <OverviewCard
          title={"Jira Bug Change"}
          countAdd={dailyCount.jiraBugChangeAdd}
          countUpdate={dailyCount.jiraBugChangeUpdate}
          countDelete={dailyCount.jiraBugChangeRemove}
        />
        <OverviewCard
          title={"Jira Story Change"}
          countAdd={dailyCount.jiraStoryChangeAdd}
          countUpdate={dailyCount.jiraStoryChangeUpdate}
          countDelete={dailyCount.jiraStoryChangeRemove}
        />
        <OverviewCard
          title={"Jira Epic Change"}
          countAdd={dailyCount.jiraEpicChangeAdd}
          countUpdate={dailyCount.jiraEpicChangeUpdate}
          countDelete={dailyCount.jiraEpicChangeRemove}
        />
         <OverviewCard
          title={"Jira Sprint Change"}
          countAdd={dailyCount.jiraSprintChangeAdd}
          countUpdate={dailyCount.jiraSprintChangeUpdate}
          countDelete={dailyCount.jiraSprintChangeRemove}
        />
      </div>
      <br />
      <div>
        <Loadable loaded={loadableForTable}>
          <ChangeLogTable changeLogData={filteredchangeLogData} />
        </Loadable>
      </div>
    </Loadable>
  );
}

export default AhaJiraChangeLogDashboard;
