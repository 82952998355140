import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import DeliveryReportInitiativeService from "../../services/DeliveryReportInitiativeService";
import { FormFeedback, FormGroup, Input } from "reactstrap";

const Option = (props) => {
    return (
        <div>
            {" "}
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                {"  "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const MultiValue = (props) => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
)

const animatedComponents = makeAnimated();

function DeliveryReportInitiatives(props) {

    const [intiativeGoalOptions, setIntiativeGoalOptions] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [disabledSave, setDisabledSave] = useState(true);
    const [changeLog, setChangeLog] = useState({
        insertList: [],
        updateList: [],
        deleteList: []
    });
    const [initialGridData, setInitialGridData] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        SetDisabledSave(gridData, initialGridData);
    }, [gridData, initialGridData]);

    const SetDisabledSave = async (gridData, initialGridData) => {
        if (gridData.length > 0) {
            let currentData = [...gridData];
            setDisabledSave(currentData.some(x => x.invalidInitiative === true || x.invalidGoalName == true));
        }
        if (gridData.length > 0 && initialGridData.length > 0 && initialGridData.length === gridData.length
            && initialGridData.every(function (this_i, i) {
                return this_i.initiativeId === gridData[i].initiativeId &&
                    this_i.initiativeName === gridData[i].initiativeName &&
                    this_i.goals.length === gridData[i].goals.length &&
                    this_i.goals.every(function (this_j, j) { return this_j.id === gridData[i].goals[j].id })
            })) {
            setDisabledSave(true);
        }
    }

    const getDefaultGridData = () => ({
        gridData: [],
    });

    const getDefaultInitiativeGoalOptions = () => ({
        intiativeGoalOptions: [],
    });

    const loadIntiativeGoalOptions = async () => {
        const intiativeGoalOptions = await DeliveryReportInitiativeService.GetGoalOptionsData();
        const objData = intiativeGoalOptions.map(function (obj) {
            return { value: obj.id, label: obj.goalName };
        });
        setIntiativeGoalOptions({ ...getDefaultInitiativeGoalOptions() })
        setIntiativeGoalOptions(objData);
    };

    const loadData = async () => {
        setIsLoading(true);
        loadIntiativeGoalOptions();

        let JsonData = await DeliveryReportInitiativeService.GetGridData();

        if (JsonData.length > 0) {
            let newData = [];
            let count = 1;

            JsonData.map(function (initiative) {
                newData.push({
                    recNo: count,
                    initiativeId: initiative.initiativeId,
                    initiativeName: initiative.initiativeName,
                    goals: initiative.goals,
                    invalidInitiative: false,
                    messageInvalidInitiative: "",
                    invalidGoalName: false,
                    messageInvalidGoalName: ""
                });
                count++;
            });
            setGridData({ ...getDefaultGridData() });
            setGridData(newData);

            setInitialGridData(JSON.parse(JSON.stringify(newData))); //deep copy
        } else {
            setGridData({ ...getDefaultGridData() });
            setGridData([
                {
                    recNo: 0,
                    initiativeId: null,
                    initiativeName: "",
                    goals: [],
                    invalidInitiative: true,
                    messageInvalidInitiative: "Initiative Name is required",
                    invalidGoalName: true,
                    messageInvalidGoalName: "Goal Name is required"
                },
            ]);
        }
        setIsLoading(false);
    };

    const handleAdd = (recNo, index1, e) => {
        setGridData((currentGridData) => [
            ...currentGridData,
            {
                recNo: recNo + 1,
                initiativeId: null,
                initiativeName: "",
                goals: [],
                invalidInitiative: true,
                messageInvalidInitiative: "Initiative Name is required",
                invalidGoalName: true,
                messageInvalidGoalName: "Goal Name is required"
            },
        ]);
    };

    const handleDelete = (recNo, index, initiativeId, e) => {
        let ans = window.confirm(
            "Are you sure you want to delete Row No. " + (index + 1) + " ?"
        );
        if (ans === true) {
            let currentGridData = [...gridData];
            currentGridData = currentGridData.filter(
                (obj) => obj.recNo !== recNo
            );
            setGridData(currentGridData);
        }
    };

    const saveClick = async () => {

        if (!gridData.find(x => x.invalidInitiative === true || x.invalidGoalName == true)) {
            setIsLoading(true);
            let updatedList = gridData.filter(x => x.initiativeId !== null &&
                !initialGridData.find(y => y.initiativeId === x.initiativeId
                    && y.initiativeName === x.initiativeName
                    && y.goals.length === x.goals.length
                    && x.goals.every(function (this_i, i) { return this_i.id == y.goals[i].id })
                )
            )
            changeLog.updateList = updatedList;
            changeLog.insertList = gridData.filter(x => x.initiativeId === null);
            changeLog.deleteList = initialGridData.filter(x => !gridData.find(y => y.initiativeId !== null && y.initiativeId === x.initiativeId));

            let res = await DeliveryReportInitiativeService.PostIntiativeGoalsData(changeLog);
            if (res.ok) alert("Delivery Report Initiatives saved successfully.");
            else alert("Something went wrong");
            loadData();
            setIsLoading(false);
        }
        else
            alert("Form is Invalid");
    }

    const handleInitiativeChange = (index, initiative) => {

        let currentGridData = [...gridData];
        if (initiative.target.value.trim() === "") {
            currentGridData[index].invalidInitiative = true;
            currentGridData[index].messageInvalidInitiative = "Initiative Name is required";
        }
        else if (currentGridData.find(x => x.initiativeName.trim().toLowerCase() === initiative.target.value.trim().toLowerCase())) {
            currentGridData[index].invalidInitiative = true;
            currentGridData[index].messageInvalidInitiative = "Initiative Name is already taken";
        }
        else {
            currentGridData[index].invalidInitiative = false;
            currentGridData[index].messageInvalidInitiative = "";
        }
        currentGridData[index].initiativeId = initiative.target.id == '' ? null : Number(initiative.target.id);;
        currentGridData[index].initiativeName = initiative.target.value;
        setGridData(currentGridData);
    };

    const handleInitiativeGoalChange = (index, initiativeId, selected) => {
        let currentGridData = [...gridData];
        let currentInitiative = currentGridData.filter(x => x.initiativeId == initiativeId);
        let existingInitiativesList = currentInitiative.flatMap(x => x.goals)
        let selectedInitiativesList = selected.map(function (newitem) {
            if (existingInitiativesList) {
                let existingItem = existingInitiativesList.filter(
                    (obj) => obj.id == newitem.value
                );
                if (existingItem !== undefined && existingItem[0])
                    return {
                        id: newitem.value,
                        goalName: newitem.label
                    };
                else
                    return {
                        id: newitem.value,
                        goalName: newitem.label
                    };
            } else return { id: newitem.value, goalName: newitem.label };
        });

        if (selectedInitiativesList.length === 0) {
            currentGridData[index].invalidGoalName = true;
            currentGridData[index].messageInvalidGoalName = "Goal Name is required";
        }
        else {
            currentGridData[index].invalidGoalName = false;
            currentGridData[index].messageInvalidGoalName = "";
        }

        currentGridData[index].goals = selectedInitiativesList;
        setGridData(currentGridData);
    };

    const renderFields = (data) => {
        if (data.length > 0) {
            return data.map((data, index) => loadWithExistingData(data, index));
        }
    };

    const loadWithExistingData = (data, index) => {
        const { recNo, initiativeId, initiativeName, goals, invalidInitiative, messageInvalidInitiative, invalidGoalName, messageInvalidGoalName } = data; //destructuring

        let existingInitiative = {
            initiativeId: null, initiativeName: "",
            invalidInitiative: invalidInitiative,
            messageInvalidInitiative: messageInvalidInitiative,
            invalidGoalName: invalidGoalName,
            messageInvalidGoalName: messageInvalidGoalName
        };
        let existingInitiativeGoalList = [];

        if (initiativeId !== 0) {
            existingInitiative = {
                initiativeId: initiativeId,
                initiativeName: initiativeName,
                invalidInitiative: invalidInitiative,
                messageInvalidInitiative: messageInvalidInitiative,
                invalidGoalName: invalidGoalName,
                messageInvalidGoalName: messageInvalidGoalName
            };

            if (goals !== null && goals !== undefined) {
                existingInitiativeGoalList = goals.map(function (obj) {
                    return { value: obj.id, label: obj.goalName };
                });
            }
        }

        return (
            <tr key={recNo}>
                <td className="col-sm-4">
                    <FormGroup>
                        <Input name="name"
                            invalid={existingInitiative.invalidInitiative}
                            type="text"
                            id={existingInitiative.initiativeId}
                            value={existingInitiative.initiativeName}
                            onChange={(e) => handleInitiativeChange(index, e)} />
                        <FormFeedback>
                            {existingInitiative.messageInvalidInitiative}
                        </FormFeedback>
                    </FormGroup>
                </td>
                <td className="col-sm-7">
                    <Select required
                        id={recNo}
                        key={recNo}
                        defaultValue={existingInitiativeGoalList}
                        isMulti
                        options={intiativeGoalOptions}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option, MultiValue, animatedComponents }}
                        allowSelectAll={true}
                        menuPortalTarget={document.body}
                        onChange={(e) => handleInitiativeGoalChange(index, initiativeId, e)}

                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                //state.isFocused can display different borderColor if you need it
                                borderColor: existingInitiative.initiativeName !== "" && existingInitiative.invalidGoalName ?
                                    '#dc3545' : '#ddd',


                                boxShadow: existingInitiative.initiativeName && existingInitiative.invalidGoalName && state.selectProps.menuIsOpen ?
                                    "0 0 0 0.2rem rgb(220 53 69 / 25%)" : "none",
                                //overwrittes hover style
                                '&:hover': {
                                    borderColor: existingInitiative.initiativeName && existingInitiative.invalidGoalName ?
                                        '#dc3545' : '#ddd',
                                    boxShadow: existingInitiative.initiativeName && existingInitiative.invalidGoalName && state.selectProps.menuIsOpen ?
                                        "0 0 0 0.2rem rgb(220 53 69 / 25%)" : "none",
                                }
                            }),
                        }}
                    />
                    {existingInitiative.initiativeName && existingInitiative.invalidGoalName === true &&
                        <div className="invalid-feedback d-block">
                            {existingInitiative.messageInvalidGoalName}
                        </div>
                    }
                </td>
                <td className="col-sm-1">
                    <div className="row" style={{ margin: 5 }}>
                        {gridData.length > index + 1 && (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">Delete Initiative</Tooltip>}
                            >
                                <a onClick={(e) => handleDelete(recNo, index, initiativeId, e)}>
                                    <svg
                                        width="35"
                                        height="35"
                                        fill="currentColor"
                                        className="bi bi-trash"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path
                                            fillRule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                        />
                                    </svg>
                                </a>
                            </OverlayTrigger>
                        )}
                        {gridData.length === 1 && (
                            <div>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip-2">Add Initiative</Tooltip>}
                                >
                                    <a onClick={(e) => handleAdd(recNo, index, e)}>
                                        <svg
                                            width="35"
                                            height="35"
                                            fill="currentColor"
                                            className="bi bi-plus-square"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                                            />
                                        </svg>
                                    </a>
                                </OverlayTrigger>
                            </div>

                        )}
                        {gridData.length !== 1 && gridData.length <= index + 1 && (
                            <div>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip-2">Delete Initiative</Tooltip>}
                                >
                                    <a onClick={(e) => handleDelete(recNo, index, initiativeId, e)}>
                                        <svg
                                            width="35"
                                            height="35"
                                            fill="currentColor"
                                            className="bi bi-trash"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path
                                                fillRule="evenodd"
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                            />
                                        </svg>
                                    </a>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip-2">Add Initiative</Tooltip>}
                                >
                                    <a onClick={(e) => handleAdd(recNo, index, e)}>
                                        <svg
                                            width="35"
                                            height="35"
                                            fill="currentColor"
                                            className="bi bi-plus-square"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                                            />
                                        </svg>
                                    </a>
                                </OverlayTrigger>
                            </div>

                        )}
                    </div>
                </td>
            </tr >
        );
    };

    return (
        <div className="container">
            <h2>Delivery Report Initiatives </h2>

            <hr className="mt-2 mb-3" />

            <div className="row spinner-row d-flex justify-content-center">
                {isLoading && (
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
            </div>

            <div className="row table-mycontainer ">
                <div className="col">
                    <table
                        className="table table-sm table-hover .table-responsive-sm"
                        aria-labelledby="tabelLabel"
                        style={{ overflowWrap: "break-word" }}
                    >
                        <thead className="stickyHeader">
                            <tr>
                                <th className="col-sm-3">Initiative Name</th>
                                <th className="col-sm-3">Goal Name</th>
                            </tr>
                        </thead>
                        <tbody>{renderFields(gridData)}</tbody>
                    </table>
                </div>
            </div>
            <div className="row footer-mycontainer">
                <div className="col">
                    <hr className="mt-2 mb-3" />
                    <button
                        onClick={saveClick}
                        type="button"
                        className="btn btn-primary"
                        disabled={disabledSave}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DeliveryReportInitiatives