import React, { Component } from 'react';
import Conditional from './Conditional';
import { UncontrolledCollapse, Button, Progress } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';

export class RefreshableMetric extends Component {
  static displayName = RefreshableMetric.name;

    constructor(props) {

        super(props);

        this.confirmHardRefresh = this.confirmHardRefresh.bind(this);

    }

    async confirmHardRefresh() {
        confirmAlert({
            title: 'Confirm to perform a hard refresh',
            message: 'This will refresh ALL metrics in the system and will take some time',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.refresh(true)
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

  render () {
    return (
        <React.Fragment>
            <Conditional if={this.props.metric.teamsRefreshing > 0}>
                <div className="alert alert-secondary" role="alert">
                    <p><em>{this.props.type} is refreshing</em></p>
                    <Progress animated color="success" value={(this.props.metric.totalTeams - this.props.metric.teamsRefreshing) / this.props.metric.totalTeams * 100}>
                        {this.props.metric.teamsRefreshing} teams remaining
                    </Progress>
                </div>
            </Conditional>
            <div>
                <Button color="primary" id="toggler" style={{ marginBottom: '1rem' }}>Show System Metrics</Button>
                <UncontrolledCollapse toggler="#toggler">
                    <table className='table table-striped' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>Event</th>
                                <th>Datetime</th>
                                <th>Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Queued: </td>
                                <td>{this.props.metric.queued}</td>
                            </tr>
                            <tr>
                                <td>Began: </td>
                                <td>{this.props.metric.began}</td>
                                <td>{this.props.metric.beganDuration}s</td>
                            </tr>
                            <tr>
                                <td>Refreshed: </td>
                                <td>{this.props.metric.refreshed}</td>
                                <td>{this.props.metric.refreshedDuration}s</td>
                            </tr>
                            <tr>
                                <td>Scheduled: </td>
                                <td>{this.props.metric.scheduled}</td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <Button color="warning" onClick={this.props.refresh}>Refresh {this.props.type}</Button>
                                    <Button color="danger" onClick={this.confirmHardRefresh}>Hard Refresh {this.props.type}</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </UncontrolledCollapse>
            </div>            
        </React.Fragment>
    );
  }
}
