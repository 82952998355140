import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { Loadable } from '../../components/Loadable';
import DevOpsMRDashboard from "../../services/DevOpsMRDashboardService";
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import MRTicketsLinkedTable from "../../components/DevOpsDashBoard/MRTicketsLinkedTable";
import IncorrectReleaseNoteTable from "../../components/DevOpsDashBoard/IncorrectReleaseNoteTable";
import Style from "../../components/DevOpsDashBoard/DevOpsDashboard.module.css"
import Conditional from "../../components/Conditional";

const mergreRequestsTab='1';
const releaseNotesTab='2';
const releaseItemsTab='3';
const SwitchToText="Switch to bulk search";
const SwitchToDropDown="Switch to dropdown";

function DevOpsMergeRequestDashboard(props) {

  const [jiraTicket, SetJiraTicket] = useState([]);
  const [linkedTicket, SetLinkedTickets] = useState([]);
  const [flag, setflag] = useState(false);
  const [refreshDate, setRefreshDate] = useState('');
  const [activeTab,setTab]=useState(mergreRequestsTab)
  const [incorrectReleaseNote, SetIncorrectReleaseNote] = useState([]);
  const[incorrectReleaseItems,SetIncorrectReleaseItems]=useState([]);
  const[display,setDisplay]=useState([]);
  const [textSearchFlag,setTextSearchFlag]=useState(false);
  const [ButtonText,setButtonText]=useState(SwitchToText);
  const [notFoundTicket, SetNotFoundTickets] = useState([]);
  const animatedComponents = makeAnimated();
  const[caching,setcaching]=useState(false);


  useEffect(()=>{
    loadData();
  },[]);

  const  loadData=async ()=>{
    setcaching(false);
    let responseDate = await fetch(`ProductDashboard/GetDataUpdateDate`);     
    let dateString = await responseDate.text();
    let date = new Date(dateString + ' UTC');
    setRefreshDate(date.toLocaleString());
    await fetch(`DevOpsMRDashboard/UpdateData`);
    setcaching(true);
  }
  const handleSelectedTicked = (e) => {
    SetJiraTicket(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const redirectToJira = () => {
    let url;

    url = JqlQuery();

    window.open(url, "_blank");
  };

  const JqlQuery = () => {
    var baseUrl = "https://onsolve.atlassian.net/issues/?jql=";
    var releaseNotes = [];
    var releaseItems = [];
    linkedTicket.map((element) => {
      if (!releaseItems.includes(element.releaseItemKey)) {
        releaseItems.push(element.releaseItemKey);
      }
      if (!releaseNotes.includes(element.releaseNotesKey)) {
        releaseNotes.push(element.releaseNotesKey);
      }
    });
    var Key =
      "key in("+releaseNotes + "," + releaseItems + ")";
    var jql = baseUrl + Key;
    return jql;
  };
  const getLinkedDetails = async (tickets) => {
    setDisplay(false);
    let mergeRequestdata = await DevOpsMRDashboard.GetLinkedTicket(tickets);
    let releaseNotesData= await DevOpsMRDashboard.GetIncorrectReleaseNoteTicket(tickets);
    let releaseItemsData= await DevOpsMRDashboard.GetIncorrectReleaseItemsTicket(tickets);
    let MRList=Array.from(new Set(mergeRequestdata.map(x=>x.mergeRequestKey)));
     let notFoundTicket=[]
     if(!textSearchFlag)
       {notFoundTicket= jiraTicket.filter(x=> MRList.indexOf(x)==-1);}
     else{
         let keys=jiraTicket.toString().trim();
         var tickets=keys.split(/[\s+,:;]+/);
         tickets=tickets.map(x=>x.toUpperCase()) 
         notFoundTicket= tickets.filter(x=> MRList.indexOf(x)==-1);
         
     }
    SetNotFoundTickets(notFoundTicket);
    SetLinkedTickets(mergeRequestdata);
    SetIncorrectReleaseNote(releaseNotesData);
    SetIncorrectReleaseItems(releaseItemsData);
    setDisplay(true);
    setflag(true);
  };

  const filterData = async (inputValue) => {
    var option = [];
    if (inputValue != "") {
      let data = await DevOpsMRDashboard.GetMRTicket(inputValue);
      data.map((element) => {
        let itm = {
          value: element.key,
          label: element.key + " " + element.summary,
        };
        option.push(itm);
      });
    }
    return option;
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterData(inputValue));
      }, 1000);
    });

    const customStyles = {
      multiValue: (base, state) => ({
        ...base,
        background: '#e6e1e1',
        maxWidth: "110px"
      })
    };

    const handleInputChange=(e)=>{
      SetJiraTicket(e.target.value);

    }
    const SwitchText=()=>{
      setTextSearchFlag(!textSearchFlag)
      SetJiraTicket([]);
      if(textSearchFlag)
      setButtonText(SwitchToText);
      else 
      setButtonText(SwitchToDropDown);
    }
  
  return (
    <Loadable loaded={caching}>
    <div>
      <div>
      <p style={{textAlign: "end" , margin:'0em'}}>Last Refresh:{refreshDate}</p>
        <h5>Search Ticket</h5>
        <div className={Style.search}>
         {(textSearchFlag)? <input type="text" 
          value={jiraTicket}
          className={Style.searchText}
          autoFocus={true}
          placeholder="Enter tickets seperated by comma , semicolon or space "
          onChange={(e)=> handleInputChange(e)}
        />
          :
          <AsyncSelect
            closeMenuOnSelect={false}
            components={animatedComponents}
            autoFocus={true}
            isMulti
            cacheOptions
            defaultOptions
            styles={customStyles}
            loadOptions={promiseOptions}
            onChange={handleSelectedTicked}
          />
          }
        </div>
        <div className={Style.buttonStyle}>
          <Button
            color="success"
            onClick={() => getLinkedDetails(jiraTicket)}
            disabled={jiraTicket.length == 0 ? true : false}
          >
            Search
          </Button>
        </div>
        <div className={Style.buttonStyle}>
          <Button
            color="primary"
            onClick={() => redirectToJira()}
            disabled={linkedTicket.length == 0 ? true : false}
          >
          GoToJira
          </Button>
        </div>
        <div>
          <Button
            color="link"
            onClick={SwitchText}
          >
           {ButtonText}
          </Button>
        </div>
      </div>
    <Conditional if={notFoundTicket.length!=0}>
      <div style={{ float: "left", marginTop: "10px",width:"100%"}}> 
      <p  className="alert alert-danger">Result Not Found for :-{notFoundTicket.map(x=> x+" ")}</p>
      </div>
     </Conditional>
      <div style={{ float: "left", marginTop: "10px",width:"100%"}}>
      <Row >
        <Col sm="12">
        <Nav tabs >
          <NavItem cssModule={Style} className={Style.nav1}>
            <NavLink 
            className={(activeTab==mergreRequestsTab)?'active':null}
            onClick={()=>setTab(mergreRequestsTab)}
            >
                Release Notes and Release Items tied to a Merge Request
            </NavLink>
          </NavItem>
          <NavItem cssModule={Style} className={Style.nav1}>
            <NavLink
             className={(activeTab==releaseNotesTab)?'active':null}
             onClick={()=>setTab(releaseNotesTab)}
            >
                Incorrect MR to Release Note Linked Items
            </NavLink>
          </NavItem>
          <NavItem cssModule={Style} className={Style.nav1}>
            <NavLink
            className={(activeTab==releaseItemsTab)?'active':null}
            onClick={()=>setTab(releaseItemsTab)}
            >
                Incorrect Release Items Linked
            </NavLink>
          </NavItem>
         </Nav>
         </Col>
       </Row>
       </div>
     
      <div className={Style.tabPanStyle}>
      <Loadable loaded={display}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={mergreRequestsTab}>
          <div className={Style.tabPanStyle}>
            {flag == true ? (
              <MRTicketsLinkedTable linkedTicket={linkedTicket} flag={flag} />
            ) : null}
          </div>
          </TabPane>
          <TabPane tabId={releaseNotesTab}>
              <div className={Style.tabPanStyle}>
              {flag == true ? (
              <IncorrectReleaseNoteTable incorrectReleaseData={incorrectReleaseNote} flag={flag} />
            ) : null}
              </div>
          </TabPane>
          <TabPane tabId={releaseItemsTab}>
              <div className={Style.tabPanStyle}>
              {flag == true ? (
              <IncorrectReleaseNoteTable incorrectReleaseData={incorrectReleaseItems} flag={flag} />
            ) : null}
            </div>
          </TabPane>
        </TabContent>
        </Loadable>
      </div>
    
    </div>
    </Loadable>
  );
  
}


export default DevOpsMergeRequestDashboard;
