export const getAll = async () => {
    const response = await fetch(`office`);
    return await response.json();
};

export const getHoliday = async (officeId) => {
    const response = await fetch(`office/${officeId}/holidays`);
    return await response.json();
};

export const save = async (office) => {
    
    const response = await fetch('office', {
        method: !office.id ? 'POST' : 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(office)
    });

    await response.text();

};

export const deleteOffice = async (officeId) => {

    const response = await fetch(`office/${officeId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    await response.text();

};

export const saveHolidays = async (officeId, holidays) => {

    const response = await fetch(`office/${officeId}/holidays`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(holidays)
    });

    await response.text()

};

export default {
    getAll,
    getHoliday,
    save,
    deleteOffice,
    saveHolidays
};
