import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import navStyle from "../../components/ObjectiveDashBoard/ObjectiveDashboard.module.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import flashReportService from "../../services/flashReportService";
import TabGridTable from "../../components/GridTable/TabGridTable";
import { Loadable } from "../../components/Loadable";
import { Row, Col } from "reactstrap";
import ExportExcel from "../../components/ExportExcel";

const ReportRoadmapDeliveryTab = "1";
const RoadmapProgressTab = "2";
const ReleaseProgressTab = "3";
const CustomerandSecurityDefectsTab = "4";
const ScopeChangeTab = "5";
function FlashReport(props) {
  const [activeTab, setActiveTab] = useState(ReportRoadmapDeliveryTab);
  const [reportDate, setReportDate] = useState(new Date());
  const [isLoader, setIsLoader] = useState(false);
  const [roadmapDeliverydata, setRoadmapDeliveryData] = useState([]);
  const [roadmapProgressdata, setRoadmapProgressData] = useState([]);
  const [releaseProgressdata, setReleaseProgressData] = useState([]);
  const [customerAndSecurityDefectdata, setCustomerAndSecurityDefectData] =
    useState([]);
  const [scopeChangeData, setScopeChangeData] = useState([]);

  useEffect(() => {
    loadData();
  }, [reportDate]);

  const loadData = async () => {
    setIsLoader(false);
    var releaseProgressData =
      await flashReportService.getFlashReportReleaseProgress(reportDate);
    setReleaseProgressData(releaseProgressData);
    var roadmapDeliveryData =
      await flashReportService.getFlashReportRoadmapDelivery(reportDate);
    setRoadmapDeliveryData(roadmapDeliveryData);
    var roadmapProgressData =
      await flashReportService.getFlashReportRoadmapProgress(reportDate);
    setRoadmapProgressData(roadmapProgressData);
    var customerAndSecurityDefectData =
      await flashReportService.getFlashReportCustomerAndSecurityDefect(
        reportDate,
      );
    setCustomerAndSecurityDefectData(customerAndSecurityDefectData);
    var scopeChangeData =
      await flashReportService.getFlashReportScopeChange(reportDate);
    setScopeChangeData(scopeChangeData);
    setIsLoader(true);
  };

  return (
    <div>
      <div>
        <Row>
          <Col sm="1">
            <div>
              <label htmlFor="date">Date: </label>
            </div>
          </Col>
          <Col>
            <DatePicker
              selected={reportDate}
              onChange={(date) => setReportDate(date)}
              filterDate={(date) => date.getDay() === 1}
              placeholderText="Select a Monday"
            />
          </Col>
          <Col sm="4" style={{ textAlign: "right" }}>
            <ExportExcel
              sheetData={roadmapDeliverydata}
              sheetData2={roadmapProgressdata}
              sheetData3={releaseProgressdata}
              sheetData4={customerAndSecurityDefectdata}
              sheetData5={scopeChangeData}
              sheetName="Roadmap Delivery"
              sheetName2="Roadmap Progress"
              sheetName3="Release Progress"
              sheetName4="Customer And Security Defects"
              sheetName5="Scope Change"
              fileName={"FlashReport_" + reportDate.toLocaleDateString()}
              buttonName="Export FlashReport"
            />
          </Col>
        </Row>
      </div>
      <br />
      <div>
        <Nav tabs>
          <NavItem cssModule={navStyle} className={navStyle.navstyle}>
            <NavLink
              className={classnames({
                active: activeTab === ReportRoadmapDeliveryTab,
              })}
              onClick={() => {
                setActiveTab(ReportRoadmapDeliveryTab);
              }}
            >
              Roadmap Delivery
            </NavLink>
          </NavItem>

          <NavItem cssModule={navStyle} className={navStyle.navstyle}>
            <NavLink
              className={classnames({
                active: activeTab === RoadmapProgressTab,
              })}
              onClick={() => {
                setActiveTab(RoadmapProgressTab);
              }}
            >
              Roadmap Progress
            </NavLink>
          </NavItem>
          <NavItem cssModule={navStyle} className={navStyle.navstyle}>
            <NavLink
              className={classnames({
                active: activeTab === ReleaseProgressTab,
              })}
              onClick={() => {
                setActiveTab(ReleaseProgressTab);
              }}
            >
              Release Progress
            </NavLink>
          </NavItem>
          <NavItem cssModule={navStyle} className={navStyle.navstyle}>
            <NavLink
              className={classnames({
                active: activeTab === CustomerandSecurityDefectsTab,
              })}
              onClick={() => {
                setActiveTab(CustomerandSecurityDefectsTab);
              }}
            >
              Customer And Security Defects
            </NavLink>
          </NavItem>
          <NavItem cssModule={navStyle} className={navStyle.navstyle}>
            <NavLink
              className={classnames({
                active: activeTab === ScopeChangeTab,
              })}
              onClick={() => {
                setActiveTab(ScopeChangeTab);
              }}
            >
              Scope Change
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div>
        <Loadable loaded={isLoader}>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={ReportRoadmapDeliveryTab}>
              &nbsp;
              <TabGridTable data={roadmapDeliverydata} />
            </TabPane>
            <TabPane tabId={RoadmapProgressTab}>
              &nbsp;
              <TabGridTable data={roadmapProgressdata} />
            </TabPane>
            <TabPane tabId={ReleaseProgressTab}>
              &nbsp;
              <TabGridTable data={releaseProgressdata} />
            </TabPane>
            <TabPane tabId={CustomerandSecurityDefectsTab}>
              &nbsp;
              <TabGridTable data={customerAndSecurityDefectdata} />
            </TabPane>
            <TabPane tabId={ScopeChangeTab}>
              &nbsp;
              <TabGridTable data={scopeChangeData} />
            </TabPane>
          </TabContent>
        </Loadable>
      </div>
    </div>
  );
}

export default FlashReport;
