import React, { Component } from 'react';
import Conditional from './Conditional';
import GridLoader from "react-spinners/GridLoader";

export class GridLoadable extends Component {

    render() {

        return (
            <React.Fragment>
                <GridLoader
                    size={this.props.size}
                    color={this.props.color}
                    loading={this.props.loaded === false}
                />
                <Conditional if={this.props.loaded}>
                    {this.props.children}
                </Conditional>
            </React.Fragment>
        );
    }
}
