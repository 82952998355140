import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

    constructor(props) {

        super(props);

        this.state = {
            claims: Object.entries(this.props.claims.rawClaims)
        };



    }

  render () {
    return (
        <div>
            <p>Dashboard of the Dashboard so you have some Dashboards inside of the Dashboard Dashboard</p>
            <p>App issued claims</p>
            <ul>
                {this.state.claims.map((value, index) => {
                    return <li key={value[0]}>{value[0]}: {value[1]}</li>
                })}
            </ul>
      </div>
    );
  }
}
