import React, { useState } from "react";
import manualServiceNowEntryService from "../../services/manualServiceNowEntryService";
import { Modal, Button } from "react-bootstrap";

function IncidentStateMappingForm(props) {
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showWarningAlert, setShowWarningAlert] = useState(false);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmedSubmit, setConfirmedSubmit] = useState(false);


    const [field, setField] = useState({
        stateID: undefined,
        stateName: undefined,
        tableName: undefined,
    });



    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!Object.values(field).some((value) => value === undefined || value === '')) {
            setShowConfirmationModal(true);
        } else {
            setShowWarningAlert(true);
            setTimeout(() => {
                setShowWarningAlert(false);
            }, 3000);
        }
    };

    const handleModalConfirm = async () => {
        setShowConfirmationModal(false);
        setConfirmedSubmit(true);
        let res = await manualServiceNowEntryService.postIncidentStateMappingForm(field);
        if (res.ok) {
            setShowSuccessAlert(true);
            setTimeout(() => {
                setShowSuccessAlert(false);
            }, 3000);
        } else {
            setShowErrorAlert(true);
            setTimeout(() => {
                setShowErrorAlert(false);
            }, 3000);
        }
    };

    const handleModalCancel = () => {
        setShowConfirmationModal(false);
    };

    const clearAll = (event) => {
        event.preventDefault();
        setField({
            stateID: undefined,
            stateName: undefined,
            tableName: undefined,
        });
    };

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;


        switch (name) {
            case "stateID":
                setField({ ...field, stateID: value });
                break;
            case "stateName":

                setField({ ...field, stateName: value });
                break;
            case "tableName":
                setField({ ...field, tableName: value });
                break;
        }
    };

    return (
        <div>
            {/* Confirmation modal */}
            <Modal show={showConfirmationModal} onHide={handleModalCancel} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to submit this data?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleModalConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Success alert */}
            {showSuccessAlert && (
                <div className="alert alert-success" role="alert">
                    Form Is Submitted Successfully
                </div>
            )}

            {/* Error alert */}
            {showErrorAlert && (
                <div className="alert alert-danger" role="alert">
                    Something Went Wrong
                </div>
            )}

            {/* Warning alert */}
            {showWarningAlert && (
                <div className="alert alert-warning" role="alert">
                    Please fill all the fields
                </div>
            )}
            <form onSubmit={handleSubmit} noValidate>
                <table className="table table-striped" aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th colSpan="2">Incident State Mapping</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>State ID</td>
                            <td>
                                <input
                                    type="number"
                                    name="stateID"
                                    value={field.stateID || ""}
                                    onChange={handleChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>State Name</td>
                            <td>
                                <input
                                    type="text"
                                    name="stateName"
                                    value={field.stateName || ""}
                                    onChange={handleChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Table Name</td>
                            <td>
                                <input
                                    type="text"
                                    name="tableName"
                                    value={field.tableName || ""}
                                    onChange={handleChange}
                                />

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button type="submit" className="btn btn-info btn-primary" >
                                    Submit
                                </button>
                            </td>
                            <td>
                                <button type="reset" className="btn btn-secondary" onClick={clearAll}>
                                    Clear
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}
export default IncidentStateMappingForm;