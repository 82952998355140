import React, { Component } from 'react';
import { Alert, Button } from 'reactstrap';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import azureService from '../../services/azureService';
import roleService from '../../services/roleService';
import jobFunctionService from '../../services/jobFunctionService';
import officeService from '../../services/officeService';
import vendorService from '../../services/vendorService';
import departmentService from '../../services/departmentService';
import common from '../../components/common';

import * as moment from 'moment';
import gitPrimeService from '../../services/gitPrimeService'

import Conditional from '../../components/Conditional';

import { Loadable } from '../../components/Loadable';
import { SearchDropDown } from '../../components/SearchDropDown';

import { RemoveTeamModal } from './RemoveTeamModal';
import './ModifyMember.css';

export class ModifyMember extends Component {
    static displayName = ModifyMember.name;

    constructor(props) {
        super(props);
        this.state = {
            member: {
                id: 0,
                preferredName: "",
                legalName: "",
                onSolveEmail: "",
                azureObjectId: "00000000-0000-0000-0000-000000000000",
                jiraId: "",
                correctJiraId: "",
                shouldLogWork: true,
                employeNumber: "",
                gitPrimeId: 0,
                gitLabUser: "",
                roleId: 1,
                primaryFunctionId: 21,
                officeId: 12,
                vendorId: 9,
                additionalFunctions: [],
                teams: [],
                startOn: null,
                endOn: null
            },
            selectedRole: {},
            selectedPrimaryFunction: {},
            selectedOffice: {},
            selectedVendor: {},
            selectedDepartment: {},
            loadingMember: true,
            loadingGitPrimeLink: false,

            editing: 0,
            deletingTeam: null,
        };
        this.handleInputChange = common.createInputHandler(this, 'member');
        this.changeRole = common.createSearchDropDownHandelChange(this, 'member', 'selectedRole', 'roleId');
        this.changePrimaryFunction = common.createSearchDropDownHandelChange(this, 'member', 'selectedPrimaryFunction', 'primaryFunctionId');
        this.changeOffice = common.createSearchDropDownHandelChange(this, 'member', 'selectedOffice', 'officeId');
        this.changeDepartment = common.createSearchDropDownHandelChange(this, 'member', 'selectedDepartment', 'departmentId');
        this.changeVendor = common.createSearchDropDownHandelChange(this, 'member', 'selectedVendor', 'vendorId');
        this.toggleShouldLogWork = this.toggleShouldLogWork.bind(this);

        this.closeEdit = this.closeEdit.bind(this);
    }

    removeTeams = toRemove => {
        this.setState({ editing: 1, deletingTeam: toRemove });
    };

    closeEdit(isRemoved, removedTeam) {
        this.setState({ editing: 0 });

        if (isRemoved === true && removedTeam !== null) {
            var member = { ...this.state.member };
            member.teams = member.teams.filter(team => team !== removedTeam);
            this.setState({ member: member });
        }
    }

    componentDidMount() {

        var memberId = this.props.match.params.memberId;

        if (memberId != null) {
            this.loadMember(memberId);
        }
    }

    format(date) {
        if (!date)
            return "";
        var iso = moment(date).format("MM/DD/YYYY");
        var iso1 = Date.parse(iso);
        return iso1;
    }
    handleCancel = () => {
        this.props.history.push('/members');
    }

    async loadMember(memberId) {
        const response = await fetch(`member/${memberId}`);
        const data = await response.json();
        this.setState({ member: data, loadingMember: false });
    }

    selectAzureMember = (azureMember) => {

        var member = { ...this.state.member }

        member.legalName = `${azureMember.givenName} ${azureMember.surname}`;
        member.preferredName = azureMember.displayName;
        member.onSolveEmail = azureMember.mail;
        member.azureObjectId = azureMember.id;
        member.givenName = azureMember.givenName;
        member.surname = azureMember.surname;
        member.jiraId = azureMember.jiraId;

        this.setState({ member });

        this.retrieveGitPrimeLink(member.onSolveEmail);

    }

    async retrieveGitPrimeLink(email) {

        this.setState({ loadingGitPrimeLink: true });

        const gitPrimeId = await gitPrimeService.retrieveLink(email);

        if (gitPrimeId != null) {

            var member = { ...this.state.member }

            member.gitPrimeId = gitPrimeId.id;

            this.setState({ member });

        }

        this.setState({ loadingGitPrimeLink: false });

    }

    toggleShouldLogWork() {
        var member = { ...this.state.member };
        member.shouldLogWork = !member.shouldLogWork;
        this.setState({ member });
    }

    changeDate = (name, date) => {
        var member = { ...this.state.member };
        member[name] = date;
        this.setState({ member });
    }

    handleSubmit = async () => {

        const response = await fetch(`member`, {
            method: this.state.member.id === 0 ? 'POST' : 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.member)
        });

        await response.json();

        this.props.history.push('/members');

    }

    render() {
        return (
            <div>
                <Conditional if={this.state.editing !== 0}>
                    <RemoveTeamModal isOpen={this.state.editing !== 0} team={this.state.deletingTeam} member={this.state.member} close={this.closeEdit} />
                </Conditional>
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <tbody>
                        <Conditional if={this.state.member.id !== 0}>
                            <tr>
                                <td>Id</td>
                                <td>{this.state.member.id}</td>
                            </tr>
                        </Conditional>
                        <Conditional if={this.state.member.id === 0}>
                            <tr>
                                <td style={{ width: '200px' }}>Select Member to begin</td>
                                <td>
                                    <SearchDropDown
                                        onSelection={this.selectAzureMember}
                                        display={x => x.displayName}
                                        title="Select Member"
                                        search={azureService.search}
                                        enabledPredicate={x => !x.inUse}
                                        max={20}
                                    />
                                </td>
                            </tr>
                        </Conditional>
                        <Conditional if={this.state.member.azureObjectId !== "00000000-0000-0000-0000-000000000000"}>
                            <tr>
                                <td style={{ width: '200px' }}>Preferred Name</td>
                                <td>
                                    <input name="preferredName" type="text" value={this.state.member.preferredName} onChange={this.handleInputChange} />
                                </td>
                            </tr>
                            <tr>
                                <td>Legal Name</td>
                                <td>{this.state.member.legalName}</td>
                            </tr>
                            <tr>
                                <td>Given Name</td>
                                <td>{this.state.member.givenName}</td>
                            </tr>
                            <tr>
                                <td>Surname</td>
                                <td>{this.state.member.surname}</td>
                            </tr>
                            <tr>
                                <td>OnSolve Email</td>
                                <td>{this.state.member.onSolveEmail}</td>
                            </tr>
                            <tr>
                                <td>Azure Id</td>
                                <td>{this.state.member.azureObjectId}</td>
                            </tr>
                            {/*<tr>*/}
                            {/*    <td>Rate</td>*/}
                            {/*    <td>*/}
                            {/*        <input name="hourlyRate" type="number" value={this.state.member.hourlyRate} onChange={this.handleInputChange} />*/}
                            {/*    </td>*/}
                            {/*</tr>*/}
                            <tr>
                                <td>Should Log work</td>
                                <td>
                                    <Toggle
                                        defaultChecked={this.state.member.shouldLogWork}
                                        onChange={this.toggleShouldLogWork} />
                                </td>
                            </tr>
                            <tr>
                                <td>Start Date</td>
                                <td>
                                    <DatePicker placeholderText="MM/DD/YYYY"
                                        selected={this.format(this.state.member.startOn)}
                                        onChange={date => this.changeDate('startOn', date)} />


                                </td>
                            </tr>

                            <tr>
                                <td>End Date</td>
                                <td>
                                    <DatePicker placeholderText="MM/DD/YYYY"
                                        minDate={this.format(this.state.member.startOn)}
                                        selected={this.format(this.state.member.endOn)}
                                        onChange={date => this.changeDate('endOn', date)}
                                        isClearable />
                                    {/* <input name="endOn" type="date" placeholder="dd/mm/yyyy" value={this.format(this.state.member.endOn)} onChange={this.handleInputChange} /> */}
                                </td>
                            </tr>
                            <tr>
                                <td>Jira Id</td>
                                <td>
                                    <input name="jiraId" type="text" value={this.state.member.jiraId} onChange={this.handleInputChange} />
                                    <Conditional if={this.state.member.correctJiraId && this.state.member.jiraId !== this.state.member.correctJiraId}>
                                        <span style={{ marginLeft: '20px' }}>
                                            JiraId is incorrect. Correct JiraId: {this.state.member.correctJiraId}
                                        </span>
                                    </Conditional>
                                </td>
                            </tr>
                            <tr>
                                <td>Employee Number</td>
                                <td>
                                    <input name="employeeNumber" type="text" value={this.state.member.employeeNumber} onChange={this.handleInputChange} />
                                </td>
                            </tr>
                            {/*<tr>*/}
                            {/*    <td>GitPrime Id</td>*/}
                            {/*    <td>*/}
                            {/*        <Loadable loaded={this.state.loadingGitPrimeLink === false}>*/}
                            {/*            <Conditional if={this.state.member.gitPrimeId === 0}>*/}
                            {/*                <Alert color="danger">*/}
                            {/*                    <span>Account could not be automatically linked</span>*/}
                            {/*                    <Button color="success" onClick={() => this.retrieveGitPrimeLink(this.state.member.onSolveEmail)}>Retry</Button>*/}
                            {/*                </Alert>*/}
                            {/*            </Conditional>*/}
                            {/*            <Conditional if={this.state.member.gitPrimeId !== 0}>*/}
                            {/*                {this.state.member.gitPrimeId}*/}
                            {/*            </Conditional>*/}
                            {/*        </Loadable>*/}
                            {/*    </td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td>GitLab User</td>*/}
                            {/*    <td>*/}
                            {/*        <input name="gitLabUser" type="text" value={this.state.member.gitLabUser} onChange={this.handleInputChange} />*/}
                            {/*    </td>*/}
                            {/*</tr>*/}
                            <tr>
                                <td>Role</td>
                                <td>
                                    <SearchDropDown
                                        onSelection={this.changeRole}
                                        display={x => x.name}
                                        title="Select Role"
                                        initialSet={roleService.getAll}
                                        initialSelectionId={this.state.member.roleId}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Primary Function</td>
                                <td>
                                    <SearchDropDown
                                        onSelection={this.changePrimaryFunction}
                                        display={x => x.name}
                                        title="Select Job Function"
                                        initialSet={jobFunctionService.getAll}
                                        initialSelectionId={this.state.member.primaryFunctionId}
                                    />
                                </td>
                            </tr>
                            {/*<tr>*/}
                            {/*    <td>Additional Functions</td>*/}
                            {/*    <td>not implemented</td>*/}
                            {/*</tr>*/}
                            <tr>
                                <td>Departments</td>
                                <td>
                                    <SearchDropDown
                                        onSelection={this.changeDepartment}
                                        display={x => `${x.name}`}
                                        title="Select Department"
                                        initialSet={departmentService.getAll}
                                        initialSelectionId={this.state.member.departmentId}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Office</td>
                                <td>
                                    <SearchDropDown
                                        onSelection={this.changeOffice}
                                        display={x => `${x.name} | ${x.timeZone}`}
                                        title={this.state.selectedOffice.name}
                                        initialSet={officeService.getAll}
                                        initialSelectionId={this.state.member.officeId}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Vendor</td>
                                <td>
                                    <SearchDropDown
                                        onSelection={this.changeVendor}
                                        display={x => `${x.name} | ${x.employmentClass}`}
                                        title="Select Vendor"
                                        initialSet={vendorService.getAll}
                                        initialSelectionId={this.state.member.vendorId}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Member of Team</td>
                                <td>
                                    <ul>
                                        {this.state.member.teams.map(team =>
                                            <li key={team.id} >{team.name}  <Button className="btn btn-sm RemoveButtonContainer" color="danger" onClick={() => this.removeTeams(team)}>Remove</Button></li>
                                        )}
                                    </ul>
                                </td>
                            </tr>
                        </Conditional>
                    </tbody>
                </table>
                <Conditional if={this.state.member.azureObjectId !== "00000000-0000-0000-0000-000000000000"}>
                    <button className='btn btn-secondary' onClick={this.handleCancel}>
                        Cancel
                    </button>
                    &nbsp;
                    <Button color="primary" onClick={this.handleSubmit}>Save member</Button>
                </Conditional>
            </div>
        );
    }
}
