export const postProductionMetricsForm = async (productionObj) => {
  var response = await fetch(
    `ManualKPIEntryDashboard/CreateProductionMetrics`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(productionObj),
    }
  )
    .then((res) => {
      if (res.status !== 200) throw new Error(res.status);
      else return res;
    })
    .catch((error) => {
      return "error";
    });
  return response;
};

export const postRiskMetricsForm = async (riskObj) => {
  var response = await fetch(
    `ManualKPIEntryDashboard/CreateRiskIntelligenceMetrics`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(riskObj),
    }
  )
    .then((res) => {
      if (res.status !== 200) throw new Error(res.status);
      else return res;
    })
    .catch((error) => {
      return "error";
    });

  return response;
};

export const getGitPrimeData = async (date) => {
  const response = await fetch(
    `ManualKPIEntryDashboard/GetGitPrimeMetrics?date=${date.toLocaleString()}`);
  return await response.json();
};

export const postUpdateGitPrimeData = async (gitData) => {
  const response = await fetch(
    `ManualKPIEntryDashboard/AddEditGitPrimeMetrics`,{
      method:"PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(gitData),
    }).then((res) => {
      if (res.status !== 200) throw new Error(res.status);
      else return  res;
    })
    .catch((error) => {
      return "error";
    });

   return  response;
};


export default {
  postProductionMetricsForm,
  postRiskMetricsForm,
  getGitPrimeData,
  postUpdateGitPrimeData
};
