export const GetClusterOptionsData= async ()=>{
    const response = await fetch(`POD/GetCluster`);
    return await response.json();
}

export const GetPodOptionsData= async ()=>{
    const response = await fetch(`POD/GetPODs`);
    return await response.json();
}

export const GetMembersOptionsData= async ()=>{
    const response = await fetch(`Member/GetAllActiveMember`);
    return await response.json();
}


export const GetTeamOptionsData= async ()=>{
    const response = await fetch(`Team/my`);
    return await response.json();
}

export const GetClusterDetailsData= async (id, clusterName)=>{
    const response = await fetch(`POD/GetClusterDetails?id=${id}&&clusterName=${clusterName}`);
    return await response.json();
}

export const PostClusterDetailsData = async (podData) => {
    const response = await fetch(
      `POD/UploadClusterPODDetails`,{
        method:"POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(podData),
      }).then((res) => {
        if (res.status !== 200) throw new Error(res.status);
        else return  res;
      })
      .catch((error) => {
        return "error";
      });
  
     return  response;
  };

export const GetSupportType= async ()=>{
    const response = await fetch(`POD/GetSupportType`);
    return await response.json();
}

const GetClusterDetail= async (id)=>{
  let response = await fetch(`RnDResourceChart/${id}`);
  return await response.json();

}

export default{
    GetClusterOptionsData,
    GetPodOptionsData,
    GetMembersOptionsData,
    GetTeamOptionsData,
    GetClusterDetailsData,
    PostClusterDetailsData,
    GetSupportType,
    GetClusterDetail
}