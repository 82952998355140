import React, { Component } from 'react';
import officeService from '../../services/officeService';
import timezoneService from '../../services/timezoneService';
import vendorService from '../../services/vendorService';
import Conditional from '../../components/Conditional';
import { Loadable } from '../../components/Loadable';

import 'react-confirm-alert/src/react-confirm-alert.css';

import { OfficeHolidayModal } from '../../components/office/OfficeHolidayModal';
import { OfficeRow } from '../../components/office/OfficeRow';

export class OfficePage extends Component {

    constructor(props) {
        super(props);

        var vendorId = parseInt(this.props.claims.rawClaims["VendorId"]);
        this.state = {
            loading: false,
            offices: [],
            vendors: [],
            timezones: [],
            isAdmin: this.props.claims.isAdmin(),
            editingHolidays: false,
            selectedOffice: {},
            defaultVendorId: vendorId
        }
    }

    async loadData() {
        this.setState({ loading: true });
        var [offices, timezones, vendors] = await Promise.all([
            officeService.getAll(),
            timezoneService.getAll(),
            vendorService.getAll()
        ]);
        this.setState({
            loading: false,
            offices: offices,
            timezones: timezones.filter(t => t.population > 0),
            vendors: vendors,
            selectedOffice: {}
        });
    }

    reload = async () => {
        this.setState({ loading: true });
        var offices = await officeService.getAll();
        this.setState({
            loading: false,
            offices: offices
        });
    }

    componentDidMount() {
        this.loadData();
    }

    editHolidays = (office) => {
        this.setState({ editingHolidays: true, selectedOffice: office });
    }

    editedHolidays = async (result) => {
        if (!result.canceled) {
            await officeService.saveHolidays(this.state.selectedOffice.id, result.value);
        } 
        this.setState({ editingHolidays: false });
    }

    render() {
        return <section>
            <h1>Configure Offices</h1>
            <Loadable loaded={!this.state.loading}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Time Zone</th>
                            <th>Vendor</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.offices.map(office => 
                            <Conditional key={office.id} if={this.props.claims.canConfigureSpecificResource('Vendor', office.primaryVendorId)}>
                                <OfficeRow 
                                    office={office} 
                                    vendors={this.state.vendors} 
                                    timezones={this.state.timezones} 
                                    isAdmin={this.state.isAdmin} 
                                    reload={this.reload} 
                                    editHolidays={this.editHolidays} />
                            </Conditional>)}
                        <OfficeRow vendors={this.state.vendors} timezones={this.state.timezones} isAdmin={this.state.isAdmin} reload={this.reload} editHolidays={this.editHolidays} defaultVendorId={this.state.defaultVendorId}/>
                    </tbody>
                </table>
                <OfficeHolidayModal isOpen={this.state.editingHolidays} editedHolidays={this.editedHolidays} office={this.state.selectedOffice} defaultVendorId={this.state.defaultVendorId} />
            </Loadable>
        </section>
    }
}