import React, { Component } from 'react';
import { Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

import { ChartCard } from '../components/ChartCard';
import { Loadable } from '../components/Loadable';
import { GridLoadable } from '../components/GridLoadable';
import Conditional from '../components/Conditional';
import { SearchDropDown } from '../components/SearchDropDown';

import {Bar} from 'react-chartjs-2';
import "chartjs-plugin-annotation";

import metricService from '../services/metricService';
import metricTypeService from '../services/metricTypeService';

export class Overview extends Component {
    static displayName = Overview.name;

    constructor(props) {
        super(props);

        this.state = {
            loadingMetricTypes: true,
            weeksBack: 12,
            metricTypes: [],
            activeTab: {
                overviewResult: {
                    Name: "Everything",
                    Average: 0,
                    Weeks: [],
                    CurrentWeek: {
                        Value: 0
                    },
                    ChildResultName: "Groups",
                    ChildOverviewResults: [],
                    loading: true

                },
                color: '255,255,255',
            }
        };

        this.toggle = this.toggle.bind(this);
        this.loadLevel = this.loadLevel.bind(this);
        this.updateWeeksBack = this.updateWeeksBack.bind(this);

    }

    componentDidMount() {
        this.setState({loadingMetricTypes: true });
        this.loadMetricTypes();
    }

    async loadMetricTypes() {
        
        const data = await metricTypeService.get();

        const metricTypes = data.map(metricType => {
            
            metricType.overviewResult = {
                Name: "Everything",
                Average: 0,
                Weeks: [],
                CurrentWeek: {
                    Value: 0
                },
                ChildResultName: "Groups",
                ChildOverviewResults: []   
            };
            metricType.loading = true;

            return metricType;


        });

        var weeksBack = 12;

        this.setState({ metricTypes: metricTypes, loadingMetricTypes: false, activeTab: metricTypes[0], weeksBack });

        this.loadLevel('', '', weeksBack);

    }

    toggle (tab) {
        if(this.state.activeTab !== tab) {
            this.setState({activeTab: tab})
        }
    }

    loadLevel(levelUri, parentOverviewUri, weeksBack) {

        this.state.metricTypes.forEach(metrictype => {

            this.loadMetricTypeForLevel(metrictype, levelUri, parentOverviewUri, weeksBack);

        });

    }

    async loadMetricTypeForLevel (metricType, levelUri, parentOverviewUri, weeksBack) {

        var metricTypes = this.state.metricTypes;
        
        var stateMetricType = metricTypes.find(mT => mT.id === metricType.id);

        stateMetricType.loading = true;

        this.setState({ metricTypes });

        const data = await metricService.getMetricLevel(metricType.id, levelUri, parentOverviewUri, weeksBack);

        //Need to relook this up in case it was modified during the await
        //Can rely on it not being touched while statements execute that aren't async since this is single threaded
        metricTypes = this.state.metricTypes;        
        stateMetricType = metricTypes.find(mT => mT.id === metricType.id);

        stateMetricType.overviewResult = data;
        stateMetricType.loading = false;

        this.setState({ metricTypes });
    
    }

    updateWeeksBack(event) {

        var weeksBack = event.id;

        if (this.state.weeksBack != weeksBack) {
            this.setState({ weeksBack }, () => {

                const overviewResult = this.state.activeTab.overviewResult;

                this.loadLevel(overviewResult.OverviewUri || '', overviewResult.ParentOverviewUri || '', weeksBack);

            });
        }        

    }

    render () {
        return (
            <Loadable loaded={this.state.loadingMetricTypes === false}>
                <Row>
                    <Col sm="12">
                        Scope: Everything
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        Time Range: {this.state.weeksBack} Weeks
                    </Col>
                </Row>
                <Nav tabs>
                    {this.state.metricTypes.map(type=>
                        <NavItem>
                            <NavLink className={{ active: this.state.activeTab.title === type.title }} onClick={() => { this.toggle(type); }}>
                                <ChartCard 
                                    dataSet={type.overviewResult.Weeks.map(x => x.Value)} 
                                    average={type.overviewResult.Average}
                                    loading={type.loading}
                                    color={type.color} 
                                    title={type.title}
                                    width={185}
                                    height={50}
                                />
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
                <GridLoadable loaded={this.state.activeTab.loading === false} color={`rgba(${this.state.activeTab.color},1)`} size={200}>
                    <Row>
                        <Col sm="2">
                            <Row>
                                
                                Weeks: <SearchDropDown 
                                    onSelection={this.updateWeeksBack} 
                                    display={x => x.id}
                                    title="Select Weeks"
                                    initialSet={() => [                                        
                                            {id: 2},
                                            {id: 4},
                                            {id: 6},
                                            {id: 8},
                                            {id: 10},
                                            {id: 12},
                                            {id: 26},
                                            {id: 36},
                                            {id: 52}]}
                                    initialSelectionId={this.state.weeksBack}
                                />
                            </Row>
                            <Row>
                                Start: {moment(this.state.activeTab.overviewResult.Start).format("MMM Do YYYY")}
                            </Row>
                            <Row>
                                End: {moment(this.state.activeTab.overviewResult.End).format("MMM Do YYYY")}
                            </Row>
                            <Row>
                                Average {this.state.activeTab.overviewResult.Average}
                            </Row>
                            <Row>
                                Trend {this.state.activeTab.overviewResult.Trend}%
                            </Row>
                        </Col>
                        <Col sm="8">                        
                            <Bar
                                data={{
                                    labels: this.state.activeTab.overviewResult.Weeks.map(x => moment(x.Start).format("MMM Do YYYY")),
                                    datasets: [
                                    {
                                        label: this.state.activeTab.overviewResult.Name,
                                        backgroundColor: `rgba(${this.state.activeTab.color},0.2)`,
                                        borderColor: `rgba(${this.state.activeTab.color},1)`,
                                        borderWidth: 1,
                                        hoverBackgroundColor: `rgba(${this.state.activeTab.color},0.5)`,
                                        hoverBorderColor: `rgba(${this.state.activeTab.color},1)`,
                                        data: this.state.activeTab.overviewResult.Weeks.map(x => x.Value),
                                        barPercentage: 1,
                                        categoryPercentage: .95
                                    }
                                    ]
                                }}
                                width={100}
                                height={500}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                beginAtZero: true,
                                                max: Math.max(...this.state.activeTab.overviewResult.Weeks.map(x => x.Value)) * 1.10
                                            }
                                        }]
                                    },
                                    annotation: {
                                        annotations: [{
                                        type: 'line',
                                        mode: 'horizontal',
                                        scaleID: 'y-axis-0',
                                        value: this.state.activeTab.overviewResult.Average,
                                        borderColor: 'gold',
                                        borderWidth: 6,
                                        label: {
                                            enabled: true,
                                            content: 'Average',
                                            yAdjust: -16,
                                            position: 'left'
                                        }
                                        },
                                        {
                                            type: 'line',
                                            mode: 'horizontal',
                                            scaleID: 'y-axis-0',
                                            value: this.state.activeTab.overviewResult.TrendStart,
                                            endValue: this.state.activeTab.overviewResult.TrendEnd,
                                            borderColor: 'black',
                                            borderWidth: 2,
                                            label: {
                                            enabled: true,
                                            content: `Trend ${this.state.activeTab.overviewResult.Trend}%`,
                                            yAdjust: -16,
                                            position: 'right'
                                            }
                                        }]
                                    }
                                }}
                                />                      
                        </Col>
                        <Col sm="2">
                            Current work
                        </Col>
                    </Row>
                    <Conditional if={this.state.activeTab.overviewResult.ChildOverviewResults.length > 0}>
                        <Row>
                            <Col sm="2">
                                {this.state.activeTab.overviewResult.ChildResultName} ({this.state.activeTab.overviewResult.ChildOverviewResults.length})
                            </Col>
                            <Col sm="8">
                            </Col>
                            <Col sm="2">
                                <Conditional if={this.state.activeTab.overviewResult.ParentOverviewUri != null}>                            
                                    <FontAwesomeIcon icon={faArrowCircleUp} onClick={() => { this.loadLevel(this.state.activeTab.overviewResult.ParentOverviewUri); }} />
                                </Conditional>
                            </Col>
                        </Row>
                        <Nav tabs>
                            {this.state.activeTab.overviewResult.ChildOverviewResults.map(child=>
                                <NavItem>
                                    <NavLink onClick={() => { this.loadLevel(child.OverviewUri, child.ParentOverviewUri, this.state.weeksBack); }}>
                                        <ChartCard 
                                            dataSet={child.Weeks.map(x => x.Value)} 
                                            average={child.Average}
                                            loading={false}
                                            color={this.state.activeTab.color} 
                                            title={child.Name}
                                            width={185}
                                            height={50}
                                        />
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </Conditional>
                </GridLoadable>
            </Loadable>
        );
    }


}

