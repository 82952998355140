import React, { useEffect, useState } from "react";
import { getIncidentSlaData, storeIncidentSlaData } from "../../services/incidentSlaService"
import { Loadable } from '../../components/Loadable';
import { Col, Nav, NavItem, Button, NavLink, Row, TabContent, TabPane, DropdownMenu, UncontrolledDropdown, DropdownToggle, DropdownItem, Dropdown } from 'reactstrap';
import ExportExcel from '../../components/ExportExcel';
import classnames from 'classnames';


const TotalTab = '1';
function IncidentSLA() {
    const [incident, setIncident] = useState([]);
    const [display, setDisplay] = useState(false);
    const [activeTab, setTab] = useState(TotalTab);

    const [dataPerPage, setDataPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const lastIndexofdata = currentPage * dataPerPage;
    const firstIndexofdata = lastIndexofdata - dataPerPage;
    const currentData = incident.slice(firstIndexofdata, lastIndexofdata);




    useEffect(() => {
        async function fetchData() {
            try {
                const mydata = await storeIncidentSlaData();
                const data = await getIncidentSlaData();
                setIncident(data);

                setDisplay(true);
            } catch (error) {
                console.error("Error fetching incident data:", error);
            }
        }
        fetchData();
    }, []);

    const pageNumber = Array.from(
        new Array(Math.ceil(incident.length / dataPerPage)),
        (val, index) => index + 1
    );
    //change page
    const paginate = (currentPage) => {
        if (currentPage >= 1 && currentPage <= pageNumber.length)
            setCurrentPage(currentPage);
    };

    const ItemPage = (number) => {
        setDataPerPage(number);
        setCurrentPage(1);
    };



    return (

        <Loadable loaded={display}>
            <section>
                <div>
                    <Row>
                        <Col sm='12' style={{ textAlign: "right" }}>
                            <ExportExcel
                                sheetData={currentData}
                                fileName={
                                    "IncidentSla"
                                }
                                buttonName="Export IncidentSla Data"
                                sheetName="IncidentSla Data"
                            />
                        </Col>
                    </Row>
                </div>
                <div>


                </div>
                <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === TotalTab })}
                                onClick={() => { setTab(TotalTab); }}>Total</NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div>
                    <TabContent>
                        <TabPane>
                            &nbsp;
                            <div style={{ overflowX: 'auto' }}>
                                <table className="table table-striped" align="center" >
                                    <thead>
                                        <tr>
                                            <th style={{ width: "10%", wordWrap: 'break-word', fontSize: '12px' }}>INCIDENT NUMBER</th>
                                            <th style={{ width: "10%", wordWrap: 'break-word', fontSize: '12px' }}>SLA DEFINITION</th>
                                            <th style={{ width: "10%", wordWrap: 'break-word', fontSize: '12px' }}>SLA TARGET</th>
                                            <th style={{ width: "10%", wordWrap: 'break-word', fontSize: '12px' }}>SLA STAGE</th>
                                            <th style={{ width: "10%", wordWrap: 'break-word', fontSize: '12px' }}>START TIME</th>
                                            <th style={{ width: "10%", wordWrap: 'break-word', fontSize: '12px' }}>STOP TIME</th>
                                            <th style={{ width: "10%", wordWrap: 'break-word', fontSize: '12px' }}>BREACHED</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(currentData.length != 0) ? currentData.map((incidentItem, index) =>
                                            <tr key={index} style={{ borderTop: (index + 1 == incident.length) && "3px solid #87A5D9" }}>

                                                <td style={{
                                                    fontWeight: (index + 1 == currentData.length), width: "10%", wordWrap: 'break-word', whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    fontSize: '12px'
                                                }}>{incidentItem.incident_number}</td>
                                                <td style={{
                                                    fontWeight: (index + 1 == currentData.length), width: "10%", wordWrap: 'break-word', whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    fontSize: '12px'
                                                }}>{incidentItem.sla_definition}</td>
                                                <td style={{
                                                    fontWeight: (index + 1 == currentData.length), width: "10%", wordWrap: 'break-word', whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    fontSize: '12px'
                                                }}>{incidentItem.sla_target}</td>

                                                <td style={{
                                                    fontWeight: (index + 1 == currentData.length), width: "10 % ", wordWrap: 'break-word', whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    fontSize: '12px'
                                                }}>{incidentItem.sla_stage}</td>
                                                <td style={{
                                                    fontWeight: (index + 1 == currentData.length), width: "10%", wordWrap: 'break-word', whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    fontSize: '12px'
                                                }}>{incidentItem.start_time}</td>
                                                <td style={{
                                                    fontWeight: (index + 1 == currentData.length), width: "10%", wordWrap: 'break-word', whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    fontSize: '12px'
                                                }}>{incidentItem.stop_time}</td>
                                                <td style={{
                                                    fontWeight: (index + 1 == currentData.length), width: "10%", wordWrap: 'break-word', whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    fontSize: '12px'
                                                }}>{incidentItem.breached ? "true" : "false"}</td>


                                            </tr>
                                        ) :
                                            <tr><td colSpan='5'>NO DATA TO SHOW</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <div>
                                    <div style={{ marginTop: "5px" }}>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-center">
                                                <li className="page-item">
                                                    <a
                                                        className="page-link"
                                                        onClick={() => paginate(currentPage - 1)}
                                                    >
                                                        Previous
                                                    </a>
                                                </li>
                                                {[0, 1, 2, 3, 4].map(
                                                    (number) =>
                                                        currentPage + number <= pageNumber.length && (
                                                            <li
                                                                key={number}
                                                                className={
                                                                    currentPage + number == currentPage
                                                                        ? "page-item active"
                                                                        : "page-item"
                                                                }
                                                            >
                                                                <a
                                                                    className="page-link"
                                                                    onClick={() => paginate(currentPage + number)}
                                                                >
                                                                    {currentPage + number}
                                                                </a>
                                                            </li>
                                                        )
                                                )}
                                                <li className="page-item">
                                                    <a
                                                        className="page-link"
                                                        onClick={() => paginate(currentPage + 1)}
                                                    >
                                                        Next
                                                    </a>
                                                </li>

                                                <li>
                                                    <div>
                                                        &nbsp; Items per page:{" "}
                                                        <div className="btn-group dropup" style={{ height: "2.4rem" }}>
                                                            <button
                                                                type="button"
                                                                className="btn  btn-sm  btn-primary dropdown-toggle"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                {dataPerPage}
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <a className="dropdown-item" onClick={() => ItemPage(25)}>
                                                                    25
                                                                </a>
                                                                <a className="dropdown-item" onClick={() => ItemPage(50)}>
                                                                    50
                                                                </a>
                                                                <a className="dropdown-item" onClick={() => ItemPage(75)}>
                                                                    75
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </nav>
                        </TabPane>
                    </TabContent>
                </div>
            </section>
        </Loadable>
    );
}

export default IncidentSLA;
