export const get = async () => {
    const response = await fetch(`system`);
    return await response.json();
};

export const refresh = async (hardRefresh) => {

    await fetch(`system`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({"isHardRefresh": hardRefresh})
    });
};

export const ahaAndJiraRefresh = async () => {
    const response = await fetch(`system/ahajira/refresh`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
};

export default
{
    get,
    refresh,
    ahaAndJiraRefresh
}