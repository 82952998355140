import React, { useEffect, useState } from "react";
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import navStyle from "../../components/ObjectiveDashBoard/ObjectiveDashboard.module.css";
import classnames from "classnames";
import DeliveryStatusOnTimeService from "../../services/deliveryStatusOnTimeService";
import { Loadable } from "../../components/Loadable";
import ReportTable from "../../components/DeliveryStatusOnTime/ReportTable";
import ExportExcel from "../../components/ExportExcel";
import moment from "moment";

const DeliveryReportTab = 1;
const ProgressDataTab = 2;
const RoadmapObjectiveMappingDataTab = 3;

function DeliveryStatusOnTimeData() {
  const [activeTab, setActiveTab] = useState(DeliveryReportTab);
  const [loaded, setloaded] = useState(false);
  const [deliveryReport, setDeliveryReport] = useState([]);
  const [storyProgessionReport, setStoryProgessionReport] = useState([]);
  const [roadmapObjectiveMappingReport, setRoadmapObjectiveMappingReport] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setloaded(false);
    let reportData = await DeliveryStatusOnTimeService.GetReports();
    setDeliveryReport(reportData.deliveryStatusReports);
    setStoryProgessionReport(reportData.progressionDatas);
    setRoadmapObjectiveMappingReport(reportData.roadmapFeaturesReports); 
    setloaded(true);
  };

  return (
    <div>
      <div style={{ textAlign: "right" }}>
        <ExportExcel
          sheetData={deliveryReport}
          sheetData2={storyProgessionReport}
          sheetData3={roadmapObjectiveMappingReport}
          sheetName="Delivery Report"
          sheetName2="Story Progression Data"
          sheetName3="Roadmap Objective Mapping"
          fileName={
            "DeliveryStatusReport_" +
            moment(new Date()).format("MM-DD-YYYY-hh-mm-A")
          }
          buttonName="Export"
        />
      </div>
      <div>
        <Nav tabs>
          <NavItem cssModule={navStyle} className={navStyle.navstyle}>
            <NavLink
              className={classnames({
                active: activeTab === DeliveryReportTab,
              })}
              onClick={() => {
                setActiveTab(DeliveryReportTab);
              }}
            >
              Delivery Report
            </NavLink>
          </NavItem>
          <NavItem cssModule={navStyle} className={navStyle.navstyle}>
            <NavLink
              className={classnames({
                active: activeTab === ProgressDataTab,
              })}
              onClick={() => {
                setActiveTab(ProgressDataTab);
              }}
            >
              Story Progression Data
            </NavLink>
          </NavItem>
          <NavItem cssModule={navStyle} className={navStyle.navstyle}>
            <NavLink
              className={classnames({
                active: activeTab === RoadmapObjectiveMappingDataTab,
              })}
              onClick={() => {
                setActiveTab(RoadmapObjectiveMappingDataTab);
              }}
            >
              Roadmap Objective Mapping
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div>
        &nbsp;
        <Loadable loaded={loaded}>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={DeliveryReportTab}>
              <ReportTable data={deliveryReport} />
            </TabPane>
            <TabPane tabId={ProgressDataTab}>
              <ReportTable data={storyProgessionReport} />
            </TabPane>
            <TabPane tabId={RoadmapObjectiveMappingDataTab}>
              <ReportTable data={roadmapObjectiveMappingReport} />
            </TabPane>
          </TabContent>
        </Loadable>
        {
          !loaded && <center>
            <small >This may take a while, please wait…</small>
          </center>
        }

      </div>
    </div>
  );
}

export default DeliveryStatusOnTimeData;
