

export const getIncidentSlaData = async () => {
    const response = await fetch(`/serviceNow/GetIncidentSlaData`);
    return await response.json();
}

export const storeIncidentSlaData = async () => {
    await fetch('/serviceNow/StoreIncidentSlaData', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export default {
    getIncidentSlaData,
    storeIncidentSlaData
}