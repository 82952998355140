import React ,{useEffect, useState}from 'react';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import { Col, Row } from 'reactstrap';
import departmentService from '../../services/departmentService';
import Conditional from '../Conditional';
import Style from './CapexMonthlyData.module.css';
function CapexMonthlyData(props) {

    
    const[dataPerPage,setDataPerPage]=useState(25);
    const [currentPage,setCurrentPage]=useState(1);
    const [missingHourstoggle,setmissingHourstoggle]=useState(false);
    const[hideColToggle,setHideColToggle]=useState(true);
    const [myDepartmentToggle, setMyDepartmentToggle] = useState(false);
    const [childDepartment,setChildDepartment]=useState([]);
    const lastIndexofdata= currentPage*dataPerPage;
    const firstIndexofdata=lastIndexofdata-dataPerPage;
    const[filterData,setfilterData]=useState([... props.filterData]);
    const currentData = filterData.slice(firstIndexofdata,lastIndexofdata);
  
    useEffect(()=>{
        async function fetchData() {
        let childDepartment= await departmentService.getChildDepartment(props.claims.rawClaims.DepartmentId);
        setChildDepartment(childDepartment);}

        fetchData();
    },[]);
    useEffect(()=>{
        Filters();
    },[props.filterData]);

    useEffect(()=>{
        Filters();
    },[missingHourstoggle]);

    useEffect(()=>{
        Filters();
    },[myDepartmentToggle]);
   
    let pageNumber=Array.from(new Array(Math.ceil(filterData.length/dataPerPage)),(val,index)=> index+1)

    const Filters=()=>{
        if(myDepartmentToggle==true && missingHourstoggle==true){
           let data= props.filterData.filter(data=> data.missingHours!=0);
           data=data.filter(data=>data.department==props.claims.rawClaims.DepartmentName)
           let childDepts=[];
           if(childDepartment.length>0){
             childDepartment.forEach((dept) => {
               let data = props.filterData.filter(
                 (data) => data.department == dept.name
               );
               childDepts.push(data);
             });
           }
           let filterdata=(childDepts.length>0)?data.concat(childDepts):data;
          setfilterData([... filterdata]);
        }
        else if(missingHourstoggle==true && myDepartmentToggle==false )
            setfilterData(props.filterData.filter(data=> data.missingHours!=0));
        else if(myDepartmentToggle==true &&  missingHourstoggle==false )
            { let data=props.filterData.filter(data=>data.department==props.claims.rawClaims.DepartmentName);
               let childDepts=[];
                if(childDepartment.length>0){
                  childDepartment.forEach(dept=> {
                   let data=props.filterData.filter(data=>data.department==dept.name)
                    childDepts.push(... data);
                  });
                }
              let filterdata=(childDepts.length>0)?data.concat(childDepts):data;
              setfilterData(filterdata)
            }       
        else if(myDepartmentToggle==false && missingHourstoggle==false )
            setfilterData([... props.filterData]);

        setCurrentPage(1);   
    }

    const ItemPage=(number)=>{
        setDataPerPage(number);
        setCurrentPage(1);
    }
   
    //change page
    const paginate = currentPage => {if(currentPage>=1 && currentPage<=pageNumber.length)setCurrentPage(currentPage);}
         return (
             <div>
                 <div>
                  <Row style={{ marginInlineStart: '3px' }}>
                      <Conditional if={props.claims.isAdmin()}>
                      <Col sm='0.5' >
                     <Toggle
                      defaultChecked={myDepartmentToggle}
                      onChange={()=>setMyDepartmentToggle(!myDepartmentToggle)}
                     />
                        &nbsp; <p style={{ marginTop:'-31px', marginLeft:'56px' }}>Show My Department</p>
                     </Col>
                     </Conditional>
                     &nbsp;
                     <Col sm='0.5'>
                         <div>
                        <Toggle
                        defaultChecked={missingHourstoggle}
                        onChange={()=> setmissingHourstoggle(!missingHourstoggle)} />
                        <p style={{ marginTop:'-31px', marginLeft:'56px' }}> Missing Hours</p>
                        </div>
                     </Col>
                     &nbsp;
                     <Col sm='0.5'>
                         <div>
                        <Toggle
                        defaultChecked={hideColToggle}
                        onChange={()=> setHideColToggle(!hideColToggle)} />
                        <p style={{ marginTop:'-31px', marginLeft:'56px' }}> Hide Columns</p>
                        </div>
                     </Col>
                  </Row> 
                 </div>
                 <div>
                 <table  className="table table-striped table-responsive" align="center" >
                 <thead>
                     <tr>
                         <th className={Style.freeze}>Employee F/L</th>
                         <th >Vendor</th>
                         <th >Department</th>
                         <th >Type</th>
                         <Conditional if={hideColToggle==false}>
                            <th>Location</th>
                            <th>Group Location</th>
                            <th>Position</th>
                            <th>RoleName</th>
                            <th>OnSolveEmail</th>
                            <th>FileNumber</th>
                         </Conditional>
                         <th>Manager</th>
                         <th>Maintaining Existing Products Total</th>
                         <th>Maintaining Existing Products Percent</th>
                         <th>Enhancing Existing Products Percent	</th>
                         <th>Enhancing Existing Products Percent</th>
                         <th>New Product Development Total</th>
                         <th>New Product Development Percent</th>
                         <th>R&D Total </th>
                         <th>R&D Percent</th>
                         <th>Management Total</th>
                         <th>Management Percent</th>
                         <th>Unapplied Total	</th>
                         <th>Unapplied Percent</th>
                         <th>Expensed Hours</th>
                         <th>Capitalized Hours</th>
                         <th>Total</th>
                         <th>Hours Should Have Logged</th>
                         <th>Missing Hours</th>
                         <th>Capex Pct</th>
                         
                         
                         
                     </tr>
                 </thead>
                 <tbody>
                     { (currentData.length!=0) ? 
                         currentData.map((data,index)=>
                             <tr key={index} >
                                 <td className={Style.freeze}>{data.employeeFL}</td>
                                 <td >{data.vendor}</td>
                                 <td>{data.department}</td>
                                 <td>{data.type}</td>                            
               
                                 <Conditional if={hideColToggle==false}>
                                 <td>{data.location}</td>
                                 <td>{data.groupLocation}</td>
                                 <td>{data.position}</td>
                                 <td>{data.roleName}</td>
                                 <td>{data.onSolveEmail}</td>
                                 <td>{data.fileNumber}</td>
                                 </Conditional>
                                 <td>{data.manager}</td>
                                 <td>{data.maintainingExistingProductsTotal}</td>
                                 <td>{data.maintainingExistingProductsPercent}</td>
                                 <td>{data.enhancingExistingProductsTotal}</td>
                                 <td>{data.enhancingExistingProductPercent}</td>
                                 <td>{data.newProductDevelopmentTotal}</td>
                                 <td>{data.newProductDevelopmentPercent}</td>
                                 <td>{data.rAndDTotal}</td>
                                 <td>{data.rAndDPercent}</td>
                                 <td>{data.managementTotal}</td>
                                 <td>{data.managementPercent}</td>
                                 <td>{data.unappliedTotal}</td>
                                 <td>{data.unappliedPercent}</td>
                                 <td>{data.expensedHours}</td>
                                 <td>{data.capitalizedHours}</td>
                                 <td>{data.total}</td>
                                 <td>{data.hoursShouldHaveLogged}</td>
                                 <td>{data.missingHours}</td>
                                 <td>{data.capexPercent}</td>
                                 
                             </tr>
                        ):
                        <tr><td colSpan='31'>NO DATA TO SHOW</td></tr>
                        
                     }
                     </tbody>
                  </table>
                 </div>
                 
                   <nav aria-label="Page navigation example">
                         <ul className="pagination justify-content-center">
                           <li className="page-item"><a className="page-link" onClick={()=> paginate(currentPage-1)}>Previous</a></li>
                             {
                                 pageNumber.map(number=>(
                                     
                                     <li key={number} className={currentPage==number?"page-item active":"page-item"}>
                                         <a  className="page-link" onClick={()=> paginate(number)}>{number}</a>
                                     </li>
                                 ))
                             }
                            <li className="page-item"><a className="page-link" onClick={()=> paginate(currentPage+1)}>Next</a></li>
 
                             <li>
                                
                                 <div>
                                   &nbsp;  Items per page:
                                     <div className="btn-group dropup">
                                     &nbsp;   <button type="button" className="btn  btn-sm  btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {dataPerPage}
                                         </button>
                                         <div className="dropdown-menu">
                                         <a className="dropdown-item" onClick={()=> ItemPage(25)}>25</a>
                                         <a className="dropdown-item" onClick={()=> ItemPage(50)}>50</a>
                                         <a className="dropdown-item" onClick={()=> ItemPage(75)}>75</a>
                                         </div>
                                     </div>
                                 </div>
                             </li>
                         </ul>
                         
                 </nav>
                 
             </div>
         );
}

export default CapexMonthlyData;