import React, { useEffect, useState } from "react";
import Style from "../capex/CapexMonthlyData.module.css";
import DevOpsStyle from "./DevOpsDashboard.module.css"

function MRTicketsLinkedTable(props) {
  const linkeddata = props.linkedTicket;
  //pagination
  const [dataPerPage, setDataPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const lastIndexofdata = currentPage * dataPerPage;
  const firstIndexofdata = lastIndexofdata - dataPerPage;
  const currentData = linkeddata.slice(firstIndexofdata, lastIndexofdata);

  useEffect(()=>{
    setCurrentPage(1);   
  },[linkeddata])
  const ItemPage = (number) => {
    setDataPerPage(number);
    setCurrentPage(1);
  };
  const pageNumber = Array.from(
    new Array(Math.ceil(linkeddata.length / dataPerPage)),
    (val, index) => index + 1
  );

  //change page
  const paginate = (currentPage) => {
    if (currentPage >= 1 && currentPage <= pageNumber.length)
      setCurrentPage(currentPage);
  };

  return (
    <div className={DevOpsStyle.tableFont}>
      <div className="table-responsive">
        <table
          className="table table-striped"
          align="center"
        >
          <thead>
            <tr>
              <th className={Style.freeze}>Merge Request Key</th>
              <th>Merge Request Type </th>
              <th>Merge Request Status</th>
              <th>Merge Summary </th>
              <th>Merge Request Reporter </th>
              <th className={Style.freezeReleaseNotesKey}>Release Notes Key</th>
              <th>Release Notes Type</th>
              <th>Release Notes Status</th>
              <th>Release Notes Summary </th>
              <th>Release Notes Reporter </th>
              <th className={Style.freezeReleaseItemKey}>Release Item Key </th>
              <th>Release Item IssueType </th>
              <th>Release Item Status </th>
              <th>Release Item Priority </th>
              <th>Release Item Project </th>
              <th>Release Item Summary </th>
              <th>Release Item TeamName </th>
              <th>Release Item Reporter </th>
              <th>Release Item EpicLink </th>
              <th>Epic Link Status </th>
              <th>Epic Link Summary </th>
              <th>Epic Link Reporter </th>
            </tr>
          </thead>
          <tbody>
            {currentData.length != 0 ? (
              currentData.map((data, index) => (
                <tr key={index}>
                  <td className={Style.freeze}>
                    <a
                      href={
                        "https://onsolve.atlassian.net/browse/" +
                        data.mergeRequestKey
                      }
                      target="_blank"
                    >
                      {data.mergeRequestKey}
                    </a>
                  </td>
                  <td>{data.mergeRequestType}</td>
                  <td>{data.mergeRequestStatus}</td>
                  <td>{data.mergeRequestSummary}</td>
                  <td>{data.mergeRequestReporter}</td>
                  <td className={Style.freezeReleaseNotesKey}>
                    <a
                      href={
                        "https://onsolve.atlassian.net/browse/" +
                        data.releaseNotesKey
                      }
                      target="_blank"
                    >
                      {data.releaseNotesKey}
                    </a>
                  </td>
                  <td>{data.releaseNotesType}</td>
                  <td>{data.releaseNotesStatus}</td>
                  <td>{data.releaseNotesSummary}</td>
                  <td>{data.releaseNotesReporter}</td>
                  <td className={Style.freezeReleaseItemKey}>
                    <a
                      href={
                        "https://onsolve.atlassian.net/browse/" +
                        data.releaseItemKey
                      }
                      target="_blank"
                    >
                      {data.releaseItemKey}
                    </a>
                  </td>
                  <td>{data.releaseItemIssueType}</td>
                  <td>{data.releaseItemStatus}</td>
                  <td>{data.releaseItemPriority}</td>
                  <td>{data.releaseItemProject}</td>
                  <td>{data.releaseItemSummary}</td>
                  <td>{data.releaseItemTeamName}</td>
                  <td>{data.releaseItemReporter}</td>
                  <td>{data.releaseItemEpicLink}</td>
                  <td>{data.epicLinkStatus}</td>
                  <td>{data.epicLinkSummary}</td>
                  <td>{data.epicLinkReporter}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="22">No Ticket is Linked</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </a>
            </li>
            {pageNumber.map((number) => (
              <li
                key={number}
                className={
                  currentPage == number ? "page-item active" : "page-item"
                }
              >
                <a className="page-link" onClick={() => paginate(number)}>
                  {number}
                </a>
              </li>
            ))}
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </a>
            </li>

            <li>
              <div>
                &nbsp; Items per page:
                <div className="btn-group dropup">
                  &nbsp;{" "}
                  <button
                    type="button"
                    className="btn  btn-sm  btn-secondary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {dataPerPage}
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" onClick={() => ItemPage(25)}>
                      25
                    </a>
                    <a className="dropdown-item" onClick={() => ItemPage(50)}>
                      50
                    </a>
                    <a className="dropdown-item" onClick={() => ItemPage(75)}>
                      75
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default MRTicketsLinkedTable;
