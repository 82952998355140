import React from "react";

function Pagination(props) {
  const paginate = props.paginate;
  const pageNumber = props.pageNumber;
  const currentPage = props.currentPage;
  const ItemPage = props.ItemPage;
  const dataPerPage = props.dataPerPage;
  return (
    <div>
      <div style={{ marginTop: "5px" }}>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </a>
            </li>
            {[0, 1, 2, 3, 4].map(
              (number) =>
                currentPage + number <= pageNumber.length && (
                  <li
                    key={number}
                    className={
                      currentPage + number == currentPage
                        ? "page-item active"
                        : "page-item"
                    }
                  >
                    <a
                      className="page-link"
                      onClick={() => paginate(currentPage + number)}
                    >
                      {currentPage + number}
                    </a>
                  </li>
                )
            )}
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </a>
            </li>

            <li>
              <div>
                &nbsp; Items per page:{" "}
                <div className="btn-group dropup" style={{ height: "2.4rem" }}>
                  <button
                    type="button"
                    className="btn  btn-sm  btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {dataPerPage}
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" onClick={() => ItemPage(25)}>
                      25
                    </a>
                    <a className="dropdown-item" onClick={() => ItemPage(50)}>
                      50
                    </a>
                    <a className="dropdown-item" onClick={() => ItemPage(75)}>
                      75
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Pagination;
