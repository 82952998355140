export const getFlashReportRoadmapDelivery = async (date) => {
    var originalDate = new Date(date);
    var year = originalDate.getFullYear();
    var month = ('0' + (originalDate.getMonth() + 1)).slice(-2);
    var day = ('0' + originalDate.getDate()).slice(-2);
    var formattedDate = year + '-' + month + '-' + day;
  const response = await fetch(
      `FlashReport/FlashReportRoadmapDelivery?reportDate=${formattedDate}`);
  return await response.json();
};

export const getFlashReportRoadmapProgress = async (date) => {
    var originalDate = new Date(date);
    var year = originalDate.getFullYear();
    var month = ('0' + (originalDate.getMonth() + 1)).slice(-2);
    var day = ('0' + originalDate.getDate()).slice(-2);
    var formattedDate = year + '-' + month + '-' + day;
    const response = await fetch(
        `FlashReport/FlashReportRoadmapProgress?reportDate=${formattedDate}`);
  return await response.json();
};

export const getFlashReportReleaseProgress = async (date) => {
    var originalDate = new Date(date);
    var year = originalDate.getFullYear();
    var month = ('0' + (originalDate.getMonth() + 1)).slice(-2);
    var day = ('0' + originalDate.getDate()).slice(-2);
    var formattedDate = year + '-' + month + '-' + day;
    const response = await fetch(
        `FlashReport/FlashReportReleaseProgress?reportDate=${formattedDate}`);
  return await response.json();
};

export const getFlashReportCustomerAndSecurityDefect = async (date) => {
    var originalDate = new Date(date);
    var year = originalDate.getFullYear();
    var month = ('0' + (originalDate.getMonth() + 1)).slice(-2);
    var day = ('0' + originalDate.getDate()).slice(-2);
    var formattedDate = year + '-' + month + '-' + day;
    const response = await fetch(
        `FlashReport/FlashReportCustomerAndSecurityDefect?reportDate=${formattedDate}`);
  return await response.json();
};

export const getFlashReportScopeChange = async (date) => {
    var originalDate = new Date(date);
    var year = originalDate.getFullYear();
    var month = ('0' + (originalDate.getMonth() + 1)).slice(-2);
    var day = ('0' + originalDate.getDate()).slice(-2);
    var formattedDate = year + '-' + month + '-' + day;
    const response = await fetch(
        `FlashReport/FlashReportScopeChange?reportDate=${formattedDate}`);
  return await response.json();
};

export default {
  getFlashReportRoadmapDelivery,
  getFlashReportRoadmapProgress,
  getFlashReportReleaseProgress,
  getFlashReportCustomerAndSecurityDefect,
  getFlashReportScopeChange
}