export const getNotes = async (sprintId, teamId) => {
    const response = await fetch(`sprints/${sprintId}/notes/${teamId}`);
    return await response.json();
};

export const setNotes = async (sprintId, teamId, section, note) => {
    const response = await fetch(`sprints/${sprintId}/notes/${teamId}/${section}`, {
        method: 'Post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(note)
    });

    return await response.json();
};

export default {
    getNotes,
    setNotes
}