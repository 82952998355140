import React, { Component } from 'react';
import memberService from '../../services/memberService';
import { DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, Button, ButtonDropdown } from 'reactstrap';
import Conditional from '../../components/Conditional';
import vendorService from '../../services/vendorService';
import common from '../../components/common';
import { SearchDropDown } from '../../components/SearchDropDown';

import teamService from '../../services/teamService';
import { ManageTeamEventsModal } from '../../components/teams/ManageTeamEventsModal';

export class ModifyTeam extends Component {
  static displayName = ModifyTeam.name;

  constructor(props) {
    super(props);
    this.state = {
      team: {
        id: 0,
        name: "",
        rapidBoardId: 0,
        //MAN-136 Commenting HasBackLog
        //hasBacklog: true,
        gitPrimeId: 0,
        groupId: 1,
        members: [],
        primaryVendorId: 1,
        targetVelocity: 0,
        teamCount: 0
      },
       loading: true,
            selectedMember: null,
            loadingMembers: true,
            searchMembers: [],
            selectedVendor: {},
            showTeamEvent: false
        };

      this.sizeDecimalCount = 2;
      this.setFloatingPoint = this.setFloatingPoint.bind(this);
      this.handleInputChange = common.createInputHandler(this, 'team');
      this.changeVendor = common.createSearchDropDownHandelChange(this, 'team', 'selectedVendor', 'primaryVendorId');
    }

  componentDidMount() {
    this.reload();
  }

  reload = async () => {
    var teamId = this.props.match.params.teamId;

    if (teamId != null) {
      this.populateTeam(teamId);
    } else {
      this.setState({ loading: false });
    }
  };

  async populateTeam(teamId) {
    const data = await teamService.get(teamId);
    this.setState({ team: data, loading: false });
  }

  handleSubmit = async () => {
    const response = await fetch(`team`, {
      method: this.state.team.id === 0 ? 'POST' : 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.team)
    });
    const data = await response.json();

    this.props.history.push('/teams');
  };

  searchMembers = async event => {
    const target = event.target;
    const value = target.value;

    this.setState({ loadingMembers: true, memberNeedle: value });

    var searchMembers = await memberService.search(value);

    this.setState({ searchMembers: searchMembers, loadingMembers: false });
  };

  selectMember = searchMember => {
    var selectedMember = { ...this.state.selectedMember };

    selectedMember.id = searchMember.id;
    selectedMember.preferredName = searchMember.preferredName;
    selectedMember.azureObjectId = searchMember.azureObjectId;

    this.setState({ selectedMember });
  };

  addMember = () => {
    var selectedMember = { ...this.state.selectedMember };
    var team = { ...this.state.team };

    team.members.push(selectedMember);

    this.setState({ team: team, selectedMember: null });
  };

  removeMember = toRemove => {
    var team = { ...this.state.team };

    team.members = team.members.filter(member => member !== toRemove);

    this.setState({ team: team });
  };

  toggle = event => {
    const target = event.target;

    if (target.name !== 'memberSearch') {
      this.setState({ isDropDownOpen: !this.state.isDropDownOpen });
    }
  };

  //MAN-136 Commenting HasBackLog
  //toggleBacklog = () => {
  //  var team = { ...this.state.team };  
  //  team.hasBacklog = !team.hasBacklog;
  //  this.setState({ team: team });
  //};

  showMangeEvents = () => {
    this.setState({ showTeamEvent: true });
  };

  close = async () => {
    this.setState({ loaded: false });
    await this.reload();
    this.setState({ showTeamEvent: false });
  };

  setFloatingPoint(e) {    
    e.target.value = parseFloat(e.target.value).toFixed(this.sizeDecimalCount);
    e.target.type = "number";
    this.handleInputChange(e);
  }

  render() {
    return (
      <div>
        <table className='table table-striped' aria-labelledby='tabelLabel'>
          <tbody>
            <tr>
              <td>Name</td>
              <td>
                <input
                  name="name"
                  type="text"
                  value={this.state.team.name}
                  onChange={this.handleInputChange}
                />
              </td>
            </tr>
                  {
                        //MAN-136 Commenting HasBackLog
                        /*<tr>
                        <td>Has Backlog</td>
                        <td>
                            <ButtonGroup>
                                <Button
                                    outline={this.state.team.hasBacklog}
                                    color="secondary"
                                    onClick={this.toggleBacklog}
                                >
                                    Off
                  </Button>
                                <Button
                                    outline={this.state.team.hasBacklog === false}
                                    color="primary"
                                    onClick={this.toggleBacklog}
                                >
                                    On
                  </Button>
                            </ButtonGroup>
                        </td>
                    </tr>*/
                    }
            <tr>
              <td>RapidBoardId</td>
              <td>
                <input
                  name="rapidBoardId"
                  type="number"
                  value={this.state.team.rapidBoardId}
                  onChange={this.handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td>GitPrimeId</td>
              <td>
                <input
                  name="gitPrimeId"
                  type="number"
                  value={this.state.team.gitPrimeId}
                  onChange={this.handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td>Workstream Id</td>
              <td>
                <input
                  name="workstreamId"
                  type="number"
                  value={this.state.team.workstreamId}
                  onChange={this.handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td>Confluence Team Page Url</td>
              <td>
                <input
                  name="confluencePageUrl"
                  type="text"
                  value={this.state.team.confluencePageUrl || ''}
                  onChange={this.handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td>Team Slack Channel Url</td>
              <td>
                <input
                  name="slackChannelUrl"
                  type="text"
                  value={this.state.team.slackChannelUrl || ''}
                  onChange={this.handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td>Target Velocity</td>
              <td>
                <input
                  name="targetVelocity"
                  type="number"
                  value={this.state.team.targetVelocity}
                  onChange={this.handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td>Team Size</td>
              <td>
                <input
                  name="teamCount"
                  type="textbox"
                  value={this.state.team.teamCount}
                  onChange={this.handleInputChange}
                  onBlur={this.setFloatingPoint}
                />
              </td>
            </tr>
            <tr>
              <td>Team Events</td>
              <td>
                <Button
                  onClick={this.showMangeEvents}
                  outline={true}
                  color="primary"
                >
                  Manage Events
                </Button>
              </td>
            </tr>
            <tr>
              <td>Primary Vendor</td>
              <td>
                <SearchDropDown
                  onSelection={this.changeVendor}
                  display={x => `${x.name} | ${x.employmentClass}`}
                  title="Select Vendor"
                  initialSet={vendorService.getAll}
                  initialSelectionId={this.state.team.primaryVendorId}
                />
              </td>
            </tr>
            <tr>
              <td>Add Member</td>
              <td>
                <ButtonGroup>
                  <ButtonDropdown
                    isOpen={this.state.isDropDownOpen}
                    toggle={this.toggle}
                  >
                    <DropdownToggle caret color="primary">
                      {this.state.selectedMember == null
                        ? "Select Member"
                        : this.state.selectedMember.preferredName}
                    </DropdownToggle>
                    <DropdownMenu left>
                      <DropdownItem header>Name</DropdownItem>
                      <DropdownItem>
                        <input
                          name="memberSearch"
                          type="text"
                          value={this.state.memberNeedle}
                          onChange={this.searchMembers}
                        />
                      </DropdownItem>
                      <DropdownItem divider />
                      <Conditional if={this.state.loadingMembers}>
                        <div className='alert alert-secondary' role='alert'>
                          <p>
                            <em>Searching...</em>
                          </p>
                        </div>
                      </Conditional>
                      <Conditional if={this.state.loadingMembers === false}>
                        {this.state.searchMembers.map(member => (
                          <DropdownItem
                            onClick={() => this.selectMember(member)}
                          >
                            {member.preferredName}
                          </DropdownItem>
                        ))}
                      </Conditional>
                    </DropdownMenu>
                  </ButtonDropdown>
                  <Button
                    color={
                      this.state.selectedMember == null
                        ? "secondary"
                        : "success"
                    }
                    onClick={this.addMember}
                    disabled={this.state.selectedMember == null}
                  >
                    Add
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
            <tr>
              <td>Members</td>
              <td>
                <ul>
                  {this.state.team.members.map(member =>
                       <li key={member.id}>{member.preferredName} <Button color="danger" onClick={() => this.removeMember(member)}>Remove</Button></li>
                  )}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <button className='btn btn-primary' onClick={this.handleSubmit}>
          Save Team
        </button>
        <ManageTeamEventsModal
          team={this.state.team}
          isOpen={this.state.showTeamEvent}
          close={this.close}
          refresh={this.reload}
        />
      </div>
    );
  }
}
