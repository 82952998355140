export const getAll = async () => {
    const response = await fetch('team');
    return await response.json();
};

export const getMy = async () => {
    const response = await fetch('team/my');
    return await response.json();
};

export const get = async (teamId) => {
    const response = await fetch(`team/${teamId}`);
    return await response.json();
};

export const getTeamComposition = async () => {
    const response = await fetch('team/composition');
    return await response.json();
};

export const checkTeamBoard = async (teamId) => {
    const response = await fetch(`team/${teamId}/check-board`);
    return await response.json();
}

export const moveMember = async (fromTeam, toTeam, member, note) => {

    await fetch(`teamMember/move`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            from: fromTeam,
            to: toTeam,
            memberId: member,
            note: note
        })
    });
};

export const addMember = async (toTeam, member, note) => {

    await fetch(`team/${toTeam}/members`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            to: toTeam,
            memberId: member,
            note: note
        })
    });
};

export const removeMember = async (fromTeam, member, note) => {

    await fetch(`team/${fromTeam}/members/${member}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            from: fromTeam,
            memberId: member,
            note: note
        })
    });
};

export const removeMemberWithResponse = async (fromTeam, member, note) => {
    var response = await fetch(`team/${fromTeam}/members/${member}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                from: fromTeam,
                memberId: member,
                note: note
            })
        })
        .then((res) => {
            if (res.status !== 200) throw new Error(res.status);
            else return res;
        })
        .catch((error) => {
            return "error";
        });

    return response;
};

export const mergeTeamEvent = async (teamId, e) => {
    return await fetch(`team/${teamId}/events`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(e)
    });
}

export const deleteTeamEvent = async (teamId, e) => {
    return await fetch(`team/${teamId}/events`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(e)
    });
}

export default {
    getAll,
    getMy,
    get,
    getTeamComposition,
    moveMember,
    addMember,
    removeMember,
    removeMemberWithResponse,
    mergeTeamEvent,
    deleteTeamEvent,
    checkTeamBoard
};
