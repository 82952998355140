export const getCapexHistoricReport= async (currentUser)=>{
    const response = await fetch(`CapexDashboard/GetCapexHistoricMonths`);
    return await response.json();
}

export const getCapexData= async ()=>{
    const response = await fetch(`CapexDashboard/GetCapexData`);
    return await response.json();
}
export const getCapexDataByMonth= async (month,year)=>{
    const response = await fetch(`CapexDashboard/GetCapexDataByMonth?month=${month}&year=${year}`);
    return await response.json();
}
export const getCapexGroupData= async (month, year)=>{
    const response = await fetch(`CapexDashboard/GetTotalReport?month=${month}&year=${year}`);
    return await response.json();
}

export const postHistoricDate= async (month, year, closedBy)=>{
    var object={
        month: month,
        year: year,
        closedBy: closedBy
    };

    await fetch(`CapexDashboard/MarkHistoric`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            month: month,
            year: year,
            closedBy: closedBy
        })
    });
}

export default {
    getCapexHistoricReport,
    getCapexData,
    getCapexGroupData,
    getCapexDataByMonth,
    postHistoricDate
}