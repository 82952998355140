export const getAll = async () => {

    const response = await fetch(`vendor`);
    return await response.json();
};

export const getScorcard = async (start, end) => {
    const response = await fetch(`vendor/scorecard?start=${start}&end=${end}`);
    return await response.json();
};

export const getInvoices = async (vendorId) => {
    const responce = await fetch(`vendor/${vendorId}/invoices`);
    return await responce.json();
}

export default {
    getAll,
    getScorcard,
    getInvoices
};
