export const getAll = async (linkedFilter) => {

    var filter = "";

    if (linkedFilter === true) {
        filter = "?fullyLinked=true";
    }
    if (linkedFilter === false) {
        filter = "?fullyLinked=false";
    }

    const response = await fetch(`member${filter}`);
    return await response.json();
};

export const search = async (term) => {
    const response = await fetch(`member?name=${term}`);
    return await response.json();
};

export const getUnassigned = async () => {
    return [];
};

export default {
    getAll,
    search,
    getUnassigned
};
