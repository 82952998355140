import React, { Component } from 'react';
import officeService from '../../services/officeService';
import { Loadable } from '../Loadable';
import common from '../common';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Col, Input } from 'reactstrap';



export class OfficeHolidayModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            holidays: [],
            office: props.office,
            loading: false
        }
    }

    handleInputChange = (i) => {
        return (event) => {
            var holidays = [ ...this.state.holidays ];
            holidays[i][event.target.name] = common.getValue(event);
            this.setState({ holidays });
        };
    }

    remove = (i) => {
        return () => {
            var holidays = [...this.state.holidays]
            holidays.splice(i, 1);
            this.setState({ holidays });
        };
    }

    addHoliday = () => {
        var holidays = this.state.holidays;
        holidays.push({ id: `holday_${new Date().getTime()}`, on: '', description: '' });
        this.setState(holidays);
    }

    async reload(officeId) {
        var holidays = await officeService.getHoliday(officeId);
        this.setState({ holidays, loading: false });
    }

    componentWillReceiveProps(n) {
        if (this.state.office.id !== n.office.id) {
            this.setState({ office: n.office, loading: true });
            this.reload(n.office.id);
        }
    }

    render() {
        const save = () => this.props.editedHolidays({ canceled: false, value: this.state.holidays });
        const cancel = () => this.props.editedHolidays({ canceled: true });

        return <Modal isOpen={this.props.isOpen}>
            <ModalHeader>Manage {this.state.office.name} Holidays</ModalHeader>
            <ModalBody>
                <Loadable loaded={!this.state.loading}>
                    <Form>
                        <FormGroup row>
                            <Col>Date</Col>
                            <Col>Name</Col>
                            <Col></Col>
                        </FormGroup>
                        {this.state.holidays.map((h, i) =>
                            <FormGroup row key={h.id} >
                                <Col><Input name="on" type="date" value={common.dateFormat(h.on)} onChange={this.handleInputChange(i)}/></Col>
                                <Col><Input name="description" type="text" value={h.description} onChange={this.handleInputChange(i)}/></Col>
                                <Col><Button outline color="danger" onClick={this.remove(i)}>X</Button></Col>
                            </FormGroup>
                        )}
                        <FormGroup row>
                            <Col></Col>
                            <Col></Col>
                            <Col><Button outline color="primary" onClick={this.addHoliday}>Add Holiday</Button></Col>
                        </FormGroup>
                    </Form>
                </Loadable>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" onClick={save} >Save</Button>
                <Button outline color="danger" onClick={cancel} >Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}