export const GetMRTicket= async (currrntKey)=>{
    const response = await fetch(`DevOpsMRDashboard/GetJiraTicket?key=${currrntKey}`);
    return await response.json();
}

export const GetLinkedTicket= async (currrntKey)=>{
    const response = await fetch(`DevOpsMRDashboard/GetLinkedTicket?key=${currrntKey}`);
    return await response.json();
}
export const GetIncorrectReleaseNoteTicket= async (currrntKey)=>{
    const response = await fetch(`DevOpsMRDashboard/GetIncorrectReleaseNoteTicket?key=${currrntKey}`);
    return await response.json();
}
export const GetIncorrectReleaseItemsTicket= async (currrntKey)=>{
    const response = await fetch(`DevOpsMRDashboard/GetIncorrectReleaseItemsTicket?key=${currrntKey}`);
    return await response.json();
}
export default{
    GetMRTicket,
    GetLinkedTicket,
    GetIncorrectReleaseNoteTicket,
    GetIncorrectReleaseItemsTicket
}