import React, { Component } from 'react';
import { Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, FormText } from 'reactstrap';
import teamService from '../../services/teamService';

export class RemoveTeamModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editNote: '',
            removeIsValid: false
        };

        this.handleEditNoteChange = this.handleEditNoteChange.bind(this);
        this.updateValidity = this.updateValidity.bind(this);
        this.setRemoveValidity = this.setRemoveValidity.bind(this);
    }

    handleEditNoteChange(event) {
        const editNote = event.target.value;
        this.setState({ editNote: editNote }, () => this.updateValidity());
    }

    updateValidity() {
        this.setRemoveValidity();
    }

    setRemoveValidity() {
        const removeIsValid = this.state.editNote !== ''
            && this.props.member !== null && this.props.team !== null

        this.setState({ removeIsValid });
    }

    async removeMember(team, member, editNote) {
        
        let ans = window.confirm("Are you sure you want to delete this team association?");
        if (ans === true) {
          var res = await teamService.removeMemberWithResponse(team.id, member.id, editNote);
          if (res.ok) 
          {
              alert("Team association removed successfully");
              this.props.close(true, team);
          }
          else alert("Something went wrong");
        }
    }

    render() {
        return (<Modal size="lg" isOpen={this.props.isOpen} toggle={() => this.props.close(false, null)}>
            <ModalHeader toggle={() => this.props.close(false, null)}>Remove Team Membership</ModalHeader>
            <ModalBody>
                <Row className="edit-team-modal-note">
                    <Col lg="12">
                        <Label for="editNote">Edit Note</Label>
                        <Input
                            invalid={this.state.editNote === ''}
                            type="textarea"
                            name="editNote"
                            id="editNote"
                            placeholder="Explaination of change..."
                            onChange={this.handleEditNoteChange}
                            value={this.state.editNote} />
                        <FormFeedback tooltip>Note is required</FormFeedback>
                        <FormText>Please fill out reason for this change</FormText>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ size: 2, offset: 1 }}>
                        Member:  {this.props.member.preferredName}
                    </Col>
                    <Col lg={{ size: 2, offset: 1 }}>
                        Team:  {this.props.team.name}
                    </Col>

                    <Col lg={{ size: 2, offset: 2 }}>
                        <Button color="warning" onClick={() => this.removeMember(this.props.team, this.props.member, this.state.editNote)} disabled={!this.state.removeIsValid}>Commit Remove</Button>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => this.props.close(false, null)}>Close</Button>
            </ModalFooter>
        </Modal>);
    }

}
