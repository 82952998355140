export const getMy = async () => {

    const response = await fetch(`profile/my`);
    return await response.json();
};

export const updateMy = async (profile) => {

    const response = await fetch(`profile/my`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(profile)
    });
    return await response.json();
};

export const getMyPto = async () => {

    const response = await fetch(`profile/my/pto`);
    return await response.json();
};

export const updateMyPto = async (pto) => {
    const response = await fetch(`profile/my/pto`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pto)
    });
    return await response.json();
};

export const deleteMyPto = async (ptoId) => {
    const response = await fetch(`profile/my/pto/${ptoId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return await response.json();
};

export default {
    getMy,
    updateMy,
    getMyPto,
    updateMyPto,
    deleteMyPto
};
