import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import ExportExcel from "../../components/ExportExcel";
import ResultByQueryTable from "../../components/FeatureHealthDashboard/ResultByQueryTable";
import { Loadable } from "../../components/Loadable";
import common from "../../components/common";
import teamService from "../../services/teamService";
import featureHealthDashboardService from "../../services/featureHealthDashboardService";
import UserSelectionDropDown from "../../components/ObjectiveDashBoard/UserSelectionDropDown";
import moment from "moment";
import Style from "./AhaJiraChangeLog.module.css";

const Ascending = "ascending";
const Descending = "descending";

const Option = (props) => {
  return (
    <div>
      {" "}
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        {"  "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();

function FeatureHealthDashboard(props) {
  const [queryResult, setQueryResult] = useState([]);
  const [filteredQueryResult, setFilteredQueryResult] = useState([]);
  const [show, setShow] = useState(false);
  const [sortOrder, setSortOrder] = useState(Ascending);
  const [filterName, setFilterName] = useState();
  const [distinctAttributeValues, setDistinctAttributeValues] =
    useState(queryResult);

  const [podOptions, setPodOptions] = useState([]);
  const [podSelected, setPodSelected] = useState([]);
  const [goalOptions, setGoalOptions] = useState([]);
  const [goalSelected, setGoalSelected] = useState([]);
  const [releaseOptions, setReleaseOptions] = useState([]);
  const [releaseSelected, setReleaseSelected] = useState([]);
  const [featureOptions, setFeatureOptions] = useState([]);
  const [featureSelected, setFeatureSelected] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [teamSelected, setTeamSelected] = useState();
  const [loadedFilter, setloadedFilter] = useState(false);
  const [jiraRefreshDate, setJiraRefreshDate] = useState("");
  const [ahaRefreshDate, setAhaRefreshDate] = useState("");
  const [podLoading, setPodLoading] = useState(false);
  const [goalLoading, setGoalLoading] = useState(false);
  const [featureLoading, setFeatureLoading] = useState(false);
  const [releaseLoading, setReleaseLoading] = useState(false);
  const [teamLoading, setTeamLoading] = useState(false);

  useEffect(() => {
    loadFilters();
  }, []);

  useEffect(() => {
    setFilteredQueryResult(queryResult);
  }, [queryResult]);

  const selectionList = (attribute) => {
    setFilterName(attribute);
    let distinctValue = common.getDistinctValues(
      queryResult,
      attribute,
    );
    setDistinctAttributeValues(distinctValue);
    setShow(true);
  };

  const toggleSortOrder = (property) => {
    if (sortOrder == Ascending) setSortOrder(Descending);
    if (sortOrder == Descending) setSortOrder(Ascending);
    sortData(property, sortOrder);
  };

  const sortData = (property) => {
    let sort_order;
    if (sortOrder == Ascending) sort_order = 1;
    if (sortOrder == Descending) sort_order = -1;
    let sortData = [...filteredQueryResult].sort(
      common.dynamicSort(property, sort_order),
    );
    setFilteredQueryResult(sortData);
  };

  const populateFilterData = async (
    podSelected = undefined,
    goalSelected = undefined,
    releaseSelected = undefined,
    featureSelected = undefined,
    teamSelected = undefined,
  ) => {
    setloadedFilter(false);
    let params = new URLSearchParams({});
    if (podSelected !== undefined) {
      podSelected.map(function (obj) {
        params.append("pods", obj.label);
      });
    }

    if (goalSelected !== undefined) {
      goalSelected.map(function (obj) {
        params.append("goals", obj.label);
      });
    }

    if (releaseSelected !== undefined) {
      releaseSelected.map(function (obj) {
        params.append("releases", obj.label);
      });
    }

    if (featureSelected !== undefined) {
      featureSelected.map(function (obj) {
        params.append("features", obj.label);
      });
    }

    if (teamSelected !== undefined) {
      teamSelected.map(function (obj) {
        params.append("teams", obj.label);
      });
    }

    let data = await featureHealthDashboardService.GetList(params);
    if (data !== null) {
      setQueryResult(data);
    }
    setloadedFilter(true);
  };

  const loadFilters = async () => {
    populateFilterData();
    loadPodOptions();
    loadGoalOptions();
    loadReleaseOptions();
    loadFeatureOptions();
    loadTeamOptions();

    let jiraResponseDate = await fetch(`ProductDashboard/GetDataUpdateDate`);
    let jiradateString = await jiraResponseDate.text();
    let jiradate = new Date(jiradateString + " UTC");
    setJiraRefreshDate(jiradate.toLocaleString());

    let ahaResponseDate = await fetch(`ProductDashboard/GetAhaDataUpdateDate`);
    let ahaDateString = await ahaResponseDate.text();
    let ahadate = new Date(ahaDateString + " UTC");
    setAhaRefreshDate(ahadate.toLocaleString());

  };

  const loadPodOptions = async () => {
    setPodLoading(true);
    const podOptionsData = await featureHealthDashboardService.GetPods();
    if (podOptionsData !== null) {
      const podOptions = podOptionsData.map(function (obj) {
        return { value: obj.id, label: obj.podName };
      });
      setPodOptions(podOptions);
    }
    setPodLoading(false);
  };

  const loadGoalOptions = async () => {
    setGoalLoading(true);
    const podObjectiveOptions = await featureHealthDashboardService.GetGoals();
    const objData = podObjectiveOptions.map(function (obj) {
      return { value: obj.id, label: obj.goalName };
    });

    setGoalOptions(objData);
    setGoalLoading(false);
  };

  const loadFeatureOptions = async () => {
    setFeatureLoading(true);
    const featureOptions = await featureHealthDashboardService.GetAhaFeatures();
    const objData = featureOptions.map(function (obj) {
      return { value: obj.id, label: obj.name };
    });

    setFeatureOptions(objData);
    setFeatureLoading(false);
  };

  const loadReleaseOptions = async () => {
    setReleaseLoading(true);
    const releaseOptions = await featureHealthDashboardService.GetAhaReleases();
    const objData = releaseOptions.map(function (obj) {
      return { value: obj.id, label: obj.name };
    });

    setReleaseOptions(objData);
    setReleaseLoading(false);
  };

  const loadTeamOptions = async () => {
    setTeamLoading(true);
    const teamOptionsData = await teamService.getAll();
    if (teamOptionsData !== null) {
      const teamOptions = teamOptionsData.map(function (obj) {
        return { value: obj.id, label: obj.name };
      });
      setTeamOptions(teamOptions);
    }
    setTeamLoading(false);
  };

  const handlePodChange = (selected) => {
    setPodSelected(selected);
    populateFilterData(
      selected,
      goalSelected,
      releaseSelected,
      featureSelected,
      teamSelected,
    );
  };

  const handleGoalChange = (selected) => {
    setGoalSelected(selected);
    populateFilterData(
      podSelected,
      selected,
      releaseSelected,
      featureSelected,
      teamSelected,
    );
  };

  const handleReleaseChange = (selected) => {
    setReleaseSelected(selected);
    populateFilterData(
      podSelected,
      goalSelected,
      selected,
      featureSelected,
      teamSelected,
    );
  };

  const handleFeatureChange = (selected) => {
    setFeatureSelected(selected);
    populateFilterData(
      podSelected,
      goalSelected,
      releaseSelected,
      selected,
      teamSelected,
    );
  };

  const handleTeamChange = (selected) => {
    setTeamSelected(selected);
    populateFilterData(
      podSelected,
      goalSelected,
      releaseSelected,
      featureSelected,
      selected,
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-8">
          <div className="row">
            <div className="col-lg-4">
              <label>
                <b>POD</b>
              </label>
              <br />
              <Select
                value={podSelected}
                isMulti
                options={podOptions}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(option) => handlePodChange(option)}
                isClearable={true}
                allowSelectAll={true}
                isLoading={podLoading}
                menuPortalTarget={document.body}
              />
            </div>
            <div className="col-lg-4">
              <label>
                <b>Goal</b>
              </label>
              <br />
              <Select
                value={goalSelected}
                isMulti
                options={goalOptions}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(option) => handleGoalChange(option)}
                isClearable={true}
                allowSelectAll={true}
                isLoading={goalLoading}
                menuPortalTarget={document.body}
              />
            </div>
            <div className="col-lg-4">
              <label>
                <b>Team</b>
              </label>
              <br />
              <Select
                value={teamSelected}
                isMulti
                options={teamOptions}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(option) => handleTeamChange(option)}
                isClearable={true}
                allowSelectAll={true}
                isLoading={teamLoading}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <label>
                <b>Feature</b>
              </label>
              <br />
              <Select
                value={featureSelected}
                isMulti
                options={featureOptions}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(option) => handleFeatureChange(option)}
                isClearable={true}
                allowSelectAll={true}
                isLoading={featureLoading}
                menuPortalTarget={document.body}
              />
            </div>
            <div className="col-lg-6">
              <label>
                <b>Release</b>
              </label>
              <br />
              <Select
                value={releaseSelected}
                isMulti
                options={releaseOptions}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(option) => handleReleaseChange(option)}
                isClearable={true}
                allowSelectAll={true}
                isLoading={releaseLoading}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
        </div>
        <div className="col-4">
          <br />
          <div className="d-flex align-items-end flex-column">
            <div>
              <p className={Style.pstyle}>
                Last Aha Refresh: {ahaRefreshDate}
              </p>
              <p className={Style.pstyle2}>
                Last Jira Refresh: {jiraRefreshDate}
              </p>
            </div>
            <br />
            <div>
              <ExportExcel
                fileName={
                  `FeatureHealthGrouping_` +
                  moment(new Date()).format("MM-DD-YYYY-hh-mm-A")
                }
                buttonName="Export Data"
                sheetName={`FeatureHealthGrouping`}
                sheetData={filteredQueryResult}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div>
        <Loadable loaded={loadedFilter}>
          {filteredQueryResult.length > 0 ? (
            <ResultByQueryTable
              objData={filteredQueryResult}
              selectionList={selectionList}
              toggleSortOrder={toggleSortOrder}
            />
          ) : (
            <div>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>No Data to Display</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Loadable>
        {show && (
          <UserSelectionDropDown
            show={show}
            setshow={setShow}
            attributeArray={distinctAttributeValues}
            originalData={queryResult}
            filterName={filterName}
            filterFunction={setFilteredQueryResult}
          />
        )}
      </div>
    </div>
  );
}

export default FeatureHealthDashboard;
