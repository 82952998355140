import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import teamService from '../../services/teamService';
import systemService from '../../services/systemService';
import { RefreshableMetric } from '../../components/RefreshableMetric';

import Conditional from '../../components/Conditional';

export class Teams extends Component {
  static displayName = Teams.name;

  constructor(props) {
      super(props);
      this.state = { teams: [], loading: true, metric: {}, boardStatus: {}, ahaJiraRefreshInprogress: false };

    this.addTeam = this.addTeam.bind(this);
    this.editTeam = this.editTeam.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.refreshSystem = this.refreshSystem.bind(this);
    this.refreshAhaAndJira = this.refreshAhaAndJira.bind(this);
  }

  componentDidMount() {
    this.populateTeams();
    this.populateSystemMetric();
  }

  async populateTeams() {
    const data = await teamService.getAll();
    this.populateBoards(data);
    this.setState({ teams: data, loading: false });
  }

  async populateBoards(teams) {
    var results = await Promise.all(teams.map(t => this.getBoardStatus(t.id)));
    var boardStatus = {};
    results.forEach(r => (boardStatus[r.id] = r.status));

    this.setState({ boardStatus });
  }

  async getBoardStatus(teamId) {
    var status = await teamService.checkTeamBoard(teamId);
    return { id: teamId, status };
  }

  async populateSystemMetric() {
    var systemMetric = await systemService.get();
    this.setState({ metric: systemMetric });
  }

  addTeam() {
    this.props.history.push('/teams/modify');
  }

  editTeam(teamId) {
    this.props.history.push(`/teams/modify/${teamId}`);
  }

  async deleteTeam(teamId) {
    await fetch(`team/${teamId}`, { method: 'DELETE' });
    await this.populateTeams();
  }

  async confirmDelete(teamId) {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this team?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.deleteTeam(teamId);
            this.setState({ loading: true });
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

    async refreshAhaAndJira() {
        this.setState({ ahaJiraRefreshInprogress: true });
        var result = await systemService.ahaAndJiraRefresh();
        if (result.status === 202) {
            this.setState({ ahaJiraRefreshInprogress: false });
        }
    }

  async refreshSystem(hardRefresh) {
    await systemService.refresh(hardRefresh === true);
    await this.populateSystemMetric();
  }

  static renderTeams(
    teams,
    addTeam,
    editTeam,
    deleteTeam,
    refreshSystem,
    metric,
    boardStatus,
    refreshAhaAndJira,
    ahaJiraRefreshInprogress
  ) {
    var renderBoardStatus = (boardStatus, teamId) => {
      if (!(teamId in boardStatus)) return "Unknown";

      return boardStatus[teamId] ? "Yes" : "No";
    };

    return (
        <div>
        <button style={{ marginBottom: '15px' }} className='btn btn-primary' onClick={refreshAhaAndJira} disabled={ahaJiraRefreshInprogress}>
          Refresh Aha & Jira
        </button>
        <RefreshableMetric
          metric={metric}
          refresh={refreshSystem}
          type={'System'}
        />
        <button className='btn btn-primary' onClick={addTeam}>
          Add Team
        </button>
        <table className='table table-striped' aria-labelledby='tabelLabel'>
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>RapidBoardId</th>
              <th>GitPrimeId</th>
              <th>Workstream</th>
              <th>Board Exists?</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {teams.map(team => (
              <tr key={team.id}>
                <td>{team.id}</td>
                <td>{team.name}</td>
                <td>{team.rapidBoardId}</td>
                <td>{team.gitPrimeId}</td>
                <td>{team.workstreamName}</td>
                <td>{renderBoardStatus(boardStatus, team.id)}</td>
                <td>
                  <button
                    className='btn btn-primary'
                    onClick={() => editTeam(team.id)}
                  >
                    Modify
                  </button>
                  <button
                    className='btn btn-danger'
                    onClick={() => deleteTeam(team.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <div>
        <h1 id='tabelLabel'>Teams</h1>
        <p>Tracked Teams</p>
        <Conditional if={this.state.loading}>
          <p>
            <em>Loading...</em>
          </p>
        </Conditional>
        <Conditional if={this.state.loading === false}>
          {Teams.renderTeams(
            this.state.teams,
            this.addTeam,
            this.editTeam,
            this.confirmDelete,
            this.refreshSystem,
            this.state.metric,
            this.state.boardStatus,
            this.refreshAhaAndJira,
            this.state.ahaJiraRefreshInprogress
          )}
        </Conditional>
      </div>
    );
  }
}
