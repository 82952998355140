export const postAssignmentGroupForm = async (assignmentObj) => {
    var response = await fetch(
        `ManualServiceNowEntry/CreateAssignmentGroup`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(assignmentObj),
        }
    )
        .then((res) => {
            if (res.status !== 200) throw new Error(res.status);
            else return res;
        })
        .catch((error) => {
            return "error";
        });
    return response;
};

export const postIncidentStateMappingForm = async (IncidentObj) => {
    const response = await fetch(
        `ManualServiceNowEntry/CreateIncidentStateMapping`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(IncidentObj),
        })
        .then((res) => {
            if (res.status !== 200) throw new Error(res.status);
            else return res;
        })
        .catch((error) => {
            return "error";
        });
    return response;
};

export const postUserForm = async (userObj) => {
    const response = await fetch(
        `ManualServiceNowEntry/CreateUser`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userObj),
        })
        .then((res) => {
            if (res.status !== 200) throw new Error(res.status);
            else return res;
        })
        .catch((error) => {
            return "error";
        });
    return response;
};

export default {
    postAssignmentGroupForm,
    postIncidentStateMappingForm,
    postUserForm
}