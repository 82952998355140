import React, { useState, useEffect } from 'react';
import capexService from '../../services/capexDashboardService';
import classnames from 'classnames';
import { Loadable } from '../../components/Loadable';
import ExportExcel from '../../components/ExportExcel';
import { Col, Nav, NavItem, Button, NavLink, Row, TabContent, TabPane, DropdownMenu, UncontrolledDropdown, DropdownToggle, DropdownItem, Dropdown } from 'reactstrap';
import CapexMonthlyGroupData from '../../components/capex/CapexMonthlyGroupData';
import CapexMonthlyData from '../../components/capex/CapexMonthlyData';

const TotalTab = '1';
const RecordsTab = '2';
function CapExDashboard(props) {

    const Month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const d = new Date();
    const month = d.getMonth();
    const year = d.getFullYear();

    const [activeTab, setTab] = useState(TotalTab);
    const [selectedMonth, setMonth] = useState(Month[month])
    const [selectedYear, setYear] = useState(year)
    const [capextotalData, setCapexTotalData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [historicMonth, sethistoricMonth] = useState([]);
    const [historicYear, sethistoricYear] = useState([]);
    const [display, setDisplay] = useState(false);
    const [refreshDate, setRefreshDate] = useState('');

    const Year = Array.from(new Array(year + 1 - 2020), (val, index) => year - index);

    useEffect(() => {
        loadData();
        applyFilter();
    }, []);

  



    const loadData = async () => {

        
        // to get hitoric data
        const currentUser = props.claims.rawClaims.Id;
        let historic = await capexService.getCapexHistoricReport(currentUser);
        historic.forEach(element => {
            sethistoricMonth(historicMonth => [...historicMonth, new Date(element.closedMonth).getMonth()]);
            if (!historicYear.includes(new Date(element.closedMonth).getFullYear())) {
                sethistoricYear(historicYear => [...historicYear, new Date(element.closedMonth).getFullYear()]);
            }
        });
        let responseDate = await fetch(`ProductDashboard/GetDataUpdateDate`);     
        let dateString = await responseDate.text();
        let date = new Date(dateString + ' UTC');
        setRefreshDate(date.toLocaleString());
        setDisplay(true);
    
    }

    const getGroupData = async () => {
        let totaldata = await capexService.getCapexGroupData(Month.indexOf(selectedMonth) + 1, selectedYear);
        setCapexTotalData(totaldata);
    }

    const monthDropDownItem = (item, index) => {

        return (
            <DropdownItem key={index} onClick={() => { setMonth(item); }}>
                {item.toUpperCase()}
            </DropdownItem>
        );
    }


    const yearDropDownItem = (item, index) => {

        return (<DropdownItem key={index} onClick={() => { setYear(item); }} >
            {item}
        </DropdownItem>
        );
    }

    const markHistoric = () => {
        let res = window.confirm("Are you sure you want to mark this as historic?");
        if (res == true) {
            const currentUser = props.claims.rawClaims.Id;
            let month = Month.indexOf(selectedMonth) + 1;
            capexService.postHistoricDate(month, selectedYear, currentUser);
            sethistoricMonth(historicMonth => [...historicMonth, Month.indexOf(selectedMonth)]);
            if (!historicYear.includes(selectedYear)) {
                sethistoricYear(historicYear => [...historicYear, selectedYear]);
            }
        }
    }

    const getMonthReport= async ()=>{
        let monthData= await capexService.getCapexDataByMonth(Month.indexOf(selectedMonth)+1,selectedYear);
        setFilterData(monthData);
    }

    const applyFilter = () => {

        getMonthReport()
        getGroupData();
    }
    return (

        <Loadable loaded={display}>
            <section>
                <div>
                    <Row>
                        <Col sm='8'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <UncontrolledDropdown >
                                                <DropdownToggle caret style={{ width: '130px', textAlign: 'left' }}>
                                                    <span>{'Select Month'}</span>
                                                    <i className="fa fa-angle-down" aria-hidden="true" style={{ position: 'absolute', top: '30%', right: '5%' }}></i>
                                                </DropdownToggle>
                                                <DropdownMenu style={{ width: '100%' }}>
                                                    {Month.map(monthDropDownItem)}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </th>
                                        <th>
                                            <UncontrolledDropdown >
                                                <DropdownToggle caret style={{ width: '130px', textAlign: 'left' }}>
                                                    <span>{'Select Year'}</span>
                                                    <i className="fa fa-angle-down" aria-hidden="true" style={{ position: 'absolute', top: '30%', right: '5%' }}></i>
                                                </DropdownToggle>
                                                <DropdownMenu style={{ width: '100%' }}>
                                                    {Year.map(yearDropDownItem)}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </th>
                                        <th>
                                            <Button color='success' onClick={() => applyFilter()} style={{ paddingTop: '3px', margin: '6px' }} disabled={(selectedYear==''||selectedMonth=='')?true:false}>
                                                Show Report
                                   </Button>
                                        </th>
                                        <th>{
                                            (!(selectedYear == year && Month.indexOf(selectedMonth) == month) &&
                                              !((selectedYear == year && Month.indexOf(selectedMonth) > month))&&
                                                (!(historicYear.includes(selectedYear) &&
                                                    historicMonth.includes(Month.indexOf(selectedMonth))))) &&
                                            <Button color="primary" onClick={() => markHistoric()} >
                                                Mark Historic Month
                                                </Button>
                                            }
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {selectedMonth &&
                                                <Button size="sm" outline color='primary' onClick={() => setMonth('')} style={{ borderRadius: '10%', paddingTop: '3px', paddingRight: '6px', margin: '6px' }}>
                                                    {selectedMonth.toUpperCase()}<i className="fa fa-times-circle" style={{ paddingLeft: '5px' }}></i>
                                                </Button>
                                            }
                                        </td>
                                        <td>
                                            {selectedYear &&
                                                <Button size="sm" outline color='primary' onClick={() => setYear('')} style={{ borderRadius: '10%', paddingTop: '3px', paddingRight: '6px', margin: '6px' }}>
                                                    {selectedYear}<i className="fa fa-times-circle" style={{ paddingLeft: '5px' }}></i>
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col sm='4' style={{ textAlign: "right" }}>
                            <ExportExcel sheetData={filterData} sheetData2={capextotalData} sheetName="CapexReport" sheetName2="Total" fileName={"CapexReport_" + selectedMonth+"_" + selectedYear} buttonName="Export CapexReport" />
                            <div>
                                Last Refresh:{refreshDate}
                           </div>
                        </Col>
                    </Row>
                </div>
                <div>


                </div>
                <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === TotalTab })}
                                onClick={() => { setTab(TotalTab); }}>Total</NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === RecordsTab })}
                                onClick={() => { setTab(RecordsTab); }}>Records</NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={TotalTab}>
                            &nbsp;
                           <CapexMonthlyGroupData capextotalData={capextotalData} />
                        </TabPane>

                        <TabPane tabId={RecordsTab}>
                            &nbsp;
                            <CapexMonthlyData filterData={filterData} claims={props.claims} />
                        </TabPane>

                    </TabContent>
                </div>


            </section>
        </Loadable>
    );

}

export default CapExDashboard;