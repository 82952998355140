import React, { Component } from 'react';
import { Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, FormText } from 'reactstrap';

import memberService from '../services/memberService';
import teamService from '../services/teamService';

import { SearchDropDown } from './SearchDropDown';

export class EditTeamModal extends Component {
    static displayName = EditTeamModal.name;

    constructor(props) {
        super(props);

        this.state = { 
            editNote: '',
            sourceMember: null,
            destinationTeam: null,
            addMember: null,
            removeMember: null,
            moveIsValid: false,
            addIsValid: false,
            removeIsValid: false
        };

        this.handleEditNoteChange = this.handleEditNoteChange.bind(this);

        this.setDestination = this.setDestination.bind(this);
        this.setSourceMember = this.setSourceMember.bind(this);
        this.setAddMember = this.setAddMember.bind(this);
        this.setRemoveMember = this.setRemoveMember.bind(this);

        this.updateValidity = this.updateValidity.bind(this);
        this.setMoveValidity = this.setMoveValidity.bind(this);
        this.setAddValidity = this.setAddValidity.bind(this);
        this.setRemoveValidity = this.setRemoveValidity.bind(this);
        
        this.commitMove = this.commitMove.bind(this);
        this.addMember = this.addMember.bind(this);
        this.removeMember = this.removeMember.bind(this);
    }

    handleEditNoteChange(event) {

        const editNote = event.target.value;

        this.setState({ editNote: editNote }, () => this.updateValidity());
    }
    
    setDestination(team) {
        this.setState({ destinationTeam: team}, () => this.updateValidity());
    }

    setSourceMember(member) {
        this.setState({ sourceMember: member}, () => this.updateValidity());
    }

    setAddMember(member) {
        this.setState({ addMember: member}, () => this.updateValidity());
    }

    setRemoveMember(member) {
        this.setState({ removeMember: member}, () => this.updateValidity());
    }

    updateValidity() {
        this.setMoveValidity();
        this.setAddValidity();
        this.setRemoveValidity();
    }

    setMoveValidity() {

        const moveIsValid = this.state.editNote !== ''
            && this.state.sourceMember !== null
            && this.state.destinationTeam !== null;
        
        this.setState({ moveIsValid });

    }

    setAddValidity() {

        const addIsValid = this.state.editNote !== ''
            && this.state.addMember !== null
        
        this.setState({ addIsValid });

    }

    setRemoveValidity() {

        const removeIsValid = this.state.editNote !== ''
            && this.state.removeMember !== null
        
        this.setState({ removeIsValid });

    }

    async commitMove() {
        await teamService.moveMember(this.props.teamId, this.state.destinationTeam.id, this.state.sourceMember.id, this.state.editNote);
        this.props.close(true);
    }

    async addMember() {
        await teamService.addMember(this.props.teamId, this.state.addMember.id, this.state.editNote);
        this.props.close(true);
    }

    async removeMember() {
        await teamService.removeMember(this.props.teamId, this.state.removeMember.id, this.state.editNote);
        this.props.close(true);
    }

    render() {
        return (<Modal size="xl" isOpen={this.props.isOpen} toggle={() => this.props.close(false)}>
            <ModalHeader toggle={() => this.props.close(false)}>Edit Team Membership</ModalHeader>
            <ModalBody>
                <Row className="edit-team-modal-note">
                    <Col lg="12">
                        <Label for="editNote">Edit Note</Label>
                        <Input 
                            invalid={this.state.editNote === ''} 
                            type="textarea" 
                            name="editNote" 
                            id="editNote" 
                            placeholder="Explaination of change..."
                            onChange={this.handleEditNoteChange}
                            value={this.state.editNote} />
                        <FormFeedback tooltip>Note is required</FormFeedback>
                        <FormText>Please fill out reason for this change</FormText>
                    </Col>
                </Row>
                <Row>
                    <Col lg="1">
                        Move Member
                    </Col>
                    <Col lg="4">
                        <SearchDropDown
                            onSelection={this.setSourceMember} 
                            display={x => x.preferredName}
                            title="Select Member"
                            initialSet={() => this.props.teamMembers}
                            max={20}
                        />
                    </Col>
                    <Col lg="1">
                        To Team
                    </Col>
                    <Col lg="4">
                        <SearchDropDown 
                            onSelection={this.setDestination} 
                            display={x => x.name}
                            title="Select Team"
                            initialSet={teamService.getAll}
                            max={20}
                        />
                    </Col>
                    <Col lg="2">
                        <Button color="warning" onClick={this.commitMove} disabled={!this.state.moveIsValid}>Commit Move</Button>
                    </Col>
                </Row>
                <Row>
                    <Col lg="1">
                        Add Member
                    </Col>
                    <Col lg="4">
                        <SearchDropDown 
                            onSelection={this.setAddMember}
                            display={x => x.preferredName}
                            title="Select Member"
                            initialSet={memberService.getAll}
                            max={10}
                        />
                    </Col>
                    <Col lg={{ size: 2, offset: 5}}>
                        <Button color="warning" onClick={this.addMember} disabled={!this.state.addIsValid}>Commit Add</Button>
                    </Col>
                </Row>
                <Row>
                    <Col lg="1">
                        Remove Member
                    </Col>
                    <Col lg="4">
                        <SearchDropDown
                            onSelection={this.setRemoveMember} 
                            display={x => x.preferredName}
                            title="Select Member"
                            initialSet={() => this.props.teamMembers}
                            max={20}
                        />
                    </Col>
                    <Col lg={{ size: 2, offset: 5}}>
                        <Button color="warning" onClick={this.removeMember} disabled={!this.state.removeIsValid}>Commit Remove</Button>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => this.props.close(false)}>Done</Button>
            </ModalFooter>
        </Modal>);
    }

}
