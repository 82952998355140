import React, { Component } from 'react';
import { Row, Col, Button, ButtonGroup  } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import memberService from '../../services/memberService';
import Conditional from '../../components/Conditional';
import Toggle from 'react-toggle';
import "react-toggle/style.css";

export class Members extends Component {
    static displayName = Members.name;

    constructor(props) {
        super(props);
        this.state = { 
            members: [], 
            loading: true,
            linkedDisplay: 1 ,
            termedResources:true
        };
        
        this.addmember = this.addmember.bind(this);
        this.editmember = this.editmember.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.impersonate = this.impersonate.bind(this);
        this.setLinkedDisplay = this.setLinkedDisplay.bind(this);

    }

    componentDidMount() {
        this.loadmembers();
    }

    async loadmembers(linkedFilter) {
        this.setState({ loading: true });
        const data = await memberService.getAll(linkedFilter);
        this.setState({ members: data, loading: false });
    }

    addmember() {
        this.props.history.push('/members/modify');
    }

    editmember(memberId) {
        this.props.history.push(`/members/modify/${memberId}`);
    }

    async impersonate(memberId) {

        var response = await fetch(`/token/impersonate/${memberId}`);
        var data = await response.json()
        window.location = `#app_token=${data.jwtToken}`;
    }

    async deletemember(memberId) {
        await fetch(`/member/${memberId}`, { method: 'DELETE' });
        await this.loadmembers();
    }

    async confirmDelete(memberId) {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want to delete this member?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        this.setState({ loading: true });
                        this.deletemember(memberId);
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    setLinkedDisplay(displayMode) {
        this.setState({linkedDisplay: displayMode});

        if (displayMode === 0) {
            this.loadmembers(false);
        }
        if (displayMode === 1) {
            this.loadmembers();
        }
        if (displayMode === 2) {
            this.loadmembers(true);
        }

    }

    termedResourcesOnly=()=> this.setState({termedResources: !this.state.termedResources})

    render() {

        return (
          <div>
            <h1 id="tabelLabel">Members</h1>
            <p>Tracked members</p>
            <div>
              <Conditional if={this.state.loading}>
                <p>
                  <em>Loading...</em>
                </p>
              </Conditional>
              <Conditional if={this.state.loading === false}>
                <Row>
                  <Col sm="12">
                    <Button color="primary" onClick={this.addmember}>
                      Add member
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col sm="2">Linked Status</Col>
                  <Col sm="10">
                    <ButtonGroup>
                      <Button
                        outline={this.state.linkedDisplay !== 0}
                                        color={this.state.linkedDisplay === 0 ? "primary" : "secondary"}
                                        onClick={() => this.setLinkedDisplay(0)}>
                        Unlinked
                      </Button>
                      <Button
                        outline={this.state.linkedDisplay !== 1}
                                        color={this.state.linkedDisplay === 1 ? "primary" : "secondary"}
                                        onClick={() => this.setLinkedDisplay(1)}>
                        Show all
                      </Button>
                      <Button
                        outline={this.state.linkedDisplay !== 2}
                                        color={this.state.linkedDisplay === 2 ? "primary" : "secondary"}
                                        onClick={() => this.setLinkedDisplay(2)}>
                        Fully Linked
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Row style={{paddingTop:'0.5rem'}}>
                  <Col sm="2">Termed Resources</Col>
                  <Col sm="10">
                    <Toggle
                      defaultChecked={this.state.termedResources}
                      onChange={this.termedResourcesOnly}
                    />
                  </Col>
                </Row>

                <table
                  className="table table-striped"
                  aria-labelledby="tabelLabel"
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Primary</th>
                      <th>On Teams</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.members.map((member) =>
                      this.renderMember(member)
                    )}
                  </tbody>
                </table>
              </Conditional>
            </div>
          </div>
        );
    }

    renderMember(member) {
        var showTermedResources= (member.endOn)?false:true;
        if(!this.state.termedResources){
            showTermedResources=true;
        }
        return (showTermedResources) && <tr key={member.id}>
            <td>{member.id}</td>
            <td>{member.preferredName}</td>
            <td>{member.role}</td>
            <td>{member.primaryFunction}</td>
            <td>
                <ul>
                    {member.teams.map(team => <li key={team.id}>{team.name}</li>
                    )}
                </ul>
            </td>
            <td>
                <button className="btn btn-primary" onClick={() => this.editmember(member.id)}>Modify</button>
                <Conditional if={this.props.claims.rawClaims.Id !== member.id}>
                    <button className="btn btn-warning" onClick={() => this.impersonate(member.id)}>Impersonate</button>
                </Conditional>
                <button className="btn btn-danger" onClick={() => this.confirmDelete(member.id)}>Delete</button>
            </td>
        </tr>;
    }
}
