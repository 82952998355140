export const GetGoalOptionsData = async () => {
    const response = await fetch(`DeliveryReportInitiative/GetAllGoals`);
    return await response.json();
}

export const GetGridData = async () => {
    const response = await fetch(`DeliveryReportInitiative/GetAllInitiatives`);
    return await response.json();
}

export const PostIntiativeGoalsData = async (initiativeGoalData) => {
    const response = await fetch(
        `DeliveryReportInitiative/UpdateDeliveryInitiatives`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(initiativeGoalData),
    }).then((res) => {
        if (res.status !== 200) throw new Error(res.status);
        else return res;
    })
        .catch((error) => {
            return "error";
        });

    return response;
};

export default {
    GetGoalOptionsData,
    GetGridData,
    PostIntiativeGoalsData
}