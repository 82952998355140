import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import common from '../../components/common';
import teamService from '../../services/teamService'


import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, ButtonGroup } from 'reactstrap';

class ManageTeamEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: this.props.editing,
            teamEvent: { ...this.props.teamEvent }
        }

        this.handleInputChange = common.createInputHandler(this, 'teamEvent');
    }

    save = async () => {
        var { teamEvent } = this.state;
        var { teamId, refresh } = this.props;

        var isNew = teamEvent.id == null;

        teamEvent = await teamService.mergeTeamEvent(teamId, teamEvent);
        refresh();
        if (isNew)
            teamEvent = { id:null, name: '', description: '' };

        this.setState({ teamEvent, editing: isNew });
    }

    cancel = () => {
        this.setState({ teamEvent: { ...this.props.teamEvent }, editing: false })
    }

    edit = () => {
        this.setState({ editing: true });
    }

    delete = () => {
        var { teamEvent } = this.state;
        var { teamId, show, hide, refresh } = this.props;

        hide();
        confirmAlert({
            title: `Delete ${teamEvent.name} Event?`,
            message: 'Are you sure you want to delete this event?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await teamService.deleteTeamEvent(teamId, teamEvent);
                        refresh();
                        show();
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        show();
                    }
                }
            ]
        });
    }

    editor(e) {
        return <tr>
            <td><Input type="text" name="name" value={e.name} onChange={this.handleInputChange}/></td>
            <td><Input type="text" name="description" multiple={true} value={e.description} onChange={this.handleInputChange}/></td>
            <td>
                <ButtonGroup>
                    <Button outline={true} color="primary" onClick={this.save}>{e.id ? "Save" : "Add"}</Button>
                    {e.id && <Button outline={true} color="primary" onClick={this.cancel}>Cancel</Button>}
                </ButtonGroup>
            </td>
        </tr>
    }

    readonly(e) {
        return <tr>
            <td>{e.name}</td>
            <td>{e.description}</td>
            <td>
                <ButtonGroup>
                    <Button outline={true} color="primary" onClick={this.edit}>Edit</Button>
                    <Button outline={true} color="danger" onClick={this.delete}>Delete</Button>
                </ButtonGroup>
            </td>
        </tr>
    }
        
    render() {
        var { teamEvent } = this.state;
        return this.state.editing ? this.editor(teamEvent) : this.readonly(teamEvent);    
    }
}

export class ManageTeamEventsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleting: false
        }
    }

    hide = () => {
        this.setState({ deleting: true });
    }

    show = () => {
        this.setState({ deleting: false });
    }

    render() {
        var { team, close, isOpen, refresh } = this.props;
        var { deleting } = this.state;

        return <Modal isOpen={isOpen && !deleting}>
            <ModalHeader>Manage {team.name} Events</ModalHeader>
            <ModalBody>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {team && team.events && team.events.map(e => <ManageTeamEvents refresh={refresh} hide={this.hide} show={this.show} key={e.id} teamId={team.id} teamEvent={e} />)}
                        <ManageTeamEvents refresh={refresh} hide={this.hide} show={this.show} editing={true} teamId={team.id} teamEvent={{id: null, name:'', description: ''}} />
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter>
                <Button outline onClick={close}>Done</Button>
            </ModalFooter>
        </Modal>
    }
}