import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import PODDetail from "./PODDetail.js";
import PODService from "../../services/PODService.js";
import { Loadable } from "../../components/Loadable.js";
import Style from './RnDResourceChart.module.css';

const animatedComponents = makeAnimated();

function RnDResourceChart(props) {

  const [clusterOptions, setClusterOptions] = useState([]);
  const [clusterGridData, setClusterGridData] = useState([]);
  const [isLoadingLine, setIsLoadingLine] = useState(false);
  const [selectedCluster, setSelectedCluster] = useState([]);
  const [podLeads, setPodLeads] = useState([]);
  const [show, setShow] = useState(false);
  const [podDetails, setPODDetails] = useState();
  const [isLoadingCircle, setIsLoadingCircle] = useState(false);


  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoadingLine(false);
    loadClusterOptions();
    setClusterGridData({ ...getDefaultClusterGridState() });
    setIsLoadingLine(true);
  };

  const getDefaultClusterGridState = () => ({
    clusterGridData: [],
  });

  const loadClusterOptions = async () => {

    let clusterServiceData = await PODService.GetClusterOptionsData();
    let clusterData = clusterServiceData.map(function (obj) {
      return { value: obj.id, label: obj.clusterName };
    });

    setClusterOptions(clusterData);
    handleSelectClusterOption([clusterData[1]]);
  };

  const viewDetails = (pod) => {
    setShow(true);
    setPODDetails(pod);
  };

  const handleSelectClusterOption = async (selectedOption) => {
    setIsLoadingCircle(true);
    let selectedOptions = selectedOption.map(function (newitem) {
      return { value: newitem.value, label: newitem.label };
    });

    let existingSelectedOptions = [...selectedCluster];
    let newItem = selectedOptions.filter(function (objSelectedOptions) {
      return !existingSelectedOptions.find(function (objExistingSelectedOptions) {
        return objSelectedOptions.value === objExistingSelectedOptions.value
      })
    })

    let removedItem;
    if (newItem.length === 0) {
      removedItem = existingSelectedOptions.filter(function (objExistingSelectedOptions) {
        return !selectedOptions.find(function (objSelectedOptions) {
          return objSelectedOptions.value === objExistingSelectedOptions.value
        })
      });


    }

    setSelectedCluster(selectedOptions);

    let currentClusterGrid = [...clusterGridData];
    if (newItem.length > 0) {
      let jsonData = await PODService.GetClusterDetail(newItem[0].value);
      currentClusterGrid.push(jsonData);
      setClusterGridData({ ...getDefaultClusterGridState() });
      setClusterGridData(currentClusterGrid);
    }
    else {
      if (removedItem && removedItem.length > 0) {
        let remainingObjects = currentClusterGrid.filter(obj => obj.clusterId !== removedItem[0].value);
        setClusterGridData({ ...getDefaultClusterGridState() });
        setClusterGridData(remainingObjects);
      }
    }
    setIsLoadingCircle(false);
  };

  return (
    <div>
      <Loadable loaded={isLoadingLine}>
        <Row className="align-items-center">
          <Col-1>
            <div style={{ width: "100%", marginLeft: "15%" }}>
              <h6>Cluster Name: </h6>
            </div>
          </Col-1>
          <Col>
            <div style={{ width: "99%", marginLeft: "1%" }}>
              <Select
                isMulti
                name="Cluster"
                value={selectedCluster}
                options={clusterOptions}
                onChange={(option) => handleSelectClusterOption(option)}
                closeMenuOnSelect={false}
                isClearable={false}
                hideSelectedOptions={false}
                components={{ animatedComponents }}
                isDisabled={isLoadingCircle}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", marginTop: "3px" }}>
          {isLoadingCircle && (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </Row>
        <Row>
          {selectedCluster && selectedCluster.length > 0 &&
            selectedCluster.map((x, index) => {
              return clusterGridData.length > 0 && clusterGridData.map((cd) => {
                return (cd && cd.clusterId === x.value &&
                  <Col key={index}>
                    <div
                      style={{
                        backgroundColor: "#8ea9db",
                        borderRadius: "5px",
                        padding: "10px",
                        textAlign: "center",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <div>
                        <h4>{cd && cd.clusterName}</h4>
                        <h6>
                          {cd.clusterLeads && cd.clusterLeads.map((l, index) => {
                            if (index == cd.clusterLeads.length - 1)
                              return l.name;
                            return l.name + " & ";
                          })}
                        </h6>
                      </div>
                    </div>
                    <br />
                    <Row
                      style={{
                        marginTop: "10px", justifyContent: "space-evenly",
                        flexFlow: "row wrap", marginLeft: "0px", marginRight: "0px"
                      }}
                    >
                      {cd.poDs && cd.poDs.map((data, index) => (
                        <Card bg="light" key={index} className={Style.Card}>
                          <Card.Body>
                            <Card.Title>POD Name: {data.podName}</Card.Title>
                            <Card.Text>
                              POD Leads: {" "} 
                              {data.podLeads.map((lead, index) => {
                                if (index === data.podLeads.length - 1)
                                  return lead.podLeadName;
                                else return lead.podLeadName + ", ";
                              })}
                            </Card.Text>
                            <Button variant="info" onClick={() => viewDetails(data)}>
                              View Details
                            </Button>
                          </Card.Body>
                        </Card>
                      ))}
                    </Row>
                    {show && (
                      <PODDetail
                        show={show}
                        setShow={setShow}
                        podDetail={podDetails}
                      />
                    )}
                  </Col>
                )
              })

            })
          }
        </Row>
      </Loadable>
    </div>
  );
}

export default RnDResourceChart;
