import React, { Component } from 'react';
import { Button } from 'reactstrap';
import departmentService from '../../services/departmentService';
import common from '../../components/common';
import Conditional from '../../components/Conditional';
import { SearchDropDown } from '../../components/SearchDropDown';
import memberService from '../../services/memberService';

export class ModifyDepartment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            department: {
                id: 0,
                name: '',
                managerId: 0,
                parentDepartmentId: 0
            },
            selectedManager: {},
            selectedParent: {},
        };

        this.handleInputChange = common.createInputHandler(this, 'department');     
        this.changeManager = common.createSearchDropDownHandelChange(this, 'department', 'selectedManager', 'managerId');
        this.changeParent = common.createSearchDropDownHandelChange(this, 'department', 'selectedParent', 'parentDepartmentId');

    }

    componentDidMount() {
        var departmentId = this.props.match.params.id;

        if (departmentId != null) {
            this.loadDepartment(departmentId);
        }
    }

    async loadDepartment(departmentId) {        
        let data = await departmentService.getDepartment(departmentId);
        this.setState({ department: data });
    }

    handleSubmit = async () => {
        let url = `departments`, method;
        if (this.state.department.id === 0) {
            method = 'POST';
        }
        else {
            url += `/${this.state.department.id}`;
            method = 'PUT'
        }

        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.department)
        });
        await response.status;
        this.props.history.push('/departments');
    }

    render() {
        return (
            <div>

                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <tbody>
                        <Conditional if={this.state.department.id !== 0}>
                            <tr>
                                <td>Id</td>
                                <td>{this.state.department.id}</td>
                            </tr>
                        </Conditional>
                        <tr>
                            <td style={{ width: '200px' }}> Name</td>
                            <td>
                                <input name="name" type="text" value={this.state.department.name || ''} onChange={this.handleInputChange} />
                            </td>
                        </tr>

                        <tr>
                            <td>Manager</td>
                            <td>

                                <SearchDropDown
                                    onSelection={this.changeManager}
                                    display={x => x.preferredName}
                                    title="Select Manager"
                                    initialSet={memberService.getAll}
                                    initialSelectionId={this.state.department.managerId}
                                    max={20}
                                />

                            </td>
                        </tr>
                        <tr>
                            <td>Parent Department</td>
                            <td>
                                { this.state.department.parentDepartmentName &&<SearchDropDown
                                        onSelection={this.changeParent}
                                        display={x => `${x.name}`}
                                        title="Select Department"
                                        initialSet={departmentService.getAll}
                                        initialSelectionId={this.state.department.parentDepartmentId}
                                    />
                                }

                                <Conditional if={this.state.department.id === 0}>
                                    <SearchDropDown
                                        onSelection={this.changeParent}
                                        display={x => `${x.name}`}
                                        title="Select Department"
                                        initialSet={departmentService.getAll}
                                        initialSelectionId={this.state.department.parentDepartmentId}
                                    />
                                </Conditional>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Button color="primary" onClick={this.handleSubmit}>Save Department</Button>
            </div >
        );
    }
}