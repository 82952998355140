export const getAll = async () => {

    const response = await fetch(`departments`);
    return await response.json();
};
export const getDepartment = async (departmentId) => {
    const response = await fetch(`departments/${departmentId}`);
    return await response.json();
}


export const removeMember = async (fromTeam, member, note) => {

    await fetch(`team/${fromTeam}/members/${member}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            from: fromTeam,
            memberId: member,
            note: note
        })
    });
};

export const getChildDepartment = async (departmentId) => {
    const response = await fetch(`departments/${departmentId}/departments`);
    return await response.json();
}



export default {
    getAll,
    getDepartment,
    getChildDepartment    
};
